import { addMonths, isAfter, isBefore } from 'date-fns'
import { useCallback, useLayoutEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import { translate } from '../../i18n'
import { getNowTrimedTime } from '../../utils/dateUtil'
import {
  datePropsToNumber,
  nullPropsToUndefined,
  numberPropsToDate,
  undefinedPropsToNull,
} from '../../utils/objectUtil'
import { setFormErrors } from '../../utils/reactUtil'
import { blankToNull, splitRemoveBlank } from '../../utils/stringUtil'
import {
  getAges,
  getAreas,
  getFacilityCategories,
  getProjects,
  getSearchFlags,
  getWebReservations,
} from '../common/codeMaster'
import { facilitySearchResultUrl } from '../common/constant/appUrl'
import {
  purposeOfUse as purposeOfUseValue,
  yesNo,
} from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import {
  clearFacilitySearchResult,
  selectFacilitySearchCodeMaster,
  selectFacilitySearchCondition,
  setFacilitySearchCodeMaster,
  setFacilitySearchCondition,
} from '../common/store/slices/facilitySearch'
import { getUseReason } from '../common/useReason'
import {
  selectFacilitySearchSettingMsts,
  setFacilitySearchSettingMsts,
} from '../common/store/slices/facilitySearchSetting'
import { getFacilitySearchSettingMsts } from '../home/homeService'
import { GetFacilitiesInputDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { executeGetFacilities } from '../../dataAccess/webApi/dao/facilitiesDao'

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
  projectIds: string[]
}

interface UrlParams {
  projectCategoryId: string
}

export interface Inputs {
  /** 地域(カンマ区切り) */
  areas: string
  /** 年齢(カンマ区切り) */
  ages: string
  /** フリーワード */
  freeWord: string
  /** Web受付可否 */
  reservationAcceptFlag: string
  /** 事業ID(カンマ区切り) */
  projectIds: string
  /** 施設種別(カンマ区切り) */
  facilityCategories: string
  /** その他詳細条件(カンマ区切り) */
  otherDetailConditions: string
  /** 利用日 */
  usageDate: Date | null
  /** 利用目的(カンマ区切り) */
  purposeOfUses: string
}
type InputTypePropName = keyof Inputs

/**
 * 施設検索制御マスタを一覧取得して返す
 * @return {Array<Object>} - facilitySearchSettingMstsのリスト
 */

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const history = useHistory<LocationState | undefined>()
  const location = useLocation<LocationState>()
  const { projectCategoryId } = useParams<UrlParams>()
  const searchCondition = useSelector(selectFacilitySearchCondition)
  const codeMaster = useSelector(selectFacilitySearchCodeMaster)
  const facilitySearchSettingMsts = useSelector(selectFacilitySearchSettingMsts)

  const locationState = history.location.state

  const dispatch = useDispatch()
  const formMethods = useForm<Inputs>({
    ...(locationState?.isKeep && {
      defaultValues: numberPropsToDate(
        {
          areas: searchCondition?.areas?.join(','),
          ages: searchCondition?.ages?.join(','),
          freeWord: searchCondition?.freeWord,
          reservationAcceptFlag:
            searchCondition?.reservationAcceptFlag?.join(','),
          projectIds: searchCondition?.projectIds?.join(','),
          facilityCategories: searchCondition?.facilityCategories?.join(','),
          otherDetailConditions:
            searchCondition?.otherDetailConditions?.join(','),
          usageDate: searchCondition?.usageDate,
          purposeOfUses: searchCondition?.purposeOfUses?.join(','),
        },
        ['usageDate']
      ),
    }),
  })

  // ちらつき防止のためにuseLayoutEffect使用
  useLayoutEffect(() => {
    if (codeMaster.areas == null || !locationState?.isKeep) {
      const initialize = async () => {
        const facilitySearchSettingMstsRes =
          await getFacilitySearchSettingMsts()

        const allFacilities = await getFacilities(
          locationState?.projectIds || []
        )
        // 選択した事業の年齢の最大値を取得
        const acceptEndAge = Math.max(...allFacilities.map(facility => facility.acceptEndAge ?? 0))

        const targetFacilitySearchSetting = facilitySearchSettingMstsRes?.find(
          (facilitySearchSettingMst) =>
            projectCategoryId === facilitySearchSettingMst.projectCategoryId
        )

        const {
          ageSearchFlag,
          facilityCategorySearchFlag,
          areaSearchFlag,
          reservationSearchFlag,
          otherSearchFlag,
          useReasonSearchFlag,
        } = targetFacilitySearchSetting || {}
        /** 初期表示情報の取得処理 */
        const resultDto = await getFacilityConditionInfo(
          acceptEndAge,
          ageSearchFlag || yesNo.no,
          facilityCategorySearchFlag || yesNo.no,
          areaSearchFlag || yesNo.no,
          reservationSearchFlag || yesNo.no,
          otherSearchFlag || yesNo.no,
          useReasonSearchFlag || yesNo.no
        )
        dispatch(setFacilitySearchCodeMaster(resultDto))
        dispatch(setFacilitySearchSettingMsts(facilitySearchSettingMstsRes))
      }
      dispatch(showLoading(errorHandle(initialize)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []

      if (data.usageDate) {
        const { usageDate } = data
        const minDate = getNowTrimedTime()
        const maxDate = addMonths(minDate, 1)
        if (isBefore(usageDate, minDate) || isAfter(usageDate, maxDate)) {
          errors.push({
            name: 'usageDate',
            message: translate('facilitySearchCondition.error.usageDateRange'),
          })
        }
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  const onSubmit = useCallback(
    async (data: Inputs) => {
      if (validateItems(data)) {
        return
      }

      dispatch(
        setFacilitySearchCondition(
          nullPropsToUndefined(
            datePropsToNumber({
              areas: splitRemoveBlank(',', data.areas),
              ages: splitRemoveBlank(',', data.ages),
              projectIds: splitRemoveBlank(',', data.projectIds).length
                ? splitRemoveBlank(',', data.projectIds)
                : locationState?.projectIds,
              facilityCategories: splitRemoveBlank(
                ',',
                data.facilityCategories
              ),
              freeWord: blankToNull(data.freeWord),
              reservationAcceptFlag: splitRemoveBlank(
                ',',
                data.reservationAcceptFlag
              ),
              otherDetailConditions: splitRemoveBlank(
                ',',
                data.otherDetailConditions
              ),
              usageDate: data.usageDate,
              purposeOfUses: splitRemoveBlank(',', data.purposeOfUses),
            })
          )
        )
      )

      dispatch(clearFacilitySearchResult())
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({
        ...history.location,
        state: {
          isKeep: true,
          projectIds: location.state.projectIds,
        },
      })
      history.push(facilitySearchResultUrl.url())
    },
    [dispatch, history, validateItems]
  )

  const targetFacilitySearchSetting = facilitySearchSettingMsts?.find(
    (facilitySearchSettingMst) =>
      projectCategoryId === facilitySearchSettingMst.projectCategoryId
  )

  return {
    codeMaster,
    onSubmit,
    formMethods,
    targetFacilitySearchSetting,
  }
}

async function getFacilityConditionInfo(
  acceptEndAge: number,
  ageSearchFlag: string,
  facilityCategorySearchFlag: string,
  areaSearchFlag: string,
  reservationSearchFlag: string,
  otherSearchFlag: string,
  useReasonSearchFlag: string
) {
  const [
    areas,
    projects,
    facilityCategories,
    searchFlags,
    allAges,
    reservationAcceptFlag,
    useReason,
  ] = await Promise.all([
    /** 非同期実行 */
    /** APIの実行（地域一覧取得）*/
    areaSearchFlag === yesNo.yes ? getAreas() : [],

    /** APIの実行（事業取得）*/
    /** 中間表示を利用する際にデフォルト値に必要となるため空の配列は不要 */
    getProjects(),

    /** APIの実行（施設種別マスタ取得）*/
    facilityCategorySearchFlag === yesNo.yes ? getFacilityCategories() : [],

    /** APIの実行（検索フラグマスタ取得）*/
    otherSearchFlag === yesNo.yes ? getSearchFlags() : [],

    /** 定数値の取得（年齢）*/
    ageSearchFlag === yesNo.yes ? getAges() : [],

    /** 定数値の取得（Web予約可否）*/
    reservationSearchFlag === yesNo.yes ? getWebReservations() : [],

    /** 利用目的の取得 */
    getUseReason(),
  ])

  const ages = allAges.filter((age) => {
    if (acceptEndAge < 6) {
      return age.value !== '6' && age.value !== '7'
    } else if (acceptEndAge < 9) {
      return age.value !== '7'
    }
    return true
  })

    console.log(ages)

  const purposeOfUses =
    useReasonSearchFlag === yesNo.yes
      ? [
          {
            value: purposeOfUseValue.irregular,
            label: useReason.displayNameForCitizenIrregular,
          },
          {
            value: purposeOfUseValue.emergency,
            label: useReason.displayNameForCitizenEmg,
          },
          {
            value: purposeOfUseValue.refresh,
            label: useReason.displayNameForCitizenRefresh,
          },
        ]
      : []

  return {
    areas,
    projects,
    facilityCategories,
    otherDetailConditions: searchFlags,
    ages,
    reservationAcceptFlag,
    purposeOfUses,
  }
}

async function getFacilities(projectIds: string[]) {
  const input: GetFacilitiesInputDto = undefinedPropsToNull({
    latitude: null,
    longitude: null,
    areaIds: [],
    ages: [],
    freeWord: null,
    reservationAcceptFlag: null,
    projectIds,
    facilityCategories: [],
    searchFlagNos: [],
    usageDate: null,
    purposeOfUses: [],
    sortOrder: '1',
  })
  const apiResponse = await executeGetFacilities(input)
  return apiResponse.result.map((facility) => {
    const { imageUrls, ...other } = facility
    return {
      ...nullPropsToUndefined(other),
      // 画像URLは穴あきで設定される可能性があるため
      imageUrl: imageUrls.find((url) => url),
    }
  })
}
