import { styled } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { forwardRef, Ref, useCallback, useEffect, useState } from 'react'
import { getLocale, getYyyymmddFormat, getYyyymmddMask } from '../../../../i18n'
import { isValidDate } from '../../../../utils/dateUtil'
import { TextBoxBaseStyled } from './textBoxBaseStyled'

interface DatePickerProps {
  value: Date | null
  onChange: (date: Date | null) => void
  fullWidth?: boolean
  maxDate?: Date
  minDate?: Date

  name?: string
  error?: boolean
  errorMessage?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
}

const InputField = styled(TextBoxBaseStyled)(() => ({
  '& .MuiInputAdornment-positionEnd': {
    marginRight: 10,
    marginLeft: 0,
  },
  '& .MuiOutlinedInput-input': {
    paddingRight: 0,
  },
}))

/** 日付コントロール */
export const DatePickerNoBind = forwardRef(function DatePickerNoBind(
  props: DatePickerProps,
  ref: Ref<HTMLInputElement>
) {
  const { value, error, errorMessage, onBlur, onChange, disabled, maxDate, minDate, ...through } = props
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => setInternalValue(value), [value])

  const onChangeHandler = useCallback(
    (date: Date | null, keyboardInputValue?: string) => {
      setInternalValue(date)
      if (isValidDate(date)) {
        if (keyboardInputValue == null || keyboardInputValue.length === 10) {
          onChange(date)
        } else {
          onChange(new Date(NaN))
        }
      } else {
        onChange(date)
      }
    },
    [onChange]
  )
  const locale = getLocale()
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DesktopDatePicker
        {...through}
        maxDate={maxDate}
        minDate={minDate}
        inputRef={ref}
        value={internalValue}
        onChange={onChangeHandler}
        showToolbar={false}
        mask={getYyyymmddMask()}
        inputFormat={getYyyymmddFormat()}
        renderInput={(params) => {
          return <InputField {...params} {...through} onBlur={onBlur} error={!!error} helperText={errorMessage} />
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  )
})
