import { useEffect, useState } from 'react'
import { useQueryParams } from '../common/transition'
import { executeGetUsageRecord } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { executeGetFacility } from '../../dataAccess/webApi/dao/facilitiesDao'
import { fromApiYmdHms, fromApiYmd, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { NoResultError } from '../../dataAccess/webApi/common/apiCaller'
import { useHistory } from 'react-router-dom'
import {
  errorUrls,
} from '../common/constant/appUrl'
import { httpStatusCode } from '../common/constant/classification'

export type ReceptViewUrlQueryParams = {
  /** 受付no */
  reservationNo: string
  /** 施設ID */
  facilityId: string
}

export type InputInfoType = {
  parentName: string
  childName: string
  amount: number
  usageFee: number
  useCouponFee: number
  extentionFee: number
  nightlyFee: number
  lunchFee: number
  snackFee: number
  otherFee1: number
  otherFee2: number
  otherFee3: number
  reduction: number
  reservationNo: string
  facilityName: string
  address1: string
  address2: string
  buildingNameRoomNo: string
  tel: string
  usageDate: string
  projectName: string
  otherFeeDisplayName1: string
  otherFeeDisplayName2: string
  otherFeeDisplayName3: string
  actualUpdateDatetime: string
}

export const useAction = () => {
  const {
    reservationNo,
    facilityId
  } = useQueryParams<ReceptViewUrlQueryParams>()
  const history = useHistory()

  const [printData, setPrintData] = useState(false)
  const [receiptData, setReceiptData] = useState<InputInfoType>({
    parentName: '',
    childName: '',
    amount: 0,
    usageFee: 0,
    useCouponFee: 0,
    extentionFee: 0,
    nightlyFee: 0,
    lunchFee: 0,
    snackFee: 0,
    otherFee1: 0,
    otherFee2: 0,
    otherFee3: 0,
    reduction: 0,
    reservationNo: '',
    facilityName: '',
    address1: '',
    address2: '',
    buildingNameRoomNo: '',
    tel: '',
    usageDate: '',
    projectName: '',
    otherFeeDisplayName1: '',
    otherFeeDisplayName2: '',
    otherFeeDisplayName3: '',
    actualUpdateDatetime: '',
  })
  const [isDefaultLayout, setIsDefaultLayout] = useState<boolean>(true)
  
  useEffect(() => {
    initialize()
      .catch((error) => {
        throw error
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  /** 初期化処理 */
  const initialize = async () => {
    try {
      const receiptViewData = await getUsageDetail(reservationNo);
      const usageDate = toApiYmd(receiptViewData.usageDate)
      const data = await getFacility(facilityId, usageDate)
  
      //事業マスタ詳細の取得
      setReceiptData({
        parentName: receiptViewData.name ?? '',
        childName: receiptViewData.childName ?? '',
        amount: receiptViewData.amount ?? 0,
        usageFee: receiptViewData.usageFee ?? 0,
        useCouponFee: receiptViewData.useCouponFee ?? 0,
        extentionFee: receiptViewData.extentionFee ?? 0,
        nightlyFee: receiptViewData.nightlyFee ?? 0,
        lunchFee: receiptViewData.lunchFee ?? 0,
        snackFee: receiptViewData.snackFee ?? 0,
        otherFee1: receiptViewData.otherFee1 ?? 0,
        otherFee2: receiptViewData.otherFee2 ?? 0,
        otherFee3: receiptViewData.otherFee3 ?? 0,
        reduction: receiptViewData.reduction ?? 0,
        reservationNo,
        facilityName: data.facilityName ?? '',
        address1: data.address1 ?? '',
        address2: data.address2 ?? '',
        buildingNameRoomNo: data.buildingNameRoomNo ?? '',
        tel: data.tel ?? '',
        usageDate,
        projectName: data.projectName ?? '',
        otherFeeDisplayName1: receiptViewData.otherFeeDisplayName1 ?? 'その他',
        otherFeeDisplayName2: receiptViewData.otherFeeDisplayName2 ?? 'その他',
        otherFeeDisplayName3: receiptViewData.otherFeeDisplayName3 ?? 'その他',
        actualUpdateDatetime: receiptViewData.actualUpdateDatetime ?? '',
      })

      /**
       * TODO: 独自レイアウト作成機能追加時にこちらのフラグを変更して領収書のレイアウトを切り替える
       */
      if (false) {
        setIsDefaultLayout(false)
      }
  
      setPrintData(true)
    } catch (e) {
      if (e instanceof NoResultError) {
        history.push(errorUrls[httpStatusCode.notFound].routerPath)
        return null
      } else {
        throw e
      }
    }
  }

  const getUsageDetail = async (reservationNo: string) => {
    const response = await executeGetUsageRecord(reservationNo)
    const { usageDate, useFromDatetime, useToDatetime, reservationCancelDatetime, usageMinute, ...other } =
      response.result
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
      reservationCancelDatetime: fromApiYmdHms(reservationCancelDatetime),
      usageMinute: formatLocaleTimeAmountHm(usageMinute),
    })
  }
  
  //施設情報取得
  const getFacility = async (
    facilityId: string, usageDate: string | undefined
  ) => {
    const response = await executeGetFacility(
      facilityId,
      ...(usageDate ? [{ targetDate: usageDate }] : [])
    )
    return response.result
  }
  
  return {
    printData,
    receiptData,
    isDefaultLayout
  }
}
