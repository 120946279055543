import { styled } from '@mui/system'
import { ButtonOutlinedWarningBase } from './buttonOutlinedWarningBase'

/**
 * warningカラーのボタンコントロールMサイズ。
 */
export const ButtonOutlinedWarningM = styled(ButtonOutlinedWarningBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  padding: '14px 25px',
  borderWidth: 4,
}))
