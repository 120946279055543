import { PostDecodeMstDto } from "../../../dataAccess/webApi/dto/decodeMstDto";

/** デコードマスタ種別 */
type ReservationSettingDecodeMst<T> = {
  default: T
};

export const DecodeMstCategory = {
  /** 病名 */
  DIAGNOSIS: 'diagonosis',
  // 症状
  SYMPTOM: 'symptom'
} as const

export const DECODE_MST_DEFAULT: ReservationSettingDecodeMst<Pick<PostDecodeMstDto, 'name'>[]> = {
  default: [
    {
      name: '利用しない'
    },
    {
      name: '利用する'
    }
  ]
}
