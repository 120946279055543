// 利用目的マスタ情報に関する共通処理を定義
import { executeGetUseReason } from '../../dataAccess/webApi/dao/useReasonDao'
import { nullPropsToUndefined } from '../../utils/objectUtil'

/**
 * @returns 利用目的マスタ情報
 */
export const getUseReason = async () => {
  const apiResponse = await executeGetUseReason()
  const result = nullPropsToUndefined(apiResponse.result)
  return result
}
