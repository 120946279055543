// 受入年齢、住所など業務要素のある項目フォーマットユーティリティ関数
// stringUtilに格納してしまうと、dateUtilなどとの循環参照が発生する為
// 別モジュールへ分離している

import { yesNo } from '../containers/common/constant/classification'
import { translate } from '../i18n'
import { ElapsedMillisecond, formatHm } from './dateUtil'
import { fromNumber } from './stringUtil'

/**
 * 受入年齢表示形式にフォーマットする
 * @param acceptAge 受入年齢(年齢)
 * @param acceptMonth 受入年齢(月齢)
 * @param acceptDay 受入年齢(日齢)
 * @param acceptAgeGradeFlag 年度満年齢フラグ
 * @returns 受入年齢表示文字列。受入年齢(年齢)～受入年齢(日齢)がすべて未設定ならundefined
 */
export const formatAcceptAge = (
  acceptAge?: number,
  acceptMonth?: number,
  acceptDay?: number,
  acceptAgeGradeFlag?: string
) => {
  if (acceptAge != null || acceptMonth != null || acceptDay != null) {
    let age = acceptAge
    let month
    if (acceptMonth != null) {
      // 月齢のみ年齢への繰り上げ処理を行う
      const upAge = Math.trunc(acceptMonth / 12)
      if (upAge) {
        age = (age ?? 0) + upAge
      }
      month = acceptMonth % 12
    }

    const isShown = (value?: number, ...highers: (number | undefined)[]) =>
      value != null && !(value === 0 && highers.some((v) => (v ?? 0) > 0))

    let dayPrefix = translate('system.text.acceptAgeUnit.dayPrefix')
    if (dayPrefix === 'system.text.acceptAgeUnit.dayPrefix') {
      // 辞書エントリ無しの場合は、空で編集
      dayPrefix = ''
    }
    const isShownDay = isShown(acceptDay, age, month)
    return [
      isShownDay ? [dayPrefix] : [],
      age != null ? [fromNumber(age), translate('system.text.acceptAgeUnit.age')] : [],
      isShown(month, age) ? [fromNumber(month), translate('system.text.acceptAgeUnit.month')] : [],
      isShownDay ? [fromNumber(acceptDay), translate('system.text.acceptAgeUnit.day')] : [],
      acceptAgeGradeFlag !== yesNo.yes ? [translate('system.text.acceptAgeUnit.class')] : [],
    ]
      .flat()
      .join('')
  }
}

/**
 * 受入年齢範囲表示形式にフォーマットする
 *
 * @param formattedAcceptAgeFrom 受入年齢表示形式（下限）
 * @param formattedAcceptAgeTo 受入年齢表示形式（上限）
 * @returns 受入年齢範囲表示形式文字列
 */
export const formatAcceptAgeRange = (formattedAcceptAgeFrom: string, formattedAcceptAgeTo: string) => {
  interface age {
    [key: string]: string
  }
  const ageMap : age = {
    '6歳児クラス': '小学校1年生',
    '7歳児クラス': '小学校2年生',
    '8歳児クラス': '小学校3年生',
    '9歳児クラス': '小学校4年生',
    '10歳児クラス': '小学校5年生',
    '11歳児クラス': '小学校6年生',
  };
  formattedAcceptAgeFrom = ageMap[formattedAcceptAgeFrom] || formattedAcceptAgeFrom;
  formattedAcceptAgeTo = ageMap[formattedAcceptAgeTo] || formattedAcceptAgeTo;

  return translate('system.text.acceptAgeUnit.range', formattedAcceptAgeFrom, formattedAcceptAgeTo);
}

/**
 * 表示用住所にフォーマット。
 * 住所１・２と建物名は半角スペースで区切る
 * @param address1 住所1
 * @param address2 住所2
 * @param buildingNameRoomNo 建物名・部屋番号
 * @returns 表示用住所
 */
export const formatAddress = (address1?: string, address2?: string, buildingNameRoomNo?: string) => {
  return [[address1, address2].join(''), buildingNameRoomNo].filter((v) => v).join(' ')
}

/**
 * 郵便番号フォーマット。
 * 数字のみ以外は渡された文字列をそのまま返す
 * @param value 文字列
 * @returns 郵便番号フォーマットか渡された文字列
 */
export const formatPostalCode = (value: string) => {
  const result = /^(\d{3})(\d+)$/.exec(value)
  if (result) {
    return `${result[1]}-${result[2]}`
  }
  return value
}

/**
 * キャンセル期限のフォーマット。
 *
 * @param cancelLimit キャンセル受付期限(日数)
 * @param cancelLimitTime キャンセル受付終了時間
 * @returns キャンセル期限のフォーマットされた文字列
 */
export const formatCancelLimit = (
  cancelLimit: number,
  cancelLimitTime: Date | ElapsedMillisecond,
  interviewFlag?: boolean
) => {
  const limitTime = formatHm(cancelLimitTime)
  return cancelLimit === 0
    ? (interviewFlag
        ? translate('system.text.interviewCancelLimit.onDay', limitTime)
        : translate('system.text.cancelLimit.onDay', limitTime)
      )
    : (interviewFlag
        ? translate('system.text.interviewCancelLimit.other', fromNumber(cancelLimit), limitTime)
        : translate('system.text.cancelLimit.other', fromNumber(cancelLimit), limitTime)
      )
}
