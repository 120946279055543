import React from 'react';
import { facilityReservationInputKey } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { RadioButtonGroup } from '../inputs/radioButtonGroup';
import { getYesNos } from '../../../../containers/common/codeMaster';

interface ReservationDetailFebrileSeizures {
    name: facilityReservationInputKey;
}

const ReservationDetailFebrileSeizures = (props: ReservationDetailFebrileSeizures) => {
    const { name } = props;

    return (
        <>
            {/** 熱性けいれん有無 */}
            <RadioButtonGroup
                name={`${name}.febrileSeizures`}
                label="熱性けいれん有無"
                row
                required
                buttonValueLabels={getYesNos().reverse()}
            />
        </>
    );
}

export default ReservationDetailFebrileSeizures;
