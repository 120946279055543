import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { datePropsToNumber, numberPropsToDate } from '../../utils/objectUtil'
import { memberAddConfirmationChildUrl } from '../common/constant/appUrl'
import { selectMemberAddChildEntry, setMemberAddChild } from '../common/store/slices/memberAddChild'
import { getNow } from '../../utils/dateUtil'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { yesNo } from '../common/constant/classification'

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
  /**
   * 登録完了後遷移先。
   * 指定があれば登録完了後に指定画面へ遷移
   */
  completionTo?: Location
}

interface Inputs {
  name: string
  kana: string
  birthday: Date
  brothersSistersFlag: string
  multipleBirthsFlag: string
  gender: string
  bloodType: string
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
}

export const useAction = () => {
  const dispatch = useDispatch()
  const currentHistory = useHistory<LocationState | undefined>()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const isKeep = !!currentHistory.location.state?.isKeep
  const completionTo = currentHistory.location.state?.completionTo

  const childBirthdayLimitDate = getNow() // 誕生日の入力上限値

  const addEntry = useSelector(selectMemberAddChildEntry)
  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(isKeep && addEntry && numberPropsToDate(addEntry, ['birthday'])),
    },
  })

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000060 })

      dispatch(setMemberAddChild(datePropsToNumber(data)))
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      currentHistory.replace({ ...currentHistory.location, state: { isKeep: true, completionTo } })
      history.push(memberAddConfirmationChildUrl.url(), { completionTo })
    },
    [dispatch, currentHistory, history, completionTo, addOperationLog]
  )

  return {
    formMethods,
    onSubmit,
    childBirthdayLimitDate,
  }
}
