import { Checkbox, FormControl, FormControlLabel, styled, FormHelperText } from '@mui/material';
import React, { useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { translate } from '../../../../i18n';
import { GContainer, GItem } from '../grids';
import { getValueByNameStr } from '../../../../utils/objectUtil';

interface CheckBoxSelectionsProps {
  name: string;
  label: string;
  valueLabels: { value: string; label: string }[];
  required?: boolean;
  control?: Control<any, any>;
  xs?: number;
  sm?: number;
  md?: number;
}

const Root = styled(FormControl)(() => ({
  '& .MuiCheckbox-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiFormControlLabel-label': {
    lineHeight: 'normal',
  },
}));

export const CheckBoxSelections = (props: CheckBoxSelectionsProps) => {
  const { name, label, valueLabels, required, control, xs, sm, md } = props;
  
  const {
    field: { ref, value = [], onChange, ...ctrlProps },
    formState: { errors }
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: translate('system.error.requiredSelection', label),
      },
    },
    defaultValue: [], // デフォルト値を空の配列に設定
    control,
  });

  const error = getValueByNameStr(errors, props.name);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    // チェックされた場合、配列に値を追加し、空文字列を除外
    if (isChecked) {
      onChange([...value.filter((v: string) => v !== ''), selectedValue]);
    } else {
      // チェックが外された場合、配列からその値を削除
      onChange(value.filter((v: string) => v !== selectedValue && v !== ''));
    }
  };
  
  useEffect(() => {
    const filterEmptyString = () => {
      onChange(value.filter((v: string) => v !== ''));
    }
    
    filterEmptyString();
  }, [])
  

  return (
    <GContainer>
      {valueLabels?.map((valueLabel) => (
        <GItem xs={xs ?? 6} sm={sm ?? 4} md={md ?? 3} key={valueLabel.value}>
          <Root required={required}>
            <FormControlLabel
              {...ctrlProps}
              inputRef={ref}
              label={valueLabel.label}
              name={name}
              control={
                <Checkbox
                  value={valueLabel.value}
                  checked={value.includes(valueLabel.value)} // 値が含まれているかチェック
                  onChange={handleCheckChange}
                />
              }
            />
          </Root>
        </GItem>
      ))}
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </GContainer>
  );
};
