// import { useCallback, useEffect, useState } from 'react'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeDeleteAccount } from '../../dataAccess/webApi/dao/accountDao'
// import { executeGetGuideText } from '../../dataAccess/webApi/dao/controlDao'
import { translate } from '../../i18n'
import { signOut } from '../../utils/authUtil'
import { loginUrl } from '../common/constant/appUrl'
// import { guidTextMstId, yesNo } from '../common/constant/classification'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { notifyMessage, reset, showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { castNonNullable } from '../../utils/typeUtil'

interface Inputs {
  isConfirmation: string
}

interface PageState {
  // unsubscribeGuideText: string
  deleteResultCode?: number
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()
  const sysCtrl = useSelector(selectSystemControl)

  const [state, setState] = useState<PageState>()

  const formMethods = useForm<Inputs>()

  // useEffect(() => {
  //   addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })

  //   dispatch(
  //     showLoading(
  //       errorHandle(async () => {
  //         const guideText = await executeGetGuideText(guidTextMstId.unsubscribe)
  //         setState({
  //           unsubscribeGuideText: guideText.result.guideTextContent,
  //         })
  //       })
  //     )
  //   )
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const onSubmit = useCallback(async () => {
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          addOperationLog({ operationId: OperationId.OP_00000156, accessData: [{ userIdRegFlag: yesNo.yes }] })

          const response = await executeDeleteAccount()
          if (response.resultCode) {
            // 退会失敗時
            setState((old) => ({ ...castNonNullable(old), deleteResultCode: response.resultCode }))
          } else {
            // 退会成功時
            dispatch(notifyMessage(translate('accountUnsubscribe.success.completionMessage')))

            await signOut()
            dispatch(reset({ isKeepAuthority: false }))
            history.push(loginUrl.url())
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [dispatch, errorHandle, history, addOperationLog])

  return {
    sysCtrl,
    formMethods,
    onSubmit,
    // unsubscribeGuideText: state?.unsubscribeGuideText,
    deleteResultCode: state?.deleteResultCode,
  }
}
