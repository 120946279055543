import { Box, Tooltip, styled } from '@mui/material'
import { ChangeEvent, useCallback, useRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { executePostReservationAttachment } from '../../dataAccess/webApi/dao/reservationAttachmentDao'
import { getAuthenticatedUser } from '../../utils/authUtil'
import { formatDate, getNow } from '../../utils/dateUtil'
import { GItem } from '../../views/components/common/grids'
import { ErrorText } from '../common/error/errorText'
import { PostReservationAttachmentInputDto } from '../../dataAccess/webApi/dto/reservationAttachmentDto'
import { Flag } from '../common/constant/classification'
import { ButtonS } from '../../views/components/common/buttons/buttonS'
import { ButtonOutlinedS } from '../../views/components/common/buttons/buttonOutlinedS'
import { translate } from '../../i18n'
import { showLoading } from '../common/store/slices/application'

const EXTENSION = [
  '.jpeg',
  '.jpg',
  '.pdf',
  '.png',
] as const;

const VALID_FILE = {
  MAX_NAME_LENGTH: 100,
  MAX_MEGA_BYTE_SIZE: 10,
  EXTENSION: EXTENSION,
} as const

/**
 * 一時アップロードkey取得
 * @param fileName ファイル名
 * @param loginUserId ログインユーザID
 * @retunr 一時アップロードkey
 */
const getTempUploadKey = (
  fileName: string,
  loginUserId: string,
  userId: string
) => {
  const currentDatetime = formatDate(getNow(), 'yyyyMMddHHmmssSSS')
  return `work/${loginUserId}/${userId}/medicalDoc/${currentDatetime}/${fileName}`
}

const UrlTextField = styled(Box)({
  border: '1px solid',
  fontSize: '1rem',
  height: '28px',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '0.25rem',
  paddingRight: '0.25rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 'bold'
});

// const ClearButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.primary.contrastText,
//   backgroundColor: theme.palette.primary.main,
//   height: '28px',
//   borderRadius: 0
// }))

const FileSelectArea = styled('label')({
  height: '30px',
  width: '100px',
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  '& input': {
    display: 'none',
  },
})

const extentionStr = VALID_FILE.EXTENSION.join(',')


interface UploadAttachmentFormProps {
  name: string
  // 登録済みファイル情報
  registeredFileKey: string | null
}

export const ReservationDetailMedicalDoc = (props: UploadAttachmentFormProps) => {
  const { name, registeredFileKey } = props
  const methods = useFormContext()
  const dispatch = useDispatch()
  const backetName = useSelector(selectSystemControl).s3BucketNameCitizenUserReservationFile
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {
    field: { onChange, value },
  } = useController({
    name: props.name,
    defaultValue: { fileName: null, delFlg: Flag.OFF, tempUploadKey: null },
    control: methods.control
  })

  /**
   * ファイルアップロード
   * @param fileInfo アップロードするファイル情報
   */
  const uploadFile = useCallback(
    async (fileInfo: File) => {
      dispatch(
        showLoading({
          process: async () => {
            try {
              const loginUser = await getAuthenticatedUser()
              const loginUserId = loginUser.attributes.sub
      
              const key = getTempUploadKey(fileInfo.name, loginUserId, loginUserId)
      
              // 署名付きURLの取得
              const inputs = {
                fileName: fileInfo.name,
                key: key
              } as PostReservationAttachmentInputDto
              const getUrl = await executePostReservationAttachment(inputs)
              const url = getUrl.result.url
              if (!url) return
      
              // work配下に一時URLでアップロード
              const response = await fetch(url, {
                method: 'PUT',
                body: fileInfo,
              });
      
              if (!response.ok) {
                throw new Error('Upload failed')
              }
      
              onChange({ fileName: fileInfo.name, delFlg: Flag.OFF, tempUploadKey: key })
            } catch (err) {
              methods.setError(name, { message: 'ファイルアップロードに失敗しました' }, { shouldFocus: true })
            } 
          },
          isHiddenMain: false,
        })
      );
    },
    [backetName, methods, name, onChange]
  )

  /**
   * ファイル選択処理
   * @param ChangeEvent<HTMLInputElement>
   */
  const handleSelectFile = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      methods.clearErrors(name)

      try {
        // ファイル情報
        const fileInfo = event.target?.files?.[0]
        // 同じファイルを選択してもchangeイベントをトリガーさせる為valueを空にする
        event.target.value = ''
        if (fileInfo != null) {
          // ファイル名の文字長チェック
          if (fileInfo.name.length > VALID_FILE.MAX_NAME_LENGTH) {
            methods.setError(
              name,
              { message: `ファイル名は${VALID_FILE.MAX_NAME_LENGTH}文字以下にしてください` },
              { shouldFocus: true }
            )
            return
          }
          // ファイルサイズチェック
          if (fileInfo.size > VALID_FILE.MAX_MEGA_BYTE_SIZE * 1000000) {
            methods.setError(
              name,
              { message: `ファイルサイズは${VALID_FILE.MAX_MEGA_BYTE_SIZE}Mバイト以下にしてください` },
              { shouldFocus: true }
            )
            return
          }
          // ファイル拡張子チェック
          const isExtentionMatch =
            VALID_FILE.EXTENSION.filter((ext) => {
              const pattern = `^.*\\${ext}$`
              return fileInfo.name.toLowerCase().match(pattern)
            }).length > 0
          if (!isExtentionMatch) {
            methods.setError(name, { message: `ファイルの拡張子が不正です` }, { shouldFocus: true })
            return
          }
          await uploadFile(fileInfo)
        }
      } finally {
      }
    },
    [dispatch, methods, name, uploadFile]
  )

  /**
   * クリアボタンクリック処理
   */
  const handleClickClear = useCallback(() => {
    methods.clearErrors(name)
    // DBに登録済みのファイルがある場合は削除フラグをONに設定
    const delFlg = registeredFileKey ? Flag.ON : Flag.OFF
    onChange({ fileName: null, delFlg: delFlg, tempUploadKey: null })
  }, [methods, name, onChange, registeredFileKey])
  
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
        <GItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
            <GItem 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '75%'
              }}
            >
              <FileSelectArea>
                <input 
                  accept={extentionStr} 
                  name={name} 
                  ref={fileInputRef}
                  type="file" 
                  onChange={handleSelectFile} />
                <ButtonS onClick={handleButtonClick} >
                  {translate('facilityReservationForm.form.medical.selectDoc')}
                </ButtonS>
              </FileSelectArea>
              <Tooltip title={value.fileName??''}>
                <UrlTextField
                  sx={{
                    width: {
                      xs: '60%',  
                      sm: '70%',   
                      md: '70%',   
                    },
                  }}
                >{value.fileName??''}</UrlTextField>
              </Tooltip>
            </GItem>
            
            <ButtonOutlinedS
              onClick={handleClickClear}
              disabled={value.fileName == null}
            >
              {translate('facilityReservationForm.form.common.clear')}
            </ButtonOutlinedS>
        </GItem>
        <GItem>
          <ErrorText name={name} />
        </GItem>
    </>
  )
}
