import { IconButton, Stack, Tooltip, styled } from '@mui/material'
import { Redirect } from 'react-router-dom'
import { useAction } from '../../containers/accountCreateForm/accountCreateFormService'
import { getAccountCreateSteps, getGenders } from '../../containers/common/codeMaster'
import { identityEmailVerificationUrl } from '../../containers/common/constant/appUrl'
import { accountCreateStep, yesNo } from '../../containers/common/constant/classification'
import { handingOfPersonalDataUrl } from '../../containers/common/constant/externalUrl'
import { cognitoPasswordMaxLength } from '../../containers/common/validator'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { ElevatedCard } from '../components/common/elevatedCard'
import { FlexboxRowTop, Separator } from '../components/common/flexboxes'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { HelpIcon } from '../components/common/icons/helpIcon'
import { InfoCard } from '../components/common/infoCard'
import { CheckBox } from '../components/common/inputs/checkBox'
import { DatePicker } from '../components/common/inputs/datePicker'
import { Form } from '../components/common/inputs/form'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TelTextBox } from '../components/common/inputs/telTextBox'
import { TextBox } from '../components/common/inputs/textBox'
import { Link } from '../components/common/link'
import { StepBar } from '../components/common/stepBar'

const AnnotationList = styled('ul')({
  paddingLeft: '20px',
  margin: '0',
})
const NormalItem = styled('li')({
  padding: '2px 0',
})
const AttentionItem = styled(NormalItem)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const HelpIconButton = styled(IconButton)({ fontSize: '1.8rem', padding: 0, marginLeft: '8px' })

/** お子さま数上限 */
const childrenLengthMax = 5

export const AccountCreateForm = () => {
  const {
    isLoggedIn,
    formMethods,
    fieldArrayMethods,
    appendChild,
    removeChild,
    autoCompleteAddress,
    onSubmit,
    childBirthdayLimitDate,
    residenceCategories,
    isDisabledAddress1,
    openClick,
    residenceCategoryMst,
  } = useAction()

  if (isLoggedIn) {
    return <Redirect to={identityEmailVerificationUrl.url()} />
  }
  const fields = fieldArrayMethods.fields
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Stack spacing={2}>
              <div>
                <StepBar activeStepValue={accountCreateStep.step1} stepValueLabels={getAccountCreateSteps()} />
                <InfoCard>
                  <AnnotationList>
                    <NormalItem>{translate('accountCreateForm.card.formAnnotation')}</NormalItem>
                    <AttentionItem>{translate('accountCreateForm.card.emailAnnotation')}</AttentionItem>
                    <NormalItem>{translate('accountCreateForm.card.inputNameAnnotation')}</NormalItem>
                    <NormalItem>{translate('accountCreateForm.card.inputEmojiAnnotation')}</NormalItem>
                  </AnnotationList>
                </InfoCard>
              </div>
              <div>
                <GContainer rowSpacing={3}>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.parentName')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="name"
                        label={translate('accountCreateForm.label.parentName')}
                        maxLength={100}
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.parentKana')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="kana"
                        label={translate('accountCreateForm.label.parentKana')}
                        maxLength={100}
                        textType="katakana"
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.tel')}
                    </GItem>
                    <GItem xs={12}>
                      <TelTextBox name="tel" label={translate('accountCreateForm.label.tel')} required />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.email')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="email"
                        label={translate('accountCreateForm.label.email')}
                        maxLength={320}
                        textType="email"
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.emailConfirmation')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="emailConfirmation"
                        label={translate('accountCreateForm.label.emailConfirmation')}
                        maxLength={320}
                        textType="email"
                        required
                        autoComplete="off"
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.password')}
                      <Tooltip title={translate('accountCreateForm.text.passwordHelp')} enterTouchDelay={0}>
                        <HelpIconButton>
                          <HelpIcon fontSize="inherit" />
                        </HelpIconButton>
                      </Tooltip>
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="password"
                        label={translate('accountCreateForm.label.password')}
                        textType="password"
                        maxLength={cognitoPasswordMaxLength}
                        required
                        autoComplete="off"
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.passwordConfirmation')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="passwordConfirmation"
                        label={translate('accountCreateForm.label.passwordConfirmation')}
                        textType="password"
                        maxLength={cognitoPasswordMaxLength}
                        required
                        autoComplete="off"
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.postalCode')}
                    </GItem>
                    <GItem xs={12}>
                      <FlexboxRowTop>
                        <TextBox
                          name="postalCode"
                          label={translate('accountCreateForm.label.postalCode')}
                          textType="postalCode"
                          maxLength={8}
                          required
                        />
                        <Separator />
                        <ButtonOutlinedS onClick={autoCompleteAddress}>
                          {translate('accountCreateForm.button.autoComplete')}
                        </ButtonOutlinedS>
                      </FlexboxRowTop>
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.prefecturesAndMunicipalities')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="address1"
                        label={translate('accountCreateForm.label.prefecturesAndMunicipalities')}
                        maxLength={100}
                        required
                        disabled={isDisabledAddress1}
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.address')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="address2"
                        label={translate('accountCreateForm.label.address')}
                        maxLength={100}
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>{translate('accountCreateForm.label.buildingNameRoomNumber')}</GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="buildingNameRoomNumber"
                        label={translate('accountCreateForm.label.buildingNameRoomNumber')}
                        maxLength={100}
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('accountCreateForm.label.residenceCategory')}
                    </GItem>
                    <GItem xs={12}>
                      <RadioButtonGroup
                        name="residenceCategory"
                        label={translate('accountCreateForm.label.residenceCategory')}
                        required
                        row
                        buttonValueLabels={residenceCategories}
                      />
                    </GItem>
                    {residenceCategoryMst?.descriptionForResidenceCategory && 
                      <AttentionLabel>{residenceCategoryMst.descriptionForResidenceCategory}</AttentionLabel>
                    }
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>{translate('accountCreateForm.label.relationship')}</GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="relationship"
                        label={translate('accountCreateForm.label.relationship')}
                        maxLength={50}
                      />
                    </GItem>
                  </GItemContainer>

                  <GItemContainer xs={12} rowSpacing={1}>
                    {fields.map((field, index) => (
                      <GItem xs={12} key={field.id}>
                        <ElevatedCard
                          title={translate('accountCreateForm.title.child')}
                          // 最低お子さま一人を登録
                          {...(fields.length > 1 && {
                            onClose: () => removeChild(index),
                          })}
                        >
                          <GContainer rowSpacing={1}>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('accountCreateForm.label.childName')}
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name={`children.${index}.name`}
                                  label={translate('accountCreateForm.label.childName')}
                                  maxLength={100}
                                  required
                                />
                              </GItem>
                            </GItemContainer>

                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('accountCreateForm.label.childKana')}
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name={`children.${index}.kana`}
                                  label={translate('accountCreateForm.label.childKana')}
                                  maxLength={100}
                                  textType="katakana"
                                  required
                                />
                              </GItem>
                            </GItemContainer>

                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('accountCreateForm.label.childBirthday')}
                              </GItem>
                              <GItem xs={12}>
                                <DatePicker
                                  name={`children.${index}.birthday`}
                                  label={translate('accountCreateForm.label.childBirthday')}
                                  maxDate={childBirthdayLimitDate}
                                  required
                                />
                              </GItem>
                            </GItemContainer>

                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('accountCreateForm.label.childGender')}
                              </GItem>
                              <GItem xs={12}>
                                <RadioButtonGroup
                                  name={`children.${index}.gender`}
                                  label={translate('accountCreateForm.label.childGender')}
                                  required
                                  row
                                  buttonValueLabels={getGenders()}
                                />
                              </GItem>
                            </GItemContainer>
                            {/*TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする*/}
                            {/*
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('accountCreateForm.label.maternalHandbookNo')}
                                {translate('accountCreateForm.label.maternalHandbookNoNote')}
                              </GItem>
                              <GItem xs={12}>{translate('accountCreateForm.label.maternalHandbookNoExample')}</GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name={`children.${index}.maternalHandbookNo`}
                                  label={translate('accountCreateForm.label.maternalHandbookNo')}
                                  maxLength={20}
                                  required
                                />
                              </GItem>
                            </GItemContainer>
                            */}

                          </GContainer>
                        </ElevatedCard>
                      </GItem>
                    ))}
                    {fields.length < childrenLengthMax && (
                      <GItem xs={12}>
                        <Link onClick={appendChild} underline="none">
                          ＋ {translate('accountCreateForm.text.addChild')}
                        </Link>
                      </GItem>
                    )}
                  </GItemContainer>

                  <GItem xs={12}>
                    <CheckBox
                      name="isAgreeTermsOfService"
                      label={translate('accountCreateForm.label.isAgreeTermsOfService')}
                      valicationMessageKeys={{ required: 'accountCreateForm.error.agree' }}
                      valueAtCheck={yesNo.yes}
                      required
                    >
                      <Link target="_blank" onClick={openClick}>
                        {translate('accountCreateForm.checkbox.isAgreeTermsOfService')}
                      </Link>
                    </CheckBox>
                  </GItem>
                  <GItem xs={12}>
                    <CheckBox
                      name="isAgreePersonalInformation"
                      label={translate('accountCreateForm.label.isAgreePersonalInformation')}
                      valicationMessageKeys={{ required: 'accountCreateForm.error.agree' }}
                      valueAtCheck={yesNo.yes}
                      required
                    >
                      <Link target="_blank" href={handingOfPersonalDataUrl}>
                        {translate('accountCreateForm.checkbox.isAgreePersonalInformation')}
                      </Link>
                    </CheckBox>
                  </GItem>
                </GContainer>
              </div>
            </Stack>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>

        <BottomButtonGroup>
          <ButtonL fullWidth type="submit">
            {translate('accountCreateForm.button.confirmation')}
          </ButtonL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
