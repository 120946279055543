// 利用予約情報に関する共通処理を定義
import { executeGetReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { fromApiYmd, fromApiYmdHms, formatYmd, formatHm } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { yesNo } from './constant/classification'
import { dateToNumber } from '../../utils/objectUtil'
import { PostDecodeMstDto } from '../../dataAccess/webApi/dto/decodeMstDto'
import { Flag } from './constant/classification';
import { getDecodeMstValue } from './getDecodeMstValue'
import { GetReservationDto } from '../../dataAccess/webApi/dto/reservationsDto'

export type ParentInfoType = {
  name: string
  kana: string
  tel: string
  email: string
  residenceCategory?: string
}

export type ChildInfoType = {
  name: string
  kana: string
  birthday: string
  gender: string
}

export type TemperatureType = {
  integer: number | undefined
  few: number | undefined
}

// export type AllergyDetailType = {
//   reason: string | null
//   condition: string | null
//   treatment: string | null
//   careful: string | null
// }
export type AllergyType = {
  flag: string,
  foodFlag: string
  // details: AllergyDetailType[]|null
} 

export type SymptomType = {
  symptom: string[]
  additional: string | null
  stringify?: string
}
export type SymptomInputType = {
  symptom: string[] | null
  additional: string | null
  stringify?: string
}

export type DiagonosisType = {
  input: string | null
  additional: string | null
  input2: string | null
  additional2: string | null
  stringify?: string
  stringify2?: string
}

export type DocType = {
  fileName: string | null
  delFlg: string | null
  tempUploadKey: string | null
}

export type MedicalDoc = {
  docs: DocType[]
  hasSubmitted: string
  status?: string
}

export type ChildReservationInfoType = {
  childId: string
  childInfo?: ChildInfoType
  diagonosis?: DiagonosisType
  symptom?: SymptomType
  temperature?: TemperatureType 
  allergy?: AllergyType
  medicalDoc?: MedicalDoc
  febrileSeizures?: string | undefined
  others?: string | null
  conditionCheck?: string | null
  conditionCheckNote?: string | null
}

export type ChildReservationInputType = {
  childId: string
  childInfo?: ChildInfoType
  diagonosis?: DiagonosisType
  symptom?: SymptomInputType
  temperature?: TemperatureType 
  allergy?: AllergyType
  medicalDoc?: MedicalDoc
  febrileSeizures?: string | null
  others?: string | null
  conditionCheck?: string
  conditionCheckNote?: string | null
}

export type GoingTimeType = {
  goingToFacilityDate: string
  goingToFacilityTime: string
  goingHomeDate: string
  goingHomeTime: string
}

export type ShowMessage = {
  Date: string;
  message: string;
}

/**
 * 指定した受付Noの利用予約情報を返す。
 *
 * @param reservationNo 受付No
 * @returns 利用予約情報
 */
export const getReservation = async (reservationNo: string) => {
  const response = await executeGetReservation(reservationNo)
  const {
    usageDate,
    useFromDatetime,
    useToDatetime,
    canUpdate,
    canCancel,
    reservationDatetime,
    reservationChangeDatetime,
    name,
    kana,
    tel,
    email,
    childId,
    childName,
    childKana,
    childBirth,
    childGender,
    hasMedicalDocSubmitted,
    foodAllergyFlag,
    febrileSeizuresFlag,
    allergyFlag,
    conditionCheck,
    conditionCheckNote,
    goingToFacilityDatetime, 
    goingHomeDatetime,
    ...other
  } = response.result
  const bodyTemperatureArray = (other.bodyTemperature) ? other.bodyTemperature.split('.') : null

  // 病児病後児病児病後児関連書類
  const docs = [];
  for ( let i = 1; i <= 5; i++ ) {
  const medicalDocKey = `medicalDoc${i}` as keyof GetReservationDto;
  const fileNameKey = `medicalDoc${i}FileName` as keyof GetReservationDto;

  const medicalDoc = response.result[medicalDocKey] as string;
  const fileName = response.result[fileNameKey] as string;
  
  if ( !medicalDoc ) break;
  docs.push( {
        fileName: fileName,
        delFlg: Flag.OFF,
        tempUploadKey: medicalDoc
    })
  }
  
  // 登降園時間を使用しない設定（事業マスタ）場合は'-'でデータが渡ってくる
  const goingDatetimeConverted = ( goingToFacilityDatetime === '-' )? null: fromApiYmdHms(goingToFacilityDatetime);
  const goingHomeDatetimeConverted = ( goingHomeDatetime === '-' )? null: fromApiYmdHms(goingHomeDatetime);
  
  const useFromDatetimeConverted = fromApiYmdHms(useFromDatetime);
  const useToDatetimeConverted = fromApiYmdHms(useToDatetime);

  return nullPropsToUndefined({
    ...other,
    
    reservationNo,
    
    usageDate: dateToNumber(fromApiYmd(usageDate)),
    useFromDatetime: dateToNumber(useFromDatetimeConverted),
    useToDatetime: dateToNumber(useToDatetimeConverted),
    goingTime: {
      goingToFacilityDate: formatYmd(goingDatetimeConverted? goingDatetimeConverted: useFromDatetimeConverted),
      goingToFacilityTime: formatHm(goingDatetimeConverted? goingDatetimeConverted: useFromDatetimeConverted),
      goingHomeDate: formatYmd(goingHomeDatetimeConverted? goingHomeDatetimeConverted: useToDatetimeConverted),
      goingHomeTime: formatHm(goingHomeDatetimeConverted? goingHomeDatetimeConverted: useToDatetimeConverted)
    },
    parentInfo: {
      name: name,
      kana: kana,
      tel: tel,
      email: email
    },
    childInfoList: [
      {
        childId: childId,
        childInfo: {
          name: childName,
          kana: childKana,
          birthday: dateToNumber(childBirth),
          gender: childGender
        },
        diagonosis: {
          input: (other.diagonosis) ? other.diagonosis : null,
          additional: (other.diagonosisOther) ? other.diagonosisOther : null,
          input2: (other.diagonosis2) ? other.diagonosis2 : null,
          additional2: (other.diagonosisOther2) ? other.diagonosisOther2 : null,
        },
        symptom: {
          symptom: (other.symptoms) ? other.symptoms.split(',') : [],
          additional: (other.symptomOther) ? other.symptomOther : null,
        },
        temperature: {
          integer: (bodyTemperatureArray && bodyTemperatureArray.length) ? Number(bodyTemperatureArray[0]) : undefined,
          few:  (bodyTemperatureArray && bodyTemperatureArray.length >= 2 && bodyTemperatureArray[1] !== 'undefined' && bodyTemperatureArray[1] !== 'null') ? Number(bodyTemperatureArray[1]) : undefined,
        },
        allergy: {
          flag: allergyFlag,
          foodFlag: foodAllergyFlag
        },
        medicalDoc: {
          docs: docs,
          hasSubmitted: hasMedicalDocSubmitted?? Flag.OFF
        },
        febrileSeizures: febrileSeizuresFlag,
        others: null,
        conditionCheck: conditionCheck,
        conditionCheckNote: conditionCheckNote,
      }
    ],
    canUpdate: canUpdate === yesNo.yes,
    canCancel: canCancel === yesNo.yes,
    reservationDatetime: fromApiYmdHms(reservationDatetime),
    reservationChangeDatetime: fromApiYmdHms(reservationChangeDatetime),
  })
}

/** 利用予約情報の型 */
export type Reservation = ReturnType<typeof getReservation> extends Promise<infer T> ? T : never


/**
 * 症状文字列取得
 * @param param 判定値
 * @param otherParam その他入力情報
 * @param decodeMst デコードマスタデータ
 * @returns
 */
export const getSymptomsStr = (param: String[] | undefined, otherParam: string | null | undefined, decodeMst: PostDecodeMstDto[]) => {
  if (!param && !otherParam) {
    return ''
  }
  const tmpArray = param
  if (!tmpArray) return ''
  const response = [];
  for (const tmpData of tmpArray) {
    const findData: String | undefined = decodeMst?.find((c) => c.code === tmpData)?.name
    if (findData) {
      response.push(findData)
      continue
    }
  }

  if (otherParam) response.push(otherParam) 
  return response.join(', ')
}

/**
 * 症状文字列取得
 * @param params 判定値
 * @param decodeMst デコードマスタデータ
 * @returns
 */
export const getSymptomsArrasy = (params: String[] | undefined, decodeMst: PostDecodeMstDto[]) => {
  const symptom = Array(decodeMst.length).fill("");
  if (!params) return []
  if (!params.length) return []
  params.map((e) => {
    symptom[Number(e)] = e
  })

  return symptom
}

/**
 * 病名文字列取得
 * @param param 判定値
 * @param otherParam その他入力情報
 * @param decodeMst デコードマスタデータ
 * @returns
 */
export const getDiagonosisStr = (param: string | null | undefined, otherParam: string | null | undefined, decodeMst: PostDecodeMstDto[]) => {
  if ( !param ) {
    return ''
  }
  
  const targetDecodeMstData = decodeMst?.find((c) => c.code === param)
  const diagonosisName = targetDecodeMstData?.name || '';

  if ( diagonosisName.includes('その他') && otherParam) return otherParam || ''
  
  return diagonosisName.trimStart();
}

/**
 * 病児病後児関連書類提出ステータス
 */
export const MEDICAL_DOC_SUBMIT_STATUS = {
  UN_SUBMITTED: '未提出',
  UPLOADED: 'アップロード提出済',
  HANDED: '施設に直接提出済み'
} as const;

export type MedicalDocSubmitStatus = typeof MEDICAL_DOC_SUBMIT_STATUS[keyof typeof MEDICAL_DOC_SUBMIT_STATUS];

export const meicalDocSubmitStatusTranslationMap = {
  [MEDICAL_DOC_SUBMIT_STATUS.UN_SUBMITTED]: 'facilityReservation.medicalDocStatus.unsubmitted',
  [MEDICAL_DOC_SUBMIT_STATUS.UPLOADED]: 'facilityReservation.medicalDocStatus.uploaded',
  [MEDICAL_DOC_SUBMIT_STATUS.HANDED]: 'facilityReservation.medicalDocStatus.handed',
};

export const getMeicalDocSubmitStatus = (hasMedicalDocSubmitted: string | null | undefined, medicalDocList: DocType[]| null | undefined) => {
  if ( hasMedicalDocSubmitted === Flag.ON ) return MEDICAL_DOC_SUBMIT_STATUS.HANDED;
  const filteredMedicalDocList = medicalDocList?.filter(( medicalDoc ) => medicalDoc.fileName && medicalDoc.tempUploadKey && medicalDoc.delFlg === Flag.OFF)
  if ( medicalDocList && filteredMedicalDocList && filteredMedicalDocList.length > 0 ) return MEDICAL_DOC_SUBMIT_STATUS.UPLOADED;
  return MEDICAL_DOC_SUBMIT_STATUS.UN_SUBMITTED;
};

export const getMeicalDocSubmitStatusFromMeicalDoc1 = (hasMedicalDocSubmitted: string | null | undefined, medicalDoc1: string | null | undefined) => {
  if ( hasMedicalDocSubmitted === Flag.ON ) return MEDICAL_DOC_SUBMIT_STATUS.HANDED
  if ( medicalDoc1 ) return MEDICAL_DOC_SUBMIT_STATUS.UPLOADED;
  return MEDICAL_DOC_SUBMIT_STATUS.UN_SUBMITTED;
};

export const displayMeicalDocSubmitStatus = (hasMedicalDocSubmitted: string | null | undefined, medicalDocList: DocType[]| null | undefined) => {
  const status = getMeicalDocSubmitStatus(hasMedicalDocSubmitted, medicalDocList);
  return meicalDocSubmitStatusTranslationMap[status];
};

export const displayMeicalDocSubmitStatusFromMeicalDoc1 = (hasMedicalDocSubmitted: string | null | undefined, medicalDoc1: string| null | undefined) => {
  const status =getMeicalDocSubmitStatusFromMeicalDoc1(hasMedicalDocSubmitted, medicalDoc1);
  return meicalDocSubmitStatusTranslationMap[status];
};

/**
 * デコードマスタ
 */
export type ReservationSettingDecodeMstKey<T> = {
  diagonosis: T
  symptom: T
  conditionCheck: T
  consentCheck: T
}

export const DECODE_MST_INPUT_LIST: ReservationSettingDecodeMstKey<string> = {
  /** 病名 */
  diagonosis: 'diagonosis',
  // 症状
  symptom: 'symptom',
  // 児童の容体が変化した場合
  conditionCheck: 'condition_check',
  // 利用同意事項
  consentCheck: 'consent_check'
}

export type ReservationSettingDecodeMst = ReservationSettingDecodeMstKey<PostDecodeMstDto[]>

export const getReservationDecodeMstValue = async () => {
  return await getDecodeMstValue(DECODE_MST_INPUT_LIST)
}

export const setStringifyNamesChildInfoList = (childInfoList: ChildReservationInfoType[], decodeMstValue: ReservationSettingDecodeMst) => {
  return childInfoList.map((childInfo) => {
    const stringifyDiagonosis = getDiagonosisStr(childInfo?.diagonosis?.input, childInfo?.diagonosis?.additional, decodeMstValue.diagonosis)
    const stringifyDiagonosis2 = getDiagonosisStr(childInfo?.diagonosis?.input2, childInfo?.diagonosis?.additional2, decodeMstValue.diagonosis)
    const stringifySymptom = getSymptomsStr((childInfo?.symptom?.symptom) ? childInfo?.symptom?.symptom : [], childInfo?.symptom?.additional, decodeMstValue.symptom)
    const status = displayMeicalDocSubmitStatus(childInfo?.medicalDoc?.hasSubmitted, childInfo?.medicalDoc?.docs)
    
    return ({
      ...childInfo,
      diagonosis: 
      {
        input: childInfo?.diagonosis?.input?? null,
        additional: childInfo?.diagonosis?.additional?? null,
        input2: childInfo?.diagonosis?.input2?? null,
        additional2: childInfo?.diagonosis?.additional2?? null,
        stringify: stringifyDiagonosis,
        stringify2: stringifyDiagonosis2
      },
      symptom: {
        symptom: getSymptomsArrasy(childInfo?.symptom?.symptom, decodeMstValue.symptom),
        additional: childInfo?.symptom?.additional?? null,
        stringify: stringifySymptom 
      },
      temperature: {
        integer: childInfo?.temperature?.integer?? undefined,
        few: childInfo?.temperature?.few?? undefined
      },
      allergy: {
        flag: childInfo?.allergy?.flag?? Flag.OFF,
        foodFlag: childInfo?.allergy?.foodFlag?? Flag.OFF
      } ,
      medicalDoc: {
        docs: childInfo?.medicalDoc?.docs?? [],
        hasSubmitted: childInfo?.medicalDoc?.hasSubmitted?? Flag.OFF,
        status: status
      },
      febrileSeizures: childInfo?.febrileSeizures?? Flag.OFF,
      others: childInfo?.others?? null,
      conditionCheck: childInfo?.conditionCheck ?? undefined,
      conditionCheckNote: childInfo?.conditionCheckNote ?? null,
    })
  })
}
