// 項目バリデーションチェック処理を定義
//

import { translate } from '../../i18n'
import { ValueType } from '../../utils/typeUtil'

type Validate = (isValid: boolean, message: string) => string | true
const validate: Validate = (isValid: boolean, message: string) => isValid || message

/**
 * @param value 文字列
 * @returns 文字列が郵便番号として妥当ならtrue
 */
export const isValidPostalCode = (value: string) => /^[0-9]{3}-[0-9]{4}$/.test(value)

/**
 * @param value 文字列
 * @returns 文字列が電話番号として妥当ならtrue
 */
export const isValidTel = (value: string) => value.length <= 13 && /^[0-9]+-[0-9]+-[0-9]+$/.test(value)

/**
 * メールアドレスの妥当性チェック。
 * 本番環境とそれ以外の環境でチェック制限が異なる。
 * ※テストで複数アカウントを作成しやすくする為
 *
 * 本番環境：Gmailのエイリアスで追加したアドレスを許容しない。
 * その他環境：Gmailのエイリアスで追加したアドレスを許容する。※xxx+xxxx@xxxx.xxx を許す
 *
 * @param value 文字列
 * @returns 文字列がメールアドレスとして妥当ならtrue
 */
export const isValidEmail = (value: string) =>
  /^(?:[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)

/**
 * @param value 文字列
 * @returns 文字列がカタカナ入力の値として妥当ならtrue
 */
export const isValidKatakana = (value: string) => /^[\u30A1-\u30F6\u30FC 　]+$/.test(value)

/**
 * REQUIRES_SYMBOLS の特殊文字
 *
 * https://github.com/aws-amplify/amplify-ui/blob/main/packages/ui/src/machines/authenticator/defaultServices.ts
 */
const cognitoAllowedSpecialCharacters = [
  '^',
  '$',
  '*',
  '.',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
  '=',
  '+',
  '-',
  ' ',
] as const
/** パスワードの最小文字数 */
const cognitoPasswordMinLength = 8
/** パスワードの最大文字数 */
export const cognitoPasswordMaxLength = 256

/** パスワード要件の文字タイプ */
const passwordCharType = {
  requiresLowercase: 1,
  requiresUppercase: 2,
  requiresNumbers: 3,
  requiresSymbols: 4,
} as const

/**
 * amplify validateFormPassword の実装を元に
 * パスワード
 * @param password 文字列
 * @returns 文字列がパスワードの値として妥当ならtrue
 */
export const isValidPassword = (password: string) => {
  if (password.length < cognitoPasswordMinLength) {
    // 最小文字数
    return false
  }
  if (/^ | $/.test(password)) {
    // 先頭、末尾のスペース
    return false
  }

  const charTypeSet = new Set<ValueType<typeof passwordCharType>>()

  for (const pwdChar of password) {
    if (/[a-z]/.test(pwdChar)) {
      charTypeSet.add(passwordCharType.requiresLowercase)
    } else if (/[A-Z]/.test(pwdChar)) {
      charTypeSet.add(passwordCharType.requiresUppercase)
    } else if (/[0-9]/.test(pwdChar)) {
      charTypeSet.add(passwordCharType.requiresNumbers)
    } else if (cognitoAllowedSpecialCharacters.some((char) => char === pwdChar)) {
      charTypeSet.add(passwordCharType.requiresSymbols)
    } else {
      // 許可文字列以外
      return false
    }
  }

  return charTypeSet.size === Object.keys(passwordCharType).length
}

/**
 * 必須バリデータ関数を返す
 * @param label 項目ラベル
 * @returns {Validate}
 */
export const requiredValidator = (label: string) => {
  return (value: string) => validate(!!value.trimEnd(), translate('system.error.requiredInput', label))
}

/**
 * 電話番号バリデータ関数を返す
 * @returns {Validate}
 */
export const telValidator = () => {
  return (value: string) => validate(value.length === 0 || isValidTel(value), translate('system.error.validTel'))
}

/**
 * 郵便番号バリデータ関数を返す
 * @returns {Validate}
 */
export const postalCodeValidator = () => {
  return (value: string) =>
    validate(value.length === 0 || isValidPostalCode(value), translate('system.error.validPostalCode'))
}

/**
 * メールアドレスバリデータ関数を返す
 * @returns {Validate}
 */
export const emailValidator = () => {
  return (value: string) => validate(value.length === 0 || isValidEmail(value), translate('system.error.validEmail'))
}

/**
 * カタカナバリデータ関数を返す
 * @returns {Validate}
 */
export const katakanaValidator = () => {
  return (value: string) =>
    validate(value.length === 0 || isValidKatakana(value), translate('system.error.validKatakana'))
}

/**
 * パスワードバリデータ関数を返す
 * @returns {Validate}
 */
export const passwordValidator = () => {
  return (value: string) =>
    validate(value.length === 0 || isValidPassword(value), translate('system.error.validPassword'))
}
