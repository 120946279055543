export interface GetAccountDto {
  email: string
  tel: string
  ssoUserId: string | null
}

export interface GetAccountIsSsoFirstLoginDto {
  isSsoFirstLogin: boolean
  loginUser: {
    email: string
  }
}

export interface PutAccountInputDto {
  accessToken: string
  email: string | null
  tel: string | null
  password: string | null
  newPassword: string | null
}

export interface PostAccountInputDto {
  accessToken: string
  category: string
  passcode: string
}

/** PostAccountIdentityVerifySubmitInputDtoのcategory */
export const accountIdentityVerifySubmitCategory = {
  email: '1',
  tel: '2',
} as const

export interface PostAccountIdentityVerifySubmitInputDto {
  accessToken: string
  category: string
  passcode: string
}

export interface PutAccountIdentityVerifyResendEmailInputDto {
  accessToken: string
  email: string
}
