import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface ResetPasswordEntry {
  email?: string,
  error?: string,
  isSuccessResetPassword?: boolean,
}

interface ResetPasswordState {
  entry?: ResetPasswordEntry
}

const initialState: ResetPasswordState = {}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setResetPassword: (state, action: PayloadAction<ResetPasswordEntry>) => {
      state.entry = action.payload
    },
    clearResetPassword: (state) => {
      delete state.entry
    },
  },
})
export const { setResetPassword, clearResetPassword } = resetPasswordSlice.actions
export const selectResetPasswordEntry = (state: RootState) => state.resetPassword.entry
export const selectResetPasswordEmail = (state: RootState) => state.resetPassword.entry?.email
export const selectResetPasswordError = (state: RootState) => state.resetPassword.entry?.error
export const selectIsSuccessResetPassword = (state: RootState) => state.resetPassword.entry?.isSuccessResetPassword
export default resetPasswordSlice.reducer
