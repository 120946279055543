import { Stack, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { couponMinuteStepUnit } from '../../../containers/common/constant/couponSetting'
import { useErrorHandle } from '../../../containers/common/error/errorHandler'
import { showLoading } from '../../../containers/common/store/slices/application'
import { translate } from '../../../i18n'
import { castNonNullable } from '../../../utils/typeUtil'
import { ButtonOutlinedWarningL } from '../common/buttons/buttonOutlinedWarningL'
import { Dialog, DialogContent } from '../common/dialog'
import { ErrorMessageCard } from '../common/errorMessageCard'
import { FlexboxCenter } from '../common/flexboxes'
import { TimeAmountSelect } from '../common/inputs/timeAmountSelect'
import { TitleLabel } from '../common/titleLabel'

const ButtonBox = styled('div')({
  width: '19rem',
})
const StyledDialogContent = styled(DialogContent)({
  paddingLeft: 8,
  paddingRight: 8,
})

interface CouponUsageDialogProps {
  isOpen: boolean
  onClose: (isDecision?: boolean) => void
  maxAmount: number
  decision: (use: number) => Promise<{ errorMessage: string } | void>
}

interface Inputs {
  usageTimeAmount: number | null
}

export const CouponUsageDialog = (props: CouponUsageDialogProps) => {
  const { isOpen, onClose, decision, maxAmount } = props

  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()

  const [errorMessages, setErrorMessages] = useState<string[]>()

  const formMethods = useForm<Inputs>()

  useEffect(() => {
    if (isOpen) {
      setErrorMessages(undefined)
      formMethods.reset({ usageTimeAmount: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onSubmit = useCallback(
    (data: Inputs) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const result = await decision(castNonNullable(data.usageTimeAmount))
            if (result) {
              setErrorMessages([result.errorMessage])
            } else {
              await onClose(true)
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [dispatch, errorHandle, decision, onClose]
  )

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      formMethods={formMethods}
      buttons={
        <ButtonBox>
          <ButtonOutlinedWarningL type="submit" fullWidth>
            {translate('facilityReservationDetail.button.decision')}
          </ButtonOutlinedWarningL>
        </ButtonBox>
      }
      maxWidth="xs"
      fullWidth
      contentComponent={StyledDialogContent}
    >
      <Stack spacing={1}>
        <FlexboxCenter>
          <TitleLabel>{translate('facilityReservationDetail.label.usageTimeAmountInput')}</TitleLabel>
        </FlexboxCenter>
        <FlexboxCenter>
          <TimeAmountSelect
            name="usageTimeAmount"
            label={translate('facilityReservationDetail.label.usageTimeAmount')}
            maxMinute={maxAmount}
            minuteStepUnit={couponMinuteStepUnit}
            required
          />
        </FlexboxCenter>
        {!!errorMessages?.length && <ErrorMessageCard messages={errorMessages} />}
      </Stack>
    </Dialog>
  )
}
