import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

interface ChildEntry {
  name: string
  kana: string
  gender: string
  birthday: ElapsedMillisecond
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
}

export interface AccountCreateEntry {
  name: string
  kana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  residenceCategoryName: string
  relationship: string
  tel: string
  email: string
  emailConfirmation: string
  password: string
  passwordConfirmation: string
  ssoLinkedParams?: {
    id: string
    email: string
  }

  children: ChildEntry[]
}

interface AccountCreateState {
  entry?: AccountCreateEntry
}

const initialState: AccountCreateState = {}

export const accountCreateSlice = createSlice({
  name: 'accountCreate',
  initialState,
  reducers: {
    setAccountCreate: (state, action: PayloadAction<AccountCreateEntry>) => {
      state.entry = action.payload
    },
    clearAccountCreate: (state) => {
      delete state.entry
    },
  },
})
export const { setAccountCreate, clearAccountCreate } = accountCreateSlice.actions
export const selectAccountCreateEntry = (state: RootState) => state.accountCreate.entry
export default accountCreateSlice.reducer
