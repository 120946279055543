import { Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/interviewReservationDetail/interviewReservationDetailService'
import { translate } from '../../i18n'
import { AlertCard } from '../components/common/alertCard'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { InfoCard } from '../components/common/infoCard'
import { InterviewDetailContent } from '../components/common/reservationDetail/interviewDetailContent'
import { interviewStatus } from '../../containers/common/constant/classification'
import { getCancelLimitHolidayOptionLabel } from '../../containers/common/codeMaster'
import { formatCancelLimit } from '../../utils/itemFormatUtil'

const AnnotationBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

export const InterviewReservationDetail = () => {
  const {
    interviewDetail,
    goCancel,
    goChange
  } = useAction()

  if (interviewDetail == null) {
    // 面談情報なし
    return null
  }

  // キャンセルについての注意文言設定
  const { cancelLimit, cancelLimitTime, cancelNote, cancelLimitHolidayOption } = interviewDetail
  const alertCardConents = []
  if (cancelLimit != null && cancelLimitTime != null) {
    alertCardConents.push(
      <div key="cancelLimit">
        {translate('interviewReservationDetail.card.cancelLimit')}：{formatCancelLimit(cancelLimit, cancelLimitTime, true)}
      </div>
    )
    if (cancelLimitHolidayOption) {
      alertCardConents.push(
        <div key="holidayOption">※{getCancelLimitHolidayOptionLabel(cancelLimitHolidayOption)}</div>
      )
    }
  }
  if (cancelNote) {
    if (alertCardConents.length) {
      alertCardConents.push(<div key="cancelNoteSep">&nbsp;</div>)
    }
    alertCardConents.push(<AnnotationBox key="cancelNote">{cancelNote}</AnnotationBox>)
  }

  return (
    <Stack spacing={2}>
      <Grid container rowSpacing={2}>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            {
              interviewDetail.canCancel &&
              interviewDetail.status !== interviewStatus.notFixed &&
              interviewDetail.status !== interviewStatus.interviewed &&
              interviewDetail.status !== interviewStatus.canceled &&
              interviewDetail.status !== interviewStatus.canceledWait &&
              !!alertCardConents.length &&
              <AlertCard>{alertCardConents.map((v) => v)}</AlertCard>
            }

            {
              !interviewDetail.canCancel &&
              !interviewDetail.canUpdate &&
              <AlertCard>・{translate('interviewReservationDetail.card.inquiry')}</AlertCard>
            }

            <InfoCard>
              <Grid container>
                <InterviewDetailContent interview={interviewDetail} displayType="reference" />
              </Grid>
            </InfoCard>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        {
          interviewDetail.status === interviewStatus.wait
            ? (interviewDetail.canCancelWait && (
                <ButtonOutlinedWarningL fullWidth onClick={() => goCancel(interviewDetail.status)}>
                  {translate('interviewReservationDetail.button.waitCancel')}
                </ButtonOutlinedWarningL>
              ))
            : (interviewDetail.canCancel && (
                <ButtonOutlinedWarningL fullWidth onClick={() => goCancel(interviewDetail.status)}>
                  {translate('interviewReservationDetail.button.reservationCancel')}
                </ButtonOutlinedWarningL>
              ))
        }
        {interviewDetail.canUpdate && (
          <ButtonL fullWidth onClick={goChange}>
            {translate('system.button.change')}
          </ButtonL>
        )}
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
