import { styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface SuccessStatusLabelProps {
  children: ReactNode
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
}

const Root = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
}))

/** 注目させたい文言に使用する赤字のラベルコントロール */
export const SuccessStatusLabel = (props: SuccessStatusLabelProps) => {
  return <Root className={props.className}>{props.children}</Root>
}
