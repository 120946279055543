import { Stack, styled } from '@mui/material'
import { useAction } from '../../containers/accountUnsubscribe/accountUnsubscribeService'
import { yesNo } from '../../containers/common/constant/classification'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonErrorL } from '../components/common/buttons/ButtonErrorL'
import { BackButton } from '../components/common/buttons/backButton'
import { CenterGItem, GContainer, GItem } from '../components/common/grids'
import { CheckBox } from '../components/common/inputs/checkBox'
import { Form } from '../components/common/inputs/form'
import { TransitionCheck } from '../components/common/transitionCheck'
import { ErrorMessageCard } from '../components/common/errorMessageCard'

const ChecklistBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

export const AccountUnsubscribe = () => {
  // const { sysCtrl, formMethods, onSubmit, unsubscribeGuideText, deleteResultCode } = useAction()
  const { sysCtrl, formMethods, onSubmit, deleteResultCode } = useAction()

  if (sysCtrl.unsubscribeAcceptFlag !== yesNo.yes) {
    return <TransitionCheck check={false} />
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <GContainer rowSpacing={1}>
              <GItem xs={12}>{translate('accountUnsubscribe.title.about')}</GItem>
              <GItem xs={12}>
                {/* <ChecklistBox>{unsubscribeGuideText}</ChecklistBox> */}
                <ChecklistBox>{sysCtrl.descriptionForUnsubscribe ?? ''}</ChecklistBox>
              </GItem>
              <CenterGItem xs={12}>
                <CheckBox
                  name="isConfirmation"
                  label={translate('accountUnsubscribe.label.isConfirmation')}
                  valicationMessageKeys={{ required: 'accountUnsubscribe.error.confirmation' }}
                  valueAtCheck={yesNo.yes}
                  required
                >
                  {translate('accountUnsubscribe.checkbox.isConfirmation')}
                </CheckBox>
              </CenterGItem>
            </GContainer>
            {!!deleteResultCode && (
              <ErrorMessageCard
                messages={[translate(`accountUnsubscribe.error.deleteResultCode${deleteResultCode}`)]}
              />
            )}
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
        <BottomButtonGroup>
          <ButtonErrorL fullWidth type="submit">
            {translate('accountUnsubscribe.button.forward')}
          </ButtonErrorL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
