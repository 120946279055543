import { Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { getBloodTypes, getCovereds, getGenders, getYesNos } from '../../containers/common/codeMaster'
import { useAction } from '../../containers/memberModifyChild/memberModifyChildService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { DatePicker } from '../components/common/inputs/datePicker'
import { Form } from '../components/common/inputs/form'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TextBox } from '../components/common/inputs/textBox'
import { SubTitle } from '../components/common/subTitle'

const ChildContentAttribute = styled(Grid)({
  padding: '0 0 0 3rem',
})

export const MemberModifyChild = () => {
  const { formMethods, onSubmit, childBirthdayLimitDate, interviewPermitCount } = useAction()

  let isDisabledBirthday = false
  if (interviewPermitCount && interviewPermitCount > 0) {
    isDisabledBirthday = true
  }
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              {/* 基本 */}
              <SubTitle title={translate('memberModifyChild.title.basic')} />
              <div>
                <ChildContentAttribute container rowSpacing={3}>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.name')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox name="name" label={translate('memberModifyChild.label.name')} maxLength={100} required />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.kana')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        name="kana"
                        label={translate('memberModifyChild.label.kana')}
                        maxLength={100}
                        textType="katakana"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.birthday')}
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        name="birthday"
                        disabled={isDisabledBirthday}
                        label={translate('memberModifyChild.label.birthday')}
                        maxDate={childBirthdayLimitDate}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.hasBrothersSisters')}
                    </Grid>
                    <Grid item xs={12}>
                      <RadioButtonGroup
                        name="brothersSistersFlag"
                        label={translate('memberModifyChild.label.hasBrothersSisters')}
                        buttonValueLabels={getYesNos().reverse()}
                        row
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.isMultipleBirths')}
                    </Grid>
                    <Grid item xs={12}>
                      <RadioButtonGroup
                        name="multipleBirthsFlag"
                        label={translate('memberModifyChild.label.isMultipleBirths')}
                        buttonValueLabels={getCovereds().reverse()}
                        row
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.gender')}
                    </Grid>
                    <Grid item xs={12}>
                      <RadioButtonGroup
                        name="gender"
                        label={translate('memberModifyChild.label.gender')}
                        buttonValueLabels={getGenders()}
                        row
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.bloodType')}
                    </Grid>
                    <Grid item xs={12}>
                      <RadioButtonGroup
                        name="bloodType"
                        label={translate('memberModifyChild.label.bloodType')}
                        buttonValueLabels={getBloodTypes()}
                        row
                        required
                      />
                    </Grid>
                  </Grid>
                  {/*
                  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyChild.label.maternalHandbookNo')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="maternalHandbookNo"
                        label={translate('memberModifyChild.label.maternalHandbookNo')}
                        maxLength={20}
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  */}
                </ChildContentAttribute>
              </div>
              {/* アレルギー */}
              <SubTitle title={translate('memberModifyChild.title.allergy')} />
              <div>
                <ChildContentAttribute container rowSpacing={3}>
                  <Grid item xs={12}>
                    {translate('memberModifyUser.text.exempt')}
                  </Grid>
                </ChildContentAttribute>
              </div>
            </Stack>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>

        <BottomButtonGroup>
          <ButtonL fullWidth type="submit">
            {translate('memberModifyChild.button.confirmation')}
          </ButtonL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
