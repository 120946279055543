import React, { useState, useEffect } from 'react';
import { GItem } from "../grids"
import { Control, useWatch } from 'react-hook-form';
import { Inputs, facilityReservationInputKey } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import { Select } from '../inputs/select'
import { TextBox } from '../inputs/textBox'
import { translate } from '../../../../i18n'

interface CustomSelectBoxProps {
    inputName: string
    additionalName: string
    required: boolean
    options?: PostDecodeMstDto[]
    addUnselected?: boolean
    control: Control<Inputs>
}

const DeseaseSelectBox: React.FC<CustomSelectBoxProps> = ({ inputName, additionalName, required, control, options, addUnselected }) => {
    const inputWatch = useWatch({ name: inputName as facilityReservationInputKey, control: control })
    const [ currentInput, setCurrentInput ] = useState<string>();
    
    useEffect(() => {
        if ( inputWatch === currentInput ) return;
        if ( typeof inputWatch !== 'string' ) return;
        setCurrentInput(inputWatch)
    }, [ inputWatch ])
    
    const optionsConvertedToLabelValue = options?.map(( option ) => ({ label: option.name, value: option.code }));
    if (addUnselected) {
        optionsConvertedToLabelValue?.unshift({
            label: translate('facilityReservationForm.form.common.unselected'),
            value: '',
        })
    }
    
    const otherOptions = 'その他';
    const shouldShowAdditional = () => {
        if ( !optionsConvertedToLabelValue ) return false;
        const label = optionsConvertedToLabelValue.find(( option ) => option.value === currentInput)?.label;
        
        if ( label && label.includes(otherOptions) ) return true;
        return false;
    }

    return (
        <>
            <GItem xs={12}>
                <Select
                  fullWidth
                  name={inputName}
                  label={translate('facilityReservationForm.form.label.diagonosis')}
                  valueLabels={optionsConvertedToLabelValue??[]}
                  required={required}
                />
            </GItem>
            
            { 
                ( currentInput && shouldShowAdditional() ) && (
                    <GItem xs={12} sx={{ mt: 1 }}>
                        <TextBox 
                            name={additionalName} 
                            label={translate('facilityReservationForm.form.label.diagonosis')}
                            maxLength={100} 
                            rows={2}
                            multiline 
                            required
                        />
                    </GItem>
                )
            }
        </>

    );
};

export default DeseaseSelectBox;
