import { useState, useEffect } from 'react';
import { CheckBoxSelections } from '../inputs/checkBoxSelections'
import { Control, useWatch } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { GItem, LabelGItemBase } from '../grids';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import { TextBox } from '../inputs/textBox';
import { translate } from '../../../../i18n'

interface ReservationDetailSymptomProps {
    name: string
    otherName: string
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
    checkedSymptom: string[]
}

const ReservationDetailSymptom = (props: ReservationDetailSymptomProps) => {
    const [ checkBoxRequired, setCheckBoxRequired ] = useState<boolean>(true);
    const { name, otherName, control, options } = props;
    
    const valueLabels = options?.map(( option ) => (
        {
            value: option.code,
            label: option.name
        }
    ))
    
    const otherWatch = useWatch({
        control,
        name: otherName as keyof Inputs
    })
    
    useEffect(() => {
        if ( otherWatch && typeof otherWatch === 'string' && otherWatch.length > 0 ) {
            if ( checkBoxRequired ) {
                setCheckBoxRequired(false);
            }
        } else {
            if ( !checkBoxRequired ) {
                setCheckBoxRequired(true);
            }
        }
    }, [ otherWatch ])
    


    return (
        <>
            <CheckBoxSelections 
              name={name}
              label={translate('facilityReservationForm.form.medical.symptoms')}
              valueLabels={valueLabels??[]}
              control={control}
              required={ checkBoxRequired }
            />
            {/** その他 */}
            <GItem xs={12} sx={{ mt: 1 }}>
                <LabelGItemBase>{translate('facilityReservationForm.form.medical.symptomsOther')}</LabelGItemBase>
                <TextBox 
                    name={otherName} 
                    label={translate('facilityReservationForm.form.medical.symptomsOther')}
                    maxLength={100} 
                    rows={2}
                    multiline 
                    required={ !checkBoxRequired }
                />
            </GItem>
        </>
    )
}

export default ReservationDetailSymptom;
