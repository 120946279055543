export const MEDICAL_DOC_SUBMIT_STATUS = {
    NOT_YET: '未提出',
    UPLOADED: 'アップロード提出済',
    HANDED: '施設に直接提出済み'
  } as const;

// 提供証明書発行ステータス
export const PROVISIONED_CERTIFICATE_STATUS = {
  /** 未発行 */
  UNISSUED: '1',
  /** 発行済 */
  ISSUE: '2'
} as const
export type PROVISIONED_CERTIFICATE_STATUS =
  (typeof PROVISIONED_CERTIFICATE_STATUS)[keyof typeof PROVISIONED_CERTIFICATE_STATUS]

//
export const PROVISION_CERTIFICATE_USER_REISSUE_STATUS = {
  /** 再発行不可 */
  REISSUE_DISABLED: '1',
  /** 再発行可 */
  REISSUE_ALLOWED: '2',
  /** 差分のみ発行可 */
  DIFFERENCE_ALLOWED: '3',
} as const
export type PROVISION_CERTIFICATE_USER_REISSUE_STATUS =
  (typeof PROVISION_CERTIFICATE_USER_REISSUE_STATUS)[keyof typeof PROVISION_CERTIFICATE_USER_REISSUE_STATUS]
