import { Box, Stack, styled } from '@mui/material'
import { facilityDetailUrl } from '../../containers/common/constant/appUrl'
import { reservationChangeRestriction, yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityReservationSelection/facilityReservationSelectionService'
import { translate } from '../../i18n'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem, MiddleGItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import {
  AvailabilityCalendar,
  calendarChangeRestriction,
  CalendarChangeRestrictionType,
} from '../components/common/inputs/availabilityCalendar'
import { DatePickerNoBind } from '../components/common/inputs/datePickerNoBind'
import { Form } from '../components/common/inputs/form'
// import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { StepBar } from '../components/common/stepBar'
import { TitleLabel } from '../components/common/titleLabel'
import { OperationId } from '../../containers/common/constant/operationLog'
import { AttentionLabel } from '../components/common/attentionLabel'
import { UseReasonRadioGroup } from '../components/facilityReservationForm/useReasonRadioGroup'
import { ReservationChildSelection } from '../components/common/reservation/reservationChildSelection'

const FacilityNoteCard = styled(InfoCard)({
  whiteSpace: 'pre-wrap',
})

/** 施設の予約変更制限値とカレンダーコントロールの変更制限値のマッピング */
const reservationChangeRestrictionToCalendarChangeableMap: Readonly<Record<string, CalendarChangeRestrictionType>> = {
  [reservationChangeRestriction.allowChangeDatetime]: calendarChangeRestriction.datetime,
  [reservationChangeRestriction.allowChangeTimeOnly]: calendarChangeRestriction.time,
  [reservationChangeRestriction.notAllowChangeDatetime]: calendarChangeRestriction.none,
} as const

interface FacilityReservationSelection {
  usageGuideStr: string
}

// だし分けが必要 => 一旦非表示に統一
// const renderText = () => {
//   return (
//     <Box>お子さま、利用目的、利用希望日を選択してください。</Box>
//   )
// }

/** 共通設定時のレンダリング */
const FacilityReservationSelectionCommon = (props: FacilityReservationSelection) => {
  const {
    facilityId,
    facility,
    childs,
    activeDate,
    availabilities,
    initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    changeSelectedChildIds,
    changeDropDownSelectedChildId,
    changeDate,
    useReason,
    purposeOfUse,
    useReasonDetailIrregular,
    useReasonDetailEmg,
    useReasonDetailRefresh,
    changeUseReason,
    multiChildReservationAvailabilityFlag,
    selectedChildrenIds,
    isCertificateUseReasonViewFlags,
  } = useAction()
  
  if (facilityId == null || facility == null) {
    return null
  }
  
  const {
    usageGuideStr,
  } = props
  
  return (
    <Stack spacing={2}>
      <Form onSubmit={onSubmit} formMethods={formMethods}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Box>
              <GContainer rowSpacing={1}>
                <GItem xs={12}>
                  <StepBar
                    activeStepValue="①"
                    stepValueLabels={[
                      { value: '①', label: '利用希望日' },
                      { value: '②', label: '必要事項入力' },
                      { value: '③', label: '入力内容確認' },
                      { value: '④', label: '完了' },
                    ]}
                  />
                </GItem>
  
                <GItem xs={12}>
                  <Link to={facilityDetailUrl.url(facilityId)} operationLog={{ operationId: OperationId.OP_00000027 }}>
                    <TitleLabel>{facility.facilityName}</TitleLabel>
                  </Link>
                </GItem>

                {/** 
                  10/09 非表示統一に一旦変更
                  <GItem xs={12}>{renderText()}</GItem>
                */}
  
                <GItem xs={12}>
                  <GContainer>
                    <ReservationChildSelection
                      changeSelectedChildIds={changeSelectedChildIds}
                      changeDropDownSelectedChildId={changeDropDownSelectedChildId}
                      childs={childs}
                      control={formMethods.control}
                      multiChildReservationAvailabilityFlag={multiChildReservationAvailabilityFlag}
                      checkedChildren={selectedChildrenIds}
                    />
                  </GContainer>
  
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      {translate('facilityReservationSelection.label.selectTheDesiredDateOfUse')}
                    </MiddleGItem>
                    <MiddleGItem xs={7} md={8}>
                      <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} />
                    </MiddleGItem>
                    <RowSpacerGItem />
                  </GContainer>
                </GItem>
  
                <GItem xs={12}>
                  <Stack spacing={2}>
                    <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>
  
                    <AvailabilityCalendar
                      name="usageDesiredDatetimes"
                      label={translate('facilityReservationSelection.label.availabilityCalendar')}
                      required
                      baseDate={activeDate}
                      previousRange={previousRange}
                      receptionTimePattern={facility.receptionTimePattern}
                      availabilities={availabilities ?? []}
                      initialValidationAvailabilities={initialValidationAvailabilities}
                      isMultiple={previousRange == null && facility.citizenBulkReservationAcceptFlag === yesNo.yes}
                      onChangeBaseDate={changeDate}
                      control={formMethods.control}
                      changeRestriction={
                        reservationChangeRestrictionToCalendarChangeableMap[facility.reservationChangeRestriction]
                      }
                    />
                  </Stack>
                </GItem>
                <GItem xs={12}>
                  {facility && facility.detailSetting.length && (
                    <FacilityNoteCard >{facility.detailSetting[0].note}</FacilityNoteCard>
                  )}
                </GItem>
                {
                  (
                    (facility?.irregularFlag === yesNo.yes                    // 施設情報設定＞基本情報＞利用目的で設定されるフラグ
                      && facility?.useReasonUsageFlagIrregular === yesNo.yes)  // 事業マスタで設定される非定型を利用するか否かを指定するフラグ
                    || (facility?.emergencyFlag === yesNo.yes                // 施設情報設定＞基本情報＞利用目的で設定するフラグ
                      && facility?.useReasonUsageFlagEmg === yesNo.yes)        // 事業マスタで設定される緊急を利用するか否かを指定するフラグ
                    || (facility?.refreshFlag === yesNo.yes                  // 施設情報設定＞基本情報＞利用目的で設定するフラグ
                      && facility?.useReasonUsageFlagRefresh === yesNo.yes)    // 事業マスタで設定されるリフレッシュを利用するか否かを指定するフラグ
                  ) && (
                    <GItem xs={12}>
                      <MiddleGItem xs={5} md={4}>
                        ご利用目的<AttentionLabel>*</AttentionLabel>
                      </MiddleGItem>
                      <MiddleGItem xs={7} md={8}>
                        <UseReasonRadioGroup
                          useReason={useReason}
                          facility={facility}
                          purposeOfUse={purposeOfUse}
                          useReasonDetailIrregular={useReasonDetailIrregular}
                          useReasonDetailEmg={useReasonDetailEmg}
                          useReasonDetailRefresh={useReasonDetailRefresh}
                          isCertificateUseReasonViewFlags={isCertificateUseReasonViewFlags}
                          onChange={changeUseReason}
                        />
                      </MiddleGItem>
                      <RowSpacerGItem />
                    </GItem>
                  )
                }
              </GContainer>
            </Box>
            <div>{translate('facilityReservationSelection.label.annotation')}</div>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
  
        <BottomButtonGroup>
          <ButtonOutlinedWarningL type="submit" fullWidth>
            {translate('facilityReservationSelection.button.applyOnTheSelectedSchedule')}
          </ButtonOutlinedWarningL>
          <BackButton />
        </BottomButtonGroup>
      </Form>
    </Stack> 
  )
}

/** 利用目的設定時のレンダリング */
const FacilityReservationSelectionUseReason = (props: FacilityReservationSelection) => {
  const {
    facilityId,
    facility,
    childs,
    activeDate,
    availabilities,
    initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    changeSelectedChildIds,
    changeDropDownSelectedChildId,
    changeDate,
    useReason,
    purposeOfUse,
    useReasonDetailIrregular,
    useReasonDetailEmg,
    useReasonDetailRefresh,
    changeUseReason,
    isCheckUseReason,
    multiChildReservationAvailabilityFlag,
    selectedChildrenIds,
    isCertificateUseReasonViewFlags,
  } = useAction()
  if (facilityId == null || facility == null) {
    return null
  }
  
  const {
    usageGuideStr,
  } = props

  return (
    <Stack spacing={2}>
      <Form onSubmit={onSubmit} formMethods={formMethods}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Box>
              <GContainer rowSpacing={1}>
                <GItem xs={12}>
                  <StepBar
                    activeStepValue="①"
                    stepValueLabels={[
                      { value: '①', label: '利用希望日' },
                      { value: '②', label: '必要事項入力' },
                      { value: '③', label: '入力内容確認' },
                      { value: '④', label: '完了' },
                    ]}
                  />
                </GItem>
  
                <GItem xs={12}>
                  <Link to={facilityDetailUrl.url(facilityId)} operationLog={{ operationId: OperationId.OP_00000027 }}>
                    <TitleLabel>{facility.facilityName}</TitleLabel>
                  </Link>
                </GItem>
                {/** 
                  10/09 非表示統一に一旦変更
                  <GItem xs={12}>{renderText()}</GItem>
                */}
                <GItem xs={12}>
                  <GContainer>
                    <ReservationChildSelection
                      changeSelectedChildIds={changeSelectedChildIds}
                      changeDropDownSelectedChildId={changeDropDownSelectedChildId}
                      childs={childs}
                      control={formMethods.control}
                      multiChildReservationAvailabilityFlag={multiChildReservationAvailabilityFlag}
                      checkedChildren={selectedChildrenIds}
                    />
                  </GContainer>
                  
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      ご利用目的<AttentionLabel>*</AttentionLabel>
                    </MiddleGItem>
                    <MiddleGItem xs={7} md={8} mb={1}>
                      <UseReasonRadioGroup
                        useReason={useReason}
                        facility={facility}
                        purposeOfUse={purposeOfUse}
                        useReasonDetailIrregular={useReasonDetailIrregular}
                        useReasonDetailEmg={useReasonDetailEmg}
                        useReasonDetailRefresh={useReasonDetailRefresh}
                        isCertificateUseReasonViewFlags={isCertificateUseReasonViewFlags}
                        onChange={changeUseReason}
                      />
                    </MiddleGItem>
                  </GContainer>
  
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      {translate('facilityReservationSelection.label.selectTheDesiredDateOfUse')}
                    </MiddleGItem>
                    <MiddleGItem xs={7} md={8}>
                      <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} disabled={(purposeOfUse === undefined)}/>
                    </MiddleGItem>
                    <RowSpacerGItem />
                  </GContainer>
                </GItem>
                
                {(isCheckUseReason === true) && (
                  <>
                    <GItem xs={12}>
                      <Stack spacing={2}>
                        <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>
      
                        <AvailabilityCalendar
                          name="usageDesiredDatetimes"
                          label={translate('facilityReservationSelection.label.availabilityCalendar')}
                          required
                          baseDate={activeDate}
                          previousRange={previousRange}
                          receptionTimePattern={facility.receptionTimePattern}
                          availabilities={availabilities ?? []}
                          initialValidationAvailabilities={initialValidationAvailabilities}
                          isMultiple={previousRange == null && facility.citizenBulkReservationAcceptFlag === yesNo.yes}
                          onChangeBaseDate={changeDate}
                          control={formMethods.control}
                          changeRestriction={
                            reservationChangeRestrictionToCalendarChangeableMap[facility.reservationChangeRestriction]
                          }
                        />
                      </Stack>
                    </GItem>
                    <GItem xs={12}>
                      {facility && facility.detailSetting.length && (
                        <FacilityNoteCard >{facility.detailSetting.find((v) => v.useReasonCode === purposeOfUse)?.note}</FacilityNoteCard>
                      )}
                    </GItem>
                  </>
                )}
              </GContainer>
            </Box>
            
            {(isCheckUseReason === true) &&
              <div>{translate('facilityReservationSelection.label.annotation')}</div>
            }
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
  
        {(isCheckUseReason === true) && (
          <BottomButtonGroup>
            <ButtonOutlinedWarningL type="submit" fullWidth>
              {translate('facilityReservationSelection.button.applyOnTheSelectedSchedule')}
            </ButtonOutlinedWarningL>
            <BackButton />
          </BottomButtonGroup>
        )}
      </Form>
    </Stack>   
  )
}

export const FacilityReservationSelection = () => {
  const {
    facilityId,
    facility,
    commonFlag,
  } = useAction()

  if (facilityId == null || facility == null || commonFlag == null) {
    return null
  }

  let usageGuideStr: string
  if (facility.immediatelyReservationFlag === yesNo.yes) {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.1'
    } else {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.2'
    }
  } else {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.3'
      } else {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.5'
      }
    } else {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.4'
      } else {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.6'
      }
    }
  }

  return (
    (commonFlag === yesNo.yes) ? (
      <FacilityReservationSelectionCommon usageGuideStr={usageGuideStr}/>
    ) : (
      <FacilityReservationSelectionUseReason usageGuideStr={usageGuideStr}/>
    )
  )
}
