import { Checkbox, FormControl, FormControlLabel, FormHelperText, styled } from '@mui/material'
import React, { ReactElement } from 'react'
import { Control, useController } from 'react-hook-form'
import { translate } from '../../../../i18n'

interface CheckBoxProps {
  children: string | ReactElement
  name: string
  label: string
  valueAtCheck: string
  required?: boolean
  valicationMessageKeys?: Record<string, string>
  control?: Control<any, any>
}

const Root = styled(FormControl)(() => ({
  '& .MuiCheckbox-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiFormControlLabel-label': {
    lineHeight: 'normal',
  },
}))

/** チェックボックス(単独) フォームコントロール */
export const CheckBox = (props: CheckBoxProps) => {
  const { name, label, required, control, valueAtCheck, children } = props
  const messageKeys = props.valicationMessageKeys ?? {}
  const {
    field: { ref, onChange, value, ...ctrlProps },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: translate(messageKeys.required ?? 'system.error.requiredSelection', label),
      },
    },
    defaultValue: '',
    control,
  })
  const error = errors[props.name]
  return (
    <Root error={!!errors[props.name]} required={required}>
      <FormControlLabel
        {...ctrlProps}
        onChange={(event, checked) => onChange(checked ? valueAtCheck : '')}
        inputRef={ref}
        label={children}
        control={<Checkbox value={valueAtCheck} checked={value === valueAtCheck} />}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </Root>
  )
}
