import { ElapsedMillisecond, toApiYmd } from '../../utils/dateUtil';
import { Flag } from './constant/classification';
import { GOING_DATETIME_DISPLAY_METHODS } from '../common/constant/projectMst';
import { executeGetProjects } from '../../dataAccess/webApi/dao/projectsDao';

export type ReservationRequirementSetting = {
    goingDatetimeDisplayMethods: GOING_DATETIME_DISPLAY_METHODS
    medicalDocUsage: string;
    medicalDocNote: string
    medicalDocNecessity: string;
    symptomsInput: string;
    diagonosisInput: string;
    bodyTemperatureInput: string;
    allergyInput: string;
    febrileSeizuresInput: string;
}

/**
 * 予約周りに関する設定値を複数日程にわたって取得可能にする
 */
export const getReservationRequirementSettingsForMultiDate = async (projectIds: string[], usageDates: Date[] | ElapsedMillisecond[]): Promise<ReservationRequirementSetting> => {
    const returnObject: ReservationRequirementSetting = {
        goingDatetimeDisplayMethods: GOING_DATETIME_DISPLAY_METHODS.NOT_USED,
        medicalDocUsage: Flag.OFF,
        medicalDocNote: '',
        medicalDocNecessity: Flag.OFF,
        symptomsInput: Flag.OFF,
        diagonosisInput: Flag.OFF,
        bodyTemperatureInput: Flag.OFF,
        allergyInput: Flag.OFF,
        febrileSeizuresInput: Flag.OFF
    };
  
    usageDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    
    for ( const projectId of projectIds ) {
        for ( const usageDatetime of usageDates ) {
            const getProjectsResults = await executeGetProjects({
                targetDate: toApiYmd(usageDatetime),
                projectId,
            });
            
            const projectMstData = getProjectsResults.result[0] as ReservationRequirementSetting;
            
            const keys: Array<keyof ReservationRequirementSetting> = ['goingDatetimeDisplayMethods', 'medicalDocUsage', 'medicalDocNecessity', 'symptomsInput', 'diagonosisInput', 'bodyTemperatureInput', 'allergyInput', 'febrileSeizuresInput', 'medicalDocNote'];
            
            keys.forEach(key => {
                // 登降園予定時間に関しては「使用」する設定を優先
                if ( key === 'goingDatetimeDisplayMethods' ) {
                    if ( returnObject[key] === GOING_DATETIME_DISPLAY_METHODS.NOT_USED && projectMstData.goingDatetimeDisplayMethods !== GOING_DATETIME_DISPLAY_METHODS.NOT_USED ) {
                        returnObject[key] = projectMstData[key];
                    }
                    return
                }
                
                // 備考に関しては一番最新の設定を適用（projectIdが万が一複数の場合はindex0の事業マスタデータを参照）
                if ( key === 'medicalDocNote' ) {
                    if ( projectMstData.medicalDocNote && returnObject.medicalDocNote === '' ) {
                        returnObject.medicalDocNote = projectMstData.medicalDocNote;
                    }
                    return;
                }
                
                // Flag に基づく条件を適用
                if ( projectMstData[key] === Flag.ON && returnObject[key] === Flag.OFF ) {
                    returnObject[key] = projectMstData[key];
                }
            });
        }        
    }

    
    return returnObject;
}