import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, styled  } from '@mui/material'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { AttentionLabel } from '../components/common/attentionLabel'
import { ButtonM } from '../components/common/buttons/buttonM'
import { loginUrl } from '../../containers/common/constant/appUrl'
import { ElevatedCard } from '../components/common/elevatedCard'
import { TextBox } from '../components/common/inputs/textBox'
import { Form } from '../components/common/inputs/form'
import { ButtonOutlinedBase } from '../components/common/buttons/buttonOutlinedBase'
import { useAction } from '../../containers/forgotPassword/forgotPasswordService'
import { setResetPassword, selectResetPasswordError } from '../../containers/common/store/slices/resetPassword'
import { translate } from '../../i18n'

// ログイン画面に戻るボタン
const BackToLoginButton = styled(ButtonOutlinedBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: 'solid',
}))

// メッセージコンテナ
const NoticeGContainer = styled(GItemContainer)({
  justifyContent: 'center',
  paddingBottom: 20,
})

// 説明文コンテナ
const DescriptionContainer = styled('p')({
  whiteSpace: 'pre-wrap',
})

// メインコンテンツコンテナ
const CustomDivContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// メインコンテンツのサブコンテナ
const CustomDivSubContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

/**
 * ワンタイムパスワード要求画面UIコンポーネント
 */
export const ForgotPassword= () => {
  const { formMethods, onSubmit } = useAction()
  const error = useSelector(selectResetPasswordError)
  const dispatch = useDispatch()
  const history = useHistory()
  
  return (
    <Grid>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <GContainer >
          {/* 左余白 */}
          <GItem md={3}></GItem>

          {/* メインコンテンツ */}
          <GItem xs={12} md={6}>
            <CustomDivContainer>

              {/* タイトル */}
              <ElevatedCard
                title={translate('forgotPassword.title.forgotPassword')}
                textAlign='center'
              >
                <GContainer rowSpacing={2}>
                  {/* 説明 */}
                  <CustomDivSubContainer>
                    <DescriptionContainer>
                      {translate('forgotPassword.text.description')}
                    </DescriptionContainer>
                  </CustomDivSubContainer>
    
                  {/* エラーメッセージ表示 */}
                  {error &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <AttentionLabel>
                          {translate(error)}
                        </AttentionLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }
  
                  {/* メールアドレス */}
                    <CustomDivSubContainer>
                      {translate('forgotPassword.label.email')}
                      <TextBox
                        name="email"
                        label={translate('forgotPassword.label.email')}
                        maxLength={320}
                        textType="email"
                        placeholder={translate('forgotPassword.placeholder.email')}
                        required
                      />
                    </CustomDivSubContainer>
    
                  {/* ワンタイムパスワード送信ボタン */}
                  <CustomDivSubContainer>
                    <ButtonM fullWidth type="submit">
                      {translate('forgotPassword.button.requestCode')}
                    </ButtonM>
                  </CustomDivSubContainer>
  
                  {/* ログインに戻るボタン */}
                  <CustomDivSubContainer>
                    <BackToLoginButton
                      fullWidth
                      onClick={() => {
                        dispatch(setResetPassword({}))
                        history.push(loginUrl.url())
                      }}
                    >
                      {translate('forgotPassword.button.backToLogin')}
                    </BackToLoginButton>
                  </CustomDivSubContainer>
                </GContainer>
              </ElevatedCard>
            </CustomDivContainer>
          </GItem>
          
          {/* 右余白 */}
          <GItem md={3}></GItem>
        </GContainer>
      </Form>
    </Grid>
  )
}