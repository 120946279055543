import React from 'react';
import { GItem } from "../grids"
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import { Select } from '../inputs/select'
import { TextBox } from '../inputs/textBox'
import { translate } from '../../../../i18n'

interface CustomSelectBoxProps {
    inputName: string
    additionalName: string
    required: boolean
    options?: PostDecodeMstDto[]
}

const ConditionCheckSelectBox: React.FC<CustomSelectBoxProps> = ({ inputName, additionalName, required, options }) => {
    
    const optionsConvertedToLabelValue = options?.map(( option ) => ({ label: option.name, value: option.code }));

    return (
        <>
            <GItem xs={12}>
                <Select
                  fullWidth
                  name={inputName}
                  label={translate('facilityReservationForm.form.medical.conditionCheck')}
                  valueLabels={optionsConvertedToLabelValue??[]}
                  required={required}
                />
            </GItem>
            <GItem xs={12} sx={{ mt: 1 }}>
                <TextBox 
                    name={additionalName} 
                    label={''}
                    maxLength={200} 
                    rows={3}
                    multiline 
                />
            </GItem>
        </>

    );
};

export default ConditionCheckSelectBox;
