import { useFieldArray } from "react-hook-form"
import { ReservationDetailMedicalDoc } from "../../../../containers/facilityReservationForm/reservationDetailMedicalDoc"
import { AttentionLabel } from "../attentionLabel"
import { GContainer, GItem } from "../grids"
import { TitleLabel } from "../titleLabel"
import DeseaseSelectBox from "./reservationDetailDesease"
import ConditionCheckSelectBox from "./reservationDetailConditionCheck"
import { Control } from 'react-hook-form';
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import ReservationDetailSymptom from "./reservationDetailSymptom"
import ReservationDetailTempature from "./reservationDetailTempature"
import ReservationDetailAllergy from "./reservationDetailAllergy"
import ReservationDetailFebrileSeizures from "./reservationDetaiFebrileSeizures"
import ReservationDetailMedicalDocSubmittion from "./reservationDetailMedicalDocSubmittion"
import { InfoCard } from "../infoCard"
import { ChildInfo } from "./reservationDetailGItems"
import { Typography } from "@mui/material"
import { GetFacilityDto } from "../../../../dataAccess/webApi/dto/facilitiesDto"
import { NullPropsToUndefinedType } from "../../../../utils/typeUtil"
import { Flag } from '../../../../containers/common/constant/classification';
import { ReservationSettingDecodeMst, ChildReservationInputType } from '../../../../containers/common/reservation'
import { ButtonS } from '../buttons/buttonS'
import { translate } from '../../../../i18n'
import { GetProjectsDto } from "../../../../dataAccess/webApi/dto/projectsDto"
import ReservationDetailConsentCheck from "./reservationDetailConsentCheck"

interface ReservationDetailChildProps {
    control: Control<Inputs>
    index: number
    reservationReference: any
    decodeOptions?: ReservationSettingDecodeMst
    facility: NullPropsToUndefinedType<GetFacilityDto>
    childrenWatch: ChildReservationInputType[]
    projectmst: GetProjectsDto | undefined
}

const ReservationDetailChild = (props: ReservationDetailChildProps) => {
    const { control, index, reservationReference, decodeOptions, facility, childrenWatch, projectmst } = props
    const { fields, append } = useFieldArray({
        name: `children.${index}.medicalDoc.docs`,
        control,
    })

    return (
        <>
            <GItem xs={12}>
                <TitleLabel>お子さま{index + 1}</TitleLabel>
            </GItem>
            <InfoCard>
                <GContainer>
                    <ChildInfo childInfo={reservationReference.childInfoList[index]} />
                </GContainer>
            </InfoCard>
            {facility.diagonosisInput === Flag.ON && (
                <>
                    <GItem xs={12}>
                        <div>
                            <TitleLabel>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('facilityReservationForm.form.medical.diagonosis')}
                            </TitleLabel>
                        </div>
                        <DeseaseSelectBox
                            inputName={`children.${index}.diagonosis.input`}
                            additionalName={`children.${index}.diagonosis.additional`}
                            required={true}
                            options={decodeOptions?.diagonosis}
                            control={control}
                        />
                    </GItem>
                    <GItem xs={12}>
                        <div>
                            <TitleLabel>
                                {translate('facilityReservationForm.form.medical.diagonosis2')}
                            </TitleLabel>
                        </div>
                        <DeseaseSelectBox
                            inputName={`children.${index}.diagonosis.input2`}
                            additionalName={`children.${index}.diagonosis.additional2`}
                            required={false}
                            options={decodeOptions?.diagonosis}
                            control={control}
                            addUnselected
                        />
                    </GItem>
                </>
            )}
            {facility.symptomsInput === Flag.ON && (
                <GItem xs={12} mt={2}>
                    <div>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.symptoms')}
                        </TitleLabel>
                    </div>
                    <ReservationDetailSymptom
                        name={`children.${index}.symptom.symptom`}
                        otherName={`children.${index}.symptom.additional`}
                        options={decodeOptions?.symptom}
                        control={control}
                        checkedSymptom={childrenWatch[index].symptom?.symptom ?? []}
                    />
                </GItem>
            )}
            {facility.bodyTemperatureInput === Flag.ON && (
                <GItem xs={12} mt={2}>
                    <div>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.bodyTemp')}
                        </TitleLabel>
                    </div>
                    <ReservationDetailTempature
                        name={`children.${index}.temperature`}
                        control={control}
                    />
                </GItem>
            )}
            {facility.allergyInput === Flag.ON && (
                <GItem xs={12} mt={2}>
                    <div>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.allergy')}
                        </TitleLabel>
                    </div>
                    <ReservationDetailAllergy
                        name={`children.${index}.allergy`}
                        control={control}
                    />
                </GItem>
            )}
            {facility.febrileSeizuresInput === Flag.ON && (
                <GItem xs={12}>
                    <div>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.febrileSeizures')}
                        </TitleLabel>
                    </div>
                    <ReservationDetailFebrileSeizures
                        name={`children.${index}`}
                    />
                </GItem>
            )}
            {facility.medicalDocUsage === Flag.ON && (
                <GItem xs={12} mt={2}>
                    <div>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.medicalDoc')}
                        </TitleLabel>
                    </div>
                    <ReservationDetailMedicalDocSubmittion
                        name={`children.${index}`}
                    />
                    {/** 施設備考 */}
                    <GItem xs={12} mt={1}>
                        <Typography variant="caption" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
                            {facility.medicalDocNote}
                        </Typography>
                    </GItem>
                    <div>
                        {fields.map((item, docIndex) => (
                            <ReservationDetailMedicalDoc
                                key={item.id}
                                name={`children.${index}.medicalDoc.docs.${docIndex}`}
                                registeredFileKey={item.tempUploadKey}
                            />
                        ))}
                        {fields?.length < 5 && (
                            <GItem
                                xs={12}
                                mt={1}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <ButtonS
                                    sx={{ width: 'fit-content' }}
                                    onClick={() => append({
                                        fileName: null,
                                        delFlg: '0',
                                        tempUploadKey: null,
                                    })}
                                >
                                    {translate('facilityReservationForm.form.common.add')}
                                </ButtonS>
                            </GItem>
                        )}
                    </div>
                </GItem>
            )}
            {facility.medicalDocUsage === Flag.ON && (
                <>
                    <GItem xs={12}>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.conditionCheck')}
                        </TitleLabel>
                        <ConditionCheckSelectBox
                            inputName={`children.${index}.conditionCheck`}
                            additionalName={`children.${index}.conditionCheckNote`}
                            required={true}
                            options={decodeOptions?.conditionCheck}
                        />
                    </GItem>
                </>
            )}
            {facility.medicalDocUsage === Flag.ON && (
                <GItem xs={12}>
                    <div>
                        <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>
                            {translate('facilityReservationForm.form.medical.consentCheck')}
                        </TitleLabel>
                    </div>
                    <div>{projectmst?.consentCheckNote}</div>
                    <ReservationDetailConsentCheck
                        name={`consentCheck`}
                        options={decodeOptions?.consentCheck}
                        control={control}
                    />
                </GItem>
            )}

        </>
    )
}

export default ReservationDetailChild;
