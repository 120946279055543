import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { translate } from '../../i18n'
import { signIn, verifyTel, verifyEmail,getAuthenticatedUser } from '../../utils/authUtil'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import {
  selectIsLoggedIn,
  selectIsVerifiedEmail,
  selectIsVerifiedTel,
  setLogin,
} from '../common/store/slices/authority'
import { setLoginForm } from '../common/store/slices/login'
import { setCognitoUser } from '../common/store/slices/cognitoUser'
import { newPasswordUrl, loginUrl } from '../common/constant/appUrl'

interface LocationState {
  from: Location
}
interface Inputs {
  email: string
  password: string
}

/**
 * ログイン画面フォームアクション
 */
export const useAction = () => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const { ssoUseFlag, ssoUseName, smsSubscriptionFlag, phoneCertificationFlag } = useSelector(selectSystemControl)
  
  const formMethods = useForm<Inputs>()
  const history = useHistory()

  const from = location.state?.from

  /**
   * フォーム送信イベント処理
   * @param data フォーム入力値
   */
  const onSubmit = useCallback(
    async (data: Inputs) => {
      // 入力情報を取得
      const { email, password } = data

      dispatch(
        // ログインリクエスト中にローディングアイコンを表示する
        showLoading(
          (async () => {
            try {
              // ログインリクエスト
              const cognitoUser = await signIn(email, password)

              if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // 初期パスワードでsignIn実行後、新パスワードを設定する
                // セッション情報を持つCognitoUserオブジェクトをインスタンス生成できないため、Reduxストアにそのまま格納する
                dispatch(setCognitoUser(cognitoUser))
                history.push(newPasswordUrl.url(), { from: loginUrl.url() })
              }
            } catch (error) {
              console.log(error)
              // エラーメッセージ表示
              dispatch(setLoginForm({
                error: error instanceof Error ? error.message : String(error)
              }));
            }

            // ログイン後のユーザー情報を取得
            const user = await getAuthenticatedUser(true)

            // メールアドレス・電話番号の検証可否を取得
            const isVerifiedEmail = !!user.attributes.email_verified
            const isVerifiedTel = !!user.attributes.phone_number_verified

            // Reduxストアにログインエントリを送信
            dispatch(
              setLogin({
                loginId: email,
                verifications: { tel: isVerifiedTel, email: isVerifiedEmail },
              })
            )
            
            // エラーメッセージを空にする
            dispatch(setLoginForm({}))
            
            if (!isVerifiedEmail) {
              // メールアドレス検証用のワンタイムパスワード発行
              await verifyEmail()
              
              // ワンタイムパスワード送信メッセージを表示
              dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
            } else if (!isVerifiedTel && smsSubscriptionFlag === '1' && phoneCertificationFlag === '1') {
              // 電話番号検証用のワンタイムパスワード発行
              await verifyTel()
              
              // ワンタイムパスワード送信メッセージを表示
              dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
            }
          })
        )
      )
    },
    []
  )

  return {
    isLoggedIn,
    formMethods,
    onSubmit,
    isVerifiedEmail,
    isVerifiedTel,
    from,
    ssoUseFlag,
    ssoUseName,
    smsSubscriptionFlag,
    phoneCertificationFlag,
  }
}
