// ReservationChildSelection.tsx
import React, { useEffect } from 'react'
import { MiddleGItem, GContainer, GItem } from '../grids'
import { translate } from '../../../../i18n'
import { styled, SelectChangeEvent, FormControl, FormGroup, FormHelperText } from '@mui/material'
import { Control, useController } from 'react-hook-form'
import { SelectNoBind } from '../inputs/selectNoBind'
import { CheckBoxesGroup } from '../inputs/checkBoxesGroup'
import { Inputs } from '../../../../../src/containers/facilityReservationSelection/facilityReservationSelectionService'
import { getValueByNameStr } from '../../../../../src/utils/objectUtil'
const ThreeRowsGContainer = styled(MiddleGItem)({
  display: 'inline-block',
  boxSizing: 'border-box',
})

const ChildSelectionGContainer = styled(GContainer)({
  marginBottom: '8px',
})

interface ChildSelectionProps {
  childs: { value: string; label: string }[]
  changeSelectedChildIds: (childId: string, checked: boolean) => void
  changeDropDownSelectedChildId: (event: SelectChangeEvent<string>) => void
  control: Control<Inputs>
  multiChildReservationAvailabilityFlag: string
  checkedChildren: string[]
}

const Root = styled(FormControl)(() => ({
  '& .MuiCheckbox-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiFormControlLabel-label': {
    lineHeight: 'normal',
  },
}))

export const ReservationChildSelection: React.FC<ChildSelectionProps> = (
  { control, childs, changeSelectedChildIds, changeDropDownSelectedChildId, multiChildReservationAvailabilityFlag, checkedChildren }
  ) => {
    
  const {
    field: { onChange, ...ctrlProps },
    formState: { errors },
  } = useController({
    name: 'selectedChildrenIds',
    rules: {
      required: {
        value: true,
        message: '1つ以上選択してください',
      },
    },
    defaultValue: ''
  })
  const error = getValueByNameStr(errors, 'selectedChildrenIds')
  
  useEffect(() => {
    if (childs.length === 1) {
      onChange([childs[0].value]);  // 自動的に選択する
    }
  }, [childs]);

  return (
    <ChildSelectionGContainer>
      { multiChildReservationAvailabilityFlag === '1' ? ( 
        childs.length === 1 ? (
          <>
            <MiddleGItem xs={5} md={4}>
              {translate('facilityReservationSelection.label.targetChild')}
            </MiddleGItem>
            <GItem>{childs[0]?.label || '※お子さまが登録されていません'}</GItem>
          </>
        ) : (
          <>
            <MiddleGItem xs={5} md={4}>
              {translate('facilityReservationSelection.label.selectionChild')}
            </MiddleGItem>
            <ThreeRowsGContainer xs={7} sm={6} md={8}>
              <FormControl
                required
                error={!!error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
              <Root
                {...ctrlProps}
              >
              <FormGroup row={true}>
              {childs.map((child, index) => (
                <CheckBoxesGroup
                  key={child.value}
                  name={`selectedChildrenIds.${index}`}
                  label={child.label}
                  valueAtCheck={child.value}
                  control={control}
                  valicationMessageKeys={{ required: 'This field is required' }}
                  onChange={changeSelectedChildIds}
                  checkedChildren={checkedChildren}
                >
                  {child.label}
                </CheckBoxesGroup>
              ))}
              </FormGroup>
              
              </Root>
              {error && <FormHelperText>{'対象のお子さまを1人以上選択してください'}</FormHelperText>}
              </FormControl>
            </ThreeRowsGContainer>
          </>
        )
        
      
      ) : (
      
        <>
          <MiddleGItem xs={5} md={4}>
            {translate('facilityReservationSelection.label.selectionChild')}
          </MiddleGItem>
          <MiddleGItem xs={7} md={8}>
            <SelectNoBind
              fullWidth
              name="selectedChildrenId"
              onChange={changeDropDownSelectedChildId}
              valueLabels={childs}
              value={checkedChildren.join()}
            />
          </MiddleGItem>
        </>
      )}
      
    </ChildSelectionGContainer>
  )
}
