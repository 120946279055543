// import { executePost, KANRI_API_NAME } from '../common/apiCaller'
import { executePost } from '../common/apiCaller'
import { GuideTextData } from '../dto/guideTextDto'

// const apiName = KANRI_API_NAME
const apiName = 'public'
const apiPath = '/guide-text-mst'

/**
 * 案内文IDの定義
 */
export const guideTextId = Object.freeze({
  // "" : 1,
  // "" : 2,
  "contactCommonGuideText" : 3,
  "faqFootnoteGuideText" : 4,
  "projectTopCommonGuideText" : 5,
  "emailVerifiedGuideText": 7,
});

/**
 * 案内文を取得
 */
// export const executePostGuideText = (parameter?: Record<string, any>) => {
//   return executePost<GuideTextData[]>(apiName, '/guide-text-mst', parameter)
// }
export const executePostGuideText = (guideTextId?: number) => {
  // return executePost<GuideTextData[]>(apiName, apiPath, {guideTextId: guideTextId})
  return executePost<GuideTextData>(apiName, apiPath, {guideTextId: guideTextId})
}

