import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fromApiHms, toApiYmd } from '../../utils/dateUtil'
import { datePropsToNumber, nullPropsToUndefined } from '../../utils/objectUtil'
import { interviewReservationDeleteConfirmationUrl, reservationStatusListUrl } from '../common/constant/appUrl'
import { reservationStatus, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { useOperationLog } from '../common/operationLog'
import { getInterview } from '../common/interview'
import { showLoading } from '../common/store/slices/application'
import {
  InterviewReservationDeleteEntry,
  selectInterviewReserveDeleteEntry,
  setInterviewReserveDeleteEntry,
  setInterviewReserveDeleteUpdateDatetime,
} from '../common/store/slices/interviewReserveDelete'

interface UrlParams {
  interviewNo: string
}
interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

interface Inputs {
  cancelReson: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { interviewNo } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()

  const locationState = history.location.state

  const deleteEntry = useSelector(selectInterviewReserveDeleteEntry)

  const formMethods = useForm<Inputs>({
    ...(locationState?.isKeep && {
      defaultValues: {
        cancelReson: deleteEntry?.cancelReason,
      },
    }),
  })
  const [interviewDetail, setInterviewDetail] = useState<
    Omit<InterviewReservationDeleteEntry, 'cancelReason'> | undefined
  >(locationState?.isKeep ? deleteEntry : undefined)

  // 初期処理
  useEffect(() => {
    if (interviewDetail == null) {
      dispatch(
        showLoading(
          errorHandle(async () => {
            const { interviewsUpdateDatetime, ...detail } = await getInterviewDetail(interviewNo)
            dispatch(setInterviewReserveDeleteUpdateDatetime(interviewsUpdateDatetime))
            if (detail.status === reservationStatus.wait) {
              // キャンセル待ちの場合、キャンセル理由の入力は不要なので確認画面まで即遷移
              dispatch(
                setInterviewReserveDeleteEntry({
                  ...detail,
                })
              )
              history.replace(interviewReservationDeleteConfirmationUrl.url())
            } else {
              addOperationLog({
                operationId: OperationId.OP_00000001,
                accessData: [
                  {
                    userIdRegFlag: yesNo.yes,
                    childId: detail.childId ?? '',
                    usageDate: toApiYmd(detail.interviewDate),
                    interviewNo,
                  },
                ],
              })
              setInterviewDetail(detail)
            }
          })
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 中止（予約一覧に戻る）
  const goList = useCallback(() => history.push(reservationStatusListUrl.url()), [])

  // 確認画面へ遷移
  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000036 })

      if (interviewDetail) {
        dispatch(
          setInterviewReserveDeleteEntry({
            ...interviewDetail,
            cancelReason: data.cancelReson,
          })
        )
        // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
        history.replace({ ...history.location, state: { isKeep: true } })
        history.push(interviewReservationDeleteConfirmationUrl.url())
      }
    },
    [interviewDetail, addOperationLog]
  )

  return {
    formMethods,
    interviewDetail,
    onSubmit,
    goList,
  }
}

/**
 * 面談詳細情報を取得
 */
const getInterviewDetail = async (interviewNo: string) => {
  const interview = await getInterview(interviewNo)
  const facility = await getFacility(interview.facilityId, interview.interviewDate)

  return nullPropsToUndefined({
    ...datePropsToNumber(interview),
    cancelLimit: facility.interviewCancelLimit,
    cancelLimitTime: fromApiHms(facility.interviewCancelLimitTime)?.getTime(),
    cancelNote: facility.interviewCancelNote,
    cancelLimitHolidayOption: facility.interviewCancelLimitHolidayOption,
    immediatelyReservationFlag: facility.interviewImmediatelyReservationFlag,
  })
}
