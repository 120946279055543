import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { loginUrl, forgotPasswordUrl } from '../common/constant/appUrl'
import { setResetPassword, selectResetPasswordEntry } from '../common/store/slices/resetPassword'
import { translate } from '../../i18n'
import { forgotPasswordSubmit } from '../../utils/authUtil'
import { setFormErrors } from '../../utils/reactUtil'

interface LocationState {
  from: string
}

interface Inputs {
  oneTimeCode: string
  password: string
  passwordConfirmation: string
}

type InputTypePropName = keyof Inputs

/**
 * パスワード再設定画面フォームアクション
 */
export const useAction = () => {
  const formMethods = useForm<Inputs>()
  const history = useHistory()
  const entry = useSelector(selectResetPasswordEntry)
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  
  const from = location.state?.from

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []
      
      if (data.password !== data.passwordConfirmation) {
        errors.push({
          name: 'passwordConfirmation',
          message: translate('system.error.notSamePassword'),
        })
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  /**
   * フォーム送信イベント処理
   * @param data フォーム入力値
   */
  const onSubmit = useCallback(
    async (data: Inputs) => {
      if (validateItems(data)) {
        return
      }

      // 入力情報を取得
      const { oneTimeCode, password } = data

      // Reduxストアからワンタイムパスワード要求時に入力したメールアドレスを取得
      const email = entry?.email
      
      try {
        if (email) {
          // パスワードリセットリクエスト
          await forgotPasswordSubmit(email, oneTimeCode, password)
        } else {
          // ワンタイムパスワード要求時に入力したメールアドレスが取得できなかった場合（通常は想定されない）
          // ワンタイムパスワード要求画面にリダイレクトし, 再度操作を促す
          history.push(forgotPasswordUrl.url())
          throw new Error(translate('system.error.unexpectedError'))
        }

        // パスワード変更ステータスを更新
        dispatch(setResetPassword({
          isSuccessResetPassword: true,
        }))

        // ログイン画面に遷移
        history.push(loginUrl.url())

      } catch (error) {
        // エラーメッセージ表示
        if (!(error instanceof Error)) {
          dispatch(setResetPassword({
            error: String(error)
          }));
        } else if (error.name === 'CodeMismatchException') {
          // Invalid verification code provided, please try again. に対応する日本語メッセージは
          // 電話番号という語を含んでいるため使用できない.
          dispatch(setResetPassword({
            error: translate('resetPassword.error.oneTimeCodeError')
          }));
        } else {
          dispatch(setResetPassword({
            error: error.message
          }));
        }
      }
    },
    [validateItems]
  )

  return {
    formMethods,
    onSubmit,
    from,
  }
}
