import CloseIcon from '@mui/icons-material/Close'
import { CardContent, CardHeader, IconButton, Card as MuiCard, styled } from '@mui/material'
import { ReactNode } from 'react'

interface ElevatedCardProps {
  /** カードのコンテンツ(カードの内に表示する内容) */
  children: ReactNode
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string

  title: string
  /**
   * カードの閉じるボタン(X ボタン)がクリックされたときに呼び出される関数。
   * 指定しなければ 閉じるボタン は表示されない
   */
  onClose?: () => void
  
  textAlign?: 'left' | 'right' | 'center' | 'justify' | undefined;
}

const Root = styled(MuiCard)(({ theme }) => ({
  '.MuiCardHeader-root': {
    paddingBottom: 8,
  },
  '.MuiCardHeader-title': {
    fontSize: theme.typography.font.sizeL1,
    fontWeight: theme.typography.fontWeightBold,
  },
  '.MuiCardContent-root, .MuiCardContent-root:last-child': {
    paddingTop: 8,
    paddingBottom: 16,
  },
}))
const CloseButton = styled(IconButton)({
  fontSize: '1.6rem',
  padding: 4,
})

export const ElevatedCard = (props: ElevatedCardProps) => {
  return (
    <Root>
      <CardHeader
        title={props.title}
        {...(props.onClose && {
          action: (
            <CloseButton onClick={props.onClose}>
              <CloseIcon fontSize="inherit" />
            </CloseButton>
          ),
        })}
        style={{ textAlign: props.textAlign }}
      />
      <CardContent>{props.children}</CardContent>
    </Root>
  )
}
