import { FormControl, FormHelperText } from '@mui/material'
import React from 'react'
import { Control, useController } from 'react-hook-form'
import { translate } from '../../../../i18n'
import { SelectNoBind } from './selectNoBind'
import { getValueByNameStr } from '../../../../utils/objectUtil'

interface SelectProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /** width: 100% にする場合true */
  fullWidth?: boolean
  /** 選択肢の値と表示文言配列 */
  valueLabels: { value: string; label: string }[]
  /**
   * 選択なしを示す項目を自動的に表示するための制御プロパティ。
   * multiple指定時：選択肢が複数あれば表示
   * always指定時：選択肢の数によらず常に表示
   */
  includeNone?: 'multiple' | 'always'
  /** 選択なしを示す項目の表示文言 */
  noneLable?: string
  /** 非活性にする場合true */
  disabled?: boolean
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
}

/** セレクトフォームコントロール */
export const Select = (props: SelectProps) => {
  const { name, label, required, control, ...through } = props
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: translate('system.error.requiredSelection', label),
      },
    },
    defaultValue: '',
    control,
  })

  const error = getValueByNameStr(errors, props.name);

  return (
    <FormControl fullWidth={through.fullWidth} error={!!error}>
      <SelectNoBind {...field} {...through} error={!!error} />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
