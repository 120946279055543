import { Grid, Stack, styled, Typography } from '@mui/material'
import { getUsageHistoryStatusSearchConditions } from '../../containers/common/codeMaster'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { OperationId } from '../../containers/common/constant/operationLog'
import {
  paymentStatus,
  provisionCertificateFeatureStatus,
  usageHistoryStatusSearchCondition,
  yesNo,
} from '../../containers/common/constant/classification'
import { useAction } from '../../containers/usageHistory/usageHistoryService'
import { translate } from '../../i18n'
import { formatLocaleYm } from '../../utils/dateUtil'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonM } from '../components/common/buttons/buttonM'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { CenterMiddleItem, LeftItem, MiddleItem, RightItem } from '../components/common/gridItems'
import { RadioButtonGroupNoBind } from '../components/common/inputs/radioButtonGroupNoBind'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { UsageHistoryCard } from '../components/common/reservation/usageHistoryCard'
import { DownloadDialog } from '../components/provisionCertificateIssuance/downloadDialog'

const NoResultMessageCard = styled('div')({
  padding: '20px 0',
  textAlign: 'center',
})

const StyledTypography = styled(Typography)({
  paddingLeft: '1rem',
  paddingRight: '1rem',
})

const MonthControl = (props: { yyyymm?: Date; previousMonth: () => void; nextMonth: () => void }) => {
  return (
    <>
      <LeftItem item xs={3}>
        <Link onClick={props.previousMonth}>{`<${translate('usageHistory.text.previousMonth')}`}</Link>
      </LeftItem>
      <CenterMiddleItem item xs={6}>
        {props.yyyymm && formatLocaleYm(props.yyyymm)}
      </CenterMiddleItem>
      <RightItem item xs={3}>
        <Link onClick={props.nextMonth}>{`${translate('usageHistory.text.nextMonth')}>`}</Link>
      </RightItem>
    </>
  )
}

export const UsageHistory = () => {
  const {
    activeYyyymm,
    activeChildId,
    usageHistories,
    childs,
    activeStatusSearchCondition,
    changeChild,
    previousMonth,
    nextMonth,
    statusSearchConditionChangeHandle,
    provisionCertificateIssuanceShowFlag,
    onClickIssueProvisionCertificateIssuance,
    provisionCertificateIssuanceHandler,
    cancelHandler,
    certificateResultCode,
    certificateResultResponseData,
    provisionCertificateFeatureStatus: provisionCertificateFeatureStatusInCtrl,
    paymentStatusFlag,
    provisionCertificatePaymentStatusFlag,
    dateChecker,
    shouldShowDownloadCertificate,
  } = useAction()

  if (usageHistories == null) {
    return null
  }
  return (
    <Stack spacing={2}>
      <DownloadDialog
        title={translate('usageHistory.dialog.title')}
        isOpen={provisionCertificateIssuanceShowFlag}
        onClose={cancelHandler}
        onSubmit={provisionCertificateIssuanceHandler}
      />

      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Grid container>
              <MiddleItem item>{translate('usageHistory.label.selectionChild')}　</MiddleItem>
              <Grid item xs>
                <SelectNoBind
                  fullWidth
                  includeNone="always"
                  noneLable={translate('system.pulldown.allChildren')}
                  valueLabels={childs}
                  onChange={changeChild}
                  value={activeChildId}
                />
              </Grid>
            </Grid>

            <Grid container>
              <LeftItem item xs={6}>
                <RadioButtonGroupNoBind
                  name={'statusSearchCondition'}
                  value={activeStatusSearchCondition}
                  onChange={statusSearchConditionChangeHandle}
                  buttonValueLabels={getUsageHistoryStatusSearchConditions()}
                  row
                />
              </LeftItem>

              <RightItem item xs={6}>
                {activeChildId && // お子様選択時
                  activeStatusSearchCondition === usageHistoryStatusSearchCondition.usedOnly && // 利用済みのみ選択時
                  usageHistories.filter((usageHistory) => usageHistory.certificateFlag === yesNo.yes).length > 0 && // 提供証明書発行の対象となる施設の利用履歴が存在するとき
                  provisionCertificateFeatureStatusInCtrl === provisionCertificateFeatureStatus.all && // 提供証明書機能を施設管理者と利用者で利用する場合
                  (paymentStatusFlag === yesNo.no ||
                    provisionCertificatePaymentStatusFlag === yesNo.no ||
                    usageHistories
                      .filter((usageHistory) => usageHistory.certificateFlag === yesNo.yes)
                      .every((history) => history.paymentStatus === paymentStatus.collected)) &&
                  dateChecker() &&
                  shouldShowDownloadCertificate && (
                    <ButtonM onClick={onClickIssueProvisionCertificateIssuance}>
                      <StyledTypography>{translate('usageHistory.button.certificateIssued')}</StyledTypography>
                    </ButtonM>
                  )}
              </RightItem>
            </Grid>

            {!!certificateResultCode && (
              <ErrorMessageCard
                messages={[
                  translate(
                    `usageHistory.error.certificateResultCode${certificateResultCode}`,
                    certificateResultResponseData?.facilityName ?? ''
                  ),
                ]}
              />
            )}

            <div>
              <Grid container rowSpacing={1}>
                <MonthControl previousMonth={previousMonth} nextMonth={nextMonth} yyyymm={activeYyyymm} />
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    {usageHistories.length ? (
                      usageHistories.map((usage) => (
                        <UsageHistoryCard key={usage.reservationNo} operationId={OperationId.OP_00000046} {...usage} />
                      ))
                    ) : (
                      <NoResultMessageCard>{translate('usageHistory.text.noUsageHistory')}</NoResultMessageCard>
                    )}
                  </Stack>
                </Grid>
                {!!usageHistories.length && <MonthControl previousMonth={previousMonth} nextMonth={nextMonth} />}
              </Grid>
            </div>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
