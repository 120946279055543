import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  executePostCreateCertificatePdf,
  executePostDownloadCertificatePdfUrl,
  executePutCertificateDetail,
} from '../../dataAccess/webApi/dao/certificateDao'
import { PutCertificateDetailInputDto } from '../../dataAccess/webApi/dto/certificateDto'
import { downloadFile } from '../../utils/reactUtil'
import { STATUS_CODE } from '../common/constant/statusCode'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { Load, PageState } from '../usageHistory/usageHistoryService'

type UseProvisionCertificateIssuanceReturn = {
  provisionCertificateIssuanceShowFlag: boolean
  onClickIssueProvisionCertificateIssuance: () => void
  provisionCertificateIssuanceHandler: () => void
  cancelHandler: () => void
  certificateResultCode: number | null
  certificateResultResponseData: CertificateResultResponseData
  resetCertificateResultResponse: () => void
}

type UseProvisionCertificateIssuanceArg = {
  childId: string
  usageFromDate: string
  state: PageState
  facilityIds: string[]
  load: Load
}

type UseProvisionCertificateIssuance = (
  arg: UseProvisionCertificateIssuanceArg
) => UseProvisionCertificateIssuanceReturn

type CertificateResultResponseData = {
  facilityId: string
  facilityName: string
} | null

export const useProvisionCertificateIssuance: UseProvisionCertificateIssuance = ({
  childId,
  usageFromDate,
  state,
  facilityIds,
  load,
}) => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()

  const [provisionCertificateIssuance, setProvisionCertificateIssuance] = useState<
    PutCertificateDetailInputDto | undefined
  >(undefined)
  const [provisionCertificateIssuanceShowFlag, setProvisionCertificateIssuanceShowFlag] = useState<boolean>(false)
  const [certificateResultCode, setCertificateResultCode] = useState<number | null>(null)
  const [certificateResultResponseData, setCertificateResultResponseData] =
    useState<CertificateResultResponseData>(null)

  const onClickIssueProvisionCertificateIssuance = useCallback(() => {
    setProvisionCertificateIssuance({
      childId,
      usageFromDate,
      facilityIds,
    })

    setProvisionCertificateIssuanceShowFlag(true)
  }, [childId, usageFromDate, facilityIds])

  const provisionCertificateIssuanceHandler = useCallback(() => {
    if (!provisionCertificateIssuance) return
    setProvisionCertificateIssuanceShowFlag(false)
    resetCertificateResultResponse()

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          // NOTE: 1. 登録処理
          const resProvisionCertificateIssuanceCreateDetail = await executePutCertificateDetail(
            provisionCertificateIssuance
          )

          if (resProvisionCertificateIssuanceCreateDetail?.resultCode !== STATUS_CODE.OK) {
            setCertificateResultCode(resProvisionCertificateIssuanceCreateDetail.resultCode)

            if (resProvisionCertificateIssuanceCreateDetail.result) {
              setCertificateResultResponseData(resProvisionCertificateIssuanceCreateDetail.result)
            }

            return
          }

          // NOTE: 2. PDF作成処理
          const resCreatePdf = await executePostCreateCertificatePdf(provisionCertificateIssuance)

          if (resCreatePdf.resultCode !== STATUS_CODE.OK) {
            throw new Error('データの作成に失敗しました。')
          }

          // NOTE: 3. PDFダウンロード処理
          const downloadPdfUrlInput = {
            downloadFile: resCreatePdf.result.createCertificates,
          }
          const resDownloadPdfUrl = await executePostDownloadCertificatePdfUrl(downloadPdfUrlInput)

          downloadFile(resDownloadPdfUrl.result.url)
          load(false, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, state.activeChildId)
        }),
        isHiddenMain: false,
      })
    )
  }, [
    dispatch,
    errorHandle,
    provisionCertificateIssuance,
    load,
    state.activeYyyymm,
    state.childs,
    state.activeStatusSearchCondition,
    state.activeChildId,
  ])

  const cancelHandler = useCallback(() => {
    setProvisionCertificateIssuance(undefined)
    setProvisionCertificateIssuanceShowFlag(false)
  }, [])

  const resetCertificateResultResponse = useCallback(() => {
    setCertificateResultCode(null)
    setCertificateResultResponseData(null)
  }, [setCertificateResultCode, setCertificateResultResponseData])

  return {
    provisionCertificateIssuanceShowFlag,
    onClickIssueProvisionCertificateIssuance,
    provisionCertificateIssuanceHandler,
    cancelHandler,
    certificateResultCode,
    certificateResultResponseData,
    resetCertificateResultResponse,
  }
}
