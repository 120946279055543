// CheckBoxesGroup.tsx
import { Checkbox, FormControl, FormControlLabel, styled } from '@mui/material'
import React, { ReactElement } from 'react'
import { Control, useController } from 'react-hook-form'
import { translate } from '../../../../i18n'

interface CheckBoxProps {
  children: string | ReactElement
  name: string
  label: string
  valueAtCheck: string
  required?: boolean
  valicationMessageKeys?: Record<string, string>
  control?: Control<any, any>
  onChange?: (value: string, checked: boolean) => void
  checkedChildren: string[]
}

const Root = styled(FormControl)(() => ({
  '& .MuiCheckbox-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiFormControlLabel-label': {
    lineHeight: 'normal',
  },
}));

export const CheckBoxesGroup: React.FC<CheckBoxProps> = (props) => {
  const { name, label, required, control, valueAtCheck, children, onChange, checkedChildren } = props
  const messageKeys = props.valicationMessageKeys ?? {}
  
  const {
    field: { ref, onChange: fieldOnChange, ...ctrlProps },
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: translate(messageKeys.required ?? 'system.error.requiredSelection', label),
      },
    },
    defaultValue: '',
    control,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    fieldOnChange(checked ? valueAtCheck : '')
    if (onChange) onChange(valueAtCheck, checked)
  }
  
  return (
    <Root required={required}>
      <FormControlLabel
        {...ctrlProps}
        inputRef={ref}
        label={children}
        name={name}
        control={<Checkbox value={valueAtCheck} checked={checkedChildren.includes(valueAtCheck)} onChange={handleChange} />}
      />
    </Root>
  )
}
