import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetPasswordUrl, forgotPasswordUrl } from '../common/constant/appUrl'
import { setResetPassword } from '../common/store/slices/resetPassword'
import { forgotPassword } from '../../utils/authUtil'

interface Inputs {
  email: string
}

/**
 * ワンタイムパスワード要求画面フォームアクション
 */
export const useAction = () => {
  const formMethods = useForm<Inputs>()
  const history = useHistory()
  const dispatch = useDispatch()

  /**
   * フォーム送信イベント処理
   * @param data フォーム入力値
   */
  const onSubmit = useCallback(
    async (data: Inputs) => {
      // 入力情報を取得
      const { email } = data

      try {
        // パスワードリセットのためのワンタイムパスワード要求
        await forgotPassword(email)

        // Reduxストアにメールアドレスを送信
        dispatch(setResetPassword({email: email}))
  
        // パスワードリセット画面に遷移
        history.push(resetPasswordUrl.url(), { from: forgotPasswordUrl.url() })

      } catch (error) {
        // エラーメッセージ表示
        dispatch(setResetPassword({
          error: error instanceof Error ? error.message : String(error),
        }));
      }
    },
    []
  )

  return {
    formMethods,
    onSubmit,
  }
}
