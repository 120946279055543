import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm, useWatch, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { NoResultError } from '../../dataAccess/webApi/common/apiCaller'
import { executeGetChildren } from '../../dataAccess/webApi/dao/childrenDao'
import { executeGetFacilityReservationsWeekCitizen } from '../../dataAccess/webApi/dao/facilityReservationsWeekCitizenDao';
import { executeGetFacilityReservationsWeekPublic } from '../../dataAccess/webApi/dao/facilityReservationsWeekPublicDao';
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto';
import { GetFacilityReservationsWeekCitizenDto } from '../../dataAccess/webApi/dto/facilityReservationsWeekCitizenDto';
import { GetFacilityReservationsWeekPublicDto } from '../../dataAccess/webApi/dto/facilityReservationsWeekPublicDto';
import { translate } from '../../i18n'
import { 
  DateRange, 
  ElapsedMillisecond, 
  isValidDate, 
  toApiYmd, 
  getBirthday, 
  getFromToBirthdayFromClassAge
} from '../../utils/dateUtil'
import { castNonNullable, NullPropsToUndefinedType } from '../../utils/typeUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { notifyMessage, showLoading } from '../common/store/slices/application';
import { selectSystemControl } from '../common/store/slices/systemControl';
import { selectIsLoggedIn } from '../common/store/slices/authority'
import { facilityReservationSelectionCreateUrl } from '../common/constant/appUrl'
import { yesNo, useReasonCode as useReasonCodeValue, purposeOfUse as purposeOfUseValue } from '../common/constant/classification'
import { getUseReason } from '../common/useReason'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { undefinedPropsToOptional } from '../../utils/objectUtil'
import { ReactBatchUpdater } from '../../utils/reactUtil'

interface UrlParams {
  facilityId: string
  childId?: string
};

interface Inputs {
  /** 利用希望日時 */
  usageDesiredDatetimes: {
    value: {
      /** 時間の基準となる日付 */
      baseDateOfTime: Date
      /** 日時範囲 */
      range: DateRange
      /** 空き状況ステータス */
      status: string
    }
  }[]
  purposeOfUse: string
  useReasonDetailIrregular: string
  useReasonDetailEmg: string
  useReasonDetailRefresh: string
  useReasonDetailDescriptionsIrregular: {
    value: string
  }[]
  useReasonDetailDescriptionsEmg: {
    value: string
  }[]
  useReasonDetailDescriptionsRefresh: {
    value: string
  }[]
};

type AcceptAge = {
  age: number | null
  month: number | null
  day: number | null
  classFlag: boolean
}

interface LocationState {
  activeChildId?: string
  // 空き状況確認基準日
  activeDate: ElapsedMillisecond | null
  // 未ログインの場合の選択可能生年月日判定に使用する施設受け入れ可能年齢情報
  acceptAgeFrom: AcceptAge
  acceptAgeTo: AcceptAge
  purposeOfUse?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string
};

type Child = {
  value: string;
  label: string;
}

interface Availabilities {
  // 未ログインの場合はお子様生年月日の文字列(YYYYMMDD)
  activeChildId: string
  // 空き状況確認基
  activeDate: Date
  facilityId: string
  availabilities: GetFacilityReservationsWeekCitizenDto[] | GetFacilityReservationsWeekPublicDto[] | null
  useReasonCode?: string
}

interface FacilityAvailabilities extends Availabilities {
  facility: NullPropsToUndefinedType<GetFacilityDto>
}

interface FacilityWithChild extends FacilityAvailabilities {
  childs: Child[] | null
  isExistPermitChilds: boolean 
}

interface PageState  {
  // 未ログインの場合はお子様生年月日の文字列(YYYYMMDD)
  activeChildId: string
  // 空き状況確認基準日
  activeDate: Date
  // 未ログインの場合の選択可能生年月日判定に使用する施設受け入れ可能年齢情報
  acceptAgeFrom: AcceptAge
  acceptAgeTo: AcceptAge
  facilityId?: string
  availabilities?: GetFacilityReservationsWeekCitizenDto[] | GetFacilityReservationsWeekPublicDto[] | null
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  childs: Child[] | null
  isExistPermitChilds: boolean 
  /** 利用目的マスタ情報 */
  useReason?: NullPropsToUndefinedType<GetUseReasonDto>
};

const isInsertParams = (urlParams: UrlParams): urlParams is UrlParams => {
  return 'facilityId' in urlParams
}

export const useAction = () => {
  const errorHandle = useErrorHandle();
  const dispatch = useDispatch();
  const sysCtrl = useSelector(selectSystemControl);
  const history = useHistory<LocationState | undefined>();
  const nextHistory = useHistory();
  const urlParams = useParams<UrlParams>();

  const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
  const locationState: LocationState | undefined = history.location.state;
  const currentLocation = history.location;
  const formMethods = useForm<Inputs>();
  const notifyDateFetchErrorMessage = useCallback(
    () => dispatch(notifyMessage(translate('facilityDetailAvailability.error.noReservationReceptionSetting'))),
    [dispatch]
  );

  // 常に useState を呼び出す
  const [state, setState] = useState<PageState | null>(null);
  
  // 未ログイン用選択生年月日上限＆下限
  // ７日分表示するので一番未来の日付において選択可能な生年月日を選択可能とする
  const datecriteria = locationState?.activeDate ? new Date(locationState.activeDate) : sysCtrl.systemDatetime? new Date(sysCtrl.systemDatetime) : new Date();
  const [ minBirthday, setMinBirthday ] = useState< Date | undefined >();
  const [ maxBirthday, setMaxBirthday ] = useState< Date | undefined >();
  
  const [isCheckUseReason, setIsCheckUseReason] = useState(() => (locationState?.purposeOfUse !== '' && locationState?.purposeOfUse !== null && locationState?.purposeOfUse !== undefined))
  const purposeOfUse = useWatch({ name: 'purposeOfUse', control: formMethods.control })
  const useReasonDetailIrregular = useWatch({ name: 'useReasonDetailIrregular', control: formMethods.control })
  const useReasonDetailEmg = useWatch({ name: 'useReasonDetailEmg', control: formMethods.control })
  const useReasonDetailRefresh = useWatch({ name: 'useReasonDetailRefresh', control: formMethods.control })
  const { 
    append: appendUseReasonDetailDescriptionsIrregular,
  } = useFieldArray({
    name: 'useReasonDetailDescriptionsIrregular',
    control: formMethods.control,
  })
  
  const { 
    append: appendUseReasonDetailDescriptionsEmg,
  } = useFieldArray({
    name: 'useReasonDetailDescriptionsEmg',
    control: formMethods.control,
  })
  
  const {
    append: appendUseReasonDetailDescriptionsRefresh,
  } = useFieldArray({
    name: 'useReasonDetailDescriptionsRefresh',
    control: formMethods.control,
  })
  
  // 初期化
  useEffect(() => {
      if (!locationState) {
        notifyDateFetchErrorMessage();
        return;
      }
      
      const initialState: PageState = {
        activeChildId: ((!isLoggedIn && !locationState.activeChildId) || !locationState.activeChildId )
                          ? convertChildBirthdayToIdLike(getAcceptBirthdayTo(locationState.acceptAgeFrom, datecriteria)) //一週間分の表示になるので表示される最後の日を基準にする
                          : locationState.activeChildId,
        activeDate: datecriteria,
        acceptAgeFrom: locationState.acceptAgeFrom,
        acceptAgeTo: locationState.acceptAgeTo,
        childs: null,
        isExistPermitChilds: false,
      };
  
      // 初期状態を設定
      setState(initialState);
  
      // 初期化後にデータ取得を行い、stateにマージ
      const fetchFacilityData = async () => {
        // 利用目的別に設定されていて表示内容が1つしかないときの初期化処理
        const initializeSinglePurposeSetting = (facility: NullPropsToUndefinedType<GetFacilityDto>): string | undefined => {
          if (
            ((facility.irregularFlag === yesNo.yes && facility.useReasonUsageFlagIrregular === yesNo.yes) ? 1 : 0) +
            ((facility.emergencyFlag === yesNo.yes && facility.useReasonUsageFlagEmg === yesNo.yes) ? 1 : 0) +
            ((facility.refreshFlag === yesNo.yes && facility.useReasonUsageFlagRefresh === yesNo.yes) ? 1 : 0) === 1
          ) {
            const purposeOfUseTmp = (facility.irregularFlag === yesNo.yes && facility.useReasonUsageFlagIrregular === yesNo.yes) ? purposeOfUseValue.irregular :
              (facility.emergencyFlag === yesNo.yes && facility.useReasonUsageFlagEmg === yesNo.yes) ? purposeOfUseValue.emergency :
              (facility.refreshFlag === yesNo.yes && facility.useReasonUsageFlagRefresh === yesNo.yes) ? purposeOfUseValue.refresh : undefined
            const batchUpdater = new ReactBatchUpdater()
            batchUpdater.add(() =>
              formMethods.reset({
                ...formMethods.getValues(),
                ...undefinedPropsToOptional({
                  purposeOfUse: purposeOfUseTmp,
                }),
              })
            )
            batchUpdater.addSetter(setIsCheckUseReason, true)
            batchUpdater.execute()
            return purposeOfUseTmp
          }
        }
        
        const [useReason, facility, childrenResponse] = await Promise.all([
          getUseReasonMaster(),
          getFacility(urlParams.facilityId, initialState.activeDate),
          isLoggedIn ? executeGetChildren({ facilityId: urlParams.facilityId }) : Promise.resolve(null),
        ]);
        const detailDescriptionsIrregular = Array(useReason.detailsIrregular.length).fill(null).map(() => ({ value: '' }))
        const detailDescriptionsEmg = Array(useReason.detailsEmg.length).fill(null).map(() => ({ value: '' }))
        const detailDescriptionsRefresh = Array(useReason.detailsRefresh.length).fill(null).map(() => ({ value: '' }))
        let useReasonCode = initializeSinglePurposeSetting(facility)
        // 施設にて許可がされていれば予約画面へ遷移
        if (childrenResponse && childrenResponse.result.length !== 0) {
          initialState.isExistPermitChilds = true
          if (
            initialState.isExistPermitChilds &&
            facility.reservationAcceptFlag === yesNo.yes
          ) {
            const childs = childrenResponse.result.map((child) => ({ value: child.childId, label: child.name }));
            const nextHistoryState: LocationState = {
              activeDate: initialState.activeDate.getTime(),
              acceptAgeFrom: initialState.acceptAgeFrom,
              acceptAgeTo: initialState.acceptAgeTo
            };
            nextHistory.replace({
              pathname: facilityReservationSelectionCreateUrl.url(
                urlParams.facilityId,
                childs[0].value,
              ),
              state: nextHistoryState,
            });
          }
        }
        // 予約受付設定を設定しているか
        if (facility.detailSetting.length === 0) {
          notifyDateFetchErrorMessage();
          return;
        }
        
        if (facility.detailSetting[0].useReasonCode === null) {
          notifyDateFetchErrorMessage();
          return;
        }
        // 共通設定かどうか
        if (facility.detailSetting.length === 1 && facility.detailSetting[0].useReasonCode === (useReasonCodeValue.common)) {
          useReasonCode = '0';
        } else {
           if (!useReasonCode) {
            setState(prev => ({
              ...prev!,
              ...initialState,
              facility: facility,
              useReason: useReason,
            }))
            return
          } 
        }

        appendUseReasonDetailDescriptionsIrregular(detailDescriptionsIrregular)
        appendUseReasonDetailDescriptionsEmg(detailDescriptionsEmg)
        appendUseReasonDetailDescriptionsRefresh(detailDescriptionsRefresh)
        const facilityWithChilds: FacilityWithChild | null = await getLoggedInJudgeFacilityAvailabilitiesWithChilds(
          urlParams.facilityId,
          initialState.activeChildId,
          initialState.activeDate,
          isLoggedIn,
          useReasonCode,
        );

        if (facilityWithChilds) {
          setState(prev => ({
            ...prev!,
            ...initialState,
            ...facilityWithChilds,
            useReason: useReason,
          }));
  
          const nextHistoryState: LocationState = {
            activeDate: initialState.activeDate.getTime(),
            acceptAgeFrom: initialState.acceptAgeFrom,
            acceptAgeTo: initialState.acceptAgeTo
          };
  
          if (
            facilityWithChilds.isExistPermitChilds &&
            facilityWithChilds.facility.reservationAcceptFlag === yesNo.yes
          ) {
            nextHistory.replace({
              pathname: facilityReservationSelectionCreateUrl.url(
                facilityWithChilds.facilityId,
                facilityWithChilds.activeChildId
              ),
              state: nextHistoryState,
            });
          }
        } else {
          notifyDateFetchErrorMessage();
        }
      };
  
      fetchFacilityData();
    }, []);

  useEffect(() => {
    if (state?.activeChildId == null) return;

    const historyState: LocationState = {
      activeChildId: state.activeChildId,
      activeDate: state.activeDate.getTime(),
      acceptAgeFrom: state.acceptAgeFrom,
      acceptAgeTo: state.acceptAgeTo
    };

    history.replace({
      ...history.location,
      state: historyState
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.activeChildId, state?.activeDate]);
  
  // 選択された日付と施設受け入れ年齢から選択可能な生年月日の上限＆下限を算出する
  useEffect(() => {
    if ( !state?.activeDate || !locationState ) {
      return;
    }
    
    // ７日分表示するので一番未来の日付において選択可能な生年月日を選択可能とする
    setMinBirthday(getAcceptBirthdayFrom(locationState.acceptAgeTo, state.activeDate));
    setMaxBirthday(getAcceptBirthdayTo(locationState.acceptAgeFrom, state.activeDate));
  }, [state?.activeDate])

  const onNextPrevExec = useCallback(
    (baseDate: Date) => {
      if (state === null) {
        notifyDateFetchErrorMessage();
        return;
      }

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const facilityAvailabilities = await getLoggedInJudgeFacilityAvailabilities(
              urlParams.facilityId,
              castNonNullable(state.activeChildId),
              baseDate,
              purposeOfUse,
              state.isExistPermitChilds
            );
            if (facilityAvailabilities) {
              setState(prev => ({
                ...prev!,
                ...facilityAvailabilities,
              }));
            } else {
              notifyDateFetchErrorMessage();
            }
          }),
          isHiddenMain: false,
        })
      );
    },
    [
      dispatch,
      errorHandle,
      notifyDateFetchErrorMessage,
      urlParams.facilityId,
      state?.activeChildId,
      state?.isExistPermitChilds,
      purposeOfUse,
    ]
  );

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (state === null) {
        notifyDateFetchErrorMessage();
        return;
      }

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const searchChildId = event.target.value;
            const availabilities = await getLoggedInJudgeAvailabilities(
              urlParams.facilityId,
              searchChildId,
              state.activeDate,
              purposeOfUse,
              state.isExistPermitChilds
            );
            if (availabilities) {
              setState(prev => ({
                ...prev!,
                ...availabilities,
              }));
            } else {
              notifyDateFetchErrorMessage();
            }
          }),
          isHiddenMain: false,
        })
      );
    },
    [
      dispatch,
      errorHandle,
      notifyDateFetchErrorMessage,
      urlParams.facilityId,
      state?.activeDate,
      state?.isExistPermitChilds,
      purposeOfUse,
    ]
  );
  
  const changeChildBirthday = useCallback(
  (date: Date | null) => {
    if (state === null) {
      notifyDateFetchErrorMessage();
      return;
    }
    if (date === null) return;

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          const searchChildId = convertChildBirthdayToIdLike(new Date(date));
          const availabilities = await getLoggedInJudgeAvailabilities(
            urlParams.facilityId,
            searchChildId,
            state.activeDate,
            purposeOfUse,
            state.isExistPermitChilds
          );
          if (availabilities) {
            setState(prev => ({
              ...prev!,
              ...availabilities,
            }));
          } else {
            notifyDateFetchErrorMessage();
          }
        }),
        isHiddenMain: false,
      })
    );
  },
  [
    dispatch,
    errorHandle,
    notifyDateFetchErrorMessage,
    urlParams.facilityId,
    state?.activeDate,
    state?.isExistPermitChilds,
    purposeOfUse,
  ]
);

  const changeDate = useCallback(
    (date: Date | null) => {
      if (isValidDate(date)) {
        onNextPrevExec(date);
      }
    },
    [onNextPrevExec]
  );
  
  const changeUseReason = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (state === null) {
        notifyDateFetchErrorMessage();
        return;
      }
      if (commonFlag === yesNo.yes) return
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            let facilityId = state.facilityId
            if (facilityId === undefined) {
              if (isInsertParams(urlParams)) {
                facilityId = urlParams.facilityId
              }
            }
            
            const availabilities = await executeGetFacilityReservationsWeekPublic(castNonNullable(facilityId), {
              targetDate: toApiYmd(state.activeDate),
              useReasonCode: event.target.value,
              childBirthday: castNonNullable(state.activeChildId),
            })
            if (availabilities.result.length) {
              /** 正常 */
              setState(prev => ({
                ...prev!,
                availabilities: availabilities.result,
              }));
              if (!isCheckUseReason) setIsCheckUseReason(!isCheckUseReason)
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [
      dispatch,
      errorHandle,
      notifyDateFetchErrorMessage,
      purposeOfUse,
      state?.activeDate,
      state?.isExistPermitChilds,
    ]
  )

  const callTel = useCallback((tel: string) => (document.location.href = `tel:${tel}`), []);

  /** 施設側が予約受付設定を共通にしているかどうかの判定 */
  const commonFlag = (function() {
    if (state === null) return null;
    // 共通の場合配列データが必ず1つとなるため
    if (state.facility?.detailSetting.length !== 1) return yesNo.no
    if (state.facility?.detailSetting[0].useReasonCode === useReasonCodeValue.common) return yesNo.yes
    return yesNo.no
  }())

  return {
    facilityId: urlParams.facilityId,
    facility: state?.facility?? null,
    childs: state?.childs?? null,
    activeChildId: state?.activeChildId?? undefined,
    activeDate: state?.activeDate?? null,
    availabilities: state?.availabilities?? null,
    formMethods,
    isExistPermitChilds: state?.isExistPermitChilds,
    currentLocation,
    isLoggedIn,
    onNextPrevExec,
    changeChild,
    changeChildBirthday,
    changeDate,
    callTel,
    sysCtrl,
    useReason: state?.useReason,
    purposeOfUse,
    useReasonDetailIrregular,
    useReasonDetailEmg,
    useReasonDetailRefresh,
    changeUseReason,
    isCheckUseReason,
    commonFlag,
    minBirthday,
    maxBirthday
  };
}

/**
 * 未ログイン、ログイン済みかを判定し、
 * 施設情報、空き情報、お子さまリストを取得
 * （getLoggedInJudgeFacilityAvailabilitiesWithChilds）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param isLoggedIn ログイン状態
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getLoggedInJudgeFacilityAvailabilitiesWithChilds = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  isLoggedIn: boolean,
  useReasonCode: string,
): Promise<FacilityWithChild | null> => {
  if (isLoggedIn) {
    return getFacilityAvailabilitiesWithChildsLoggedIn(facilityId, childId, targetDate, useReasonCode)
  } else {
    return getFacilityAvailabilitiesWithChildsNotLoggedIn(facilityId, childId, targetDate, useReasonCode)
  }
}

/**
 * 施設許可お子さま存在かを判定し、
 * 施設情報、空き情報を取得
 * （getLoggedInJudgeFacilityAvailabilities）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param useReasonCode 利用目的コード
 * @param isExistPermitChilds 施設許可お子さま存在フラグ（true:存在 false:存在しない）
 * @returns 施設情報、空き情報
 */
const getLoggedInJudgeFacilityAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  useReasonCode: string,
  isExistPermitChilds: boolean
) => {
  if (isExistPermitChilds) {
    return getFacilityAvailabilitiesLoggedIn(facilityId, childId, targetDate, useReasonCode)
  } else {
    return getFacilityAvailabilitiesNotLoggedIn(facilityId, childId, targetDate, useReasonCode)
  }
}

/**
 * 施設許可お子さま存在かを判定し、
 * 空き情報を取得
 * （getLoggedInJudgeAvailabilities）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param useReasonCode 利用目的コード
 * @param isExistPermitChilds 施設許可お子さま存在フラグ（true:存在 false:存在しない）
 * @returns 空き情報
 */
const getLoggedInJudgeAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  useReasonCode: string,
  isExistPermitChilds: boolean
) => {
  if (isExistPermitChilds) {
    return getAvailabilitiesLoggedIn(facilityId, childId, targetDate, useReasonCode)
  } else {
    return getAvailabilitiesNotLoggedIn(facilityId, childId, targetDate, useReasonCode)
  }
}

/**
 * 空き情報を取得（未ログイン時）
 * @param facilityId 施設ID
 * @param childBirthday お子さま生年月日(YYYYMMDD)
 * @param targetDate 取得基準日
 * @param useReasonCode 利用目的コード
 * @returns 空き情報
 */
const getAvailabilitiesNotLoggedIn = async (facilityId: string, childBirthday: string, targetDate: Date, useReasonCode: string): Promise<Availabilities | null> => {
  const availabilitiesResponse = await executeGetFacilityReservationsWeekPublic(facilityId, {
    childBirthday: childBirthday,
    targetDate: toApiYmd(targetDate),
    useReasonCode: (useReasonCode) ? useReasonCode : '0',
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeChildId: childBirthday,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 空き情報を取得（ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param useReasonCode 利用目的コード
 * @returns 空き情報
 */
const getAvailabilitiesLoggedIn = async (facilityId: string, childId: string, targetDate: Date, useReasonCode: string): Promise<Availabilities | null> => {
  const availabilitiesResponse = await executeGetFacilityReservationsWeekCitizen(facilityId, {
    childId,
    targetDate: toApiYmd(targetDate),
    useReasonCode: (useReasonCode) ? useReasonCode : '0',
    reservationNo: null,
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeChildId: childId,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報を取得（未ログイン時）
 * @param facilityId 施設ID
 * @param childBirthday お子さま生年月日(YYYYMMDD)
 * @param targetDate 取得基準日
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報
 */
const getFacilityAvailabilitiesNotLoggedIn = async (facilityId: string, childBirthday: string, targetDate: Date, useReasonCode: string): Promise<FacilityAvailabilities | null> => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilitiesNotLoggedIn(facilityId, childBirthday, targetDate, useReasonCode),
    ])
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報を取得（ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報
 */
const getFacilityAvailabilitiesLoggedIn = async (facilityId: string, childId: string, targetDate: Date, useReasonCode: string):  Promise<FacilityAvailabilities | null> => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilitiesLoggedIn(facilityId, childId, targetDate, useReasonCode),
    ])
    
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報、お子さまリストを取得（未ログイン時）
 * @param facilityId 施設ID
 * @param child
 * @param targetDate 取得基準日
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getFacilityAvailabilitiesWithChildsNotLoggedIn = async (
  facilityId: string,
  childBirthday: string,
  targetDate: Date,
  useReasonCode: string,
):  Promise<FacilityWithChild | null> => {

  const facilityAvailabilities = await getFacilityAvailabilitiesNotLoggedIn(facilityId, childBirthday, targetDate, useReasonCode);
  
  if (facilityAvailabilities) {
    return {
      ...facilityAvailabilities,
      childs: null,
      isExistPermitChilds: false,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報、お子さまリストを取得（ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getFacilityAvailabilitiesWithChildsLoggedIn = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  useReasonCode: string,
):  Promise<FacilityWithChild | null> => {
  const childrenResponse = await executeGetChildren({ facilityId });

  // 利用者に属するお子様がいない場合
  if (childrenResponse.result.length === 0) {
    return getLoggedInJudgeFacilityAvailabilitiesWithChilds(
      facilityId,
      childId,
      targetDate,
      false,
      useReasonCode,
    );
  }
  
  // YYYYMMDDの未ログインユーザー用のIDが設定されていた時リセット
　if (childId.length > 2) {
　  childId = '';
　}
  
  /* 
    お子様ID（childId）が未設定（undefined）の場合（遷移直後）、
    ログイン状態では、利用者に属するお子様で、施設に許可されたお子様リストの先頭を選択値として設定する。*/
  const childs = childrenResponse.result.map((child) => ({ value: child.childId, label: child.name }));

  const subjectChildId = getSubjectChildId(childs, childId);

  const facilityAvailabilities = await getFacilityAvailabilitiesLoggedIn(facilityId, subjectChildId, targetDate, useReasonCode);
  
  if (facilityAvailabilities) {
    return {
      ...facilityAvailabilities,
      childs,
      isExistPermitChilds:true,
    }
  } else {
    return null
  }
}

const getSubjectChildId = (childs: Child[], childId: string | null) => {
  if (childId == null || !childs.some((v) => v.value === childId)) {
    // 戻る初期表示時に施設許可お子さま存在が変わっていた場合
    // システムエラーにならないように一致する選択の有無を判定しておく
    return childs[0].value
  } else {
    return childId
  }
}

/**
 * IDとして渡せるようにDateを文字列に変更
 * @param birthday 
 * @returns YYYYMMDD形式の文字列に変更
 */
const convertChildBirthdayToIdLike = (birthday: Date): string => {
  return `${birthday.getFullYear()}${(birthday.getMonth() + 1).toString().padStart(2, "0")}${birthday.getDate().toString().padStart(2, "0")}`
}

function convertToDate(dateString: string): Date {
  // YYYYMMDD形式の文字列から年、月、日を抽出
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // JavaScriptのDateは月が0から始まるため -1
  const day = parseInt(dateString.substring(6, 8), 10);

  // 抽出した年、月、日を使用してDateオブジェクトを作成
  return new Date(year, month, day);
}

async function getUseReasonMaster() {
  return await getUseReason()
}

/**
 * 施設の受け入れ上限年齢から利用できるお子様の下限生年月日を算出
 * @param acceptAgeFrom
 * @return Date 基準日　
 */
const getAcceptBirthdayFrom = (acceptAgeTo: AcceptAge, datetime: Date) => {
  if( acceptAgeTo.classFlag &&  acceptAgeTo.age ) {
    return getFromToBirthdayFromClassAge(datetime, acceptAgeTo.age).from
  }else {
    return getBirthday(datetime, acceptAgeTo.age, acceptAgeTo.month, acceptAgeTo.day, false);
  }
}

/**
 * 施設の受け入れ下限年齢から利用できるお子様の上限生年月日を算出
 * 最大値は当日になる
 * @param acceptAgeTo
 * @return Date 基準日
 */
const getAcceptBirthdayTo = (acceptAgeFrom: AcceptAge, datetime: Date) => {
  let resultDate: Date;
  if (acceptAgeFrom.classFlag && acceptAgeFrom.age) {
    resultDate = getFromToBirthdayFromClassAge(datetime, acceptAgeFrom.age).to;
  } else {
    resultDate = getBirthday(datetime, acceptAgeFrom.age, acceptAgeFrom.month, acceptAgeFrom.day, true);
  }
  
  // 生後57日の生年月日
  const maxReservableBirthday = getBirthday(datetime, null, null, 57, true);

  // 生後57日の生年月日と比較して、日付を返す
  if (resultDate >= maxReservableBirthday) {
    return maxReservableBirthday;
  } else {
    return resultDate;
  }
}


export { convertToDate };
export type { LocationState };