// 日付関連の操作を行うユーティリティ関数を定義
// format系関数は、使用される可能性があるものは一通りの形式をそろえている。

import { 
  differenceInHours, 
  differenceInMinutes, 
  format, 
  getMinutes, 
  isEqual, 
  parse, 
  startOfDay } from 'date-fns'
import { getLocale } from '../i18n'
import { fromNumber } from './stringUtil'

/** 無効な日付を表す文字列値 */
export const INVALID_DATE_STRING = 'Invalid Date'
/**
 * Date.getTime()で取得した1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒。
 * reduxではシリアライズ可能なインスタンスしか保存出来ない為、DateはDate.getTime()をこの型を指定したプロパティに格納
 * Dateに復元する場合は、new Date(ElapsedMillisecondの値)でDateインスタンス化する
 */
export type ElapsedMillisecond = number
/**
 * 日付範囲(Date型)
 */
export interface DateRange {
  from: Date
  to: Date
}
/**
 * 日付範囲(Date、ElapsedMillisecond型混在可能)
 */
export interface DateElapsedRange {
  from: Date | ElapsedMillisecond
  to: Date | ElapsedMillisecond
}

/**
 * 日付インスタンスが有効な日付であるか判定する。
 * 実在しない日付を入力された場合は、Invalid Date となる為これを判別する為に使用できる。
 * 渡された値がnull,undefinedの場合はfalseとする。
 * また、Date型への型ガード関数としても機能するので型をDateに特定したい場合にも使用できる。
 *
 * @param value 日付インスタンス
 * @returns 有効な日付ならtrue
 */
export const isValidDate = (value?: Date | null): value is Date => value != null && !Number.isNaN(value?.getDate())

export const isValidApiYmd = (value?: string | null): value is string => {
  if (value === INVALID_DATE_STRING) {
    return false
  }
  try {
    return isValidDate(fromApiYmd(value))
  } catch {
    return false
  }
}

export const isValidApiHms = (value?: string | null): value is string => {
  if (value === INVALID_DATE_STRING) {
    return false
  }
  try {
    return isValidDate(fromApiHms(value))
  } catch {
    return false
  }
}

type FormatDateString<T> = T extends Date | ElapsedMillisecond ? string : T

/**
 * 日付を指定したパターンでフォーマットする。
 * 使用できるパターンは以下サイト参照
 * https://date-fns.org/v2.28.0/docs/format
 *
 * @param value 日付
 * @param pattern フォーマットパターン
 * @returns フォーマットされた文字列
 */
export const formatDate = <T extends Date | ElapsedMillisecond | undefined | null>(
  value: T,
  pattern: string
): FormatDateString<T> => {
  let result
  if (value == null) {
    result = value
  } else {
    const locale = getLocale()
    result = format(value, pattern, { locale })
  }
  return result as FormatDateString<T>
}

/**
 * 日付をHH:mm(24時間以上)形式にフォーマット
 *
 * @param value 日付
 * @param baseDate 基準とする日付(24以上とするかを判別するために必須)
 * @returns フォーマットされた文字列
 */
export const formatHmOver = (value: Date | ElapsedMillisecond, baseDate: Date | ElapsedMillisecond) => {
  const hours = differenceInHours(value, startOfDay(baseDate))
  const minutes = getMinutes(value)
  return `${fromNumber(hours).padStart(2, '0')}:${fromNumber(minutes).padStart(2, '0')}`
}

/**
 * 日付範囲をHH:mm～HH:mm(24時間以上)形式にフォーマット
 *
 * @param value 日付範囲
 * @param baseDate 基準とする日付(24以上とするかを判別するために必須)
 * @returns フォーマットされた文字列
 */
export const formatHmtoHmOver = (value: DateElapsedRange, baseDate: Date | ElapsedMillisecond) => {
  return `${formatHmOver(value.from, baseDate)}～${formatHmOver(value.to, baseDate)}`
}

/**
 * 日付をHH:mm形式にフォーマット
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatHm = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'HH:mm')
}

/**
 * 日付をHH:mm形式にフォーマット
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatHyphenYmd = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'yyyy-MM-dd')
}

/**
 * 日付をyyyy/MM/dd形式にフォーマット
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatYmd = <T extends Date | ElapsedMillisecond | undefined | null>(value: T) => {
  return formatDate(value, 'yyyy/MM/dd')
}

/**
 * 日付をyyyy/MM/dd(E)形式にフォーマット
 * Eは曜日。※月、火、水が入る
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatYmdWeek = <T extends Date | ElapsedMillisecond | undefined | null>(value: T) => {
  return formatDate(value, 'yyyy/MM/dd(E)')
}

/**
 * 日付をyyyy年M月形式にフォーマット
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatLocaleYm = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'yyyy年M月')
}

/**
 * 日付範囲をyyyy年M月～yyyy年M月形式にフォーマット
 *
 * @param value 日付範囲
 * @returns フォーマットされた文字列
 */
export const formatLocaleYmToYm = (value: DateElapsedRange) => {
  return `${formatDate(value.from, 'yyyy年M月')}～${formatDate(value.to, 'yyyy年M月')}`
}

/**
 * 日付をyyyy/MM/dd HH:mm形式にフォーマット
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatYmdHm = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'yyyy/MM/dd HH:mm')
}

/**
 * 日付をyyyy/MM/dd HH:mm:ss形式にフォーマット
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatYmdHms = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'yyyy/MM/dd HH:mm:ss')
}

/**
 * 日付をyyyy/MM/dd HH:mm(24時間以上)形式にフォーマット
 *
 * @param value 日付
 * @param baseDate 基準とする日付(24以上とするかを判別するために必須)
 * @returns フォーマットされた文字列
 */
export const formatYmdHmOver = (value: Date | ElapsedMillisecond, baseDate: Date | ElapsedMillisecond) => {
  return `${formatYmd(baseDate)} ${formatHmOver(value, baseDate)}`
}

/**
 * 日付をyyyy/MM/dd(E) HH:mm形式にフォーマット
 * Eは曜日。※月、火、水が入る
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatYmdHmWeek = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'yyyy/MM/dd(E) HH:mm')
}

/**
 * 日付をyyyy/MM/dd(E) HH:mm(24時間以上)形式にフォーマット
 * Eは曜日。※月、火、水が入る
 *
 * @param value 日付
 * @param baseDate 基準とする日付(24以上とするかを判別するために必須)
 * @returns フォーマットされた文字列
 */
export const formatYmdHmOverWeek = (value: Date | ElapsedMillisecond, baseDate: Date | ElapsedMillisecond) => {
  return `${formatYmdWeek(baseDate)} ${formatHmOver(value, baseDate)}`
}

/**
 * 日付をyyyy/MM/dd(E) HH:mm:ss形式にフォーマット
 * Eは曜日。※月、火、水が入る
 *
 * @param value 日付
 * @returns フォーマットされた文字列
 */
export const formatYmdHmsWeek = (value: Date | ElapsedMillisecond) => {
  return formatDate(value, 'yyyy/MM/dd(E) HH:mm:ss')
}

/**
 * 日付をyyyy/MM/dd(E) HH:mm～HH:mm(24時間以上)形式にフォーマット
 * Eは曜日。※月、火、水が入る
 *
 * @param value 日付範囲
 * @param baseDate 基準とする日付(24以上とするかを判別するために必須)
 * @returns フォーマットされた文字列
 */
export const formatYmdHmToHmOverWeek = (value: DateElapsedRange, baseDate: Date | ElapsedMillisecond) => {
  return `${formatYmdWeek(baseDate)} ${formatHmtoHmOver(value, baseDate)}`
}

/**
 * 日付文字列をパースし、日付インスタンスを返す。
 *
 * @param value 日付文字列
 * @param pattern 日付文字列のフォーマットパターン
 * @param baseDate 基準とする日付(文字列が時間のみの場合を想定し、日を補完する目的で使用する)
 * デフォルトは現在日時(getNow())
 * @returns 日付インスタンス
 */
export const parseDate = (value: string, pattern: string, baseDate = getNow()) => {
  const locale = getLocale()
  return parse(value, pattern, baseDate, { locale })
}

/**
 * 基準とする日付から何時間、何分の差があるかを返す
 * 利用時間の表示に使用する想定で作成したもの。
 * ※現在はUIが変わり使用箇所が無くなったが、将来使用する可能性がある為残す
 *
 * @param value 日付
 * @param base 基準とする日付
 * @returns
 */
export const differenceInHoursMinutes = (value: Date, base: Date) => {
  const diffMinute = differenceInMinutes(value, base)
  const hour = Math.trunc(diffMinute / 60)
  const minute = diffMinute % 60
  return {
    hour,
    minute,
  }
}

/**
 * 日付が日付範囲に含まれるか否かを返す。
 *
 * @param value 日付
 * @param range 日付範囲
 * @param option オプション
 * @param option.isExcludingFrom:判定に範囲のfromを含めない場合はtrue
 * @param option.isExcludingTo 判定に範囲のtoを含めない場合はtrue
 * @returns 日付が日付範囲に含まれる場合はtrue
 */
export const isIncludeDate = (
  value: Date,
  range: DateRange,
  { isExcludingFrom = false, isExcludingTo = false } = {}
) => {
  if (range.from < value && value < range.to) {
    return true
  }
  if (!isExcludingFrom && isEqual(range.from, value)) {
    return true
  }
  if (!isExcludingTo && isEqual(range.to, value)) {
    return true
  }
  return false
}

/**
 * @param date 日時
 * @returns 日時から時刻成分を0に設定したDateインスタンス
 */
export const trimTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

/**
 * システム日付(業務日付)
 * テストなどで現在日を固定したい場合に使用する。
 * 通常はundefined
 */
let sysDate: Date | undefined

/**
 * DateUtil初期化
 * @param systemDatetime システム日時
 */
export const initializeDateUtil = (systemDatetime: Date | undefined) => {
  sysDate = systemDatetime
}

/**
 * 現在日時を取得する。※日本の時間に補正された値
 * ただし、業務日付が設定されていれば、
 * システム日付の年月日をその設定値に置き換えたものを返す
 * @param isFixedTime 時刻についてもシステム日付で固定する場合はtrue
 * @returns 現在日時
 */
export const getNow = (isFixedTime?: boolean) => {
  const now = getJstNow()
  if (sysDate) {
    if (isFixedTime) {
      now.setTime(sysDate.getTime())
    } else {
      now.setFullYear(sysDate.getFullYear(), sysDate.getMonth(), sysDate.getDate())
    }
  }
  return now
}

/**
 * @returns 現在日時(時刻成分を0に設定したDateインスタンス)
 */
export const getNowTrimedTime = () => trimTime(getNow())

/**
 * 機械日時 + (getTimezoneOffset()) + (+9時間のミリ秒単位)
 * を行うことで日本の時間に補正
 * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
 * @returns 日本の時間に補正したDate
 */
const getJstNow = () => {
  const now = new Date()
  const utcOffsetMinuteFromLocal = now.getTimezoneOffset()
  return new Date(now.getTime() + utcOffsetMinuteFromLocal * 60 * 1000 + 9 * 60 * 60 * 1000)
}

/**
 * @param dateString 日付文字列（Date.parse() メソッドで認識される形式）
 * @returns dateString で指定した日付の一日の終わりに対応する Date オブジェクト（日本の時間に補正されたもの）
 */
export const endOfDay = (dateString: string): Date => {
  const time = new Date(dateString).getTime()
  const timeOffset = time + 9 * 60 * 60 * 1000
  const timeOfEndOfDay = new Date(timeOffset).setUTCHours(23, 59, 59, 999)
  return new Date(timeOfEndOfDay - 9 * 60 * 60 * 1000)
}

type DateToApiString<T> = T extends Date | ElapsedMillisecond ? string : T
type DateFromApiString<T> = T extends string ? Date : T

/** APIで扱い日付文字列フォーマットパターン */
const apiFormat = {
  ymdHmsS: 'yyyy-MM-dd HH:mm:ss.SSS',
  ymdHms: 'yyyy-MM-dd HH:mm:ss',
  ymdHm: 'yyyy-MM-dd HH:mm',
  ymd: 'yyyy-MM-dd',
  ym: 'yyyy-MM',
  hms: 'HH:mm:ss',
  hm: 'HH:mm',
} as const

/** APIのタイムスタンプ項目をパースする際に使用するフォーマット */
const apiYmdHmsSParseFormats = [
  'yyyy-MM-dd HH:mm:ss.SSS',
  'yyyy-MM-dd HH:mm:ss.SS',
  'yyyy-MM-dd HH:mm:ss.S',
  'yyyy-MM-dd HH:mm:ss',
]

const toApiFormat = <T extends Date | ElapsedMillisecond | undefined | null>(
  pattern: string,
  value: T
): DateToApiString<T> => {
  let result
  if (value != null) {
    result = formatDate(value, pattern)
  } else {
    result = value
  }
  return result as DateToApiString<T>
}
const fromApiFormat = <T extends string | undefined | null>(
  pattern: string,
  value: T,
  baseDate?: Date
): DateFromApiString<T> => {
  let result
  if (value != null) {
    result = parseDate(value, pattern, baseDate)
  } else {
    result = value
  }
  return result as DateFromApiString<T>
}

/**
 * 日付をAPI受け渡し用日付文字列(yyyy-MM-dd HH:mm:ss.SSS)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiYmdHmsS = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.ymdHmsS, value)
}
/**
 * 日付をAPI受け渡し用日付文字列(yyyy-MM-dd HH:mm:ss)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiYmdHms = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.ymdHms, value)
}
/**
 * 日付をAPI受け渡し用日付文字列(yyyy-MM-dd HH:mm)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiYmdHm = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.ymdHm, value)
}
/**
 * 日付をAPI受け渡し用日付文字列(yyyy-MM-dd)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiYmd = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.ymd, value)
}
/**
 * 日付をAPI受け渡し用日付文字列(yyyy-MM)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiYm = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.ym, value)
}
/**
 * 日付をAPI受け渡し用日付文字列(HH:mm:ss)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiHms = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.hms, value)
}
/**
 * 日付をAPI受け渡し用日付文字列(HH:mm)へ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付
 * @returns API受け渡し用日付文字列
 */
export const toApiHm = <T extends Date | ElapsedMillisecond | undefined | null>(value: T): DateToApiString<T> => {
  return toApiFormat(apiFormat.hm, value)
}

/**
 * API受け渡し用日付文字列(yyyy-MM-dd HH:mm:ss.SSS)※1 から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * ※1
 * ミリ秒について、末尾0が省略された文字列が返されてしまう為
 * 文字列長に応じた書式を適用してパースする。
 * 適用する書式は以下の通り
 * yyyy-MM-dd HH:mm:ss.SSS
 * yyyy-MM-dd HH:mm:ss.SS
 * yyyy-MM-dd HH:mm:ss.S
 * yyyy-MM-dd HH:mm:ss
 *
 * @param value 日付文字列
 * @returns 日付インスタンス
 */
export const fromApiYmdHmsS = <T extends string | undefined | null>(value: T): DateFromApiString<T> => {
  for (const format of apiYmdHmsSParseFormats) {
    if (value != null && value.length === format.length) {
      return fromApiFormat(format, value)
    }
  }
  // もしいずれにもマッチしない場合は以下フォーマットで処理
  return fromApiFormat(apiFormat.ymdHmsS, value)
}
/**
 * API受け渡し用日付文字列(yyyy-MM-dd HH:mm:ss)から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付文字列
 * @returns 日付インスタンス
 */
export const fromApiYmdHms = <T extends string | undefined | null>(value: T): DateFromApiString<T> => {
  return fromApiFormat(apiFormat.ymdHms, value)
}
/**
 * API受け渡し用日付文字列(yyyy-MM-dd HH:mm)から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付文字列
 * @returns 日付インスタンス
 */
export const fromApiYmdHm = <T extends string | undefined | null>(value: T): DateFromApiString<T> => {
  return fromApiFormat(apiFormat.ymdHm, value)
}
/**
 * API受け渡し用日付文字列(yyyy-MM-dd)から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付文字列
 * @returns 日付インスタンス
 */
export const fromApiYmd = <T extends string | undefined | null>(value: T): DateFromApiString<T> => {
  return fromApiFormat(apiFormat.ymd, value)
}
/**
 * API受け渡し用日付文字列(yyyy-MM)から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付文字列
 * @returns 日付インスタンス
 */
export const fromApiYm = <T extends string | undefined | null>(value: T): DateFromApiString<T> => {
  return fromApiFormat(apiFormat.ym, value)
}
/**
 * API受け渡し用日付文字列(HH:mm:ss)から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付文字列
 * @param baseDate 基準とする日付(日を補完する目的で使用する)。省略した場合はgetNow()を採用
 * @returns 日付インスタンス
 */
export const fromApiHms = <T extends string | undefined | null>(value: T, baseDate?: Date): DateFromApiString<T> => {
  return fromApiFormat(apiFormat.hms, value, baseDate)
}
/**
 * API受け渡し用日付文字列(HH:mm)から日付インスタンスへ変換する。
 * null,undefinedを受け渡した場合は、そのままnull,undefinedを返す。
 *
 * @param value 日付文字列
 * @param baseDate 基準とする日付(日を補完する目的で使用する)。省略した場合はgetNow()を採用
 * @returns 日付インスタンス
 */
export const fromApiHm = <T extends string | undefined | null>(value: T, baseDate?: Date): DateFromApiString<T> => {
  return fromApiFormat(apiFormat.hm, value, baseDate)
}

/**
 * 入力されたクラス年齢から対象の誕生日の下限と上限日を取得する。
 * @param age 
 * @return { from, to } Date
 */
export const getFromToBirthdayFromClassAge = (dateCriteria: Date, age: number) => {
  let from = null;
  let to = null;

  const criteriaYear = dateCriteria.getFullYear() - age;

  // １～３月の場合
  if (dateCriteria.getMonth() <= 2) {
    from = new Date(`${criteriaYear - 2}/04/02`);
    to = new Date(`${criteriaYear - 1}/04/01`);
  }else {
    from = new Date(`${criteriaYear - 1}/04/02`);
    to = new Date(`${criteriaYear}/04/01`);
  }
  
  if (age === 0) {
    to = dateCriteria;
  }

  return { from, to };
}

/**
 * 入力された年齢（月齢、日齢）から誕生日を割り出す。
 * @param dateCriteria 日付基準
 * @param age 年齢（年単位）
 * @param month 月齢（月単位）
 * @param day 日齢（日単位）
 * @param isClosestDate 最も近い日付を返すかどうか（true: 最も近い, false: 最も遠い）
 * @return Date 誕生日
 */
export const  getBirthday = (dateCriteria: Date, age: number | null, months: number | null, days: number | null, isClosestDate: boolean): Date => {
  // 全てのパラメータがnullの場合、基準日付をそのまま返す
  if (age === null && months === null && days === null) {
      return dateCriteria;
  }

  // 年齢がnullの場合は0を代入
  age = age ?? 0;

  let calcDays;

  if (days === null) {
    if (isClosestDate) {
      calcDays = 0;
    }else {
      calcDays = new Date(dateCriteria.getFullYear(), dateCriteria.getMonth() + 1, 0).getDate();
    }
  }else {
    calcDays = days;
  }

  if (months === null) {
    if (isClosestDate) {
      months = 0;
    }else if (days === null) {
      months = 11;
    }else {
      months = 0;
    }
  }

  // 日付を基準に年齢、月齢、日齢を減算
  let year = dateCriteria.getFullYear() - age;
  let month = dateCriteria.getMonth() - months;
  let day = dateCriteria.getDate() - calcDays;

  // 日がマイナスになるのを防ぐ
  if (day < 1) {
      month -= 1;
      if (month < 0) {
          year -= 1;
          month += 12;
      }
      day += new Date(year, month + 1, 0).getDate();
  }

  // 正しい日付を作成
  const calculatedDate = new Date(year, month, day);

  // days=null で isClosestDate=false の場合に1日追加
  if (!isClosestDate && days === null) {
    return new Date(year, month, dateCriteria.getDate() + 1);
  }

  return calculatedDate;
}

/**
 * 日付に日数を足す
 * @param date Date
 * @param days number 足したい日数
 */
export const addDays = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
}