import { Grid, Stack, styled } from '@mui/material'
import React, { Fragment } from 'react'
import { useAction } from '../../containers/memberModifyUser/memberModifyUserService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { FlexboxRowTop, Separator } from '../components/common/flexboxes'
import { Form } from '../components/common/inputs/form'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TelTextBox } from '../components/common/inputs/telTextBox'
import { TextBox } from '../components/common/inputs/textBox'
import { SubTitle } from '../components/common/subTitle'

const UserContentAttribute = styled(Grid)({
  padding: '0 0 0 3rem',
})

export const MemberModifyUser = () => {
  const { formMethods, autoCompleteAddress, onSubmit, isDisabledResidenceCategory, residenceCategories, isDisabledAddress1 } = useAction()

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              {/* 基本 */}
              <SubTitle title={translate('memberModifyUser.title.basic')} />
              <div>
                <UserContentAttribute container rowSpacing={3}>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyUser.label.name')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox name="name" label={translate('memberModifyUser.label.name')} maxLength={100} required />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyUser.label.kana')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        name="kana"
                        label={translate('memberModifyUser.label.kana')}
                        maxLength={100}
                        textType="katakana"
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyUser.label.postalCode')}
                    </Grid>
                    <Grid item xs={12}>
                      <FlexboxRowTop>
                        <TextBox
                          name="postalCode"
                          label={translate('memberModifyUser.label.postalCode')}
                          textType="postalCode"
                          maxLength={8}
                          required
                        />
                        <Separator />
                        <ButtonOutlinedS onClick={autoCompleteAddress}>
                          {translate('memberModifyUser.button.autoComplete')}
                        </ButtonOutlinedS>
                      </FlexboxRowTop>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyUser.label.prefecturesAndMunicipalities')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        name="address1"
                        label={translate('memberModifyUser.label.prefecturesAndMunicipalities')}
                        maxLength={100}
                        required
                        disabled={isDisabledAddress1}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyUser.label.address')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        name="address2"
                        label={translate('memberModifyUser.label.address')}
                        maxLength={100}
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      {translate('memberModifyUser.label.buildingNameRoomNumber')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        name="buildingNameRoomNumber"
                        label={translate('memberModifyUser.label.buildingNameRoomNumber')}
                        maxLength={100}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberModifyUser.label.residenceCategory')}
                    </Grid>
                    <Grid item xs={12}>
                      <RadioButtonGroup
                        name="residenceCategory"
                        label={translate('memberModifyUser.label.residenceCategory')}
                        required
                        row
                        buttonValueLabels={residenceCategories}
                        disabled={isDisabledResidenceCategory}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      {translate('memberModifyUser.label.relationship')}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        name="relationship"
                        label={translate('memberModifyUser.label.relationship')}
                        maxLength={50}
                      />
                    </Grid>
                  </Grid>
                </UserContentAttribute>
              </div>
              {/* 緊急連絡先１～３ */}
              {['1', '2', '3'].map((suffix) => (
                <Fragment key={suffix}>
                  <SubTitle title={translate(`memberModifyUser.title.emergencyContact${suffix}`)} />
                  <div>
                    <UserContentAttribute container rowSpacing={3}>
                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          {translate('memberModifyUser.label.name')}
                        </Grid>
                        <Grid item xs={12}>
                          <TextBox
                            name={`emergencyContactName${suffix}`}
                            label={translate('memberModifyUser.label.name')}
                            maxLength={100}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          {translate('memberModifyUser.label.kana')}
                        </Grid>
                        <Grid item xs={12}>
                          <TextBox
                            name={`emergencyContactKana${suffix}`}
                            label={translate('memberModifyUser.label.kana')}
                            maxLength={100}
                            textType="katakana"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          {translate('memberModifyUser.label.relationship')}
                        </Grid>
                        <Grid item xs={12}>
                          <TextBox
                            name={`emergencyContactRelationship${suffix}`}
                            label={translate('memberModifyUser.label.relationship')}
                            maxLength={50}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          {translate('memberModifyUser.label.company')}
                        </Grid>
                        <Grid item xs={12}>
                          <TextBox
                            name={`emergencyContactCompany${suffix}`}
                            label={translate('memberModifyUser.label.company')}
                            maxLength={50}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          {translate('memberModifyUser.label.tel')}
                        </Grid>
                        <Grid item xs={12}>
                          <TelTextBox
                            name={`emergencyContactTel${suffix}`}
                            label={translate('memberModifyUser.label.tel')}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          {translate('memberModifyUser.label.email')}
                        </Grid>
                        <Grid item xs={12}>
                          <TextBox
                            name={`emergencyContactEmail${suffix}`}
                            label={translate('memberModifyUser.label.email')}
                            maxLength={320}
                            textType="email"
                          />
                        </Grid>
                      </Grid>
                    </UserContentAttribute>
                  </div>
                </Fragment>
              ))}
              {/* 減免 */}
              <SubTitle title={translate('memberModifyUser.title.exempt')} />
              <div>
                <UserContentAttribute container rowSpacing={3}>
                  <Grid item xs={12}>
                    {translate('memberModifyUser.text.exempt')}
                  </Grid>
                </UserContentAttribute>
              </div>
            </Stack>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>

        <BottomButtonGroup>
          <ButtonL fullWidth type="submit">
            {translate('memberModifyUser.button.confirmation')}
          </ButtonL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
