import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface InterviewReservationDeleteEntry {
  interviewNo: string

  facilityId: string
  facilityName: string

  interviewDate?: ElapsedMillisecond
  interviewStartDatetime?: ElapsedMillisecond
  interviewEndDatetime?: ElapsedMillisecond
  reservationDatetime: ElapsedMillisecond
  reservationChangeDatetime?: ElapsedMillisecond
  reservationCancelDatetime?: ElapsedMillisecond
  status: string
  permitFlag: string

  parentName: string
  parentKana: string
  tel: string
  email: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber?: string
  residenceCategory: string
  residenceCategoryName: string
  relationship?: string

  childId?: string
  childName: string
  childKana: string
  childBirthday: ElapsedMillisecond
  childGender: string
  childMedicalHistoryFlag: string
  childMedicalHistory?: string
  childAllergyFlag: string
  childAllergy?: string

  maternalHandbookNo?: string
  note?: string
  facilityNumber?: string

  cancelLimit?: number
  cancelLimitTime?: ElapsedMillisecond
  cancelNote?: string
  cancelLimitHolidayOption?: string

  cancelReason?: string

  immediatelyReservationFlag: string
}

interface InterviewReserveDeleteState {
  entry?: InterviewReservationDeleteEntry
  /** 排他制御用更新日時 */
  updateDatetime?: string
}
const initialState: InterviewReserveDeleteState = {}

export const interviewReserveDeleteSlice = createSlice({
  name: 'interviewReserveDelete',
  initialState,
  reducers: {
    setInterviewReserveDeleteEntry: (state, action: PayloadAction<InterviewReservationDeleteEntry>) => {
      state.entry = action.payload
    },
    setInterviewReserveDeleteUpdateDatetime: (state, action: PayloadAction<string>) => {
      state.updateDatetime = action.payload
    },
    clearInterviewReserveDelete: (state) => {
      delete state.entry
      delete state.updateDatetime
    },
  },
})

export const { setInterviewReserveDeleteEntry, setInterviewReserveDeleteUpdateDatetime, clearInterviewReserveDelete } =
  interviewReserveDeleteSlice.actions
export const selectInterviewReserveDeleteEntry = (state: RootState) => state.interviewReserveDelete.entry
export const selectInterviewReserveDeleteUpdateDatetime = (state: RootState) =>
  state.interviewReserveDelete.updateDatetime
export default interviewReserveDeleteSlice.reducer
