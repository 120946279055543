import { Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/accountEmailChange/accountEmailChangeService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroupNoWidth } from '../components/common/bottomButtonGroupNoWidth'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningM } from '../components/common/buttons/buttonOutlinedWarningM'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
import { CenterTitle } from '../components/common/centerTitle'

const ContentContainer = styled(Grid)({
  marginTop: '8vh',
})

// 説明文
const NoResultMessageCard = styled('div')({
  paddingTop: '3px',
})

export const AccountEmailChange = () => {
  const { formMethods, onSubmit } = useAction()
  return (
    <ContentContainer>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Stack spacing={4}>
          <Grid container>
            <Grid item md={4}></Grid>
            <Grid item xs={12} md={4}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <CenterTitle title={translate('accountEmailChange.label.newEmailLabel')} />
                </Grid>
                <Grid item xs={12}>
                  <NoResultMessageCard>
                    {translate('accountEmailChange.text.newEmailSetting')}
                  </NoResultMessageCard>
                  <NoResultMessageCard>
                    {translate('accountEmailChange.text.newEmailSend')}
                  </NoResultMessageCard>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <AttentionLabel>*</AttentionLabel>
                    {translate('accountEmailChange.label.newEmail')}
                  </Grid>
                  <Grid item xs={12}>
                    <TextBox
                      name="newEmail"
                      label={translate('accountEmailChange.label.newEmail')}
                      textType="email"
                      maxLength={320}
                      required
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <AttentionLabel>*</AttentionLabel>
                    {translate('accountEmailChange.label.newEmailConfirmation')}
                  </Grid>
                  <Grid item xs={12}>
                    <TextBox
                      name="newEmailConfirmation"
                      label={translate('accountEmailChange.label.newEmailConfirmation')}
                      textType="email"
                      maxLength={320}
                      required
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
          <BottomButtonGroupNoWidth>
            <ButtonOutlinedWarningM fullWidth type="submit">
              {translate('accountEmailChange.button.change')}
            </ButtonOutlinedWarningM>
            <BackButton />
          </BottomButtonGroupNoWidth>
        </Stack>
      </Form> 
    </ContentContainer>
  )
}
