import { executePost } from '../common/apiCaller';
import { PostFrontErrorLog } from '../dto/frontErrorLogDto';

const apiName = 'public'
const apiPath = '/front-error-log'

/**
 * エラーログをClourWatch上に出力
 */
export const executePostFrontErrorLog = (data: PostFrontErrorLog) => {
  return executePost(apiName, apiPath, data)
}

