import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeDeleteInterview, executeGetSameAddChildInterviews } from '../../dataAccess/webApi/dao/interviewsDao'
import { GetSameAddChildInterviewsDto } from '../../dataAccess/webApi/dto/interviewsDto'
import { translate } from '../../i18n'
import { toApiYmd } from '../../utils/dateUtil'
import { blankToNull } from '../../utils/stringUtil'
import { reservationStatusListUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import {
  clearInterviewReserveDelete,
  selectInterviewReserveDeleteEntry,
  selectInterviewReserveDeleteUpdateDatetime,
} from '../common/store/slices/interviewReserveDelete'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const deleteEntry = useSelector(selectInterviewReserveDeleteEntry)
  const updateDatetime = useSelector(selectInterviewReserveDeleteUpdateDatetime)

  const [deleteResultCode, setDeleteResultCode] = useState<number>()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [sameAddChildInfo, setAddChildInfo] = useState<GetSameAddChildInterviewsDto[]>()

  // 初期処理
  useEffect(() => {
    addOperationLog({
      operationId: OperationId.OP_00000001,
      ...(deleteEntry && {
        accessData: [
          {
            userIdRegFlag: yesNo.yes,
            childId: deleteEntry.childId ?? '',
            usageDate: deleteEntry.interviewDate ? toApiYmd(deleteEntry.interviewDate) : '',
            interviewNo: deleteEntry.interviewNo,
          },
        ],
      }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // キャンセル処理
  const decideFinal = useCallback((sameUpdateFlag: boolean) => {
    if (deleteEntry == null || updateDatetime == null) {
      // 確定押下時には発生しない。型ガードの為の分岐
      return
    }
    addOperationLog({
      operationId: OperationId.OP_00000037,
      accessData: [
        {
          userIdRegFlag: yesNo.yes,
          childId: deleteEntry.childId ?? '',
          usageDate: deleteEntry.interviewDate ? toApiYmd(deleteEntry.interviewDate) : '',
          interviewNo: deleteEntry.interviewNo,
        },
      ],
    })
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          const response = await deleteInterview(
            deleteEntry.interviewNo,
            updateDatetime,
            sameUpdateFlag,
            sameAddChildInfo ?? [],
            deleteEntry.cancelReason
          )
          if (response.resultCode) {
            // キャンセル失敗時
            setDeleteResultCode(response.resultCode)
          } else {
            // キャンセル成功時
            dispatch(notifyMessage(translate('interviewReservationDeleteConfirmation.success.completionMessage')))
            history.push(reservationStatusListUrl.url())
            dispatch(clearInterviewReserveDelete())
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [addOperationLog, deleteEntry, updateDatetime, dispatch, errorHandle, history, sameAddChildInfo])

  // キャンセル前処理
  const decide = useCallback(async () => {
    if (deleteEntry == null) {
      // 確定押下時には発生しない。型ガードの為の分岐
      return
    }

    let decideFinalFlag = false
    await dispatch(
      showLoading({
        process: errorHandle(async () => {
          // 同時更新できるお子様の面談があるか取得
          const getSameAddChildInterviewsRes = await executeGetSameAddChildInterviews(
            deleteEntry.interviewNo,
          )
          if (getSameAddChildInterviewsRes.resultCode) {
            // 失敗時
            setDeleteResultCode(getSameAddChildInterviewsRes.resultCode)
          } else {
            // 成功時
            // お子さま名を設定
            if (getSameAddChildInterviewsRes.result && getSameAddChildInterviewsRes.result.length > 0) {
              setAddChildInfo(getSameAddChildInterviewsRes.result)
              setIsConfirmDialogOpen(true)
            } else {
              // 登録処理を実行する
              decideFinalFlag = true
            }
          }
        }),
        isHiddenMain: false,
      })
    )

    if (decideFinalFlag) {
      // 登録処理
      decideFinal(false)
    }
  }, [addOperationLog, dispatch, errorHandle, decideFinal, history, deleteEntry])

  // 同時キャンセル確認ダイアログ：同時更新しない
  const onConfirmDialogNo = useCallback(async() => {
    // ダイアログを閉じて登録処理を実行
    setIsConfirmDialogOpen(false)
    decideFinal(false)
  },[decideFinal])

  // 同時キャンセル確認ダイアログ：同時更新する
  const onConfirmDialogYes = useCallback(async() => {
    // ダイアログを閉じて登録処理を実行
    setIsConfirmDialogOpen(false)
    decideFinal(true)
  },[decideFinal])

  // 同時キャンセル確認ダイアログ：閉じる
  const onConfirmDialogClose = useCallback(() => {
    //閉じたくないので処理なし
  },[])

  return {
    deleteEntry,
    deleteResultCode,
    decide,
    isConfirmDialogOpen,
    onConfirmDialogClose,
    onConfirmDialogNo,
    onConfirmDialogYes,
    sameAddChildInfo,
  }
}

/**
 * キャンセルAPI呼び出し
 */
const deleteInterview = (
  interviewNo: string,
  updateDatetime: string,
  sameUpdateFlag: boolean,
  sameAddChildInfo: GetSameAddChildInterviewsDto[],
  cancelReason?: string
) => {
  return executeDeleteInterview(
    interviewNo,
    {
      cancelReasonCategory: blankToNull(cancelReason),
      updateDatetime,
      sameAddChildren:
        (!sameUpdateFlag || !sameAddChildInfo.length) ? [] : sameAddChildInfo.map((child) => {
          return (
            {
              interviewsNo: child.interviewNo,
              childId: child.childId,
              interviewUpdateDatetime: child.interviewUpdateDatetime,
            }
          )
        }),
    }
  )
}
