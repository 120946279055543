import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading } from '../../containers/common/store/slices/application'
import { executeGetCouponsIssues } from '../../dataAccess/webApi/dao/couponsDao'
import { executeGetInformations } from '../../dataAccess/webApi/dao/informationsDao'
import { executePostFacilitySearchSettingMsts } from '../../dataAccess/webApi/dao/facilitySearchSettingMstsDao'
// import { checkDto } from 'containers/common/commonFunction'
import { executeGetLinkSettings } from '../../dataAccess/webApi/dao/linkSettingsDao'
// import { LinkMasterData, LinkMasterDto } from 'dataAccess/webApi/dto/linkMasterDto'
import { PostLinkSettingsDto } from '../../dataAccess/webApi/dto/linkSettingsDto'
import { ElapsedMillisecond, fromApiYmd, fromApiYmdHms, toApiYmd } from '../../utils/dateUtil'
import { datePropsToNumber, nullPropsToUndefined } from '../../utils/objectUtil'
import { yesNo } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { selectIsLoggedIn, selectIsVerifiedEmail, selectIsVerifiedTel } from '../common/store/slices/authority'
import { SystemControl, selectSystemControl } from '../common/store/slices/systemControl'
import { setFacilitySearchCondition } from '../common/store/slices/facilitySearch'
import { useHistory } from 'react-router-dom'
import { myFacilityUrl, reservationStatusListUrl, usageHistoryUrl, couponUrl, facilitySearchResultUrl, facilitySearchSettingMasterSelectionUrl, facilitySearchConditionUrl } from '../common/constant/appUrl'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { setProjectMsts } from '../common/store/slices/facilitySearchSetting'
import { executeGetProjects } from '../../dataAccess/webApi/dao/projectsDao'
// import { executeApi } from 'dataAccess/webApi/dao/commonDao'

interface Information {
  informaionNo: string
  publicationStartDatetime: Date
  facilityName: string
  title: string
}
// interface LinkSettings {
//   link_id: number
//   link_name: string
//   link_url: string
// }
export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const sysCtrl = useSelector(selectSystemControl)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const history = useHistory()
  const { addOperationLog } = useOperationLog()
  
  const [informations, setInformations] = useState<Information[]>()
  // const [linkSettingsList, setLinkSettingsList] = useState<LinkSettings[]>()
  const [linkSettingsList, setLinkSettingsList] = useState<PostLinkSettingsDto[]>()
  const [showCouponFunctionFlag, setShowCouponFunctionFlag] = useState<boolean>(false)
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    const initialize = async () => {
      const result = await getInformations(isLoggedIn, isVerifiedEmail, isVerifiedTel)
      setInformations(result)
      if (isLoggedIn && isVerifiedEmail && isVerifiedTel) {
        // クーポン利用可能判定
        const isUsable = await canUseCouponFunction(sysCtrl)
        setShowCouponFunctionFlag(isUsable)
      }
      
      // 各種リンクを取得
      { 
        const linkSettingsList = await getLinkSettings()
        setLinkSettingsList(linkSettingsList)
        // console.log("linkSettingsList", linkSettingsList)
      }

      // 事業マスタを取得
      {
        const projectRes = await getProjectMsts()
        dispatch(setProjectMsts(projectRes))
      }

      
    }
    dispatch(showLoading(errorHandle(initialize)))

    dispatch(
      setFacilitySearchCondition(
        nullPropsToUndefined(
          datePropsToNumber({})
        )
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 施設を探すボタンをクリック
  const handleClickSearchFacility = async () => {
    addOperationLog({ operationId: OperationId.OP_00000168 })

    const facilitySearchSettingMstsRes = await getFacilitySearchSettingMsts()

    const {
      projectCategoryId,
      intermediateDisplayFlag, 
      searchConditionDisplayFlag, 
     } = facilitySearchSettingMstsRes?.[0] || {}
  
    if (intermediateDisplayFlag === '0') {
      if (searchConditionDisplayFlag === '0') {
        history.push(facilitySearchResultUrl.url())

        dispatch(
          setFacilitySearchCondition(
            nullPropsToUndefined(
              datePropsToNumber({})
            )
          )
        )  
      } else {
        history.push({
          pathname: facilitySearchConditionUrl.url(projectCategoryId),
          state: {},
        })  
        }
    } else {
      history.push(facilitySearchSettingMasterSelectionUrl.url())
    }
  }
  
  // My施設へ遷移
  const goMyFacility = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000019 })
    history.push(myFacilityUrl.url())
  }, [addOperationLog, history])

  // 予約状況へ遷移
  const goReservationStatusList = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000020 })
    history.push(reservationStatusListUrl.url())
  }, [addOperationLog, history])

  // 利用履歴へ遷移
  const goUsageHistory = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000021 })
    history.push(usageHistoryUrl.url())
  }, [addOperationLog, history])

  // はじめてのおあずかり券へ遷移
  const goCoupon = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000022 })
    history.push(couponUrl.url())
  }, [addOperationLog, history])

  return {
    informations,
    linkSettingsList,
    showCouponFunctionFlag,
    goMyFacility,
    goReservationStatusList,
    goUsageHistory,
    goCoupon,
    handleClickSearchFacility,
  }
}

const getInformations = async (isLoggedIn: boolean, isVerifiedEmail: boolean, isVerifiedTel: boolean) => {
  const response = await executeGetInformations(isLoggedIn && isVerifiedEmail && isVerifiedTel)
  // HOMEに表示するお知らせは２件まで
  return response.result.slice(0, 2).map((info) => {
    const { publicationStartDatetime, ...other } = info
    return {
      ...other,
      publicationStartDatetime: fromApiYmdHms(publicationStartDatetime),
    }
  })
}
const getCouponsIssue = async () => {
  const response = await executeGetCouponsIssues()
  return response.result.map((coupon) => {
    const { issuedNo, limitUsageMinute, totalBalanceMinute, limitDate, childId, deleteFlag, ...other } = coupon
    return {
      ...other,
      issuedNo: issuedNo,
      limitUsageMinute: limitUsageMinute,
      remainingMinute: totalBalanceMinute,
      limitDate: fromApiYmd(limitDate),
      childId: childId,
      deleteFlag: deleteFlag,
    }
  })
}
/**
 * 各種リンクを一覧取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getLinkSettings = async () => {
  // const response = await executePostLinkSettings()
  const response = await executeGetLinkSettings()
  return response.result.map((entry) => {
    const { ...other } = entry
    return {
      ...other,
    }
  })
}

/**
 * 施設検索制御マスタを一覧取得して返す
 * @return {Array<Object>} - facilitySearchSettingMstsのリスト
 */
export const getFacilitySearchSettingMsts = async () => {
  const response = await executePostFacilitySearchSettingMsts()
  return response.result.map((facilitySearchSettingMst) => {
    const { ...other } = facilitySearchSettingMst
    return {
      ...other,
    }
  })
}

/**
 * 事業マスタマスタを一覧取得して返す
 * @return {Array<Object>} - projectMstsのリスト
 */
export const getProjectMsts = async (targetDate?: Date | ElapsedMillisecond) => {
  const response = await executeGetProjects({ targetDate: toApiYmd(targetDate) })
  return response.result
}

const canUseCouponFunction = async (sysCtrl: SystemControl) => {
  const isUsable = sysCtrl.useCouponFunctionFlag === yesNo.yes
  if (!isUsable) {
    // コントロール設定で利用不可
    return false
  }

  const result = await getCouponsIssue()
  // 利用可能かクーポンが存在すれば利用可
  return result.length >= 1
}
