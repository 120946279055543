import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePutChild } from '../../dataAccess/webApi/dao/childrenDao'
import { translate } from '../../i18n'
import { toApiYmd } from '../../utils/dateUtil'
import { memberUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import {
  clearMemberModify,
  MemberModifyChildEntry,
  selectMemberModifyChildEntry,
  selectMemberModifyUpdateDatetime,
} from '../common/store/slices/memberModify'
import { tabValue } from '../member/memberService'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { yesNo } from '../common/constant/classification'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const modifyEntry = useSelector(selectMemberModifyChildEntry)
  const updateDatetime = useSelector(selectMemberModifyUpdateDatetime)

  const [childrenResultCode, setChildrenResultCode] = useState<number>()

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const decide = useCallback(() => {
    addOperationLog({
      operationId: OperationId.OP_00000037,
      accessData: [{ userIdRegFlag: yesNo.yes, childId: modifyEntry?.childId }],
    })

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          // entry, updateDatetimeは必ず設定されている
          if (modifyEntry && updateDatetime) {
            const resp = await putChild(modifyEntry, updateDatetime)
            if (resp.resultCode) {
              // 失敗時
              setChildrenResultCode(resp.resultCode)
            } else {
              // 成功時
              dispatch(notifyMessage(translate('memberModifyConfirmationChild.success.completionMessage')))
              history.push(memberUrl.url(), { tabValue: tabValue.child, childId: modifyEntry.childId })
              dispatch(clearMemberModify())
            }
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [addOperationLog])

  return {
    child: modifyEntry && toChild(modifyEntry),
    childrenResultCode,
    decide,
  }
}

const toChild = (entry: MemberModifyChildEntry) => {
  const { name, kana, birthday, brothersSistersFlag, multipleBirthsFlag, gender, bloodType } = entry
  return {
    name,
    kana,
    birthday,
    brothersSistersFlag,
    multipleBirthsFlag,
    gender,
    bloodType,
    //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
    //maternalHandbookNo,
  }
}

const putChild = (entry: MemberModifyChildEntry, updateDatetime: string) => {
  return executePutChild(entry.childId, {
    name: entry.name,
    kana: entry.kana,
    birthday: toApiYmd(entry.birthday),
    brothersSistersFlag: entry.brothersSistersFlag,
    multipleBirthsFlag: entry.multipleBirthsFlag,
    gender: entry.gender,
    bloodType: entry.bloodType,
    //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
    //maternalHandbookNo: entry.maternalHandbookNo,

    updateDatetime,
  })
}
