import { Control, useController } from 'react-hook-form';
import { getValueByNameStr } from '../../../../utils/objectUtil';
import { TextBoxBaseStyled } from './textBoxBaseStyled';
import { translate } from '../../../../i18n';

interface NumberBoxProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  max?: number;
  min?: number;
  placeholder?: string;
  control?: Control<any, any>;
  showLabel?: boolean;
  showError?: boolean;
}

export const NumberBox = (props: NumberBoxProps) => {
  const { name, label, required, disabled, max, min, placeholder, control, showLabel, showError } = props;

  const {
    field: { ref, ...ctrlProps },
    formState: { errors },
  } = useController({
    name,
    defaultValue: undefined,
    control,
    rules: {
      required: {
        value: !!required,
        message: translate('system.error.requiredInput', label),
      },
      ...(max !== undefined && {
        max: {
          value: max,
          message: translate('system.error.numberOver', max.toString()),
        },
      }),
      ...(min !== undefined && {
        min: {
          value: min,
          message: translate('system.error.numberBelow', min.toString()),
        },
      }),
      // カスタムバリデーション: 半角数字のみ許可
      pattern: {
        value: /^[0-9]*$/,
        message: translate('system.error.onlyHalfWidthNumbers'),
      },
    },
  });

  const error = getValueByNameStr(errors, name);

  return (
    <TextBoxBaseStyled
      {...ctrlProps}
      name={name}
      label={showLabel ? label : ''}
      InputLabelProps={{ required }}
      inputProps={{ min: min, max: max, type: 'number' }} // 半角数字のみを許容
      inputRef={ref}
      error={!!error}
      helperText={showError ? error?.message : ''}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
