type Key = string | number | symbol

/**
 * 指定のキーを元にグループ化
 *
 * @param array
 * @param key
 * @returns
 */
export const groupBy = <T extends Record<Key, any>>(array: T[], key: keyof T): Record<string, T[]> => {
  return array.reduce((result: Record<string, T[]>, currentValue: T) => {
    const groupKey = currentValue[key] as unknown as string

    if (!result[groupKey]) {
      result[groupKey] = []
    }

    result[groupKey].push(currentValue)

    return result
  }, {})
}
