// 面談予約情報に関する共通処理を定義

import { executeGetInterview } from '../../dataAccess/webApi/dao/interviewsDao'
import { fromApiYmd, fromApiYmdHms, endOfDay, getNow } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { yesNo } from './constant/classification'

/**
 * 指定した受付Noの面談予約情報を返す。
 *
 * @param interviewNo 受付No
 * @returns 面談予約情報
 */
export const getInterview = async (interviewNo: string) => {
  const response = await executeGetInterview(interviewNo)
  const {
    interviewDate,
    interviewStartDatetime,
    interviewEndDatetime,
    childBirthday,
    canUpdate,
    canCancel,
    reservationDatetime,
    reservationChangeDatetime,
    reservationCancelDatetime,
    ...other
  } = response.result
  
  return nullPropsToUndefined({
    ...other,
    interviewNo,
    interviewDate: fromApiYmd(interviewDate),
    interviewStartDatetime: fromApiYmdHms(interviewStartDatetime),
    interviewEndDatetime: fromApiYmdHms(interviewEndDatetime),
    childBirthday: fromApiYmd(childBirthday),
    canUpdate: canUpdate === yesNo.yes,
    canCancel: canCancel === yesNo.yes,
    canCancelWait: interviewDate && getNow().getTime() <= endOfDay(interviewDate).getTime(),
    reservationDatetime: fromApiYmdHms(reservationDatetime),
    reservationChangeDatetime: fromApiYmdHms(reservationChangeDatetime),
    reservationCancelDatetime: fromApiYmdHms(reservationCancelDatetime),
  })
}

/** 面談予約情報の型 */
export type Interview = ReturnType<typeof getInterview> extends Promise<infer T> ? T : never
