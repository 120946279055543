import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'
import { GetUseReasonDto } from '../../../../dataAccess/webApi/dto/useReasonDto'
import { ParentInfoType, ChildReservationInfoType, GoingTimeType, ShowMessage } from '../../reservation'

export interface IsCertificateUseReasonViewFlags {
  irregularFlag: boolean
  emgFlag: boolean
  refreshFlag: boolean
}

export interface FacilityReserveEntry {
  reservationNo?: string
  facilityId: string
  showMessages: ShowMessage[]
  useReasonCategory?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string

  /** 利用日時 */
  usageDatetimes: {
    usageDate: ElapsedMillisecond
    useFromDatetime: ElapsedMillisecond
    useToDatetime: ElapsedMillisecond
    usageBaseDate: string
    status: string
  }[]

  goingTimes: GoingTimeType[]

  /** 必要事項入力 */
  input?: {
    lunchFlag: string
    snackFlag: string
    postponeCancelWaitFlag: string
    citizenNote?: string
  }

  /** 施設情報 */
  facility?: {
    facilityName: string
    /** 利用予約即時予約フラグ 0:不可、1:可 */
    immediatelyReservationFlag: string
    /** 昼食受付可否フラグ 0:受け付けない、1:受け付ける */
    lunchAcceptFlag: string
    /** おやつ受付可否フラグ 0:受け付けない、1:受け付ける */
    snackAcceptFlag: string
    /** 見送り時のキャンセル待ち受付可否フラグ 0:受け付けない、1:受け付ける */
    postponeCancelWaitAcceptFlag: string
    /** 利用目的：「非定型」利用フラグ 0:利用しない、1:利用する */
    useReasonUsageFlagIrregular: string
    /** 「非定型」の詳細事由利用フラグ 0:利用しない、1:利用する */
    useReasonDetailUsageFlagIrregular: string
    /** 利用目的：「緊急」利用フラグ 0:利用しない、1:利用する */
    useReasonUsageFlagEmg: string
    /** 「緊急」の利用詳細目的利用フラグ 0:利用しない、1:利用する */
    useReasonDetailUsageFlagEmg: string
    /** 利用目的：「リフレッシュ」利用フラグ 0:利用しない、1:利用する */
    useReasonUsageFlagRefresh: string
    /** 「リフレッシュ」の利用詳細目的利用フラグ 0:利用しない、1:利用する */
    useReasonDetailUsageFlagRefresh: string
  }

  /** 保護者情報 */
  parentInfo?: ParentInfoType
  
  /** お子様さま情報 */
  childInfoList: ChildReservationInfoType[]
  
  /** 利用目的マスタ情報 */
  useReason?: GetUseReasonDto
  isCertificateUseReasonViewFlags: IsCertificateUseReasonViewFlags

  /** 利用同意事項同意 */
  consentCheck?: string
}

interface FacilityReserveState {
  entry?: FacilityReserveEntry
  updateDatetime?: string
}

const initialState: FacilityReserveState = {}

export const facilityReserveSlice = createSlice({
  name: 'facilityReserve',
  initialState,
  reducers: {
    setFacilityReserve: (state, action: PayloadAction<FacilityReserveEntry>) => {
      state.entry = action.payload
    },
    setReserveModifyUpdateDatetime: (state, action: PayloadAction<string>) => {
      state.updateDatetime = action.payload
    },
    clearFacilityReserve: (state) => {
      delete state.entry
      delete state.updateDatetime
    },
  },
})

export const { setFacilityReserve, setReserveModifyUpdateDatetime, clearFacilityReserve } = facilityReserveSlice.actions
export const selectFacilityReserveEntry = (state: RootState) => state.facilityReserve.entry
export const selectReserveModifyUpdateDatetime = (state: RootState) => state.facilityReserve.updateDatetime
export default facilityReserveSlice.reducer
