import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { ApiError, NoResultError } from '../../../dataAccess/webApi/common/apiCaller'
import { isKeyOf } from '../../../utils/typeUtil'
import { getSizeOfObject } from '../../../utils/objectUtil'
import { errorUrls } from '../constant/appUrl'
import { httpStatusCode } from '../constant/classification'
import { PostFrontErrorLog } from '../../../dataAccess/webApi/dto/frontErrorLogDto'
import { executePostFrontErrorLog } from '../../../dataAccess/webApi/dao/frontErrorLogDao'

const MAX_BODY_SIZE = 7900

/**
 * useEffect、onClickなどのイベントハンドラー内の処理で
 * 例外ハンドリングを行う関数を生成するフック関数。
 * この関数でラップしなければ、処理内で例外が発生してもエラー画面へ遷移させる処理が行われず
 * 例外は握りつぶされることになる。
 *
 * @returns 例外ハンドリング関数
 */
export const useErrorHandle = () => {
  const history = useHistory()

  const errorHandle = useCallback((e: unknown, data?: Object) => {
    if (e instanceof NoResultError) {
      // APIレスポンスのresult=nullなどで発生した例外の場合
      history.replace(errorUrls[httpStatusCode.notFound].url())
    } else if (e instanceof ApiError) {
      // その他のAPI呼び出しで発生した例外(httpstatus!=200)
      if (e.statusCode == null) {
        // statusCodeが無い場合、メンテナンス中用に設定した
        // WAFのレスポンスである可能性がある為、一度503ページへ遷移
        history.replace(errorUrls[503].url())
      } else if (isKeyOf(e.statusCode, errorUrls)) {
        history.replace(errorUrls[e.statusCode].url())
      } else {
        history.replace(errorUrls.unknown.url())
      }
    } else {
      // 想定されないエラー。開発中のバグなど
      console.error('想定されないエラー', e);
      const  postFrontErrorLog: PostFrontErrorLog = {
        url: window.location.pathname,
        userAgent: navigator.userAgent,
        data: data        
      }
      if (e instanceof Error) {
        postFrontErrorLog.errorName = e.name
        postFrontErrorLog.errorMessage = e.message
        postFrontErrorLog.stack = e.stack
      }
      
      const bodySize = getSizeOfObject(postFrontErrorLog)
      if ( bodySize > MAX_BODY_SIZE ) {
        postFrontErrorLog.data = {
          error: 'ボディサイズが8kbを超えています'
        }
      }
      
      executePostFrontErrorLog(postFrontErrorLog);
      
      history.replace(errorUrls.unknown.url())
    }
  }, [])

  const errorHandler = useCallback(<R, T extends (...args: never[]) => R>(process: T, data?: Object) => {
    const wrapedProcess = (...args: never[]) => {
      try {
        const returnValue = process(...args)
        if (returnValue instanceof Promise) {
          return returnValue.catch((error) => errorHandle(error, data));
        } else {
          return returnValue
        }
      } catch (e) {
        errorHandle(e, data)
      }
    }
    return wrapedProcess as T
  }, [])

  return errorHandler
}
