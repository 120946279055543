import { FC } from 'react'
import { Stack, styled } from '@mui/material'
import { GItem } from '../components/common/grids'
import { DefaultReceipt } from '../components/receipt/default/defaultReceipt'
import { useAction } from '../../containers/receiptView/receiptViewService'

const ReceiptItem = styled(GItem)({
  width: '210mm',
  marginTop: '-55px',
})

export const ReceiptView: FC = () => {
  const { printData, receiptData, isDefaultLayout } = useAction()
  
  return (
    <Stack>
      <ReceiptItem>
        {
          printData && isDefaultLayout 
            ? <DefaultReceipt receiptData={receiptData} />
            : <></>
        }
      </ReceiptItem>
    </Stack>
  )
}