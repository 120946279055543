import { styled } from '@mui/material'
import React from 'react'

export const Flexbox = styled('div')({
  display: 'flex',
})

export const FlexboxRow = styled(Flexbox)({
  flexDirection: 'row',
})

export const FlexboxCenter = styled(Flexbox)({
  justifyContent: 'center',
})

export const FlexboxMiddle = styled(Flexbox)({
  alignItems: 'center',
})

export const FlexboxRowTop = styled(FlexboxRow)({
  alignItems: 'start',
})

export const FlexboxRowMiddle = styled(FlexboxRow)({
  alignItems: 'center',
})

export const InlineFlexbox = styled('div')({
  display: 'inline-flex',
})

export const InlineFlexboxMiddle = styled(InlineFlexbox)({
  alignItems: 'center',
})

export const Separator = styled('div')(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'center',
  minWidth: theme.typography.body1.fontSize,
}))

export const ColonSeparator = () => {
  return <Separator>:</Separator>
}

export const HyphenSeparator = () => {
  return <Separator>-</Separator>
}

export const SpaceSeparator = () => {
  return <Separator>&nbsp;</Separator>
}
