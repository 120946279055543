import { Stack, styled, FormHelperText } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ChildrenChoice } from '../../../containers/common/child'
import { translate } from '../../../i18n'
import { ButtonOutlinedS } from '../common/buttons/buttonOutlinedS'
import { ButtonS } from '../common/buttons/buttonS'
import { Dialog } from '../common/dialog'
import { GContainer, GItem } from '../common/grids'
import { RadioButtonGroup } from '../common/inputs/radioButtonGroup'
import { Link } from '../common/link'
import { useHistory } from 'react-router'
import { memberAddChildUrl } from '../../../containers/common/constant/appUrl'
import { CheckBox } from '../common/inputs/checkBox'
import { yesNo } from '../../../containers/common/constant/classification'

interface ChildSelectDialogProps {
  isOpen: boolean
  onClose: (isCancel: boolean, childId?: string[]) => void
  childs: ChildrenChoice[]
  interviewedChildsAndStatus: ChildrenChoice[]
  isShowToNextButton?: boolean
  isInterviewBulkReception?: string
}

interface Inputs {
  activeChildIds: string[]
}

const MessageBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

const InterviewedChildsAndStatusItem = styled(GItem)({
  marginBottom: '5px',
  marginLeft: '3.1rem',
})

const ErrorMessageItem = styled(FormHelperText)({
  color: '#c53030',
})

export const ChildSelectDialog = (props: ChildSelectDialogProps) => {
  const history = useHistory()
  const formMethods = useForm<Inputs>()
  const [errMsg, setErrMsg] = useState<string>('')

  useEffect(() => {
    //formMethods.reset({ activeChildIds: props.childs[0]?.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen])

  const close = useCallback(
    (isCancel: boolean, childIds?: string[]) => {
      props.onClose(isCancel, childIds)
    },
    [props]
  )
  const cancel = useCallback(() => {
    close(true)
  }, [close])

  const onSubmit = useCallback(
    (data: Inputs) => {
      if (props.isInterviewBulkReception === yesNo.yes) {
        const selectChildIds = data.activeChildIds.filter((v: string) => { return v.length })
        if (!selectChildIds.length) {
          setErrMsg(translate('system.error.requiredSelection', 'お子さま'))
        } else if (selectChildIds.length > 5) {
          setErrMsg(translate('system.error.limitOverInterviews'))
        } else {
          close(false, data.activeChildIds)
        }
      } else {
        close(false, data.activeChildIds)
      }
    },
    [close]
  )
  const goAddChild = useCallback(() => {
    history.push(memberAddChildUrl.url(), { completionTo: history.location })
  }, [history])

  const interviewedChildsAndStatusConstents = props.interviewedChildsAndStatus.map((v) => (
    <InterviewedChildsAndStatusItem key={v.value} xs={12}>
      {v.label}
    </InterviewedChildsAndStatusItem>
  ))

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={cancel}
      formMethods={formMethods}
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonOutlinedS onClick={cancel}>{translate('system.button.back')}</ButtonOutlinedS>
          {props.isShowToNextButton && (
            <ButtonS type="submit">{translate('accountConfirmation.button.toNext')}</ButtonS>
          )}
        </>
      }
    >
      <Stack spacing={2}>
        <MessageBox>{translate('accountConfirmation.text.childSelectDialogMessage')}</MessageBox>
        <GContainer>
          {interviewedChildsAndStatusConstents}
          
            {props.isInterviewBulkReception === yesNo.yes? 
              (
                <>
                  {
                    props.childs.map((child:{ label: string, value: string }, index:number) => {
                      return (
                      　<GItem xs={12} key ={index}>
                        <CheckBox
                          key ={index}
                          name={`activeChildIds.${index}`}
                          label="お子さま"
                          valueAtCheck={child.value}
                        >
                          {child.label}
                        </CheckBox>
                        </GItem>
                      )
                    })
                  }
                  {
                    errMsg && (<ErrorMessageItem>{errMsg}</ErrorMessageItem>)
                  }
                </>
              ):(
              <GItem>
                <RadioButtonGroup name="activeChildIds.0" label="お子さま" required buttonValueLabels={props.childs} />
              </GItem>
              )
            }
          
        </GContainer>
        <GContainer>
          <GItem>
            <Link onClick={goAddChild} underline="none">
              ＋ {translate('accountConfirmation.text.addChild')}
            </Link>
          </GItem>
        </GContainer>
      </Stack>
    </Dialog>
  )
}
