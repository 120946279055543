import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface MemberModifyUserEntry {
  name: string
  kana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  residenceCategoryName: string
  relationship: string

  emergencyContactName1: string
  emergencyContactKana1: string
  emergencyContactRelationship1: string
  emergencyContactCompany1: string
  emergencyContactTel1: string
  emergencyContactEmail1: string

  emergencyContactName2: string
  emergencyContactKana2: string
  emergencyContactRelationship2: string
  emergencyContactCompany2: string
  emergencyContactTel2: string
  emergencyContactEmail2: string

  emergencyContactName3: string
  emergencyContactKana3: string
  emergencyContactRelationship3: string
  emergencyContactCompany3: string
  emergencyContactTel3: string
  emergencyContactEmail3: string
  
  isDisabledResidenceCategory: boolean
}

export interface MemberModifyChildEntry {
  childId: string
  name: string
  kana: string
  birthday: ElapsedMillisecond
  brothersSistersFlag: string
  multipleBirthsFlag: string
  gender: string
  bloodType: string
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
}

interface MemberModifyState {
  userEntry?: MemberModifyUserEntry
  childEntry?: MemberModifyChildEntry
  /** 排他制御用更新日時 */
  updateDatetime?: string
}

const initialState: MemberModifyState = {}

export const memberModifySlice = createSlice({
  name: 'memberModify',
  initialState,
  reducers: {
    setMemberModifyUser: (state, action: PayloadAction<MemberModifyUserEntry>) => {
      state.userEntry = action.payload
    },
    setMemberModifyChild: (state, action: PayloadAction<MemberModifyChildEntry>) => {
      state.childEntry = action.payload
    },
    setMemberModifyUpdateDatetime: (state, action: PayloadAction<string>) => {
      state.updateDatetime = action.payload
    },
    clearMemberModify: (state) => {
      delete state.userEntry
      delete state.childEntry
      delete state.updateDatetime
    },
  },
})
export const { setMemberModifyUser, setMemberModifyChild, setMemberModifyUpdateDatetime, clearMemberModify } =
  memberModifySlice.actions
export const selectMemberModifyUserEntry = (state: RootState) => state.memberModify.userEntry
export const selectMemberModifyChildEntry = (state: RootState) => state.memberModify.childEntry
export const selectMemberModifyUpdateDatetime = (state: RootState) => state.memberModify.updateDatetime
export default memberModifySlice.reducer
