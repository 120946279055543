import React from 'react'
import { useController } from 'react-hook-form'
import { FormControl, RadioGroup, FormControlLabel, FormHelperText, Radio, styled } from '@mui/material';
import { purposeOfUse as purposeOfUseValue, yesNo } from '../../../containers/common/constant/classification'
import { UseReasonDetailRadioGroup } from './useReasonDetailRadioGroup'
import { translate } from '../../../i18n'
import { getValueByNameStr } from '../../../utils/objectUtil'
import { GetUseReasonDto } from '../../../dataAccess/webApi/dto/useReasonDto'
import { ReservationDetailSettingDto } from '../../../dataAccess/webApi/dto/reservationsDto'
import { IsCertificateUseReasonViewFlags } from '../../../containers/common/store/slices/facilityReserve'


interface UseReasonRadioGroupProps {
  useReason?: GetUseReasonDto
  facility?: {
    irregularFlag: string
    emergencyFlag: string
    refreshFlag: string
    
    useReasonUsageFlagIrregular: string
    useReasonDetailUsageFlagIrregular: string
    detailsIrregular: {
      useReasonDetailCategory: string
      detailItemFlag: string
    }[]
    useReasonDetailUsageFlagEmg: string
    useReasonUsageFlagEmg: string
    detailsEmg: {
      useReasonDetailCategory: string
      detailItemFlag: string
    }[]
    useReasonDetailUsageFlagRefresh: string
    useReasonUsageFlagRefresh: string
    detailsRefresh: {
      useReasonDetailCategory: string
      detailItemFlag: string
    }[]
    
    detailSetting: ReservationDetailSettingDto[]
  }
  purposeOfUse: string
  useReasonDetailIrregular: string
  useReasonDetailEmg: string
  useReasonDetailRefresh: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isHidden?: boolean
  isCertificateUseReasonViewFlags?: IsCertificateUseReasonViewFlags
}

const Root = styled(RadioGroup)(() => ({
  '& .MuiRadio-root': {
    padding: '0 9px',
  },
}))

export const UseReasonRadioGroup = (props: UseReasonRadioGroupProps) => {
  const {
    useReason,
    facility,
    purposeOfUse,
    useReasonDetailIrregular,
    useReasonDetailEmg,
    useReasonDetailRefresh,
    onChange,
    isHidden,
    isCertificateUseReasonViewFlags,
  } = props
  
  const {
    field: { ref, ...ctrlProps },
    formState: { errors },
  } = useController({
    name: 'purposeOfUse',
    rules: {
      required: {
        value: true,
        message: translate('system.error.requiredSelection', translate('facilityReservationForm.useReason.purpose')),
      },
    },
    defaultValue: ''
  })
  
  const error = getValueByNameStr(errors, 'purposeOfUse')
    
  if (!useReason) {
    return null
  }

  return (
    <FormControl error={!!error} required>
      <Root
        {...ctrlProps}
      >
        
        {facility?.irregularFlag === yesNo.yes  // 施設情報設定＞基本情報＞利用目的で設定されるフラグ
          && facility?.useReasonUsageFlagIrregular === yesNo.yes  // 事業マスタで設定される非定型を利用するか否かを指定するフラグ
          && (!isCertificateUseReasonViewFlags || isCertificateUseReasonViewFlags?.irregularFlag)
          && (
            <FormControlLabel
              value={purposeOfUseValue.irregular}
              control={<Radio onChange={onChange}/>}
              label={useReason.displayNameForCitizenIrregular}
              inputRef={ref}
            />
          )
        }
        
        {facility?.irregularFlag === yesNo.yes  // 施設情報設定＞基本情報＞利用目的で設定するフラグ
          && facility?.useReasonUsageFlagIrregular === yesNo.yes  // 事業マスタで設定される非定型を利用するか否かを指定するフラグ
          && facility?.useReasonDetailUsageFlagIrregular === yesNo.yes  // 事業マスタで設定される非定型の詳細事由を利用するか否かを指定するフラグ
          && useReason.detailUsageFlagIrregular === yesNo.yes  // 利用目的マスタで設定される非定型の詳細事由を利用するか否かを指定するフラグ
          && purposeOfUse === purposeOfUseValue.irregular
          && !isHidden
          && (!isCertificateUseReasonViewFlags || isCertificateUseReasonViewFlags?.irregularFlag)
          && (
            <UseReasonDetailRadioGroup
              useReasonName='Irregular'
              label={translate('facilityReservationForm.useReason.details')}
              buttonValueLabels={useReason.detailsIrregular.map((detail, index) => ({ value: (index + 1).toString(), label: detail.name, textInputType: detail.textInputType }))}
              required
              useReasonDetail={useReasonDetailIrregular}
              useReasonDetailFlags={facility?.detailsIrregular ?? []}
            />
          )
        }
        
        {facility?.emergencyFlag === yesNo.yes  // 施設情報設定＞基本情報＞利用目的で設定するフラグ
          && facility?.useReasonUsageFlagEmg === yesNo.yes  // 事業マスタで設定される緊急を利用するか否かを指定するフラグ
          && (!isCertificateUseReasonViewFlags || isCertificateUseReasonViewFlags?.emgFlag)
          && (
            <FormControlLabel
              value={purposeOfUseValue.emergency}
              control={<Radio onChange={onChange}/>}
              label={useReason.displayNameForCitizenEmg}
            />
          )
        }
  
        {facility?.emergencyFlag === yesNo.yes  // 施設情報設定＞基本情報＞利用目的で設定するフラグ
          && facility?.useReasonUsageFlagEmg === yesNo.yes  // 事業マスタで設定される緊急を利用するか否かを指定するフラグ
          && facility?.useReasonDetailUsageFlagEmg === yesNo.yes  // 事業マスタで設定される緊急の詳細事由を利用するか否かを指定するフラグ
          && useReason.detailUsageFlagEmg === yesNo.yes  // 利用目的マスタで設定される緊急の詳細事由を利用するか否かを指定するフラグ
          && purposeOfUse === purposeOfUseValue.emergency
          && !isHidden
          && (!isCertificateUseReasonViewFlags || isCertificateUseReasonViewFlags?.emgFlag)
          && (
            <UseReasonDetailRadioGroup
              useReasonName='Emg'
              label={translate('facilityReservationForm.useReason.details')}
              buttonValueLabels={useReason.detailsEmg.map((detail, index) => ({ value: (index + 1).toString(), label: detail.name, textInputType: detail.textInputType }))}
              required
              useReasonDetail={useReasonDetailEmg}
              useReasonDetailFlags={facility?.detailsEmg ?? []}
            />
          )
        }
        
        {facility?.refreshFlag === yesNo.yes  // 施設情報設定＞基本情報＞利用目的で設定するフラグ
          && facility?.useReasonUsageFlagRefresh === yesNo.yes  // 事業マスタで設定されるリフレッシュを利用するか否かを指定するフラグ
          && (!isCertificateUseReasonViewFlags || isCertificateUseReasonViewFlags?.refreshFlag)
          && (
            <FormControlLabel
              value={purposeOfUseValue.refresh}
              control={<Radio onChange={onChange}/>}
              label={useReason.displayNameForCitizenRefresh}
            />
          )
        }

        {facility?.refreshFlag === yesNo.yes  // 施設情報設定＞基本情報＞利用目的で設定するフラグ
          && facility?.useReasonUsageFlagRefresh === yesNo.yes  // 事業マスタで設定されるリフレッシュを利用するか否かを指定するフラグ
          && facility?.useReasonDetailUsageFlagRefresh === yesNo.yes  // 事業マスタで設定されるリフレッシュの詳細事由を利用するか否かを指定するフラグ
          && useReason.detailUsageFlagRefresh === yesNo.yes  // 利用目的マスタで設定されるリフレッシュの詳細事由を利用するか否かを指定するフラグ
          && purposeOfUse === purposeOfUseValue.refresh
          && !isHidden
          && (!isCertificateUseReasonViewFlags || isCertificateUseReasonViewFlags?.refreshFlag)
          && (
            <UseReasonDetailRadioGroup
              useReasonName='Refresh'
              label={translate('facilityReservationForm.useReason.details')}
              buttonValueLabels={useReason.detailsRefresh.map((detail, index) => ({ value: (index + 1).toString(), label: detail.name, textInputType: detail.textInputType }))}
              required
              useReasonDetail={useReasonDetailRefresh}
              useReasonDetailFlags={facility?.detailsRefresh ?? []}
            />
          )
        }
      </Root>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
