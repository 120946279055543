import { Grid, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { getGenderLabel, getYesNoLabel } from '../../containers/common/codeMaster'
import { interviewReservationStep, interviewReservationStep2, yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/interviewReservationConfirmation/interviewReservationConfirmationService'
import { translate } from '../../i18n'
import { formatYmd, formatHmtoHmOver, formatYmdWeek } from '../../utils/dateUtil'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { Card } from '../components/common/card'
import { ElevatedCard } from '../components/common/elevatedCard'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem, LabelGItemBase, RowSpacerGItem, ValueGItemBase } from '../components/common/grids'
import { StepBar } from '../components/common/stepBar'
import { TransitionCheck } from '../components/common/transitionCheck'
import { TitleLabel } from '../components/common/titleLabel'
import { Dialog } from '../components/common/dialog'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { ButtonS } from '../components/common/buttons/buttonS'

const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={7}>{props.children}</ValueGItemBase>

export const InterviewReservationConfirmation = () => {
  const {
    reserveEntry,
    facilityName,
    steps,
    interviewResultCode,
    decide,
    interviewDailyAcceptFlag,
    applicationChildren,
    isConfirmDialogOpen,
    onConfirmDialogClose,
    onConfirmDialogNo,
    onConfirmDialogYes,
    sameAddChildInfo,
  } = useAction()

  if (reserveEntry == null) {
    return <TransitionCheck check={false} />
  }
  if (facilityName == null) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Dialog
        isOpen={isConfirmDialogOpen}
        onClose={onConfirmDialogClose}
        isShownCloseButton={false}
        buttons={
          <>
            <ButtonOutlinedS onClick={onConfirmDialogNo}>{translate('interviewReservationConfirmation.button.no')}</ButtonOutlinedS>
            <ButtonS onClick={onConfirmDialogYes}>{translate('interviewReservationConfirmation.button.yes')}</ButtonS>
          </>
        }
        maxWidth={'xs'}
        fullWidth
      >
        <>
          {translate('interviewReservationConfirmation.text.sameAddChildDialog')}
          <ul>
            {sameAddChildInfo && sameAddChildInfo.map((child, idx) => {
              return (
                <li key={idx}>{child.childName}</li>
              )
            })}
          </ul>
        </>
      </Dialog>

      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <div>
              <StepBar
                activeStepValue={
                  interviewDailyAcceptFlag === yesNo.yes
                  ? interviewReservationStep2.step3
                  : interviewReservationStep.step2
                }
                stepValueLabels={steps}
              />
            </div>
            <Stack spacing={2}>
                {interviewDailyAcceptFlag === yesNo.yes && reserveEntry.interviewDatetimes && reserveEntry.interviewDatetimes.length > 0 && 
                  <>
                    <GContainer spacing={1}>
                      <GItem xs={12}>
                        <TitleLabel>{translate('interviewReservationConfirmation.label.interviewDatetime')}</TitleLabel>
                      </GItem>
                      <GItem xs={6}>
                        <Card>
                          <GContainer columnSpacing={1}>
                            <GItem>
                              <TitleLabel>
                                <AttentionLabel>{formatYmdWeek(reserveEntry.interviewDatetimes[0].interviewDate)}</AttentionLabel>
                              </TitleLabel>
                            </GItem>
                            <GItem>
                              <TitleLabel>
                                <AttentionLabel>
                                  {formatHmtoHmOver(
                                    {
                                      from: reserveEntry.interviewDatetimes[0].interviewFromDatetime,
                                      to: reserveEntry.interviewDatetimes[0].interviewToDatetime,
                                    },
                                    reserveEntry.interviewDatetimes[0].interviewDate
                                  )}
                                </AttentionLabel>
                              </TitleLabel>
                            </GItem>
                          </GContainer>
                        </Card>
                      </GItem>
                    </GContainer>
                    <RowSpacerGItem/>
                  </>
                }
                <GContainer>
                <LabelGItem>{translate('interviewReservationConfirmation.label.facilityName')}</LabelGItem>
                <ValueGItem>{facilityName}</ValueGItem>
                <RowSpacerGItem />

                <LabelGItem>{translate('interviewReservationConfirmation.label.parentName')}</LabelGItem>
                <ValueGItem>{reserveEntry.parentName}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.parentKana')}</LabelGItem>
                <ValueGItem>{reserveEntry.parentKana}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.postalCode')}</LabelGItem>
                <ValueGItem>{reserveEntry.postalCode}</ValueGItem>
                <LabelGItem>
                  {translate('interviewReservationConfirmation.label.prefecturesAndMunicipalities')}
                </LabelGItem>
                <ValueGItem>{reserveEntry.address1}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.address')}</LabelGItem>
                <ValueGItem>{reserveEntry.address2}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.buildingNameRoomNumber')}</LabelGItem>
                <ValueGItem>{reserveEntry.buildingNameRoomNumber}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.residenceCategory')}</LabelGItem>
                <ValueGItem>{reserveEntry.residenceCategoryName}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.relationship')}</LabelGItem>
                <ValueGItem>{reserveEntry.relationship}</ValueGItem>
              </GContainer>

              {applicationChildren && applicationChildren.map((child, index) => {
                return (
                  <GContainer rowSpacing={1} key={index}>
                    <GItem xs={12} >
                      <ElevatedCard title={translate('interviewReservationConfirmation.title.child')}>
                        <GContainer>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.childName')}</LabelGItem>
                          <ValueGItem>{child.childName}</ValueGItem>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.childKana')}</LabelGItem>
                          <ValueGItem>{child.childKana}</ValueGItem>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.childBirthday')}</LabelGItem>
                          <ValueGItem>{formatYmd(child.childBirthday)}</ValueGItem>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.childGender')}</LabelGItem>
                          <ValueGItem>{getGenderLabel(child.childGender)}</ValueGItem>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.childAllergyFlag')}</LabelGItem>
                          <ValueGItem>{getYesNoLabel(child.childAllergyFlag)}</ValueGItem>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.childAllergyContent')}</LabelGItem>
                          <ValueGItem>{child.childAllergy}</ValueGItem>
                          <LabelGItem>
                            {translate('interviewReservationConfirmation.label.childMedicalHistoryFlag')}
                          </LabelGItem>
                          <ValueGItem>{getYesNoLabel(child.childMedicalHistoryFlag)}</ValueGItem>
                          <LabelGItem>
                            {translate('interviewReservationConfirmation.label.childMedicalHistoryContent')}
                          </LabelGItem>
                          <ValueGItem>{child.childMedicalHistory}</ValueGItem>
                          {/*TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする*/}
                          {/*<LabelGItem>{translate('interviewReservationConfirmation.label.maternalHandbookNo')}</LabelGItem>
                          <ValueGItem>{child.maternalHandbookNo}</ValueGItem>*/}
                          <LabelGItem>{translate('interviewReservationConfirmation.label.note')}</LabelGItem>
                          <ValueGItem>{child.note}</ValueGItem>
                          <LabelGItem>{translate('interviewReservationConfirmation.label.facilityNumber')}</LabelGItem>
                          <ValueGItem>{child.facilityNumber}</ValueGItem>
                        </GContainer>
                      </ElevatedCard>
                    </GItem>
                  </GContainer>
                )
              })}

              {!!interviewResultCode && (
                <ErrorMessageCard
                  messages={[
                    translate(`interviewReservationConfirmation.error.interviewResultCode${interviewResultCode}`),
                  ]}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          {translate('interviewReservationConfirmation.button.decide')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
