import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface InterviewReserveEntry {
  facilityId: string
  childId: string
  interviewNo?: string
  childIds?: string[]

  parentName?: string
  parentKana?: string
  postalCode?: string
  address1?: string
  address2?: string
  buildingNameRoomNumber?: string
  residenceCategory?: string
  residenceCategoryName?: string
  relationship?: string

  isDisabledResidenceCategory?: boolean

  children?: {
    childId: string
    childName: string
    childKana: string
    childGender: string
    childBirthday: ElapsedMillisecond
    childMedicalHistoryFlag: string
    childMedicalHistory: string
    childAllergyFlag: string
    childAllergy: string
    //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
    //maternalHandbookNo: string
    note: string
    facilityNumber: string
    childUpdateDatetime: string
    childInterviewPermitCount: number
    availabilityOfApplication: string
  }[]

  /** 面談日時 */
  interviewDatetimes?: {
    interviewDate: ElapsedMillisecond
    interviewFromDatetime: ElapsedMillisecond
    interviewToDatetime: ElapsedMillisecond
    status: string
  }[]
}

interface InterviewReserveState {
  entry?: InterviewReserveEntry
  /** 排他制御用更新日時 */
  updateDatetimes?: {
    userUpdateDatetime: string
    interviewUpdateDatetime?: string
  }
}

const initialState: InterviewReserveState = {}

export const interviewReserveSlice = createSlice({
  name: 'interviewReserve',
  initialState,
  reducers: {
    setInterviewReserve: (state, action: PayloadAction<InterviewReserveEntry>) => {
      state.entry = action.payload
    },
    setInterviewReserveUpdateDatetime: (
      state,
      action: PayloadAction<{ userUpdateDatetime: string; interviewUpdateDatetime?: string }>
    ) => {
      state.updateDatetimes = action.payload
    },
    clearInterviewReserve: (state) => {
      delete state.entry
    },
  },
})
export const { setInterviewReserve, setInterviewReserveUpdateDatetime, clearInterviewReserve } =
  interviewReserveSlice.actions
export const selectInterviewReserveEntry = (state: RootState) => state.interviewReserve.entry
export const selectInterviewReserveUpdateDatetimes = (state: RootState) => state.interviewReserve.updateDatetimes
export default interviewReserveSlice.reducer
