import { FC } from 'react'
import { Box, styled, Table, TableCell, TableRow } from '@mui/material'
import { DefaultFeeList } from './defaultFeeList'
import { InputInfoType } from '../../../../containers/receiptView/receiptViewService'
import {
  CenterGItem,
  GContainer,
  GItem,
  LeftGItem,
  RightGItem
} from '../../common/grids'
import {
  formatYmd,
  fromApiYmd,
  getNow,
  isValidApiYmd
} from '../../../../utils/dateUtil'
import { formatNumberComma } from '../../../../utils/stringUtil'

const FacilityTableCell = styled(TableCell)({
  borderBottom: 'none',
  textAlign: 'left',
  fontSize: '12px',
  minWidth: '70px',
  margin: 0,
  padding: '2px 0px',
})

const TableCellLeft = styled(TableCell)({
  borderBottom: 'none',
  textAlign: 'left',
  fontSize: '12px',
  minWidth: '70px',
  margin: 0,
  padding: '2px 0px',
})

const TableCellRight = styled(TableCell)({
  borderBottom: 'none',
  textAlign: 'right',
  fontSize: '12px',
  minWidth: '70px',
  margin: 0,
  padding: '2px 0px',
})

const AmountTextItem = styled(CenterGItem)({
  borderBottom: '1px solid',
  marginBottom: '10px',
  marginTop: '0px',
  padding: '2px 0px',
  fontSize: '21px',
})

const RecepttemplateBox = styled(Box)({
  height: '50%',
})

const TitleItem = styled(CenterGItem)({
  fontSize: '24px',
  marginBottom: '5px',
  marginTop: '30px',
})

const FeeTableGItem = styled(CenterGItem)({
  fontSize: '13px',
})

const AddressNameItem = styled(LeftGItem)({
  fontSize: '19px',
})

type DefaultReceiptProp = {
  receiptData: InputInfoType
}

export const DefaultReceipt: FC<DefaultReceiptProp> = ({ receiptData }) => {
  const textMb = 3
  
  return (
    <RecepttemplateBox mb={0}>
      <GContainer>
        <GItem xs={1} md={1}></GItem>
        <GItem xs={10} md={10}>
          <GContainer rowSpacing={0}>
            <TitleItem xs={12} md={12}>
              領収証
            </TitleItem>
          </GContainer>
          {receiptData.actualUpdateDatetime && (
            <GContainer rowSpacing={0}>
              <RightGItem xs={12} md={12}>
                No. {receiptData.reservationNo}
              </RightGItem>
            </GContainer>
          )}
          <GContainer rowSpacing={0}>
            <AddressNameItem xs={6} md={6}>
              {receiptData.parentName} 様
            </AddressNameItem>

            <RightGItem xs={6} md={6}>
              発行日 {formatYmd(getNow())}
            </RightGItem>
          </GContainer>
          <GContainer rowSpacing={2}>
            <GItem xs={2} md={2}></GItem>
            <GItem xs={8} md={8}>
              <GContainer>
                <AmountTextItem mb={textMb} xs={12} md={12}>
                  金額　{formatNumberComma(receiptData.amount)} 円
                </AmountTextItem>

                <FeeTableGItem mb={textMb} xs={12} md={12}>
                  <Table>
                    <TableRow>
                      <TableCellLeft>＜内訳＞</TableCellLeft>
                      <TableCellRight>
                        {isValidApiYmd(receiptData.usageDate) ? formatYmd(fromApiYmd(receiptData.usageDate)) : ''}
                        {'　'}
                        {receiptData.childName}　様利用分
                      </TableCellRight>
                    </TableRow>
                    <DefaultFeeList receiptData={receiptData} />
                  </Table>
                </FeeTableGItem>
              </GContainer>
            </GItem>
            <GContainer>
              <GContainer>
                <GItem xs={7} md={7}></GItem>
                <RightGItem xs={5} md={5}>
                  <Table>
                    <TableRow>
                      <FacilityTableCell>施設名</FacilityTableCell>
                      <FacilityTableCell>{receiptData.facilityName}</FacilityTableCell>
                    </TableRow>
                    <TableRow>
                      <FacilityTableCell>事業名</FacilityTableCell>

                      <FacilityTableCell>{receiptData.projectName}</FacilityTableCell>
                    </TableRow>
                    <TableRow>
                      <FacilityTableCell>施設住所</FacilityTableCell>
                      <FacilityTableCell>
                        {receiptData.address1}
                        {receiptData.address2}
                        <br />
                        {receiptData.buildingNameRoomNo}{' '}
                      </FacilityTableCell>
                    </TableRow>
                    <TableRow>
                      <FacilityTableCell>電話番号</FacilityTableCell>
                      <FacilityTableCell>{receiptData.tel}</FacilityTableCell>
                    </TableRow>
                  </Table>
                </RightGItem>
              </GContainer>
            </GContainer>
          </GContainer>
        </GItem>
      </GContainer>
    </RecepttemplateBox>
  )
}