import { useCallback, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { datePropsToNumber, numberPropsToDate, undefinedPropsToOptional } from '../../utils/objectUtil'
import { getChild } from '../common/child'
import { memberModifyConfirmationChildUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import {
  selectMemberModifyChildEntry,
  setMemberModifyChild,
  setMemberModifyUpdateDatetime,
} from '../common/store/slices/memberModify'
import { getNow } from '../../utils/dateUtil'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { yesNo } from '../common/constant/classification'

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}
interface UrlParams {
  childId: string
}

interface Inputs {
  name: string
  kana: string
  birthday: Date
  brothersSistersFlag: string
  multipleBirthsFlag: string
  gender: string
  bloodType: string
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { childId } = useParams<UrlParams>()
  const [interviewPermitCount, setInterviewPermitCount] = useState<number>(0)
  const isKeep = !!history.location.state?.isKeep
  const { addOperationLog } = useOperationLog()

  const childBirthdayLimitDate = getNow() // 誕生日の入力上限値

  const modifyEntry = useSelector(selectMemberModifyChildEntry)
  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(isKeep && modifyEntry && numberPropsToDate(modifyEntry, ['birthday'])),
    },
  })

  // ちらつき防止のためにuseLayoutEffect使用
  useLayoutEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes, childId }] })

    if (!(isKeep && modifyEntry)) {
      dispatch(
        showLoading(
          errorHandle(async () => {
            const { updateDatetime, interviewPermitCount, ...initialInputs } = await getInitialInputs(childId)
            formMethods.reset({
              ...formMethods.getValues(),
              ...initialInputs,
            })
            setInterviewPermitCount(interviewPermitCount)
            dispatch(setMemberModifyUpdateDatetime(updateDatetime))
          })
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000058 })

      dispatch(setMemberModifyChild(datePropsToNumber({ ...data, childId })))
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({ ...history.location, state: { isKeep: true } })
      history.push(memberModifyConfirmationChildUrl.url())
    },
    [addOperationLog]
  )

  return {
    formMethods,
    onSubmit,
    childBirthdayLimitDate,
    interviewPermitCount,
  }
}

const getInitialInputs = async (childId: string) => {
  const child = await getChild(childId)
  const {
    name,
    kana,
    birthday,
    brothersSistersFlag,
    multipleBirthsFlag,
    gender,
    bloodType,
    maternalHandbookNo,
    interviewPermitCount,
    updateDatetime,
  } = child

  return undefinedPropsToOptional({
    name,
    kana,
    birthday,
    brothersSistersFlag,
    multipleBirthsFlag,
    gender,
    bloodType,
    maternalHandbookNo,
    interviewPermitCount,
    updateDatetime,
  })
}
