import { executePostDecodeMst } from "../../dataAccess/webApi/dao/decodeMstDao";
import { PostDecodeMstDto } from "../../dataAccess/webApi/dto/decodeMstDto";

// StringObjectの型定義
type StringObject = {
  [key: string]: string;
};

// DecodeMstReturnObjectの型定義
type DecodeMstReturnObject = {
  [key in keyof StringObject]: PostDecodeMstDto[];
};

export const getDecodeMstValue = async <T extends StringObject>(decodeMstInputList: T): Promise<{ [K in keyof T]: PostDecodeMstDto[] }> => {
  const returnObjects: Partial<DecodeMstReturnObject> = {};

  for (const key in decodeMstInputList) {
    try {
      const response = await executePostDecodeMst({
        category: decodeMstInputList[key],
      });
      returnObjects[key] = response.result;
    } catch (error) {
      console.log(`デコードマスタ取得エラー： ${error}`);
      throw error;
    }
  }

  return returnObjects as { [K in keyof T]: PostDecodeMstDto[] };
};
