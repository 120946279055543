import { Stack } from '@mui/material'
import { useAction } from '../../containers/accountCreateConfirmation/accountCreateConfirmationService'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { GContainer, GItem } from '../components/common/grids'
import { StepBar } from '../components/common/stepBar'

import { ReactNode } from 'react'
import { getAccountCreateSteps, getGenderLabel } from '../../containers/common/codeMaster'
import { accountCreateStep } from '../../containers/common/constant/classification'
import { translate } from '../../i18n'
import { formatYmd } from '../../utils/dateUtil'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ElevatedCard } from '../components/common/elevatedCard'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { LabelGItemBase, ValueGItemBase } from '../components/common/grids'
import { TransitionCheck } from '../components/common/transitionCheck'

const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={7}>{props.children}</ValueGItemBase>

export const AccountCreateConfirmation = () => {
  const { accountEntry, userAccountResultCode, register } = useAction()
  if (accountEntry == null) {
    return <TransitionCheck check={false} />
  }
  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Stack spacing={2}>
            <div>
              <StepBar activeStepValue={accountCreateStep.step2} stepValueLabels={getAccountCreateSteps()} />
            </div>
            <Stack spacing={2}>
              <GContainer>
                <LabelGItem>{translate('accountCreateConfirmation.label.parentName')}</LabelGItem>
                <ValueGItem>{accountEntry.name}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.parentKana')}</LabelGItem>
                <ValueGItem>{accountEntry.kana}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.tel')}</LabelGItem>
                <ValueGItem>{accountEntry.tel}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.email')}</LabelGItem>
                <ValueGItem>{accountEntry.email}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.password')}</LabelGItem>
                <ValueGItem>********</ValueGItem>

                <LabelGItem>{translate('accountCreateConfirmation.label.postalCode')}</LabelGItem>
                <ValueGItem>{accountEntry.postalCode}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.prefecturesAndMunicipalities')}</LabelGItem>
                <ValueGItem>{accountEntry.address1}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.address')}</LabelGItem>
                <ValueGItem>{accountEntry.address2}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.buildingNameRoomNumber')}</LabelGItem>
                <ValueGItem>{accountEntry.buildingNameRoomNumber}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.residenceCategory')}</LabelGItem>
                <ValueGItem>{accountEntry.residenceCategoryName}</ValueGItem>
                <LabelGItem>{translate('accountCreateConfirmation.label.relationship')}</LabelGItem>
                <ValueGItem>{accountEntry.relationship}</ValueGItem>
              </GContainer>

              <GContainer rowSpacing={1}>
                {accountEntry.children.map((child, index) => (
                  <GItem xs={12} key={index}>
                    <ElevatedCard title={translate('accountCreateConfirmation.title.child')}>
                      <GContainer>
                        <LabelGItem>{translate('accountCreateConfirmation.label.childName')}</LabelGItem>
                        <ValueGItem>{child.name}</ValueGItem>
                        <LabelGItem>{translate('accountCreateConfirmation.label.childKana')}</LabelGItem>
                        <ValueGItem>{child.kana}</ValueGItem>
                        <LabelGItem>{translate('accountCreateConfirmation.label.childBirthday')}</LabelGItem>
                        <ValueGItem>{formatYmd(child.birthday)}</ValueGItem>
                        <LabelGItem>{translate('accountCreateConfirmation.label.childGender')}</LabelGItem>
                        <ValueGItem>{getGenderLabel(child.gender)}</ValueGItem>
                        {/*TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする*/}
                        {/*<LabelGItem>{translate('accountCreateConfirmation.label.maternalHandbookNo')}</LabelGItem>
                        <ValueGItem>{child.maternalHandbookNo}</ValueGItem>*/}
                      </GContainer>
                    </ElevatedCard>
                  </GItem>
                ))}
              </GContainer>

              {!!userAccountResultCode && (
                <ErrorMessageCard
                  messages={[
                    translate(`accountCreateConfirmation.error.userAccountResultCode${userAccountResultCode}`),
                  ]}
                />
              )}
            </Stack>
          </Stack>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>

      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={register}>
          {translate('accountCreateConfirmation.button.register')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
