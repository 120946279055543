// 予約機能で共通する利用予約に関する表示部分を定義
// 情報照会、変更内容確認で共有
//

import React from 'react'
import { Stack } from '@mui/material'
import {
  getDoNotWaitlistLabel,
  getGenderLabel,
  getReservationStatusLabel,
  getYesNoLabel,
} from '../../../../containers/common/codeMaster'
import { purposeOfUse as purposeOfUseValue, yesNo, Flag } from '../../../../containers/common/constant/classification'
import { translate } from '../../../../i18n'
import { ElapsedMillisecond, formatHmtoHmOver, formatYmd, formatYmdWeek, toApiHms, formatHmOver } from '../../../../utils/dateUtil'
import { RowSpacerGItem } from '../grids'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import { GetUseReasonDto } from '../../../../dataAccess/webApi/dto/useReasonDto'
import { ChildReservationInfoType, ParentInfoType, GoingTimeType } from '../../../../containers/common/reservation'
import { GOING_DATETIME_DISPLAY_METHODS } from '../../../../containers/common/constant/projectMst'

interface Reservation {
  facilityName: string
  
  useReasonCategory?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string
  childInfoList: ChildReservationInfoType[]

  parentInfo: ParentInfoType
}

export interface ReservationConfirmation extends Reservation {
  lunchFlag: string
  lunchAcceptFlag: string
  snackFlag: string
  snackAcceptFlag: string
  citizenNote?: string
  
  postponeCancelWaitFlag: string
  postponeCancelWaitAcceptFlag: string
  immediatelyReservationFlag: string
  
  // 病児/・病後児関連
  medicalDocUsage: string
  symptomsInput: string
  diagonosisInput: string
  bodyTemperatureInput: string
  allergyInput: string 
  febrileSeizuresInput: string
  conditionCheck?: string | null
  conditionCheckNote?: string | null
}

export interface ReservationDelete extends ReservationConfirmation {
  goingTime: GoingTimeType
  goingDatetimeDisplayMethods: GOING_DATETIME_DISPLAY_METHODS
  usageDate: Date | ElapsedMillisecond
  useFromDatetime: Date | ElapsedMillisecond
  useToDatetime: Date | ElapsedMillisecond
  status: string
}

export interface ReservationReference extends ReservationDelete {
  reservationDatetime: Date
  reservationChangeDatetime?: Date
}

/** 入力時に必要なプロパティ */
interface Input {
  reservation: Reservation
  useReason: GetUseReasonDto
  displayType?: never
}

/** 入力内容確認時に必要なプロパティ */
interface Confirmation {
  reservation: ReservationConfirmation
  useReason: GetUseReasonDto
  displayType: 'confirmation'
}

/** 利用予約情報削除時の詳細照会に必要なプロパティ */
interface Delete {
  reservation: ReservationDelete
  useReason: GetUseReasonDto
  displayType: 'delete'
}

/** 詳細照会時に必要なプロパティ */
interface Reference {
  reservation: ReservationReference
  useReason: GetUseReasonDto
  displayType: 'reference'
}


const ParentInfo = (props: ParentInfoType) => {
  return (
    <>
      {/* 保護者 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.userName')}</LabelGItem>
      <ValueGItem>{props.name}</ValueGItem>
      {/* フリガナ */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.userNameKana')}</LabelGItem>
      <ValueGItem>{props.kana}</ValueGItem>
      {/* 携帯電話番号 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.tel')}</LabelGItem>
      <ValueGItem>{props.tel}</ValueGItem>
      {/* メールアドレス */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.email')}</LabelGItem>
      <ValueGItem>{props.email}</ValueGItem>
    </>
  )
}

type MedicalSetting = {
  medicalDocUsage: string
  symptomsInput: string
  diagonosisInput: string
  bodyTemperatureInput: string
  allergyInput: string 
  febrileSeizuresInput: string
}
type MedicalDetailProps = MedicalSetting & {
  hasSubmitted: string | undefined
  symptoms: string | undefined
  diagonosis: string | undefined
  diagonosis2: string | undefined
  bodyTemperature: string | number |undefined
  allergyFlag: string | undefined
  febrileSeizuresFlag: string | undefined
};
export const MedicalDetail =  (props: MedicalDetailProps) => {
  return (
    <>
      {props.diagonosisInput === Flag.ON &&  (
        <>
          {/* 病名１ */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.diagonosis')}</LabelGItem>
          <ValueGItem>{props.diagonosis?? ''}</ValueGItem>
          {/* 病名２ */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.diagonosis2')}</LabelGItem>
          <ValueGItem>{props.diagonosis2?? ''}</ValueGItem>
        </>
      )}
      {props.symptomsInput === Flag.ON &&  (
        <>
          {/* 症状 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.symptoms')}</LabelGItem>
          <ValueGItem>{props.symptoms?? ''}</ValueGItem>
        </>
      )}
      {props.bodyTemperatureInput === Flag.ON &&(
        <>
          {/* 体温 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.bodyTemperature')}</LabelGItem>
          <ValueGItem>{props.bodyTemperature? `${props.bodyTemperature}℃` :''}</ValueGItem>
        </>
      )}
      {props.allergyInput === Flag.ON &&(
        <>
          {/* 食物アレルギー */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.foodAllergy')}</LabelGItem>
          <ValueGItem>{props.allergyFlag?getYesNoLabel(props.allergyFlag): ''}</ValueGItem>  
        </>
      )}
      {props.febrileSeizuresInput  === Flag.ON &&(
        <>
          {/* 熱性けいれん */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.febrileSeizures')}</LabelGItem>
          <ValueGItem>{props.febrileSeizuresFlag?getYesNoLabel(props.febrileSeizuresFlag): ''}</ValueGItem>
        </>
      )}
      {props.medicalDocUsage === Flag.ON &&  (
        <>
          {/* 医師連絡票提出状況 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.medicalDoc')}</LabelGItem>
          <ValueGItem>{props.hasSubmitted?getYesNoLabel(props.hasSubmitted): ''}</ValueGItem>
        </>
      )}
    </>
  );
};

type ChildInfoProps = {
  childInfo:  ChildReservationInfoType,
  medicalSetting?: MedicalSetting
}
export const ChildInfo = (props: ChildInfoProps) => {
  const { childInfo } = props;
  
  return (
    <>
      {/* お子さま */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.childName')}</LabelGItem>
      <ValueGItem>{childInfo.childInfo?.name}</ValueGItem>
      {/* お子さまフリガナ */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.childNameKana')}</LabelGItem>
      <ValueGItem>{childInfo.childInfo?.kana}</ValueGItem>
      {/* お子さま誕生日 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.birthday')}</LabelGItem>
      <ValueGItem>{childInfo.childInfo?.birthday? formatYmd(new Date(childInfo.childInfo?.birthday)): ''}</ValueGItem>
      {/* お子さま性別 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.gender')}</LabelGItem>
      <ValueGItem>{getGenderLabel(childInfo.childInfo?.gender)}</ValueGItem>
      {/* お子さまアレルギー */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.allergy')}</LabelGItem>
      <ValueGItem>{childInfo.allergy?.flag?getYesNoLabel(childInfo.allergy?.flag): ''}</ValueGItem>   
      {
        props.medicalSetting && (
          <MedicalDetail 
            medicalDocUsage={props.medicalSetting.medicalDocUsage}
            hasSubmitted={childInfo.medicalDoc?.hasSubmitted} 
            symptomsInput={props.medicalSetting.symptomsInput}
            symptoms={childInfo.symptom?.stringify} 
            diagonosis={childInfo.diagonosis?.stringify} 
            diagonosis2={childInfo.diagonosis?.stringify2} 
            bodyTemperature={
              childInfo.temperature?.integer || childInfo.temperature?.few
              ? `${childInfo.temperature?.integer??'00'}.${childInfo.temperature?.few??'0'}`
              : undefined
            }
            allergyFlag={childInfo.allergy?.foodFlag} 
            febrileSeizuresFlag={childInfo.febrileSeizures} 
            
            diagonosisInput={props.medicalSetting.diagonosisInput}
            bodyTemperatureInput={props.medicalSetting.bodyTemperatureInput}
            allergyInput={props.medicalSetting.allergyInput}
            febrileSeizuresInput ={props.medicalSetting.febrileSeizuresInput}
          />
        )
      }
    </>
  )
}

type SharedDetailProps = {
  lunchAcceptFlag: string
  lunchFlag: string
  snackAcceptFlag: string
  snackFlag: string
  immediatelyReservationFlag: string
  postponeCancelWaitAcceptFlag: string
  postponeCancelWaitFlag: string
};
export const SharedDetail = (props: SharedDetailProps) => {
  return (
    <>
      {/* 給食 */}
      {props.lunchAcceptFlag === yesNo.yes && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.lunch')}</LabelGItem>
          <ValueGItem>{getYesNoLabel(props.lunchFlag)}</ValueGItem>
        </>
      )}
      {/* おやつ */}
      {props.snackAcceptFlag === yesNo.yes && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.snack')}</LabelGItem>
          <ValueGItem>{getYesNoLabel(props.snackFlag)}</ValueGItem>
        </>
      )}
      {/* キャンセル待ち可否 */}
      {props.immediatelyReservationFlag === yesNo.no &&
        props.postponeCancelWaitAcceptFlag === yesNo.yes && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.postponeCancelWait')}</LabelGItem>
            <ValueGItem>{getDoNotWaitlistLabel(props.postponeCancelWaitFlag)}</ValueGItem>
          </>
        )}
    </>
  );
};

type ReservationDetailGItemsProps = Input | Confirmation | Delete | Reference;

export const SharedInfo = (props: ReservationDetailGItemsProps) => {
  const isShownStatusItem = props.displayType === 'delete' || props.displayType === 'reference';

  return (
    <>
      {/* 施設名 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.facilityName')}</LabelGItem>
      <ValueGItem>{props.reservation.facilityName}</ValueGItem>
      
      {isShownStatusItem && (
        <>
          {/** 利用予定日時 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.reservationDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.reservation.usageDate)}</div>
              <div>
                {formatHmtoHmOver(
                  { from: props.reservation.useFromDatetime, to: props.reservation.useToDatetime },
                  props.reservation.usageDate
                )}
              </div>
            </Stack>
          </ValueGItem>
          {
            props.reservation.goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY && (
            <>
              {/** 登園予定時間 */}
              <LabelGItem>{translate('system.reservationDetailGItems.label.goingDatetime')}</LabelGItem>
              <ValueGItem>
                {
                  formatHmOver(
                    new Date(`${props.reservation.goingTime.goingToFacilityDate} ${props.reservation.goingTime.goingToFacilityTime}`),
                    props.reservation.usageDate
                  )
                }
              </ValueGItem>
              {/** 降園予定時間 */}
              <LabelGItem>{translate('system.reservationDetailGItems.label.goingHomeDatetime')}</LabelGItem>
              <ValueGItem>
                {
                  formatHmOver(
                    new Date(`${props.reservation.goingTime.goingHomeDate} ${props.reservation.goingTime.goingHomeTime}`),
                    props.reservation.usageDate
                  )
                }
              </ValueGItem>
            </>
              
            )
          }
          {/** 予約状態 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.status')}</LabelGItem>
          <ValueGItem>{getReservationStatusLabel(props.reservation.status)}</ValueGItem>
        </>
      )}
      <RowSpacerGItem />
      
      {/* ご利用目的 */}
      {props.reservation.useReasonCategory && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonCategory')}</LabelGItem>
          <ValueGItem>
            {
              props.reservation.useReasonCategory === purposeOfUseValue.irregular && props.useReason?.displayNameForCitizenIrregular ||
              props.reservation.useReasonCategory === purposeOfUseValue.emergency && props.useReason?.displayNameForCitizenEmg ||
              props.reservation.useReasonCategory === purposeOfUseValue.refresh && props.useReason?.displayNameForCitizenRefresh
            }
          </ValueGItem>
        </>
      )}

      {/* ご利用目的詳細：以下の三つのコンポーネントのうちいずれかが表示される（もしくは一つも表示されない） */}
      {props.reservation.useReasonDetailCategory && (
        props.reservation.useReasonCategory === purposeOfUseValue.irregular && props.useReason?.detailsIrregular && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelGItem>
            <ValueGItem>
              {props.useReason?.detailsIrregular[Number(props.reservation.useReasonDetailCategory) - 1].name}
            </ValueGItem>
          </>
        ) || 
        props.reservation.useReasonCategory === purposeOfUseValue.emergency && props.useReason?.detailsEmg && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelGItem>
            <ValueGItem>
              {props.useReason?.detailsEmg[Number(props.reservation.useReasonDetailCategory) - 1].name}
            </ValueGItem>
          </>
        ) || 
        props.reservation.useReasonCategory === purposeOfUseValue.refresh && props.useReason?.detailsRefresh && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelGItem>
            <ValueGItem>
              {props.useReason?.detailsRefresh[Number(props.reservation.useReasonDetailCategory) - 1].name}
            </ValueGItem>
          </>
        )
      )}
      {/* 詳細事由の入力欄の内容を表示 */}
      {props.reservation.useReasonDetailDescription && (
        <>
          <LabelGItem/>
          <ValueGItem hideColon>
            {props.reservation.useReasonDetailDescription}
          </ValueGItem>
        </>
      )}
      {
        props.reservation.useReasonDetailCategory && <RowSpacerGItem />
      }
      
      <ParentInfo { ...props.reservation.parentInfo }/>
    </>
  )
}

/** 予約機能の利用予約情報コンテンツ部分 */
export const ReservationDetailGItems = (props: ReservationDetailGItemsProps) => {
  const isShownReservationItem =
    props.displayType === 'confirmation' || props.displayType === 'delete' || props.displayType === 'reference'
  const isShownReservationDatetimeItem = props.displayType === 'reference'
  
  const childInfoList = props.reservation.childInfoList;

  return (
    <>
      <SharedInfo {...props} />
      <RowSpacerGItem />

      {
        childInfoList.map((child, index) => (
          <React.Fragment key={index}>
            <ChildInfo 
              childInfo={child}
              medicalSetting={ isShownReservationItem ? {
                medicalDocUsage: props.reservation.medicalDocUsage,
                symptomsInput: props.reservation.symptomsInput,
                diagonosisInput: props.reservation.diagonosisInput,
                bodyTemperatureInput: props.reservation.bodyTemperatureInput,
                allergyInput: props.reservation.allergyInput,
                febrileSeizuresInput: props.reservation.febrileSeizuresInput
              } : undefined }
            />
            <RowSpacerGItem />
          </React.Fragment>
        ))
      }


      { isShownReservationItem && (
      <>
        <SharedDetail 
          lunchAcceptFlag={props.reservation.lunchAcceptFlag}
          lunchFlag={props.reservation.lunchFlag}
          snackAcceptFlag={props.reservation.snackAcceptFlag}
          snackFlag={props.reservation.snackFlag}
          immediatelyReservationFlag={props.reservation.immediatelyReservationFlag}
          postponeCancelWaitAcceptFlag={props.reservation.postponeCancelWaitAcceptFlag}
          postponeCancelWaitFlag={props.reservation.postponeCancelWaitFlag}
        />
        {/* 備考 */}
        <LabelGItem>{translate('system.reservationDetailGItems.label.note')}</LabelGItem>
        <ValueGItem>{props.reservation.citizenNote}</ValueGItem>        
      </>
      )}
      
      { isShownReservationDatetimeItem && (
        <>
          <RowSpacerGItem />
          <LabelGItem>{translate('system.reservationDetailGItems.label.reservationReceptionDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.reservation.reservationDatetime)}</div>
              <div>{toApiHms(props.reservation.reservationDatetime)}</div>
            </Stack>
          </ValueGItem>
          { props.reservation.reservationChangeDatetime != null && (
            <>
              <LabelGItem>{translate('system.reservationDetailGItems.label.reservationChangeDatetime')}</LabelGItem>
              <ValueGItem>
                <Stack>
                  <div>{formatYmdWeek(props.reservation.reservationChangeDatetime)}</div>
                  <div>{toApiHms(props.reservation.reservationChangeDatetime)}</div>
                </Stack>
              </ValueGItem>
            </>
          )}
        </>
      )}
    </>
  )
}
