import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { List } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLogoutActions } from '../../../containers/common/application'
import {
  facilitySearchConditionUrl,
  facilitySearchResultUrl,
  facilitySearchSettingMasterSelectionUrl,
  informationUrl,
  myFacilityUrl,
  myPageUrl,
  reservationStatusListUrl,
  rootUrl,
  usageHistoryUrl,
} from '../../../containers/common/constant/appUrl'
import { closeSideMenu } from '../../../containers/common/store/slices/application'
import { selectIsLoggedIn } from '../../../containers/common/store/slices/authority'
import { translate } from '../../../i18n'
import { FacilitySearchIcon } from './icons/facilitySearchIcon'
import { HomeIcon } from './icons/homeIcon'
import { LogoutIcon } from './icons/logoutIcon'
import { MyFacilityIcon } from './icons/myFacilityIcon'
import { ReserveStatusCheckIcon } from './icons/reserveStatusCheckIcon'
import { UsageHistoryIcon } from './icons/usageHistoryIcon'
import { ListItemLink } from './listItemLink'
import { useOperationLog } from '../../../containers/common/operationLog'
import { OperationId } from '../../../containers/common/constant/operationLog'
import { selectProjectMsts } from '../../../containers/common/store/slices/facilitySearchSetting'
import { setFacilitySearchCondition } from '../../../containers/common/store/slices/facilitySearch'
import { datePropsToNumber, nullPropsToUndefined } from '../../../utils/objectUtil'
import { getFacilitySearchSettingMsts } from '../../../containers/home/homeService'

export const SideMenuContent = () => {
  const { logout } = useLogoutActions()
  const dispatch = useDispatch()
  const projectMsts = useSelector(selectProjectMsts)
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const close = useCallback(() => dispatch(closeSideMenu()), [])

  const isLoggedIn = useSelector(selectIsLoggedIn)

  const handleClickFacilitySearch = async () => {
    addOperationLog({ operationId: OperationId.OP_00000168 })

    const facilitySearchSettingMstsRes = await getFacilitySearchSettingMsts()

    const { projectCategoryId, intermediateDisplayFlag, searchConditionDisplayFlag } = facilitySearchSettingMstsRes?.[0] || {}
  
    if (intermediateDisplayFlag === '0') {
      const projectIds = projectMsts
        ?.filter((projectMst: any) => projectMst.projectCategoryId === '01')
        .map((projectMst: any) => projectMst.projectId)
  
      if (searchConditionDisplayFlag === '0') {
        history.push(facilitySearchResultUrl.url())
  
        dispatch(
          setFacilitySearchCondition(
            nullPropsToUndefined(
              datePropsToNumber({})
            )
          )
        )
      } else {
        history.push({
          pathname: facilitySearchConditionUrl.url(projectCategoryId),
          state: { projectIds },
        })
      }
    } else {
      history.push(facilitySearchSettingMasterSelectionUrl.url())
    }
  }  

  return (
    <>
      <List onClick={close}>
        <ListItemLink icon={<HomeIcon />} primary={translate('system.sideMenuContent.home')} to={rootUrl.url()} />
        <ListItemLink
          icon={<FacilitySearchIcon />}
          primary={translate('system.sideMenuContent.facilitySearch')}
          onClick={handleClickFacilitySearch}
        />
        <ListItemLink
          icon={<MyFacilityIcon />}
          primary={translate('system.sideMenuContent.myFacility')}
          to={myFacilityUrl.url()}
          onClick={() => addOperationLog({ operationId: OperationId.OP_00000019 })}
        />
        <ListItemLink
          icon={<ReserveStatusCheckIcon />}
          primary={translate('system.sideMenuContent.reserveStatusCheck')}
          to={reservationStatusListUrl.url()}
          onClick={() => addOperationLog({ operationId: OperationId.OP_00000020 })}
        />
        <ListItemLink
          icon={<UsageHistoryIcon />}
          primary={translate('system.sideMenuContent.usageHistory')}
          to={usageHistoryUrl.url()}
          onClick={() => addOperationLog({ operationId: OperationId.OP_00000021 })}
        />
        <ListItemLink
          icon={<NotificationsIcon />}
          primary={translate('system.sideMenuContent.information')}
          to={informationUrl.url()}
        />
        {isLoggedIn && (
          <ListItemLink
            icon={<AccountCircleIcon />}
            primary={translate('system.sideMenuContent.myPage')}
            to={myPageUrl.url()}
            onClick={() => addOperationLog({ operationId: OperationId.OP_00000023 })}
          />
        )}
        {isLoggedIn && (
          <ListItemLink icon={<LogoutIcon />} primary={translate('system.sideMenuContent.logout')} onClick={logout} />
        )}
      </List>
    </>
  )
}
