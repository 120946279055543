import { InputAdornment } from '@mui/material'
import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns'
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import { Control, useController } from 'react-hook-form'
import { availabilityStatus } from '../../../../../containers/common/constant/classification'
import { useSafeState, useUnmountRef } from '../../../../../containers/common/stateHooks'
import { translate } from '../../../../../i18n'
import { DateRange, formatYmdHmToHmOverWeek, isIncludeDate } from '../../../../../utils/dateUtil'
import { IconLinkButton } from '../../buttons/iconLinkButton'
import { EditIcon } from '../../icons/editIcon'
import { RemoveIcon } from '../../icons/removeIcon'
import { TextBoxBaseStyled } from '../textBoxBaseStyled'
import { ArrayInputValue, AvailabilityMap, InputValue, PreviousRange } from './common'

interface SelectedRangeTextBoxProps {
  arrayName: string
  index: number
  fieldName: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>

  /** 空き状況マップ */
  availabilityMap: AvailabilityMap
  /** 全ての入力値 */
  allInputs: ArrayInputValue[]
  /** 変更前の範囲 */
  previousRange?: PreviousRange
  /** 変更不可 */
  isReadonly?: boolean
  /**
   * 初期表示時バリデーションを行う場合に必要な空き状況マップ。
   */
  initialValidationAvailabilityMap: AvailabilityMap

  isActived: boolean
  onClickEdit: (index: number) => void
  onClickRemove: (index: number) => void
  putInitialInputs: (index: number, input: InputValue | null) => void
}

/**
 * 選択エラーとするステータスがさればエラーとするバリデータを返す
 *
 * @param availabilities （カレンダー表示分＋選択している枠）の空き状況
 * @param errorStatuses 選択枠に含まれているとエラーとするステータス配列
 * 複数指定時は、その両方が含まれている場合をエラーとする
 * @param message エラー時に表示するメッセージ
 * @returns バリデーション関数
 */
const statusValidator = (availabilities: InputValue[], errorStatuses: string[], message: string) => {
  return (value: InputValue) =>
    !errorStatuses.every((errorStatus) =>
      availabilities.some(
        (availability) =>
          isIncludeDate(availability.range.from, value.range, { isExcludingTo: true }) &&
          availability.status === errorStatus
      )
    ) || message
}
/**
 * 選択範囲が重複すればエラーとするバリデータを返す
 *
 * @param otherInputs 重複範囲比較対象の他入力値
 * @returns バリデーション関数
 */
const duplicationValidator = (otherInputs: ArrayInputValue[]) => {
  return (value: InputValue) =>
    !otherInputs
      .map((v) => v.value?.range)
      .filter((v): v is DateRange => !!v)
      .some(({ from, to }) => isBefore(value.range.from, to) && isAfter(value.range.to, from)) ||
    translate('system.error.selectedDatetimeAvailabilityDuplication')
}

/**
 * 選択状態のバリデーションを行う関数を返す。
 * 枠選択のエラーパターン:https://docs.google.com/spreadsheets/d/1vkgz4AauitYXeMntStGL0zRHmXmzKAQPPBlNhr5yQiY/edit#gid=1749515790&range=A49
 *
 * 選択範囲の空き状況を受け渡しているのは、選択範囲と別の週を表示していてもエラーで引っ掛ける為の措置
 *
 * @param selectionAvailabilities 選択範囲の空き状況
 * @param availabilityMapValues 空き状況マップの値配列
 * @param otherInputs 重複範囲比較対象の他入力値
 * @param previousRange 変更前の選択範囲
 * @returns バリデーション関数の配列
 */
const getValidators = (
  selectionAvailabilities: InputValue[],
  availabilityMapValues: InputValue[],
  isMultiple: boolean,
  otherInputs: ArrayInputValue[],
  previousRange?: DateRange
) => {
  const availabilities = availabilityMapValues.concat(selectionAvailabilities)
  const availabilitiesExcludePrevious = previousRange
    ? availabilities.filter(({ range }) => !isIncludeDate(range.from, previousRange, { isExcludingTo: true }))
    : availabilities
  return [
    statusValidator(
      availabilities,
      [availabilityStatus.outside],
      translate('system.error.selectedDatetimeAvailabilityDisabled')
    ),
    statusValidator(
      availabilities,
      [availabilityStatus.noSpace],
      translate('system.error.selectedDatetimeAvailabilityDisabled')
    ),
    statusValidator(
      availabilitiesExcludePrevious,
      [availabilityStatus.doneFixed],
      translate('system.error.selectedDatetimeAvailabilityDisabled')
    ),
    statusValidator(
      availabilitiesExcludePrevious,
      [availabilityStatus.doneNotFixed],
      translate('system.error.selectedDatetimeAvailabilityDisabled')
    ),
    statusValidator(
      availabilities,
      [availabilityStatus.doneWait],
      translate('system.error.selectedDatetimeAvailabilityDisabled')
    ),
    duplicationValidator(otherInputs),
    ...(isMultiple
      ? [
          statusValidator(
            availabilities,
            [availabilityStatus.wait],
            translate('system.error.selectedDatetimeAvailabilityMultipleNotAllowed')
          ),
        ]
      : []),
  ]
}

const findAvailability = (availabilities: InputValue[], range: DateRange, status: string) =>
  availabilities.find(
    (availability) =>
      isIncludeDate(availability.range.from, range, { isExcludingTo: true }) && availability.status === status
  )

/**
 * 空き状況と選択範囲から「キャンセル待ち可」のステータスを検索する
 * 存在しなければ undefined
 *
 * @param availabilities 空き状況ステータス一覧
 * @param range 選択範囲
 * @returns 「キャンセル待ち可」 または undefined
 */
const findWaitAvailabilityStatus = (availabilities: InputValue[], range: DateRange) =>
  findAvailability(availabilities, range, availabilityStatus.wait)?.status

/**
 * 枠選択時の時間設定処理
 */
const selectDateRange = (
  currentInput: InputValue | null,
  newInput: InputValue,
  availabilityMapValues: InputValue[],
  isSingleSlotSelection: boolean
): InputValue | null => {
  if (currentInput && currentInput.range.to === newInput.range.to) {
    // 同じ範囲を選択した場合は選択解除
    return null
  } else if (currentInput && !isSingleSlotSelection && differenceInCalendarDays(currentInput.baseDateOfTime, newInput.baseDateOfTime) === 0) {
    // 同じ日の場合のみtoを変更（複数枠選択可能の場合）
    const range = { ...currentInput.range }
    if (newInput.range.from < range.from) {
      range.from = newInput.range.from
    } else {
      range.to = newInput.range.to
    }

    // 空き状況と選択範囲から「キャンセル待ち可」のステータスを検索する
    const status = findWaitAvailabilityStatus(availabilityMapValues, range) ?? newInput.status

    return {
      ...currentInput,
      range,
      status,
    }
  } else {
    // 初回選択または単一選択
    return newInput
  }
}

export interface SelectedRangeTextHandler {
  setValue: (availability: InputValue, isSingleSlotSelection: boolean) => void
}

export const SelectedRangeTextBox = forwardRef(function SelectedRangeTextBox(
  props: SelectedRangeTextBoxProps,
  ref: Ref<SelectedRangeTextHandler | undefined>
) {
  const name = `${props.arrayName}.${props.index}.${props.fieldName}`
  const availabilityMapValues = useMemo(() => Object.values(props.availabilityMap), [props.availabilityMap])

  const unmountRef = useUnmountRef()
  const [selectionAvailabilities, setSelectionAvailabilities] = useSafeState<InputValue[]>(unmountRef, [])

  const validators = useMemo(
    () =>
      getValidators(
        selectionAvailabilities,
        availabilityMapValues,
        props.allInputs.length > 1,
        props.allInputs.slice(0, props.index),
        props.previousRange?.range
      ),
    [selectionAvailabilities, availabilityMapValues, props.allInputs, props.previousRange, props.index]
  )

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: { value: !!props.required, message: translate('system.error.requiredSelection', props.label) },
      validate: (value: InputValue) => {
        for (const validator of validators) {
          const result = validator(value)
          if (typeof result === 'string') {
            return result
          }
        }
        return true
      },
    },
    control: props.control,
  })

  const currentInput = field.value as InputValue | null

  /**
   * 枠情報設定処理（親コンポーネントから呼び出される）
   */
  useImperativeHandle(
    ref,
    () => ({
      setValue: (availability, isSingleSlotSelection) => {
        const newInput = selectDateRange(currentInput, availability, availabilityMapValues, isSingleSlotSelection)
        setSelectionAvailabilities(
          newInput
            ? availabilityMapValues.filter((v) => isIncludeDate(v.range.from, newInput.range, { isExcludingTo: true }))
            : []
        )
        field.onChange(newInput)
      },
    }),
    [currentInput, availabilityMapValues, field.onChange]
  )

  useEffect(() => {
    // useWatchは初期表示後の変更はウォッチできるが初期表示時の値は取得できない為
    // ここで初期値を収集する
    props.putInitialInputs(props.index, currentInput)
  }, [])
  useEffect(() => {
    // 初期表示時に選択されている場合
    // 基準日の為に選択範囲の空き状況を保存
    if (currentInput) {
      // 初期表示時バリデーション用の空き状況を優先して探索
      const availabilities = Object.values(props.initialValidationAvailabilityMap)
        .concat(availabilityMapValues)
        .filter((v) => isIncludeDate(v.range.from, currentInput.range, { isExcludingTo: true }))
      if (availabilities.length && !unmountRef.current) {
        setSelectionAvailabilities(availabilities)
        const status = findWaitAvailabilityStatus(availabilities, currentInput.range) ?? availabilities[0].status
        if (status !== currentInput.status) {
          // 状況が変化したらinputの状況も変えておく。
          // サブミット後の処理で古い情報で判定することを防ぐため
          field.onChange({ ...currentInput, status })
        }
      }
    }
  }, [props.initialValidationAvailabilityMap, availabilityMapValues])

  const onClickEditHandler = useCallback(() => {
    props.onClickEdit(props.index)
  }, [props.index, props.onClickEdit])
  const onClickRemoveHandler = useCallback(() => {
    props.onClickRemove(props.index)
  }, [props.index, props.onClickRemove])

  const error = errors[props.arrayName]?.[props.index]?.[props.fieldName]
  return (
    <TextBoxBaseStyled
      name={name}
      inputRef={field.ref}
      InputProps={{
        readOnly: true,
        ...(!props.isReadonly && {
          endAdornment: (
            <InputAdornment position="end">
              <IconLinkButton color="primary" onClick={onClickEditHandler}>
                <EditIcon />
              </IconLinkButton>
              <IconLinkButton color="error" onClick={onClickRemoveHandler}>
                <RemoveIcon />
              </IconLinkButton>
            </InputAdornment>
          ),
        }),
      }}
      focused={props.isActived}
      value={currentInput ? formatYmdHmToHmOverWeek(currentInput.range, currentInput.baseDateOfTime) : ''}
      error={!!error}
      helperText={error?.message}
    />
  )
})
