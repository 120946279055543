import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showLoading } from '../../containers/common/store/slices/application'
import { executePostFAQsForEnduser } from '../../dataAccess/webApi/dao/faqDao'
import { executePostGuideText, guideTextId } from '../../dataAccess/webApi/dao/guideTextDao'
// import { ContactData } from '../../dataAccess/webApi/dto/contactDto'
import { FaqData } from '../../dataAccess/webApi/dto/faqDto'
import { GuideTextData } from '../../dataAccess/webApi/dto/guideTextDto'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const { addOperationLog } = useOperationLog()
  
  const [faqList, setFaqList] = useState<FaqData[]>()
  const [guideText, setGuideText] = useState<GuideTextData>()
  
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    const initialize = async () => {
      // よくあるご質問リストを取得
      { 
        const faqList = await getFAQs()
        setFaqList(faqList)
        // console.log("faqList", faqList)
      }
      
      // 案内文を取得
      { 
        const guideText = await getFaqFootnoteGuideText()
        setGuideText(guideText)
        // console.log("guideText", guideText)
      }
      
    }
    dispatch(showLoading(errorHandle(initialize)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    faqList,
    guideText,
  }
}

/**
 * 一覧取得して返す
 * @return {Array<Object>}
 */
const getFAQs = async () => {
  const response = await executePostFAQsForEnduser()
  return response.result.map((entry) => {
    const { ...other } = entry
    return {
      ...other,
    }
  })
}

/**
 * 案内文を取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getFaqFootnoteGuideText = async () => {
  const response = await executePostGuideText( guideTextId.faqFootnoteGuideText )
  return response.result;
}

