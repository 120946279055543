// ボタンコントロールの共通部分を定義
//

import { Button, styled } from '@mui/material'
import { LocationDescriptor } from 'history'
import React, { MouseEventHandler, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export interface ButtonProps {
  /**
   * ボタンの中身。
   * ボタンの表示文言を設定。
   */
  children: ReactNode
  /** buttonタグのtype属性 */
  type?: 'submit' | 'reset' | 'button'
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
  /** ボタンクリック時に呼び出される関数 */
  onClick?: MouseEventHandler<HTMLButtonElement>
  /** ボタン左に表示するアイコン */
  startIcon?: React.ReactNode
  /** ボタン右に表示するアイコン */
  endIcon?: React.ReactNode
  /**
   * 遷移先を示す情報。
   * 参照：https://v5.reactrouter.com/web/api/Link
   */
  to?: LocationDescriptor
  /** history.replaceで遷移する場合true */
  replace?: boolean
  /**
   * リンクのタグのhref属性。
   * toプロパティが設定されている場合は、このプロパティを無視します
   */
  href?: string
  /** width: 100% にする場合true */
  fullWidth?: boolean
  
  disabled?: boolean
}

const Root = (props: ButtonProps) => {
  const { children, to, replace, href, onClick, ...buttonAttrs } = props
  return to ? (
    <Button {...buttonAttrs} component={RouterLink} to={to} replace={replace}>
      {children}
    </Button>
  ) : href ? (
    <Button {...buttonAttrs} href={href}>
      {children}
    </Button>
  ) : (
    <Button {...buttonAttrs} onClick={onClick}>
      {children}
    </Button>
  )
}

/**
 * ボタンコントロールの基本部分。
 * テキストは中央寄せ、英字の大文字変換は無効化している。
 */
export const ButtonBase = styled(Root)({
  textAlign: 'center',
  textTransform: 'none',
})
