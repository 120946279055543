import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePostChild } from '../../dataAccess/webApi/dao/childrenDao'
import { translate } from '../../i18n'
import { toApiYmd } from '../../utils/dateUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { memberUrl } from '../common/constant/appUrl'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getLatestAddOperationDatetime, useOperationLog } from '../common/operationLog'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import {
  MemberAddChildEntry,
  clearMemberAddChild,
  selectMemberAddChildEntry,
} from '../common/store/slices/memberAddChild'
import { tabValue } from '../member/memberService'

interface LocationState {
  /**
   * 登録完了後遷移先。
   * 指定があれば登録完了後に指定画面へ遷移
   */
  completionTo?: Location
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const currentHistory = useHistory<LocationState | undefined>()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const completionTo = currentHistory.location.state?.completionTo

  const addEntry = useSelector(selectMemberAddChildEntry)

  const [childrenResultCode, setChildrenResultCode] = useState<number>()

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const decide = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000037 })
    const operationLogDatetime = castNonNullable(getLatestAddOperationDatetime())

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          // entryは必ず設定されている
          if (addEntry) {
            const resp = await postChild(addEntry, operationLogDatetime)
            if (resp.resultCode) {
              // 失敗時
              setChildrenResultCode(resp.resultCode)
            } else {
              // 成功時
              dispatch(notifyMessage(translate('memberAddConfirmationChild.success.completionMessage')))
              if (completionTo) {
                history.push(completionTo)
              } else {
                history.push(memberUrl.url(), { tabValue: tabValue.child })
              }
              dispatch(clearMemberAddChild())
            }
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [dispatch, addEntry, errorHandle, history, completionTo, addOperationLog])

  return {
    child: addEntry && toChild(addEntry),
    childrenResultCode,
    decide,
  }
}

const toChild = (entry: MemberAddChildEntry) => {
  const { name, kana, birthday, brothersSistersFlag, multipleBirthsFlag, gender, bloodType } = entry
  return {
    name,
    kana,
    birthday,
    brothersSistersFlag,
    multipleBirthsFlag,
    gender,
    bloodType,
    //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
    //maternalHandbookNo,
  }
}

const postChild = (entry: MemberAddChildEntry, operationLogDatetime: string) => {
  return executePostChild({
    name: entry.name,
    kana: entry.kana,
    birthday: toApiYmd(entry.birthday),
    brothersSistersFlag: entry.brothersSistersFlag,
    multipleBirthsFlag: entry.multipleBirthsFlag,
    gender: entry.gender,
    bloodType: entry.bloodType,
    //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
    //maternalHandbookNo: entry.maternalHandbookNo,
    operationLogDatetime,
  })
}
