import { executeGet, executeGetNoResultError } from '../common/apiCaller'
import { GetFacilitiesDto, GetFacilitiesInputDto, GetFacilityDto, GetFacilityInputDto, GetFacilityIdDto } from '../dto/facilitiesDto'

const apiName = 'public'
const apiPath = '/facilities'

export const resultCodeGetFacilities = {
  /** 検索結果件数が上限を超え、結果が上限数でカットされている */
  overLimit: 1,
} as const

export const executeGetFacilities = async (input: GetFacilitiesInputDto) => {
  const response = await executeGet<GetFacilitiesDto[]>(apiName, apiPath, input)
  const coordinateNullFacilityIds: string[] = []
  response.result = response.result.map((facility) => {
    const isFixed = fixCoordinateNull(facility)
    if (isFixed) {
      coordinateNullFacilityIds.push(facility.facilityId)
    }
    return facility
  })
  if (coordinateNullFacilityIds.length) {
    console.error('座標null施設Id=', coordinateNullFacilityIds)
  }
  return response
}

export const executeGetFacility = async (id: string, input?: GetFacilityInputDto) => {
  const response = await executeGetNoResultError<GetFacilityDto>(apiName, `${apiPath}/${id}`, input)
  const isFixed = fixCoordinateNull(response.result)
  if (isFixed) {
    console.error('座標null施設Id=', id)
  }
  return response
}

export const executeGetFacilitiesForAgesSixOver = async () => {
  return executeGet<GetFacilityIdDto[]>(apiName, `${apiPath}/for-ages/six-over`)
}

/**
 * 開発作業に支障が出る為、座標nullは0で補完する。
 * ただし、座標なしの施設は存在しない前提なのでこの動作を前提としたコードを記載しないこと
 * 例えば、if (facility.latitude === 0) ... といった事をしない
 * @param facility 施設
 * @returns 座標nullを0で補完した場合true
 */
const fixCoordinateNull = <T extends GetFacilitiesDto | GetFacilityDto>(facility: T) => {
  let isFixed = false
  if (facility.latitude == null || facility.longitude == null) {
    facility.latitude = facility.latitude ?? 0
    facility.longitude = facility.longitude ?? 0
    isFixed = true
  }
  return isFixed
}
