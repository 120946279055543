// 会員情報機能で共通するお子さまに関する表示部分を定義
// 情報照会、変更内容確認で共有
//

import { Grid, Stack, styled } from '@mui/material'
import { ReactNode } from 'react'
import {
  getBloodTypeLabel,
  getCoveredLabel,
  getGenderLabel,
  getYesNoLabel,
} from '../../../../containers/common/codeMaster'
import { translate, translateNode } from '../../../../i18n'
import { ElapsedMillisecond, formatYmd } from '../../../../utils/dateUtil'
import { GItem, LabelGItemBase, ValueGItemBase } from '../grids'
import { SubTitle } from '../subTitle'
import { OperationId } from '../../../../containers/common/constant/operationLog'
import { childUseEndUrl } from '../../../../containers/common/constant/appUrl'
import { Link } from '../link'

interface Child {
  name: string
  kana: string
  birthday: Date | ElapsedMillisecond
  brothersSistersFlag: string
  multipleBirthsFlag: string
  gender: string
  bloodType?: string
  maternalHandbookNo?: string
}

interface ChildReference extends Child {
  childId: string
  allergyFlag: string
  /** アレルギー１～３ */
  allergies: {
    causes?: string
    symptoms?: string
    treatments?: string
    concerns?: string
  }[]
}
/** 情報照会時以外で必要なプロパティ */
interface Confirmation {
  child: Child
  displayType?: never
  /** アレルギー項目を非表示にする場合true */
  isHideAllergy?: boolean
}
/** 情報照会時に必要なプロパティ */
interface Reference {
  child: ChildReference
  /** 利用終了はこちらを表示する場合true */
  isShowUseEnd?: boolean
  displayType: 'reference'
}

type ChildProps = Confirmation | Reference
const LabelItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
const ValueItem = (props: { children: ReactNode }) => <ValueGItemBase xs={7}>{props.children}</ValueGItemBase>

const ChildContentAttribute = styled(Grid)({
  padding: '0 0 0 3rem',
})

/** 会員情報機能のお子さま情報コンテンツ部分 */
export const ChildContent = (props: ChildProps) => {
  const { child } = props
  const unSetLabel = translate('system.childContent.label.notSet')
  return (
    <Stack spacing={2}>
      {/* 基本 */}
      <Stack spacing={1}>
        <SubTitle title={translate('system.childContent.title.basic')} />
        <div>
          <ChildContentAttribute container>
            <LabelItem>{translate('system.childContent.label.childName')}</LabelItem>
            <ValueItem>{child.name}</ValueItem>
            <LabelItem>{translate('system.childContent.label.childNameKana')}</LabelItem>
            <ValueItem>{child.kana}</ValueItem>
            <LabelItem>{translate('system.childContent.label.birthday')}</LabelItem>
            <ValueItem>{formatYmd(child.birthday)}</ValueItem>
            <LabelItem>{translate('system.childContent.label.hasBrothersSisters')}</LabelItem>
            <ValueItem>{getYesNoLabel(child.brothersSistersFlag)}</ValueItem>
            <LabelItem>{translate('system.childContent.label.isMultipleBirths')}</LabelItem>
            <ValueItem>{getCoveredLabel(child.multipleBirthsFlag)}</ValueItem>
            <LabelItem>{translate('system.childContent.label.gender')}</LabelItem>
            <ValueItem>{getGenderLabel(child.gender)}</ValueItem>
            <LabelItem>{translate('system.childContent.label.bloodType')}</LabelItem>
            <ValueItem>{getBloodTypeLabel(child.bloodType)}</ValueItem>
            {/*TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする*/}
            {/*<LabelItem>{translate('system.childContent.label.maternalHandbookNo')}</LabelItem>
            <ValueItem>{child.maternalHandbookNo}</ValueItem>*/}
          </ChildContentAttribute>
        </div>
      </Stack>

      {props.displayType === 'reference' ? (
        <Stack spacing={1}>
          <SubTitle title={translate(`system.childContent.title.allergy`)} />
          <div>
            <ChildContentAttribute container>
              <LabelItem>{translate('system.childContent.label.hasAllergy')}</LabelItem>
              <ValueItem>{getYesNoLabel(props.child.allergyFlag)}</ValueItem>
            </ChildContentAttribute>
          </div>
        </Stack>
      ) : (
        <>
          {!props.isHideAllergy && (
            <Stack spacing={1}>
              <SubTitle title={translate(`system.childContent.title.allergy`)} />
              <div>
                <ChildContentAttribute>
                  <GItem xs={12}>{translate('system.userContent.text.exempt')}</GItem>
                </ChildContentAttribute>
              </div>
            </Stack>
          )}
        </>
      )}
      {/* アレルギー１～３ */}
      {props.displayType === 'reference' &&
        ['allergy1', 'allergy2', 'allergy3'].map((title, index) => {
          const allergy = props.child.allergies[index]
          return (
            <Stack key={title} spacing={1}>
              <SubTitle title={translate(`system.childContent.title.${title}`)} />
              <div>
                <ChildContentAttribute container>
                  <LabelItem>{translate('system.childContent.label.allergyCause')}</LabelItem>
                  <ValueItem>{allergy.causes || unSetLabel}</ValueItem>
                  <LabelItem>{translate('system.childContent.label.allergySymptom')}</LabelItem>
                  <ValueItem>{allergy.symptoms || unSetLabel}</ValueItem>
                  <LabelItem>{translate('system.childContent.label.allergyTreatment')}</LabelItem>
                  <ValueItem>{allergy.treatments || unSetLabel}</ValueItem>
                  <LabelItem>{translate('system.childContent.label.allergyConcern')}</LabelItem>
                  <ValueItem>{allergy.concerns || unSetLabel}</ValueItem>
                </ChildContentAttribute>
              </div>
            </Stack>
          )
        })}

      {/* そのほか */}
      {props.displayType === 'reference' && props.isShowUseEnd &&
        <Stack spacing={1}>
          <SubTitle title={translate('system.childContent.title.other')} />
          <div>
            <ChildContentAttribute container>
              <GItem xs={12}>
                {translateNode(
                  'system.childContent.text.useEnd',
                  <Link
                    to={childUseEndUrl.url(props.child.childId)}
                    operationLog={{ operationId: OperationId.OP_00000159 }}>
                    {translate('system.childContent.text.here')}
                  </Link>
                )}
              </GItem>
            </ChildContentAttribute>
          </div>
        </Stack>
      }
    </Stack>
  )
}
