import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
// import { executeGetGuideText } from '../../dataAccess/webApi/dao/controlDao'
import { translate } from '../../i18n'
import { memberUrl } from '../common/constant/appUrl'
// import { guidTextMstId, yesNo } from '../common/constant/classification'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { castNonNullable } from '../../utils/typeUtil'
import { tabValue } from '../member/memberService'
import { executeDeleteChild } from '../../dataAccess/webApi/dao/childrenDao'
import { getChild, Child } from '../common/child'

interface UrlParams {
  childId: string
}

interface Inputs {
  isConfirmation: string
}

interface PageState {
  // useEndGuideText: string
  child: Child
  deleteResultCode?: number
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { childId } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()
  const sysCtrl = useSelector(selectSystemControl)

  const [state, setState] = useState<PageState>()

  const formMethods = useForm<Inputs>()

  useEffect(() => {
    addOperationLog({
      operationId: OperationId.OP_00000001,
      accessData: [{ userIdRegFlag: yesNo.yes, childId }],
    })

    dispatch(
      showLoading(
        errorHandle(async () => {
          // const [guideText, child] = await Promise.all([
          const [child] = await Promise.all([
            // executeGetGuideText(guidTextMstId.childUseEnd),
            getChild(childId),
          ])
          setState({
            // useEndGuideText: guideText.result.guideTextContent,
            child,
          })
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback(async () => {
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          addOperationLog({
            operationId: OperationId.OP_00000160,
            accessData: [{ userIdRegFlag: yesNo.yes, childId }],
          })

          const response = await executeDeleteChild(childId)
          if (response.resultCode) {
            // 利用終了失敗時
            setState((old) => ({ ...castNonNullable(old), deleteResultCode: response.resultCode }))
          } else {
            // 利用終了成功時
            dispatch(notifyMessage(translate('childUseEnd.success.completionMessage')))

            history.push(memberUrl.url(), { tabValue: tabValue.child })
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [dispatch, errorHandle, history, addOperationLog, childId])

  return {
    sysCtrl,
    formMethods,
    onSubmit,
    state,
  }
}
