import { Stack, styled } from '@mui/material'
// import { ReactNode, useState } from 'react'
import { useState } from 'react'
// import {
//   auMailSetupUrl,
//   docomoMailSetupUrl,
//   softbankMailSetupUrl,
//   ymobileMailSetupUrl,
// } from '../../containers/common/constant/externalUrl'
import { AccordionCard } from '../components/common/accordionCard'
import { BottomActionContainer } from '../components/common/bottomActionContainer'
import { BottomButtonStack } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { InlineFlexbox } from '../components/common/flexboxes'
import { CenterGItem, GContainer, GItem } from '../components/common/grids'
import { ExternalLinkIcon } from '../components/common/icons/externalLinkIcon'
import { Link } from '../components/common/link'
import { SubTitle } from '../components/common/subTitle'

import { useAction } from '../../containers/faq/faqService'

// const QaCard = ({ question, answer, qaNum }: { question: string; answer: ReactNode; qaNum: number }) => {
const QaCard = ({ question, answer, qaNum }: { question: string; answer: string; qaNum: number }) => {
    const [setOpen, setOpenState] = useState(false)
  const toggleAccordion = () => {
    setOpenState(!setOpen)
  }
  return (
    <AccordionCard title={'Q' + qaNum + '.' + question} expanded={setOpen} onChange={toggleAccordion}>
      <div>
        {'A' + qaNum + '.'}
        {/* {answer} */}
        {/* <div dangerouslySetInnerHTML={{ __html: 'A' + qaNum + '.' + convertToHTML(answer) }} /> */}
        {convertToReactNode(answer)}
      </div>
    </AccordionCard>
  )
}
const ItemIconBox = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.white.main,
  borderRadius: 24,
  fontSize: '1rem',
  marginTop: '0.15rem',
  marginRight: '4px',
}))
// const LinkList = styled('ul')({
//   marginTop: '0',
// })
const TrademarkNoticeGItem = styled(CenterGItem)(({ theme }) => ({
  fontSize: theme.typography.font.sizeS,
  letterSpacing: '0.2em',
  color: theme.palette.grayDark.main,
  borderTop: `1px solid ${theme.palette.grayDark.main}`,
  marginTop: '6rem',
  padding: '1rem 0',
  // whiteSpace: 'pre',
  whiteSpace: 'pre-wrap',
}))
const FaqStack = styled(Stack)({
  paddingBottom: '5rem',
})
/*
//   FAQ掲載内容
const categList = [
  {
    category: 'アカウント作成',
    qaList: [
      {
        question: 'アカウント作成したのですが、確認メールが届きません。',
        answer: (
          <>
            迷惑メールに割り振られている可能性があります。受信ボックスを確認してください。
            <br />
            全ての受信ボックスにメールが届いていない場合は、「no-reply@michi-shiru.jp」からのメールを受け取れるように設定を変更してください。
            <br />
            <br />
            ※通信キャリア各社における受信設定の変更方法
            <LinkList>
              <li>
                <Link href={docomoMailSetupUrl} target="_blank">
                  ドコモ
                  <ItemIconBox>
                    <ExternalLinkIcon fontSize="inherit" />
                  </ItemIconBox>
                </Link>
              </li>
              <li>
                <Link href={auMailSetupUrl} target="_blank">
                  au
                  <ItemIconBox>
                    <ExternalLinkIcon fontSize="inherit" />
                  </ItemIconBox>
                </Link>
              </li>
              <li>
                <Link href={softbankMailSetupUrl} target="_blank">
                  ソフトバンク
                  <ItemIconBox>
                    <ExternalLinkIcon fontSize="inherit" />
                  </ItemIconBox>
                </Link>
              </li>
              <li>
                <Link href={ymobileMailSetupUrl} target="_blank">
                  ワイモバイル
                  <ItemIconBox>
                    <ExternalLinkIcon fontSize="inherit" />
                  </ItemIconBox>
                </Link>
              </li>
            </LinkList>
          </>
        ),
      },
      {
        question: 'アカウント作成したのですが、ワンタイムパスワード(SMS)が届きません。',
        answer: [
          'ワンタイムパスワードは、入力していただいた携帯電話番号に、SMS（ショートメッセージサービス）で送られます。',
          'ワンタイムパスワードの受信には時間がかかる場合があります。',
          'SMSが届かない場合は、SMSを受信できる環境であること、また、登録した携帯電話番号が正しいものか確認してください。',
          '登録した携帯電話番号は、マイページのアカウント情報から確認することができます。',
        ].join('\n'),
      },
      {
        question: 'パスワードを変更したいのですが、新しいパスワードを受け付けてもらえません。',
        answer: [
          '新しく設定するパスワードには、ルールがあります。',
          '以下の文字を最低1つずつ以上含み、半角で8文字以上のパスワードを入力してください。',
          '１．大文字英字',
          '２．小文字英字',
          '３．数字',
          '４．記号（記号例）^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =',
        ].join('\n'),
      },
      {
        question: '「子育て応援サイト　パマトコのアカウントでログイン」とは何でしょうか。',
        answer: [
          '横浜市が提供している子育て応援サイト『パマトコ』へ登録がお済みの方は、応援サイトのアカウント情報を利用して、',
          '一時預かりWEB予約システムにログインすることや、一時預かりWEB予約システムでの予約情報をパマトコで確認することができます。',
          '詳細は、操作マニュアル「横浜市子育て応援サイト「パマトコ」との連携」をご確認ください。',
        ].join('\n'),
      },
      {
        question: 'パスワードをリセットしたのですが、ワンタイムパスワード(メール)が届きません。',
        answer: [
          'パスワードをリセットした時のワンタイムパスワードは、入力していただいたメールアドレスに、メールで送られます。',
          'ワンタイムパスワードの受信には時間がかかる場合があります。',
          '【メールが届かない場合】',
          '１．メールを受信できる環境（ドメイン拒否設定の確認等）であることを確認してください。',
          '　　Q1.A1.を参考にご確認ください。',
          '２．登録したメールアドレスが正しいものであることを確認してください。',
          '　　登録したメールアドレスは、マイページのアカウント情報から確認することができます。',
          '３．再度画面からワンタイムパスワードを送信してください。',
          '',
          '※2024/07/01よりワンタイムパスワードはSMS(ショートメッセージ）からメールアドレスへ送信先を変更いたしました。',
        ].join('\n'),
      },
    ],
  },
  {
    category: '面談・登録情報',
    qaList: [
      {
        question: '面談申込をしましたが、違う施設へ変更したいです。',
        answer: [
          '面談申込した施設に連絡して面談予約をキャンセルしてください。',
          'また、利用を希望する施設へ改めて面談申込を行ってください。',
          '複数施設に対し面談申込することも可能です。',
        ].join('\n'),
      },
    ],
  },
  {
    category: '利用予約',
    qaList: [
      {
        question: '一時預かり利用の予約ができません。',
        answer: [
          'アカウント作成後に利用予定施設へ面談申込を行い、電話にて施設と日程調整を行ったうえで、面談を実施します。',
          'その後、施設から利用案内メールが届いた段階で、利用予約をすることができます。',
          '【上記の手順を行っても利用予約ができない場合】',
          '・ログインしていることを確認してください。',
          '・施設側の設定によって、予約受付の期限などを設定している場合があります。',
          '　施設へ直接ご連絡ください。',
          '・お子さまお一人につき、月「120時間」の利用上限を設けております。',
          '　予約状態が「未確定(施設確認中)」「確定済」「利用済」の予約の合計時間が、',
          '　同じ月の中で120時間に達していないかどうかご確認いただき、ご不明な場合は市へご連絡ください。',
          '　※ただし、公立保育園が実施する一時保育の利用上限は、月「140時間」です。詳細は、施設へ直接ご確認ください。',
        ].join('\n'),
      },
      {
        question: '予約したのに「予約の見送り」メールが届きました。利用できないのですか？',
        answer: [
          '施設によって予約の受付方法が異なります（詳細については、マニュアルp.5を参照してください）。',
          '予約確認をしてから、予約完了とする受付方法を取っている施設があります（施設承認予約）。',
          '予約確認にて、予約の整理が行われたり、定員に達したりした場合に、「予約の見送り」メールにて予約が難しいことをご案内しています。',
          '※予約の受付時に送信される「ご予約仮受付」メールは、予約の完了をお知らせするメールではありません。',
        ].join('\n'),
      },
    ],
  },
]
*/

export const Faq = () => {
  // データ取得
  const { faqList, guideText } = useAction()

  // データをカテゴリーごとにまとめる
  const categList: any[] = [];
  {
    if(faqList){
      let lastCategory = null
      for(const faq of faqList){
        const category = faq.category ?? ''

        // １つ前のデータと違うカテゴリーなら
        if(category !== lastCategory){
          // 新しいカテゴリーMAPを追加
          categList.push({
            category: category,
            qaList: [],
          })
        }

        // 追加用のデータに加工
        const qa = {
          question: faq.question,
          answer:   faq.answer,
        }

        // 最後尾のリストに追加
        categList[categList.length - 1].qaList.push(qa)

        lastCategory = category
      }
    }
  }

  // // 脚注を加工
  // let footnote = guideText?.guideTextContent ?? ''
  // {
  //   // 改行文字
  //   footnote = footnote.replace(/(\r\n|\r|\n)/gm, "<br />")

  //   // リンク
  //   footnote = footnote.replace(/\[(.+)\]\((.+)\)/gm, '<a href="$2" target="_blank">$1</a>')
  // }


  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={2}></GItem>
        <GItem xs={12} md={8}>
          <FaqStack spacing={2}>
            {categList.map((category, index) => (
              <Stack key={index} spacing={2}>
                <SubTitle title={category.category} />
                <Stack spacing={1}>
                  {category.qaList.map((qa:any, qaIndex:any) => (
                    // カテゴリごとにグループ化され見た目に影響があるためspanで囲う
                    <span key={qaIndex}>
                      <QaCard {...qa} qaNum={qaIndex + 1} />
                    </span>
                  ))}
                </Stack>
              </Stack>
            ))}
          </FaqStack>
        </GItem>
        <GItem md={2}></GItem>
      </GContainer>
      <BottomActionContainer>
        <BottomButtonStack>
          <BackButton />
        </BottomButtonStack>
        <GContainer>
          <GItem md={2}></GItem>
          {/*
          <TrademarkNoticeGItem xs={12} md={8}>
            ※ドコモは日本電信電話株式会社の商標または登録商標です。 <br />
            ※auはＫＤＤＩ株式会社の商標または登録商標です。 <br />
            ※ソフトバンクはソフトバンクグループ株式会社の商標または登録商標です。 <br />
            ※ワイモバイルはヤフー株式会社の商標または登録商標です。 <br />
          </TrademarkNoticeGItem>
          */}
          <TrademarkNoticeGItem xs={12} md={8}>
            {/* <div dangerouslySetInnerHTML={{ __html: footnote }} /> */}
            {/* <div dangerouslySetInnerHTML={{ __html: convertToHTML(guideText?.guideTextContent ?? '') }} /> */}
            {convertToReactNode(guideText?.guideTextContent ?? '')}
          </TrademarkNoticeGItem>
          
        </GContainer>
      </BottomActionContainer>
    </Stack>
  )
}


/**
 * テキストをHTML形式に変換する
 * @param plainText 
 * @returns 
 */
// function convertToHTML(plainText?: string){
//   let ret = plainText ?? ''
//   // 改行文字
//   ret = ret.replace(/(\r\n|\r|\n)/gm, "<br />")
//   // リンク
//   ret = ret.replace(/\[([^\]]+)\]\(([^\)]+)\)/gm, '<a href="$2" target="_blank">$1</a>')

//   return ret
// }
function convertToReactNode(plainText?: string){
  const ret = plainText ?? ''

  // リンク形式とそれ以外の形式とで分割して全て抽出
  // const matches = ret.match(/(\[[^\]]+\]\([^\)]+\)|[^\[]*)/g);
  const matches = ret.match(/(\[[^\]]+\]\([^\)]+\)|[^\[]*|\[[^\[]*)/g);
  let match = null

  return (
    <>
      {matches?.map((str) => (
        // リンク表記なら
        (match = str.match(/\[([^\]]+)\]\(([^\)]+)\)/)) ? 
          <Link href={match[2]} target="_blank">
            {match[1]}
            <ItemIconBox>
              <ExternalLinkIcon fontSize="inherit" />
            </ItemIconBox>
          </Link>
        :
          str
          // str.replace(/(\r\n|\r|\n)/gm, "<br />")
      ))}
    </>
  )
}