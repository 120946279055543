import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import {
  identityEmailVerificationUrl,
  identityVerificationUrl,
  loginUrl,
} from '../../../containers/common/constant/appUrl'
import {
  selectIsLoggedIn,
  selectIsVerifiedEmail,
  selectIsVerifiedTel,
} from '../../../containers/common/store/slices/authority'
import { selectSystemControl } from '../../../containers/common/store/slices/systemControl'
import { yesNo } from '../../../containers/common/constant/classification'

interface AuthenticatedGuardProps {
  children: ReactNode
  /**
   * trueの場合、電話番号の検証フラグチェックは行わない
   * 本人確認用の電話番号検証画面の為に用意したフラグ
   * ※検証画面はログインしている必要がある為
   */
  isIgnoreVerifiedTel?: boolean

  isIgnoreVerifiedEmail?: boolean
}

/**
 * ログイン状態をチェックし、
 * ログイン状態なら子コントロール(各ページ)を実行
 * 未ログイン状態ならログイン画面へリダイレクトする
 * ログイン必須画面が未ログインで実行されないようにする部品。
 *
 * 本人確認が未実施の場合、検証コードを確認する画面へ遷移する。
 *
 * ログイン後、本来の遷移先へ遷移出来るようにするために
 * リダイレクト時に現在のロケーション情報をhistoryのstateとして設定している。
 */
export const AuthenticatedGuard = (props: AuthenticatedGuardProps) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const { smsSubscriptionFlag, phoneCertificationFlag } = useSelector(selectSystemControl)
  const location = useLocation()
  
  let toPathname
  if (isLoggedIn) {
    if (!props.isIgnoreVerifiedEmail && !isVerifiedEmail) {
      toPathname = identityEmailVerificationUrl.url()
    } else if (!props.isIgnoreVerifiedTel && !isVerifiedTel && smsSubscriptionFlag === yesNo.yes && phoneCertificationFlag === yesNo.yes) {
      toPathname = identityVerificationUrl.url()
    }
  } else {
    toPathname = loginUrl.url()
  }

  return toPathname ? (
    <Redirect
      to={{
        pathname: toPathname,
        state: { from: location },
      }}
    />
  ) : (
    <>{props.children}</>
  )
}
