import { Control, useFormState  } from "react-hook-form"
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { Grid, FormHelperText } from "@mui/material";
import { getValueByNameStr } from "../../../../utils/objectUtil";
import { NumberBox } from '../inputs/numberBox'
import { translate } from '../../../../i18n'

interface ReservationDetailTempatureProps {
    name: string
    control: Control<Inputs>
}

const ReservationDetailTempature = (props: ReservationDetailTempatureProps) => {
    const { name, control } = props

    const { errors } = useFormState({ control });
    const integerError = getValueByNameStr(errors, `${name}.integer`)
    const fewError = getValueByNameStr(errors, `${name}.few`)

    return (
        <>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <NumberBox
                        name={`${name}.integer`}
                        label={translate('facilityReservationForm.form.medical.bodyTemp')}
                        max={43}
                        min={35}
                        placeholder={'37'}
                        control={control}
                        required
                    />
                </Grid>
                <Grid item xs="auto" style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}>
                    .
                </Grid>
                <Grid item xs={1}>
                    <NumberBox
                        name={`${name}.few`}
                        label={translate('facilityReservationForm.form.medical.bodyTemp')}
                        max={9}
                        min={0}
                        placeholder={'0'}
                        control={control}
                    />
                </Grid>
                <Grid item xs="auto" style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}>
                    ℃
                </Grid>
            </Grid>
            {integerError && <FormHelperText error>{integerError.message}</FormHelperText>}
            {fewError && <FormHelperText error>少数部分は{fewError.message}</FormHelperText>}
        </>
    )

}

export default ReservationDetailTempature;
