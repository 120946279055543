import { useSelector, useDispatch } from 'react-redux'
import { Grid, styled  } from '@mui/material'
import { Redirect } from 'react-router'
import {
  accountCreateFormUrl,
  forgotPasswordUrl,
  identityEmailVerificationUrl,
  identityVerificationUrl,
  rootUrl,
  ssoUrl,
  loginUrl,
} from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/login/loginService'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { Link } from '../components/common/link'
import { translate } from '../../i18n'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
import { IdentityRedirect } from '../components/common/identityRedirect'
import { ButtonM } from '../components/common/buttons/buttonM'
import { ElevatedCard } from '../components/common/elevatedCard'
import { AttentionLabel } from '../components/common/attentionLabel'
import { SuccessStatusLabel } from '../components/common/successStatusLabel'
import { yesNo } from '../../containers/common/constant/classification'
import { setLoginForm, selectLoginError } from '../../containers/common/store/slices/login'
import { selectIsSuccessResetPassword } from '../../containers/common/store/slices/resetPassword'

// メッセージコンテナ
const NoticeGContainer = styled(GItemContainer)({
  justifyContent: 'center',
  paddingBottom: 20,
})

// メインコンテンツコンテナ
const CustomDivContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// メインコンテンツのサブコンテナ
const CustomDivSubContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// SSOボタンコンテナ
const SsoButtonContainer = styled(GContainer)({
  justifyContent: 'center',
  margin: '20px 0px',
})

// SSOボタン
const SsoLoginButton = styled(ButtonM)(({ theme }) => ({
  borderRadius: '3px',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  backgroundColor: '#c8e3f3',
  color: 'black',
  "&:hover": {
    backgroundColor: '#8ecef5',
  },
}))

/**
 * ログイン画面UIコンポーネント
 */
export const Login = () => {
  const { isLoggedIn, formMethods, onSubmit, isVerifiedEmail, isVerifiedTel, from, ssoUseFlag, ssoUseName, smsSubscriptionFlag, phoneCertificationFlag } = useAction()
  const error = useSelector(selectLoginError)
  const isSuccessResetPassword = useSelector(selectIsSuccessResetPassword)
  const dispatch = useDispatch()
  
  if (isLoggedIn) {
    if (isVerifiedEmail && isVerifiedTel) {
      return <Redirect to={from ? from : rootUrl.url()} />
    } else if (!isVerifiedEmail) {
      return <IdentityRedirect from={from} toPathname={identityEmailVerificationUrl.url()} />
    } else if (!isVerifiedTel && smsSubscriptionFlag === yesNo.yes && phoneCertificationFlag === yesNo.yes) {
      return <IdentityRedirect from={from} toPathname={identityVerificationUrl.url()} />
    } else {
      return <Redirect to={from ? from : rootUrl.url()} />
    }
  }

  return (
    <Grid>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <GContainer >
          {/* 左余白 */}
          <GItem md={3}></GItem>

          {/* メインコンテンツ */}
          <GItem xs={12} md={6}>
            <CustomDivContainer>

              {/* タイトル */}
              <ElevatedCard
                title={translate('login.title.login')}
                textAlign='center'
              >
                <GContainer rowSpacing={2}>
  
                  {/* パスワード変更完了メッセージ */}
                  {isSuccessResetPassword &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <SuccessStatusLabel>
                          {translate('resetPassword.text.success')}
                        </SuccessStatusLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }
    
                  {/* エラーメッセージ表示 */}
                  {error &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <AttentionLabel>
                          {translate(error)}
                        </AttentionLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }
    
                  {/* メールアドレス */}
                  <CustomDivSubContainer>
                    {translate('login.label.email')}
                    <TextBox
                      name="email"
                      label={translate('login.label.email')}
                      maxLength={320}
                      textType="email"
                      placeholder={translate('login.placeholder.email')}
                      required
                    />
                  </CustomDivSubContainer>
  
                  {/* パスワード */}
                  <CustomDivSubContainer>
                    {translate('login.label.password')}
                    <TextBox
                      name="password"
                      label={translate('login.label.password')}
                      maxLength={320}
                      textType="password"
                      placeholder={translate('login.placeholder.password')}
                      required
                      requiredOnly={true}
                    />
                  </CustomDivSubContainer>
    
                  {/* パスワードリセット */}
                  <CustomDivSubContainer style={{textAlign: 'center'}}>
                    <Link
                      to={forgotPasswordUrl.url()}
                      onClick={() => dispatch(setLoginForm({}))}
                    >
                      {translate('login.link.forgotPassword')}
                    </Link>
                  </CustomDivSubContainer>
  
                  {/* ログインボタン */}
                  <CustomDivSubContainer>
                    <ButtonM fullWidth type="submit">
                      {translate('login.button.login')}
                    </ButtonM>
                  </CustomDivSubContainer>
                </GContainer>
              </ElevatedCard>
  
              {/* SSOボタン */}
              <SsoButtonContainer>
                { ssoUseFlag === yesNo.yes && (
                  <SsoLoginButton to={`${ssoUrl.url()}?loginRedirectTo=${loginUrl.url()}`} >
                    {translate('login.button.ssoLink', ssoUseName ?? '')}
                  </SsoLoginButton>
                )}
              </SsoButtonContainer>
  
              {/* アカウント作成画面に遷移 */}
                <CustomDivSubContainer style={{textAlign: 'center'}}>
                  <Link
                    to={accountCreateFormUrl.url()}
                    onClick={() => dispatch(setLoginForm({}))}
                  >
                    {translate('login.link.accountCreate')}
                  </Link>
                </CustomDivSubContainer>
            </CustomDivContainer>
          </GItem>

          {/* 右余白 */}
          <GItem md={3}></GItem>
        </GContainer>
      </Form>
    </Grid>
  )
}
