// 会員情報機能で共通するユーザー(保護者)に関する表示部分を定義
// 情報照会、変更内容確認で共有
//

import { Stack, styled } from '@mui/material'
import React, { Fragment, ReactNode } from 'react'
import { getCoveredLabel } from '../../../../containers/common/codeMaster'
import { yesNo } from '../../../../containers/common/constant/classification'
import { translate } from '../../../../i18n'
import { formatYmd } from '../../../../utils/dateUtil'
import { GContainer, GItem, LabelGItemBase, RightGItem, ValueGItemBase } from '../grids'
import { Link } from '../link'
import { SubTitle } from '../subTitle'

interface User {
  // 基本
  name: string
  kana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber?: string
  residenceCategory: string
  residenceCategoryName: string
  relationship?: string

  /** 緊急連絡先１～３ */
  emergencyContacts: {
    name?: string
    kana?: string
    relationship?: string
    company?: string
    tel?: string
    email?: string
  }[]
}

interface UserReference extends User {
  // 減免
  reductionInfos: {
    reductionId: string
    coveredFlag: string
    reductionHistoryFlag: string
    reductionName: string
    reductionStartDate?: Date
    reductionEndDate?: Date
  }[]
}

/** 情報照会時以外で必要なプロパティ */
interface Confirmation {
  user: User
  displayType?: never
}
/** 情報照会時に必要なプロパティ */
interface Reference {
  user: UserReference
  displayType: 'reference'
  onClickReductionHistories: (id: string) => void
}

type UserProps = Confirmation | Reference

const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={4}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={8}>{props.children}</ValueGItemBase>
const AttributeGContainer = styled(GContainer)({
  padding: '0 0 0 3rem',
})

/** 会員情報機能のユーザー(保護者)情報コンテンツ部分 */
export const UserContent = (props: UserProps) => {
  const unSetLabel = translate('system.userContent.label.notSet')

  return (
    <Stack spacing={2}>
      {/* 基本 */}
      <Stack spacing={1}>
        <SubTitle title={translate('system.userContent.title.basic')} />
        <div>
          <AttributeGContainer>
            <LabelGItem>{translate('system.userContent.label.userName')}</LabelGItem>
            <ValueGItem>{props.user.name}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.userNameKana')}</LabelGItem>
            <ValueGItem>{props.user.kana}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.postalCode')}</LabelGItem>
            <ValueGItem>{props.user.postalCode}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.prefecturesAndMunicipalities')}</LabelGItem>
            <ValueGItem>{props.user.address1}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.address')}</LabelGItem>
            <ValueGItem>{props.user.address2}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.buildingNameRoomNumber')}</LabelGItem>
            <ValueGItem>{props.user.buildingNameRoomNumber}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.residenceCategory')}</LabelGItem>
            <ValueGItem>{props.user.residenceCategoryName}</ValueGItem>
            <LabelGItem>{translate('system.userContent.label.relationship')}</LabelGItem>
            <ValueGItem>{props.user.relationship}</ValueGItem>
          </AttributeGContainer>
        </div>
      </Stack>
      {/* 緊急連絡先１～３ */}
      {['emergencyContact1', 'emergencyContact2', 'emergencyContact3'].map((title, index) => {
        const contact = props.user.emergencyContacts[index]
        return (
          <Stack key={title} spacing={1}>
            <SubTitle title={translate(`system.userContent.title.${title}`)} />
            <div>
              <AttributeGContainer>
                <LabelGItem>{translate('system.userContent.label.userName')}</LabelGItem>
                <ValueGItem>{contact.name || unSetLabel}</ValueGItem>
                <LabelGItem>{translate('system.userContent.label.userNameKana')}</LabelGItem>
                <ValueGItem>{contact.kana || unSetLabel}</ValueGItem>
                <LabelGItem>{translate('system.userContent.label.relationship')}</LabelGItem>
                <ValueGItem>{contact.relationship || unSetLabel}</ValueGItem>
                <LabelGItem>{translate('system.userContent.label.company')}</LabelGItem>
                <ValueGItem>{contact.company || unSetLabel}</ValueGItem>
                <LabelGItem>{translate('system.userContent.label.tel')}</LabelGItem>
                <ValueGItem>{contact.tel || unSetLabel}</ValueGItem>
                <LabelGItem>{translate('system.userContent.label.email')}</LabelGItem>
                <ValueGItem>{contact.email || unSetLabel}</ValueGItem>
              </AttributeGContainer>
            </div>
          </Stack>
        )
      })}

      {/* 減免 */}
      <Stack spacing={1}>
        <SubTitle title={translate('system.userContent.title.exempt')} />
        {props.displayType === 'reference' ? (
          <div>
            <AttributeGContainer>
              {props.user.reductionInfos.map((v, index) => {
                return (
                  <Fragment key={index}>
                    <LabelGItem>{v.reductionName}</LabelGItem>
                    <ValueGItem>
                      {getCoveredLabel(v.coveredFlag)}
                      <div>
                        {v.coveredFlag === yesNo.yes && (v.reductionStartDate || v.reductionEndDate) && (
                          <>
                            ({v.reductionStartDate && formatYmd(v.reductionStartDate)}～
                            {v.reductionEndDate && formatYmd(v.reductionEndDate)})
                          </>
                        )}
                      </div>
                    </ValueGItem>
                    {v.reductionHistoryFlag === yesNo.yes && (
                      <RightGItem xs={12}>
                        <Link onClick={() => props.onClickReductionHistories(v.reductionId)}>{`${translate(
                          'system.button.detail'
                        )}>`}</Link>
                      </RightGItem>
                    )}
                  </Fragment>
                )
              })}
            </AttributeGContainer>
          </div>
        ) : (
          <AttributeGContainer>
            <GItem xs={12}>{translate('system.userContent.text.exempt')}</GItem>
          </AttributeGContainer>
        )}
      </Stack>
    </Stack>
  )
}
