import { Box, styled } from '@mui/material'
import { ReactNode } from 'react'

interface CenterTitleProps {
  title: ReactNode
}

const Root = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  letterSpacing: '0.2em',
  color: theme.palette.secondary.main,
  textAlign: 'center',
  paddingTop: '20px',
}))

export const CenterTitle = (props: CenterTitleProps) => {
  return <Root>{props.title}</Root>
}
