import { Stack, styled } from '@mui/material'
import {
  getCancelLimitHolidayOptionLabel,
  getDoNotWaitlist,
  getYesNos,
} from '../../containers/common/codeMaster'
import { availabilityStatus, yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityReservationForm/facilityReservationFormService'
import { translate } from '../../i18n'
import { formatHmtoHmOver, formatYmdWeek, fromApiHms } from '../../utils/dateUtil'
import { formatCancelLimit } from '../../utils/itemFormatUtil'
import { AlertCard } from '../components/common/alertCard'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { Card } from '../components/common/card'
import { GContainer, GItem, LabelGItemBase } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { Form } from '../components/common/inputs/form'
import { TimePicker } from '../components/common/inputs/timePicker'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TextBox } from '../components/common/inputs/textBox'
import { ReservationDetailGItems, SharedInfo } from '../components/common/reservationDetail/reservationDetailGItems'
import { StepBar } from '../components/common/stepBar'
import { TitleLabel } from '../components/common/titleLabel'
import { TransitionCheck } from '../components/common/transitionCheck'
import ReservationDetailChild from '../components/common/reservationDetail/reservationDetailChild'
import ReservationDetailSingleChild from '../components/common/reservationDetail/reservationDetailSingleChild'

const TimePickerWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',
  textIndent: '1em',
})

export const FacilityReservationForm = () => {
  const {
    reserveEntry,
    reservationReference,
    facility,
    isShownPostponeCancelWaitItem,
    formMethods,
    onSubmit,
    goingTimeFileds,
    showMessages,
    decodemst,
    projectMst,
    childrenWatch,
    cancelSendingFlag,
  } = useAction()

  if (reserveEntry == null) {
    return <TransitionCheck check={false} />
  }
  if (facility == null || reservationReference == null) {
    // 初期表示処理完了前
    return null
  }
  const detailSetting = (facility.detailSetting.length === 1 && facility.detailSetting[0].useReasonCode === '0') ? facility.detailSetting[0] : facility.detailSetting.find((v) => v.useReasonCode === reservationReference.useReasonCategory)
  const alertCardConents = []
  const messageContent = []
  const AnnotationBox = styled('div')({
    whiteSpace: 'pre-wrap',
  })

  if (detailSetting) {
    const { cancelLimit, cancelNote, cancelLimitHolidayOption, cancelLimitTime } = detailSetting
    const fromCancelLimitTime = fromApiHms(cancelLimitTime)

    if (cancelLimit != null && fromCancelLimitTime != null) {
      alertCardConents.push(
        <div key="cancelLimit">
          {translate('facilityReservationDetail.card.cancelLimit')}：{formatCancelLimit(cancelLimit, fromCancelLimitTime)}
        </div>
      )
      if (cancelLimitHolidayOption) {
        alertCardConents.push(
          <div key="holidayOption">※{getCancelLimitHolidayOptionLabel(cancelLimitHolidayOption)}</div>
        )
      }
    }
    if (cancelNote) {
      if (alertCardConents.length) {
        alertCardConents.push(<div key="cancelNoteSep">&nbsp;</div>)
      }
      alertCardConents.push(<AnnotationBox key="cancelNote">{cancelNote}</AnnotationBox>)
    }
  }

  if (showMessages !== undefined && showMessages.length) {
    for (const messages of showMessages) {
      messageContent.push(
        <div key="cancelLimit">
          {messages.message}
        </div>
      )
    }
  }

  return (
    <>
    <Form onSubmit={onSubmit} formMethods={formMethods}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <StepBar
              activeStepValue="②"
              stepValueLabels={[
                { value: '①', label: '利用希望日' },
                { value: '②', label: '必要事項入力' },
                { value: '③', label: '入力内容確認' },
                { value: '④', label: '完了' },
              ]}
            />

            <Stack spacing={3}>
              {reserveEntry.usageDatetimes[0].status === availabilityStatus.wait && (
                <AlertCard>
                  <div>キャンセル待ち日時が選択されています</div>
                  {facility.immediatelyReservationFlag === yesNo.yes ? (
                    cancelSendingFlag && (
                      <>
                        <div>&nbsp;</div>
                        <div>
                          申し込みをした日においてキャンセルが発生した場合、その日でキャンセル待ちを申し込んでいる利用者全員に通知メールが届くようになっています。
                        </div>
                        <div>
                          <AnnotationLabel>
                            ※メールが届いてもその直後に他の利用者による予約で枠が埋まった場合、利用申し込みはできません。
                          </AnnotationLabel>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <div>&nbsp;</div>
                      <div>キャンセル待ちの期限（当日・前日・希望日）を備考欄に記入してください。</div>
                      <div>申し込みをした日にキャンセルが発生した場合、改めて施設より連絡があります。</div>
                      <div>尚、キャンセル待ちを取り消す場合は、キャンセル待ち取消を行ってください。</div>
                    </>
                  )}
                </AlertCard>
              )}

              {reserveEntry.usageDatetimes[0].status !== availabilityStatus.wait && !!alertCardConents.length && (
                <AlertCard>
                  <div>キャンセルについて</div>
                  <div>&nbsp;</div>
                  {alertCardConents.map((v) => v)}
                </AlertCard>
              )}

              {showMessages?.length && !!messageContent.length && (
                <AlertCard>
                  <div>未確定の予約と重なる日時に確定済予約を登録します。以下日程の未確定予約の確認をお願いします。</div>
                  <div>&nbsp;</div>
                  {messageContent.map((v) => v)}
                </AlertCard>
              )}

              <div>
                <GContainer spacing={1}>
                  <GItem xs={12}>
                    <TitleLabel>利用希望日時</TitleLabel>
                  </GItem>
                  {reserveEntry.usageDatetimes.map((usageDatetime, index) => (
                    <GItem xs={6} key={index}>
                      <Card>
                        <GContainer columnSpacing={1}>
                          <GItem>
                            <TitleLabel>
                              <AttentionLabel>{formatYmdWeek(usageDatetime.usageDate)}</AttentionLabel>
                            </TitleLabel>
                          </GItem>
                          <GItem>
                            <TitleLabel>
                              <AttentionLabel>
                                {formatHmtoHmOver(
                                  {
                                    from: usageDatetime.useFromDatetime,
                                    to: usageDatetime.useToDatetime,
                                  },
                                  usageDatetime.usageDate
                                )}
                              </AttentionLabel>
                            </TitleLabel>
                          </GItem>
                        </GContainer>
                      </Card>
                    </GItem>
                  ))}
                </GContainer>
              </div>
              {/* 一人と複数人予約でだし分け */}
              {
                reserveEntry.childInfoList.length === 1 
                ? <>
                    <InfoCard>
                      <GContainer>
                        <ReservationDetailGItems reservation={reservationReference} useReason={reservationReference.useReason} />
                      </GContainer>
                    </InfoCard>
                    {
                      reserveEntry.childInfoList.map((childInfo, index) => (
                        <ReservationDetailSingleChild
                          control={formMethods.control}
                          index={index}
                          reservationReference={reservationReference}
                          key={childInfo.childId}
                          decodeOptions={decodemst}
                          facility={facility}
                          childrenWatch={childrenWatch}
                          projectmst={projectMst}
                        />
                      ))
                    }
                  </>
                : <>
                    <InfoCard>
                      <GContainer>
                        <SharedInfo reservation={reservationReference} useReason={reservationReference.useReason} />
                      </GContainer>
                    </InfoCard>
                  </>
              }

              <div>
                <GContainer spacing={2}>
                  {facility.lunchAcceptFlag === yesNo.yes && (
                    <GItem xs={12}>
                      <div>
                        <TitleLabel>
                          <AttentionLabel>*</AttentionLabel>給食注文
                        </TitleLabel>
                      </div>
                      <RadioButtonGroup
                        name="lunchFlag"
                        label="給食注文"
                        row
                        required
                        buttonValueLabels={getYesNos().reverse()}
                      />
                    </GItem>
                  )}
                  {facility.snackAcceptFlag === yesNo.yes && (
                    <GItem xs={12}>
                      <div>
                        <TitleLabel>
                          <AttentionLabel>*</AttentionLabel>おやつ注文
                        </TitleLabel>
                      </div>
                      <RadioButtonGroup
                        name="snackFlag"
                        label="おやつ注文"
                        row
                        required
                        buttonValueLabels={getYesNos().reverse()}
                      />
                    </GItem>
                  )}

                  {goingTimeFileds.some((goingTimeFiled) => !!goingTimeFiled.goingToFacilityDate) && (
                    <GItem xs={12}>
                      <div>
                        <TitleLabel>
                          <AttentionLabel>*</AttentionLabel>登園予定時間と降園予定時間
                        </TitleLabel>
                        {goingTimeFileds.map((goingTimeFiled, index) => {
                          if (
                            !goingTimeFiled.goingToFacilityDate &&
                            !goingTimeFiled.goingToFacilityTime &&
                            !goingTimeFiled.goingHomeDate &&
                            !goingTimeFiled.goingHomeTime
                          )
                            return <div key={goingTimeFiled.id}></div>
                          return (
                            <div key={goingTimeFiled.id}>
                              <GItem xs={12}>
                                <LabelGItemBase>
                                  {formatYmdWeek(reserveEntry.usageDatetimes[index].usageDate)}
                                  {'　'}
                                  {formatHmtoHmOver(
                                    {
                                      from: reserveEntry.usageDatetimes[index].useFromDatetime,
                                      to: reserveEntry.usageDatetimes[index].useToDatetime,
                                    },
                                    reserveEntry.usageDatetimes[index].usageDate
                                  )}
                                </LabelGItemBase>
                              </GItem>
                              <GItem xs={12}>
                                <TimePickerWrap>
                                  <LabelGItemBase>登園予定時間</LabelGItemBase>
                                  <TimePicker name={`goingTimes.${index}.goingToFacilityTime`} />
                                </TimePickerWrap>
                              </GItem>
                              <GItem xs={12}>
                                <TimePickerWrap>
                                  <LabelGItemBase>降園予定時間</LabelGItemBase>
                                  <TimePicker name={`goingTimes[${index}].goingHomeTime`} />
                                </TimePickerWrap>
                              </GItem>
                            </div>
                          )
                        })}
                      </div>
                    </GItem>
                  )}
                  {isShownPostponeCancelWaitItem && (
                    <>
                      <GItem xs={12}>
                        <div>
                          <TitleLabel>
                            <AttentionLabel>*</AttentionLabel>予約が取れなかった場合
                          </TitleLabel>
                        </div>
                        <RadioButtonGroup
                          name="postponeCancelWaitFlag"
                          label="予約が取れなかった場合"
                          row
                          required
                          buttonValueLabels={getDoNotWaitlist().reverse()}
                        />
                      </GItem>
                      <GItem xs={12}>
                        <AnnotationBox>
                          <AttentionLabel>{translate('facilityReservationForm.text.attentionMessage')}</AttentionLabel>
                        </AnnotationBox>
                      </GItem>
                    </>
                  )}

                  {/* 複数用 */}
                  {reserveEntry.childInfoList.length > 1 && reserveEntry.childInfoList.map((childInfo, index) => (
                    <GItem xs={12} mt={2} marginBottom={2} key={childInfo.childId}>
                      <ReservationDetailChild
                        control={formMethods.control}
                        index={index}
                        reservationReference={reservationReference}
                        decodeOptions={decodemst}
                        facility={facility}
                        childrenWatch={childrenWatch}
                        projectmst={projectMst}
                      />
                    </GItem>
                  ))}
                  <GItem xs={12} marginBottom={3}>
                    <div>備考</div>
                    <TextBox name="note" label="備考" maxLength={200} multiline rows={4} fullWidth />
                  </GItem>
                </GContainer>
              </div>
            </Stack>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>

        {/* 画面下部ボタングループ */}
        <BottomButtonGroup>
          <ButtonL type="submit" fullWidth>
            申込内容の確認
          </ButtonL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
    </>
  )
}
