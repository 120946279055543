import { executeGet } from '../common/apiCaller'
import {
  GetInterviewReservationsWeekCitizenDto,
  GetInterviewReservationsWeekCitizenInputDto,
} from '../dto/interviewReservationsWeekCitizenDto'

const apiName = 'citizen'
const apiPath = '/facilities'

export const executeGetInterviewReservationsWeekCitizen = (
  id: string,
  input?: GetInterviewReservationsWeekCitizenInputDto
) => {
  return executeGet<GetInterviewReservationsWeekCitizenDto[]>(apiName, `${apiPath}/${id}/interviews/week`, input)
}
