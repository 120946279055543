import { Box, Stack } from '@mui/material'
import { getCancelReasonLabel, getInterviewReservationDeleteSteps } from '../../containers/common/codeMaster'
import { interviewReservationDeleteStep, interviewStatus } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/interviewReservationDeleteConfirmation/interviewReservationDeleteConfirmationService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { InterviewDetailContent } from '../components/common/reservationDetail/interviewDetailContent'
import { LabelGItem, ValueGItem } from '../components/common/reservationDetail/reservationDetailParts'
import { StepBar } from '../components/common/stepBar'
import { TransitionCheck } from '../components/common/transitionCheck'
import { Dialog } from '../components/common/dialog'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { ButtonS } from '../components/common/buttons/buttonS'

export const InterviewReservationDeleteConfirmation = () => {
  const {
    deleteEntry,
    deleteResultCode,
    decide,
    isConfirmDialogOpen,
    onConfirmDialogClose,
    onConfirmDialogNo,
    onConfirmDialogYes,
    sameAddChildInfo
  } = useAction()

  if (deleteEntry == null) {
    return <TransitionCheck check={false} />
  }
  return (
    <Stack spacing={2}>
      <Dialog
        isOpen={isConfirmDialogOpen}
        onClose={onConfirmDialogClose}
        isShownCloseButton={false}
        buttons={
          <>
            <ButtonOutlinedS onClick={onConfirmDialogNo}>{translate('interviewReservationDeleteConfirmation.button.no')}</ButtonOutlinedS>
            <ButtonS onClick={onConfirmDialogYes}>{translate('interviewReservationDeleteConfirmation.button.yes')}</ButtonS>
          </>
        }
        maxWidth={'xs'}
        fullWidth
      >
        <>
          {translate('interviewReservationDeleteConfirmation.text.sameAddChildDialog')}
          <ul>
            {sameAddChildInfo && sameAddChildInfo.map((child, idx) => {
              return (
                <li key={idx}>{child.childName}</li>
              )
            })}
          </ul>
        </>
      </Dialog>

      <Box>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Stack spacing={2}>
              {deleteEntry.status !== interviewStatus.wait && (
                <StepBar
                  activeStepValue={interviewReservationDeleteStep.step2}
                  stepValueLabels={getInterviewReservationDeleteSteps()}
                />
              )}

              <InfoCard>
                <GContainer>
                  {deleteEntry.status !== interviewStatus.wait && (
                    <>
                      <LabelGItem>{translate('interviewReservationDeleteConfirmation.label.cancelReason')}</LabelGItem>
                      <ValueGItem>{getCancelReasonLabel(deleteEntry.cancelReason)}</ValueGItem>
                      <RowSpacerGItem />
                    </>
                  )}

                  <InterviewDetailContent interview={deleteEntry} displayType="delete" />
                </GContainer>
              </InfoCard>

              {!!deleteResultCode && (
                <ErrorMessageCard
                  messages={[
                    translate(`interviewReservationDeleteConfirmation.error.deleteResultCode${deleteResultCode}`),
                  ]}
                />
              )}
            </Stack>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
      </Box>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          {translate('interviewReservationDeleteConfirmation.button.decision')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
