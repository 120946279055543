// 区分値定数の定義
//

/**
 * 有り無し
 * フラグの判定用にも使用する。
 */
export const yesNo = {
  yes: '1',
  no: '0',
} as const

/**
 * キャンセル待ちしない キャンセル待ちする
 * フラグの判定用にも使用する。
 */
export const doNotWaitlist = {
  do: '1',
  not: '0',
} as const

/** 年齢 */
export const age = {
  class0: '0',
  class1: '1',
  class2: '2',
  class3: '3',
  class4: '4',
  class5: '5',
  class6: '6',
  class7: '7',
} as const

/** 年齢（2桁表記） */
export const ageTwoDigits = {
  class0: '00',
  class1: '01',
  class2: '02',
  class3: '03',
  class4: '04',
  class5: '05',
  class6: '06',
  class7: '07',
} as const

/** 利用目的（施設検索） */
export const purposeOfUse = {
  irregular: '1',
  emergency: '2',
  refresh: '3',
} as const

/** 利用目的 */
export const useReasonCategory = {
  irregular: '1',
  emergency_flag: '2',
  refresh_flag: '3',
} as const

/** 施設検索並び順 */
export const facilitySearchSortOrder = {
  currentLocation: '1',
  facilityName: '2',
} as const

/** HTTPステータスコード */
export const httpStatusCode = {
  ok: 200,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  methodNotAllowed: 405,
  requestTimeout: 408,
  conflict: 409,
  internalServerError: 500,
  serviceUnavailable: 503,
} as const

/** 利用予約情報ステータス */
export const reservationStatus = {
  /**未確定 */
  notFixed: '0',
  /**確定済み */
  fixed: '1',
  /**利用済み */
  used: '2',
  /**キャンセル済み */
  canceled: '3',
  /**キャンセル待ち */
  wait: '4',
  /**キャンセル待ち取り消し */
  canceledWait: '5',
  /**見送り */
  rejected: '6',
  /**未利用 */
  unused: '7',
} as const

/** 面談予約情報ステータス */
export const interviewStatus = {
  notFixed: '0',
  fixed: '1',
  interviewed: '2',
  canceled: '3',
  wait: '4',
  canceledWait: '5',
} as const

/** 面談予約利用許可フラグ */
export const permitFlag = {
  shelved: '0',
  permitted: '1',
  notImplemented: '2',
} as const

/** 性別 */
export const gender = {
  male: '1',
  female: '2',
} as const
/** 在住種別 */
export const residenceCategory = {
  /** 市内・区内 */
  inside: '1',
  /** 市外・区外 */
  outsid: '2',
  /** その他 */
  other: '3',
} as const
/** 対象 */
export const covered = {
  /** 対象 */
  covered: '1',
  /** 対象外 */
  notCovered: '0',
} as const

/** キャンセル理由 */
export const cancelReason = {
  inconvenient: '1',
  poorPhysicalCondition: '2',
  other: '3',
} as const

/** アカウント作成ステップ */
export const accountCreateStep = {
  step1: '①',
  step2: '②',
  step3: '③',
} as const

/** 面談予約ステップ */
export const interviewReservationStep = {
  step1: '①',
  step2: '②',
  step3: '③',
} as const

/** 面談予約ステップ */
export const interviewReservationStep2 = {
  step1: '①',
  step2: '②',
  step3: '③',
  step4: '④',
} as const

/** 面談予約キャンセルステップ */
export const interviewReservationDeleteStep = {
  step1: '①',
  step2: '②',
} as const

export const facilityReservationStep = {
  step1: '①',
  step2: '②',
  step3: '③',
  step4: '④',
} as const

/** 施設予約キャンセルステップ */
export const facilityReservationDeleteStep = {
  step1: '①',
  step2: '②',
} as const

/** 空き状況ステータス */
export const availabilityStatus = {
  /** 対象外 */
  outside: '0',
  /** 空き無し */
  noSpace: '1',
  /** 空きあり */
  vacant: '2',
  /** キャンセル待ち可 */
  wait: '3',
  /** 予約済(確定済み) */
  doneFixed: '4',
  /** 予約済(キャンセル待ち) */
  doneWait: '5',
  /** 予約済(未確定) */
  doneNotFixed: '6',
} as const

/** 受付時間単位パターン */
export const receptionTimePattern = {
  quarterOfHour: '1',
  halfOfHour: '2',
  hour: '3',
  amPm: '4',
  allDay: '5',
} as const

interface reservableUnitPatterMinuteType {
  readonly [key: string]: number | null
}
/**  予約可能単位の時間（分）*/
export const reservableUnitPatterMinutes: reservableUnitPatterMinuteType = {
  /**15分 */
  [receptionTimePattern.quarterOfHour]: 15,
  /** 30分 */
  [receptionTimePattern.halfOfHour]: 30,
  /** 1時間 */
  [receptionTimePattern.hour]: 60,
  /** AMPM */
  [receptionTimePattern.amPm]: null,
  /** 1日 */
  [receptionTimePattern.allDay]: null,
} as const

/** 利用実績APIのステータス */
export const usageRecordApiStatus = {
  unused: '0',
  used: '1',
  canceled: '2',
} as const

/** 血液型 */
export const bloodType = {
  typeUnselected: '-',
  typeA: 'A',
  typeB: 'B',
  typeO: 'O',
  typeAB: 'AB',
} as const

/** アカウント情報変更時の対象を識別するためのカテゴリー */
export const accountChangeVerificationCategory = {
  email: '1',
  tel: '2',
  password: '3',
} as const

/** 事前面談予約受付可否 */
export const preinterviewAccept = {
  /** 電話のみ */
  tel: '0',
  /** 電話とWeb */
  telWeb: '1',
  /** Webのみ */
  web: '2',
  /** 掲載のみ */
  postOnly: '3',
} as const

/** Web予約可否 */
export const webReservation = {
  /** Web予約可能 */
  acceptWebReservation: '1',
} as const

/** 可・不可 */
export const available = {
  /** 可 */
  available: '1',
  /** 不可 */
  notAvailable: '0',
} as const

/** 予約変更制限（面談予約） */
export const interviewChangeRestriction = {
  /** 日時共に変更可 */
  allowChangeDatetime: '1',
  /** 時間のみ変更可 */
  allowChangeTimeOnly: '2',
  /** 日時共に変更不可 */
  notAllowChangeDatetime: '3',
} as const

/** 予約変更制限（利用予約） */
export const reservationChangeRestriction = {
  /** 日時共に変更可 */
  allowChangeDatetime: '1',
  /** 時間のみ変更可 */
  allowChangeTimeOnly: '2',
  /** 日時共に変更不可 */
  notAllowChangeDatetime: '3',
} as const

/** 案内文マスタのID */
export const guidTextMstId = {
  /** 退会 */
  unsubscribe: 1,
  /** お子さま利用終了 */
  childUseEnd: 2,
}

/** 利用予約のステータスの検索条件のID */
export const reservationStatusSearchCondition = {
  /** 確定済のみ */
  confirmedOnly: '1',
  /** すべての予約 */
  all: '2',
} as const

/** 利用履歴のステータスの検索条件のID */
export const usageHistoryStatusSearchCondition = {
  /** 利用済のみ */
  usedOnly: '1',
  /** すべての予約 */
  all: '2',
} as const

/** 利用履歴のステータスの検索条件のID */
export const availabilityOfApplication = {
  /** 利用済のみ */
  apply: '1',
  /** すべての予約 */
  notApply: '0',
} as const

/** 利用目的別コード番号 */
export const useReasonCode = {
  common: '0',
  irregular: '1',
  emg: '2',
  refresh: '3',
} as const

/** 提供証明書機能利用ステータス */
export const provisionCertificateFeatureStatus = {
  /** 利用しない */
  notUsed: '1',
  /** 施設管理者のみ利用する */
  onlyManagement: '2',
  /** 施設管理者と利用者で利用する */
  all: '3',
}

export const paymentStatus = {
  /** 未徴収 */
  notCollected: '1',
  /** 徴収済み */
  collected: '2',
}

export const Flag = {
  OFF: '0',
  ON: '1'
} as const

export const mailType = {
  immediatelyCancelVacant: 13,
} as const;
