import styled from '@emotion/styled'
import { FC } from 'react'
import { GetFacilityDto } from '../../../dataAccess/webApi/dto/facilitiesDto'
import { NullPropsToUndefinedType } from '../../../utils/typeUtil'
import { InlineFlexbox } from '../common/flexboxes'
import { TopGItem } from '../common/grids'
import { MoneyIcon } from '../common/icons/moneyIcon'

const ItemIcon = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 24,
  fontSize: '1.4rem',
  marginTop: '0.15rem',
  marginRight: '4px',
}))

const FeeText = styled('p')({
  whiteSpace: 'pre-wrap',
  margin: 0,
})

type FeeNotionProps = {
  facility: NullPropsToUndefinedType<GetFacilityDto>
}

export const FeeNotion: FC<FeeNotionProps> = ({ facility }) => {
  if (!facility.feeNote) return <></>

  return (
    <>
      {/* TODO: 別タスク(HOIKU-1084)にて表示切り替え処理を追加する */}
      <TopGItem xs={12}>
        <ItemIcon>
          <MoneyIcon fontSize="inherit" />
        </ItemIcon>
        <FeeText>{facility.feeNote}</FeeText>
      </TopGItem>
    </>
  )
}
