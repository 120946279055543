// ユーザー(保護者)情報に関する共通処理を定義
import { executeGetUser } from '../../dataAccess/webApi/dao/userDao'
import { fromApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
/** ユーザー(保護者)情報 */
export interface User {
  // 基本
  name: string
  kana: string
  tel: string
  email: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber?: string
  residenceCategory: string
  residenceCategoryName: string
  relationship?: string
  interviewPermitCount: number

  // 減免
  reductionInfos: {
    reductionId: string
    coveredFlag: string
    reductionHistoryFlag: string
    reductionName: string
    reductionStaretDate?: Date
    reductionEndDate?: Date
  }[]

  /** 緊急連絡先１～３ */
  emergencyContacts: {
    name?: string
    kana?: string
    relationship?: string
    company?: string
    tel?: string
    email?: string
  }[]
  /** 排他チェック用の更新日時 */
  updateDatetime: string
}

/**
 * @returns ユーザー(保護者)情報
 */
export const getUser = async (): Promise<User> => {
  const response = await executeGetUser()
  const user = response.result

  const reductionInfos = user.reductionInfos.map((v) =>
    nullPropsToUndefined({
      reductionId: v.reductionId,
      coveredFlag: v.coveredFlag,
      reductionHistoryFlag: v.reductionHistoryFlag,
      reductionName: v.reductionName,
      reductionStartDate: fromApiYmd(v.reductionStartDate),
      reductionEndDate: fromApiYmd(v.reductionEndDate),
    })
  )
  const emergencyContacts = [
    {
      name: user.emergencyContactName1,
      kana: user.emergencyContactKana1,
      relationship: user.emergencyContactRelationship1,
      company: user.emergencyContactCompany1,
      tel: user.emergencyContactTel1,
      email: user.emergencyContactEmail1,
    },
    {
      name: user.emergencyContactName2,
      kana: user.emergencyContactKana2,
      relationship: user.emergencyContactRelationship2,
      company: user.emergencyContactCompany2,
      tel: user.emergencyContactTel2,
      email: user.emergencyContactEmail2,
    },
    {
      name: user.emergencyContactName3,
      kana: user.emergencyContactKana3,
      relationship: user.emergencyContactRelationship3,
      company: user.emergencyContactCompany3,
      tel: user.emergencyContactTel3,
      email: user.emergencyContactEmail3,
    },
  ].map((v) => nullPropsToUndefined(v))

  return nullPropsToUndefined({
    // 基本
    name: user.name,
    kana: user.kana,
    tel: user.tel,
    email: user.email,
    postalCode: user.postalCode,
    address1: user.address1,
    address2: user.address2,
    buildingNameRoomNumber: user.buildingNameRoomNumber,
    residenceCategory: user.residenceCategory,
    residenceCategoryName: user.residenceCategoryName,
    relationship: user.relationship,
    interviewPermitCount: user.interviewPermitCount,
    // 減免
    reductionInfos: reductionInfos,
    // 緊急連絡先１～３
    emergencyContacts,
    updateDatetime: user.updateDatetime,
  })
}
