import { Box, Stack } from '@mui/material'
import { ReactNode } from 'react'
import {
  getUsageRecordApiStatusLabel,
  getYesNoLabel,
} from '../../containers/common/codeMaster'
import { purposeOfUse as purposeOfUseValue, usageRecordApiStatus, yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/usageHistoryDetail/usageHistoryDetailService'
import { translate } from '../../i18n'
import { formatHmtoHmOver, formatYmdWeek, toApiHms } from '../../utils/dateUtil'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ButtonL } from '../components/common/buttons/buttonL'
import { GContainer, GItem, LabelGItemBase, RowSpacerGItem, ValueGItemBase } from '../components/common/grids'
import { formatNumberYenString } from '../../utils/stringUtil'

import { InfoCard } from '../components/common/infoCard'

/** 利用済状態 */
const STATUS_USED = '1'
const USAGE_RECEIPT_VIEW_METHOD_ALL = '3'

const LabelItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
const ValueItem = (props: { children: ReactNode, hideColon?: boolean }) => <ValueGItemBase xs={7} hideColon={props.hideColon}>{props.children}</ValueGItemBase>

const getYesNoValue = (acceptFlag: string, count: number | undefined) => {
  let value

  if (count == null) {
    value = acceptFlag
  } else {
    if (count === 0) {
      value = yesNo.no
    } else if (count > 0) {
      value = yesNo.yes
    } else {
      value = yesNo.no
    }
  }
  return value
}

export const UsageHistoryDetail = () => {
  const  {
    usageDetail,
    facilityInfo,
    goReservation,
    useReason,
    goReceiptView,
  } = useAction();

  if (usageDetail == null) {
    return null
  }
  let usageMinute
  if (usageDetail.usageMinute) {
    usageMinute = (
      <>
        <LabelItem>{translate('usageHistoryDetail.label.couponUsageMinute')}</LabelItem>
        <ValueItem>{usageDetail.usageMinute}</ValueItem>
      </>
    )
  }
  return (
    <Stack spacing={2}>
      <Box>
        <GContainer rowSpacing={2}>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <InfoCard>
              <GContainer>
                <LabelItem>{translate('usageHistoryDetail.label.facilityName')}</LabelItem>
                <ValueItem>{usageDetail.facilityName}</ValueItem>
                <LabelItem>{translate('usageHistoryDetail.label.usageDatetime')}</LabelItem>
                <ValueItem>
                  <Stack>
                    <div>{formatYmdWeek(usageDetail.usageDate)}</div>
                    <div>
                      {formatHmtoHmOver(
                        { from: usageDetail.useFromDatetime, to: usageDetail.useToDatetime },
                        usageDetail.usageDate
                      )}
                    </div>
                  </Stack>
                </ValueItem>

                {usageDetail.goingToFacilityTime && usageDetail.goingHomeTime && (
                  <>
                    <LabelItem>{translate('usageHistoryDetail.label.goingToFacilityTime')}</LabelItem>
                    <ValueItem>{usageDetail.goingToFacilityTime}</ValueItem>
                    <LabelItem>{translate('usageHistoryDetail.label.goingHomeTime')}</LabelItem>
                    <ValueItem>{usageDetail.goingHomeTime}</ValueItem>
                  </>
                )}

                <LabelItem>{translate('usageHistoryDetail.label.status')}</LabelItem>
                <ValueItem>{getUsageRecordApiStatusLabel(usageDetail.status)}</ValueItem>
                {usageMinute}
                {usageDetail.amount != null && (
                  <>
                    <LabelItem>{translate('usageHistoryDetail.label.amount')}</LabelItem>
                    <ValueItem>{formatNumberYenString(usageDetail.amount)}</ValueItem>
                  </>
                )}
                <RowSpacerGItem />

                <LabelItem>{translate('usageHistoryDetail.label.userName')}</LabelItem>
                <ValueItem>{usageDetail.name}</ValueItem>
                <LabelItem>{translate('usageHistoryDetail.label.userNameKana')}</LabelItem>
                <ValueItem>{usageDetail.kana}</ValueItem>
                <LabelItem>{translate('usageHistoryDetail.label.tel')}</LabelItem>
                <ValueItem>{usageDetail.tel}</ValueItem>
                <LabelItem>{translate('usageHistoryDetail.label.email')}</LabelItem>
                <ValueItem>{usageDetail.email}</ValueItem>
                <RowSpacerGItem />

                <LabelItem>{translate('usageHistoryDetail.label.childName')}</LabelItem>
                <ValueItem>{usageDetail.childName}</ValueItem>
                <LabelItem>{translate('usageHistoryDetail.label.childNameKana')}</LabelItem>
                <ValueItem>{usageDetail.childKana}</ValueItem>
                
                {/* 利用目的の表示 */}
                {usageDetail.useReasonCategory && (
                  <>
                    <LabelItem>{translate('usageHistoryDetail.label.useReasonCategory')}</LabelItem>
                    <ValueItem>
                      {
                        usageDetail.useReasonCategory === purposeOfUseValue.irregular && useReason?.displayNameForCitizenIrregular ||
                        usageDetail.useReasonCategory === purposeOfUseValue.emergency && useReason?.displayNameForCitizenEmg ||
                        usageDetail.useReasonCategory === purposeOfUseValue.refresh && useReason?.displayNameForCitizenRefresh
                      }
                    </ValueItem>
                  </>
                )}
                
                {/* 詳細事由の表示：以下の三つのコンポーネントのうちいずれかが表示される（もしくは一つも表示されない） */}
                {usageDetail.useReasonDetailCategory && (
                  usageDetail.useReasonCategory === purposeOfUseValue.irregular && useReason?.detailsIrregular && (
                    <>
                      <LabelItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelItem>
                      <ValueItem>
                        {useReason?.detailsIrregular[Number(usageDetail.useReasonDetailCategory) - 1].name}
                      </ValueItem>
                    </>
                  ) || 
                  usageDetail.useReasonCategory === purposeOfUseValue.emergency && useReason?.detailsEmg && (
                    <>
                      <LabelItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelItem>
                      <ValueItem>
                        {useReason?.detailsEmg[Number(usageDetail.useReasonDetailCategory) - 1].name}
                      </ValueItem>
                    </>
                  ) || 
                  usageDetail.useReasonCategory === purposeOfUseValue.refresh && useReason?.detailsRefresh && (
                    <>
                      <LabelItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelItem>
                      <ValueItem>
                        {useReason?.detailsRefresh[Number(usageDetail.useReasonDetailCategory) - 1].name}
                      </ValueItem>
                    </>
                  )
                )}
                
                {/* 詳細事由の入力欄の内容を表示 */}
                {usageDetail.useReasonDetailDescription && (
                  <>
                    <LabelItem/>
                    <ValueItem hideColon>
                      {usageDetail.useReasonDetailDescription}
                    </ValueItem>
                  </>
                )}
                
                {facilityInfo?.lunchAcceptFlag === yesNo.yes && (
                  <>
                    <LabelItem>{translate('usageHistoryDetail.label.lunch')}</LabelItem>
                    <ValueItem>{getYesNoLabel(getYesNoValue(usageDetail.lunchFlag, usageDetail.lunchCount))}</ValueItem>
                  </>
                )}
                {facilityInfo?.snackAcceptFlag === yesNo.yes && (
                  <>
                    <LabelItem>{translate('usageHistoryDetail.label.snack')}</LabelItem>
                    <ValueItem>{getYesNoLabel(getYesNoValue(usageDetail.snackFlag, usageDetail.snackCount))}</ValueItem>
                  </>
                )}
                <LabelItem>{translate('usageHistoryDetail.label.note')}</LabelItem>
                <ValueItem>{usageDetail.citizenNote}</ValueItem>
                {usageDetail.status === usageRecordApiStatus.canceled && usageDetail?.reservationCancelDatetime && (
                  <>
                    <RowSpacerGItem />
                    <LabelItem>{translate('usageHistoryDetail.label.reservationCancelDatetime')}</LabelItem>
                    <ValueItem>
                      <Stack>
                        <div>{formatYmdWeek(usageDetail.reservationCancelDatetime)}</div>
                        <div>{toApiHms(usageDetail.reservationCancelDatetime)}</div>
                      </Stack>
                    </ValueItem>
                  </>
                )}
              </GContainer>
            </InfoCard>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
      </Box>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={goReservation}>
          {translate('usageHistoryDetail.button.reservation')}
        </ButtonOutlinedWarningL>
        {
          usageDetail.status === STATUS_USED
          && usageDetail.usageReceiptViewMethod === USAGE_RECEIPT_VIEW_METHOD_ALL
          && (
            <ButtonL fullWidth onClick={goReceiptView}>
              領収証を発行
            </ButtonL>
          )
        }
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
