import { useCallback, FC } from 'react'
import { Control, useController } from 'react-hook-form'
import { TextFieldProps, styled, FormHelperText } from '@mui/material'
import { LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { getLocale } from '../../../../i18n'
import {
  fromApiHms,
  INVALID_DATE_STRING,
  toApiHms,
  isValidDate
} from '../../../../utils/dateUtil'
import { getValueByNameStr } from '../../../../utils/objectUtil'
import { TextBoxBaseStyled } from './textBoxBaseStyled'

const ErrorText = styled(FormHelperText)({
  color: '#c53030',
})

type TimePickerProps = Pick<TextFieldProps, 'fullWidth' | 'required' | 'disabled'> & {
  name: string
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>

  /** 秒入力を有効にする場合true */
  enabledSecondsInput?: boolean
}

const InputField = styled(TextBoxBaseStyled)({
  '& .MuiInputAdornment-positionEnd': {
    marginRight: 10,
    marginLeft: 0,
  },
  '& .MuiOutlinedInput-input': {
    paddingRight: 0,
  },
  '& legend': {
    width: 0,
  },
})

export const TimePicker: FC<TimePickerProps> = ({
  name,
  required,
  disabled,
  fullWidth,
  enabledSecondsInput,
  control
}) => {
  const {
    field: { ref, value, onChange, onBlur },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: '入力してください',
      },
      validate: {
        invalidDate: (value?: string) => {
          return value !== INVALID_DATE_STRING || '有効な時刻形式で入力してください'
        },
      },
    },
    defaultValue: null,
    control,
  })

  const onChangeHandler = useCallback(
    (date: Date | null, keyboardInputValue?: string) => {
      if (date == null) {
        onChange(null)
      } else if (keyboardInputValue && keyboardInputValue.length < 5) {
        // 14:1 は無効にする。
        // useWatchで値の変更を監視する処理で
        // 14:15 のように入力中でも値が確定してしまうのを防ぐ為
        onChange(INVALID_DATE_STRING)
      } else if (isValidDate(date)) {
        onChange(toApiHms(date))
      } else {
        onChange(INVALID_DATE_STRING)
      }
    },
    [onChange]
  )
  let timeValue
  if (value === INVALID_DATE_STRING) {
    timeValue = value
  } else {
    timeValue = fromApiHms(value) ?? null
  }
  const locale = getLocale()
  const error = getValueByNameStr(errors, name)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DesktopTimePicker
        inputRef={ref}
        // HH:mm:ssの文字列では反映されない為、Dateに変換して設定する。
        // また、undefinedではnew Date()の値が初期表示されてしまう為、nullを設定する
        value={timeValue}
        {...(enabledSecondsInput && {
          views: ['hours', 'minutes', 'seconds'],
          inputFormat: 'HH:mm:ss',
          mask: '__:__:__',
        })}
        ampm={false}
        onChange={onChangeHandler}
        disabled={disabled}
        shouldDisableTime={(timeValue, clockType) => {
          if (clockType === 'minutes' && timeValue % 3) return true
          return false
        }}
        renderInput={(params) => (
          <>
            <InputField
              {...params}
              name={name}
              fullWidth={fullWidth}
              onBlur={onBlur}
              error={!!error}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // Enterでsubmitされないようにする措置
                  event.preventDefault()
                }
              }}
            />
            {error && <ErrorText>{error.message}</ErrorText>}
          </>
        )}
      />
    </LocalizationProvider>
  )
}
