import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface LoginEntry {
  email?: string
  password?: string
  error?: string
}

interface LoginState {
  entry?: LoginEntry
}

const initialState: LoginState = {}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginForm: (state, action: PayloadAction<LoginEntry>) => {
      state.entry = action.payload
    },
    clearLoginForm: (state) => {
      delete state.entry
    },
  },
})
export const { setLoginForm, clearLoginForm } = loginSlice.actions
export const selectLogin = (state: RootState) => state.login.entry
export const selectLoginError = (state: RootState) => state.login.entry?.error
export default loginSlice.reducer
