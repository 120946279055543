import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { CognitoUser } from 'amazon-cognito-identity-js';

interface CognitoUserState {
  entry?: CognitoUser
}

const initialState: CognitoUserState = {}

export const cognitoUserSlice = createSlice({
  name: 'cognitoUser',
  initialState,
  reducers: {
    setCognitoUser: (state, action: PayloadAction<CognitoUser>) => {
      state.entry = action.payload
    },
    clearCognitoUser: (state) => {
      delete state.entry
    },
  },
})

export const { setCognitoUser, clearCognitoUser } = cognitoUserSlice.actions
export const selectCognitoUser = (state: RootState) => state.cognitoUser.entry
export default cognitoUserSlice.reducer
