import { executeGet, executeGetNoResultError, executePost, executePut, executeDelete } from '../common/apiCaller'
import {
  GetInterviewDto,
  GetInterviewsDto,
  GetSameAddChildInterviewsDto,
  GetInterviewsInputDto,
  PostInterviewInputDto,
  PutInterviewInputDto,
  DeleteInterviewInputDto,
} from '../dto/interviewsDto'

const apiNameCitizen = 'citizen'
const apiPath = '/interviews'

export const executeGetInterviews = async (input: GetInterviewsInputDto) => {
  return executeGet<GetInterviewsDto[]>(apiNameCitizen, apiPath, input)
}

export const executeGetSameAddChildInterviews = async (id: string) => {
  return executeGet<GetSameAddChildInterviewsDto[]>(apiNameCitizen, `${apiPath}/same-add-child/${id}`)
}

export const executeGetInterview = (id: string) => {
  return executeGetNoResultError<GetInterviewDto>(apiNameCitizen, `${apiPath}/${id}`)
}

export const executePostInterview = (input: PostInterviewInputDto) => {
  return executePost<null>(apiNameCitizen, apiPath, input)
}

export const executePutInterview = (id: string, input: PutInterviewInputDto) => {
  return executePut<null>(apiNameCitizen, `${apiPath}/${id}`, input)
}

export const executeDeleteInterview = (id: string, input: DeleteInterviewInputDto) => {
  return executeDelete<null>(apiNameCitizen, `${apiPath}/${id}`, input)
}