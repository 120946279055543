import { FC } from 'react'
import { styled, TableCell, TableRow } from '@mui/material'
import { formatNumberComma } from '../../../../utils/stringUtil'
import { InputInfoType } from '../../../../containers/receiptView/receiptViewService'

type DefaultFeeList = {
    receiptData: InputInfoType
}

const TableCellLeft = styled(TableCell)({
  borderBottom: 'none',
  textAlign: 'left',
  fontSize: '12px',
  minWidth: '70px',
  margin: 0,
  padding: '2px 0px',
})

const TableCellRight = styled(TableCell)({
  borderBottom: 'none',
  textAlign: 'right',
  fontSize: '12px',
  minWidth: '70px',
  margin: 0,
  padding: '2px 0px',
})

export const DefaultFeeList: FC<DefaultFeeList> = ({ receiptData }) => {
  const cells = []
  let cell
  if (receiptData.usageFee !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>一時保育利用料</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.usageFee)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.useCouponFee !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>はじめてのおあずかり券</TableCellLeft>
        <TableCellRight>-{formatNumberComma(receiptData.useCouponFee)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.extentionFee !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>時間外料金</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.extentionFee)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.nightlyFee !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>夜間料金</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.nightlyFee)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.lunchFee !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>給食代</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.lunchFee)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.snackFee !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>おやつ代</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.snackFee)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.otherFee1 !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>{receiptData.otherFeeDisplayName1}</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.otherFee1)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.otherFee2 !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>{receiptData.otherFeeDisplayName2}</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.otherFee2)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.otherFee3 !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>{receiptData.otherFeeDisplayName3}</TableCellLeft>
        <TableCellRight>{formatNumberComma(receiptData.otherFee3)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  if (receiptData.reduction !== 0) {
    cell = (
      <TableRow>
        <TableCellLeft>減額</TableCellLeft>
        <TableCellRight>-{formatNumberComma(receiptData.reduction)}円</TableCellRight>
      </TableRow>
    )
    cells.push(cell)
  }
  return <>{cells}</>
}