import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { memberUrl, rootUrl } from '../common/constant/appUrl'
import { selectIsLoggedIn } from '../common/store/slices/authority'
import {
  executePostGuideText,
  guideTextId,
} from '../../dataAccess/webApi/dao/guideTextDao'

interface DialogParam {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  message?: string
}

const DIALOG_PARAM_DEFAULT_VALUE: DialogParam = {
  isOpen: false,
  onClose: () => {},
  onClick: () => {},
}

export const useAction = () => {
  const history = useHistory()
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const [dialog, setDialog] = useState<DialogParam>(DIALOG_PARAM_DEFAULT_VALUE)

  useEffect(() => {
    initialize()
  }, [])

  const initialize = useCallback(async () => {
    const { guideTextContent } = await getEmailVerifiedGuideText()

    if (!guideTextContent) {
      history.replace(rootUrl.url())
    }

    setDialog({
      isOpen: true,
      message: guideTextContent || '',
      onClose: () => {
        handleClose()
      },
      onClick: () => {
        handleClickMember()
      }
    })
  }, [isLoggedIn])

  const handleClose = useCallback(async () => {
    setDialog(DIALOG_PARAM_DEFAULT_VALUE)
    history.replace(rootUrl.url())
  }, [])

  const handleClickMember = useCallback(async() => {
    setDialog(DIALOG_PARAM_DEFAULT_VALUE)
    history.push(memberUrl.url())
  }, [])

  const getEmailVerifiedGuideText = useCallback(async () => {
    const response = await executePostGuideText(
      guideTextId.emailVerifiedGuideText
    )
    return response.result
  }, [])

  return {
    dialog,
  }
}
