// 予約機能で共通する利用予約に関する表示部品を定義
// 情報照会、変更内容確認で共有
//

import React, { ReactNode } from 'react'
import { LabelGItemBase, ValueGItemBase } from '../grids'

/** 項目ラベルのGrid item */
export const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
/** 項目値のGrid item */
export const ValueGItem = (props: { children: ReactNode, hideColon?: boolean }) => <ValueGItemBase xs={7} hideColon={props.hideColon}>{props.children}</ValueGItemBase>
