import { Analytics } from 'aws-amplify'
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { showLoading } from '../../containers/common/store/slices/application'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { ElapsedMillisecond } from '../../utils/dateUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getUseReason } from '../common/useReason'

interface UrlParams {
  facilityId: string
}

interface LocationState {
  /** 空き状況基準日 */
  availabilityBaseDate?: ElapsedMillisecond
  documentFileUri?: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const { facilityId } = useParams<UrlParams>()
  const currentHistory = useHistory<LocationState | undefined>()
  const locationState = currentHistory.location.state
  const sysCtrl = useSelector(selectSystemControl)

  const [facility, setFacility] = useState<NullPropsToUndefinedType<GetFacilityDto>>()
  const [useReason, setUseReason] = useState<NullPropsToUndefinedType<GetUseReasonDto>>()
  const dispatch = useDispatch()

  const onClickLink = (
    event: MouseEvent<HTMLAnchorElement>,
    documentInfo: {
      documentFileUrl: string | null
      documentFileName: string | null
    }
  ) => {
    event.preventDefault()
  
    if (!documentInfo.documentFileUrl && documentInfo.documentFileName) {
      window.open(documentInfo.documentFileName,)
      return
    }
  
    if (!documentInfo.documentFileName && documentInfo.documentFileUrl) {
      const lastSegment = documentInfo.documentFileUrl.substring(
        documentInfo.documentFileUrl.lastIndexOf('/') + 1
      )
      fetch(documentInfo.documentFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = lastSegment
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => console.error('ダウンロードエラー:', error))
      return
    }
  
    if (documentInfo.documentFileUrl && documentInfo.documentFileName) {
      fetch(documentInfo.documentFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = documentInfo.documentFileName ?? ''
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => console.error('ダウンロードエラー:', error))
      return
    }
  }
    
  useEffect(() => {
    dispatch(
      showLoading(
        errorHandle(async () => {
          const [facilityDto, useReasonDto] = await Promise.all([
            getFacility(facilityId),
            getUseReason(),
          ])
          console.log("facilityDto")
          console.log(JSON.stringify(facilityDto))
          setFacility(facilityDto)
          setUseReason(useReasonDto)
        })
      )
    )
    // AmazonPinpointへ追加情報送信。
    sendAnalyticsRecord(facilityId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    facilityId,
    facility,
    useReason,
    availabilityBaseDate: locationState?.availabilityBaseDate,
    sysCtrl,
    onClickLink,
  }
}

/**
 * AmazonPinpointへ追加情報送信
 * 呼び出し完了を待つ必要は無くエラーのハンドリング不要、awaitしないこと
 * @param facilityId 表示している施設ID
 */
const sendAnalyticsRecord = (facilityId: string) => {
  Analytics.record({
    name: 'facilityDetail',
    attributes: { facilityId },
  })
}
