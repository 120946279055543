import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeDeleteReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { translate } from '../../i18n'
import { toApiYmd } from '../../utils/dateUtil'
import { blankToNull } from '../../utils/stringUtil'
import { reservationStatusListUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import {
  clearFacilityReserveDelete,
  selectFacilityReserveDeleteEntry,
  selectFacilityReserveDeleteUpdateDatetime,
} from '../common/store/slices/facilityReserveDelete'
import { ReservationDelete } from '../../views/components/common/reservationDetail/reservationDetailGItems'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { setStringifyNamesChildInfoList, getReservationDecodeMstValue } from '../common/reservation'

type ReservationDetail = ReservationDelete & {
  useReason: GetUseReasonDto
  reservationNo: string,
  facilityId: string,
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const deleteEntry = useSelector(selectFacilityReserveDeleteEntry)
  const updateDatetime = useSelector(selectFacilityReserveDeleteUpdateDatetime)

  const [deleteResultCode, setDeleteResultCode] = useState<number>()
  const [reservationDetail, setReservationDetail] = useState<ReservationDetail | undefined>()

  useEffect(() => {
    if ( deleteEntry ) {
      addOperationLog({
        operationId: OperationId.OP_00000001,
        ...(deleteEntry && {
          accessData: [
            ...deleteEntry.childInfoList.map((childInfo) => ({
              userIdRegFlag: yesNo.yes,
              childId: childInfo.childId,
              usageDate: toApiYmd(deleteEntry.usageDate),
              reservationNo: deleteEntry.reservationNo,
            }))
          ],
        }),
      })
      

      dispatch(
        showLoading(
          errorHandle(async () => {
              const decodeMstValue = await getReservationDecodeMstValue();
              
              setReservationDetail({
                ...deleteEntry,
                childInfoList: setStringifyNamesChildInfoList(deleteEntry.childInfoList, decodeMstValue)
              })
          })
        )
      )      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntry])

  const decide = useCallback(() => {
    if (deleteEntry == null || updateDatetime == null) {
      // 確定押下時には発生しない。型ガードの為の分岐
      return
    }
    addOperationLog({
      operationId: OperationId.OP_00000037,
      accessData: [
        ...deleteEntry.childInfoList.map((childInfo) => ({
          userIdRegFlag: yesNo.yes,
          childId: childInfo.childId,
          usageDate: toApiYmd(deleteEntry.usageDate),
          reservationNo: deleteEntry.reservationNo,
        }))
      ]
    })
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          const response = await deleteReservation(deleteEntry.reservationNo, updateDatetime, deleteEntry.cancelReason)
          if (response.resultCode) {
            // キャンセル失敗時
            setDeleteResultCode(response.resultCode)
          } else {
            // キャンセル成功時
            dispatch(notifyMessage(translate('facilityReservationDeleteConfirmation.success.completionMessage')))
            history.push(reservationStatusListUrl.url())
            dispatch(clearFacilityReserveDelete())
          }
        }, deleteEntry),
        isHiddenMain: false,
      })
    )
  }, [addOperationLog, deleteEntry, updateDatetime, dispatch, errorHandle, history])

  return {
    deleteEntry,
    reservationDetail,
    deleteResultCode,
    decide,
  }
}

const deleteReservation = (reservationNo: string, updateDatetime: string, cancelReason?: string) => {
  return executeDeleteReservation(reservationNo, {
    cancelReasonCategory: blankToNull(cancelReason),
    updateDatetime,
  })
}
