import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, styled } from '@mui/material'
import React from 'react'
import { Control, useController } from 'react-hook-form'
import { translate } from '../../../i18n'
import { getValueByNameStr } from '../../../utils/objectUtil'
import { TextBox } from '../common/inputs/textBox'
import { yesNo } from '../../../containers/common/constant/classification'
import { GItem, GItemContainer } from '../common/grids'

interface UseReasonDetailRadioGroupProps {
  /** 利用目的名*/
  useReasonName: 'Irregular' | 'Emg' | 'Refresh'
  /** 選択されている詳細事由 */
  useReasonDetail: string
  /** 詳細事由を利用するか否かのフラグ */
  useReasonDetailFlags: {
    useReasonDetailCategory: string
    detailItemFlag: string
  }[]
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /** 横に並べて表示する場合true */
  row?: boolean
  /** 選択肢の値と表示文言配列 */
  buttonValueLabels: { value: string; label: string, textInputType: string }[]
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
  /** 読み取り専用の場合true */
  disabled?: boolean
  
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Root = styled(RadioGroup)(() => ({
  '& .MuiRadio-root': {
    padding: '0 9px',
  },
}))

const textInputTypeValue = {
  noInputField: '0',
  inputFieldNotRequired: '1',
  inputFieldRequired: '2',
} as const

const maxLengthOfDescription = 50

/** ラジオボタンフォームコントロール */
export const UseReasonDetailRadioGroup = (props: UseReasonDetailRadioGroupProps) => {
  const { label, useReasonName, useReasonDetail, useReasonDetailFlags, required, buttonValueLabels, control, disabled, onChange, ...through } = props
  const name = `useReasonDetail${useReasonName}`
  
  const {
    field: { ref, ...ctrlProps },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: translate('system.error.requiredSelection', label),
      },
    },
    defaultValue: '',
    control,
  })

  const error = getValueByNameStr(errors, name)
  return (
    <FormControl error={!!error} required={required} disabled={disabled}>
      <Root
        {...ctrlProps}
        {...through}
        onChange={(e) => {
          ctrlProps.onChange(e)
          if (onChange) onChange(e)
        }}
      >
        {buttonValueLabels.map(({ value, label, textInputType }, index) => {
          const useDetailFlag = useReasonDetailFlags.find(({
            useReasonDetailCategory,
          }) => {
            return useReasonDetailCategory === (index + 1).toString()
          })?.detailItemFlag

          if (useDetailFlag !== yesNo.yes) {
            return null
          }
          
          return (
            <GItemContainer 
              key={value}
              direction='row'
            >
              <GItem xs={1} />
              
              <GItem>
                <FormControlLabel
                  {...{ value, label }}
                  control={<Radio />}
                  {...(index === 0 && { inputRef: ref })}
                />
              </GItem>
              {textInputType === textInputTypeValue.inputFieldNotRequired && (
                <GItem xs={4}>
                  <TextBox
                    name={`useReasonDetailDescriptions${useReasonName}.${index}.value`}
                    label={translate('facilityReservationForm.useReason.description')}
                    maxLength={maxLengthOfDescription}
                  />
                </GItem>
              )}
              {textInputType === textInputTypeValue.inputFieldRequired && (
                <GItem xs={4}>
                  <TextBox
                    name={`useReasonDetailDescriptions${useReasonName}.${index}.value`}
                    label={translate('facilityReservationForm.useReason.description')}
                    maxLength={maxLengthOfDescription}
                    required={useReasonDetail === value}
                  />
                </GItem>
              )}
            </GItemContainer>
          )
        })}
      </Root>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
