import { Box, Stack, styled } from '@mui/material'
import { facilityDetailUrl } from '../../containers/common/constant/appUrl'
import { interviewChangeRestriction, yesNo, interviewReservationStep2 } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/interviewReservationSelection/interviewReservationSelectionService'
import { translate } from '../../i18n'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem, MiddleGItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import {
  AvailabilityCalendar,
  calendarChangeRestriction,
  CalendarChangeRestrictionType,
} from '../components/common/inputs/availabilityCalendar'
import { DatePickerNoBind } from '../components/common/inputs/datePickerNoBind'
import { Form } from '../components/common/inputs/form'
import { Link } from '../components/common/link'
import { StepBar } from '../components/common/stepBar'
import { TitleLabel } from '../components/common/titleLabel'
import { getInterviewReservationSteps2 } from '../../containers/common/codeMaster'
import { ChildSelectDialog } from '../components/accountConfirmation/childSelectDialog'
import { OperationId } from '../../containers/common/constant/operationLog'

const FacilityNoteCard = styled(InfoCard)({
  whiteSpace: 'pre-wrap',
})

/** 施設の予約変更制限値とカレンダーコントロールの変更制限値のマッピング */
const interviewChangeRestrictionToCalendarChangeableMap: Readonly<Record<string, CalendarChangeRestrictionType>> = {
  [interviewChangeRestriction.allowChangeDatetime]: calendarChangeRestriction.datetime,
  [interviewChangeRestriction.allowChangeTimeOnly]: calendarChangeRestriction.time,
  [interviewChangeRestriction.notAllowChangeDatetime]: calendarChangeRestriction.none,
} as const

export const InterviewReservationSelection = () => {
  const {
    facilityId,
    facility,
    childrenName,
    activeChildId,
    activeDate,
    availabilities,
    initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    onNextPrevExec,
    changeDate,
    isOpenInterviewChildSelect,
    onCloseInterviewChildSelect,
    uninterviewedChilds,
    interviewedChildsAndStatus,
    sysCtrl,
  } = useAction()
  
  // TODO エラー回避の仮実装
  console.log(activeChildId)

  if (facilityId == null || facility == null) {
    return null
  }

  let usageGuideStr: string
  if (facility.interviewImmediatelyReservationFlag === yesNo.yes) {
    if (facility.interviewCancelWaitAcceptFlag === yesNo.yes) {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.1'
    } else {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.2'
    }
  } else {
    if (facility.interviewCancelWaitAcceptFlag === yesNo.yes) {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.5'
    } else {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.6'
    }
  }

  return (
    <Stack spacing={2}>
      <ChildSelectDialog
        isOpen={isOpenInterviewChildSelect}
        onClose={onCloseInterviewChildSelect}
        childs={uninterviewedChilds}
        interviewedChildsAndStatus={interviewedChildsAndStatus}
        isShowToNextButton={!!uninterviewedChilds.length}
        isInterviewBulkReception={sysCtrl.interviewBulkReceptionFlag}
      />
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Box>
            <GContainer rowSpacing={1}>
              <GItem xs={12}>
                <StepBar
                  activeStepValue={interviewReservationStep2.step1}
                  stepValueLabels={getInterviewReservationSteps2()}
                />
              </GItem>

              <GItem xs={12}>
                <Link to={facilityDetailUrl.url(facilityId)} operationLog={{ operationId: OperationId.OP_00000027 }}>
                  <TitleLabel>{facility.facilityName}</TitleLabel>
                </Link>
              </GItem>

              <GItem xs={12}>
                <GContainer>
                  <MiddleGItem xs={5} md={4}>
                    {translate('interviewReservationSelection.label.selectionChild')}
                  </MiddleGItem>
                  <MiddleGItem xs={7} md={8}>
                    {childrenName}
                  </MiddleGItem>
                </GContainer>

                <GContainer>
                  <MiddleGItem xs={5} md={4}>
                    {translate('interviewReservationSelection.label.selectTheDesiredDateOfUse')}
                  </MiddleGItem>
                  <MiddleGItem xs={7} md={8}>
                    <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} />
                  </MiddleGItem>
                  <RowSpacerGItem />
                </GContainer>
              </GItem>

              <GItem xs={12}>
                <Stack spacing={2}>
                  <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>

                  <AvailabilityCalendar
                    name="interviewDesiredDatetimes"
                    label={translate('interviewReservationSelection.label.availabilityCalendar')}
                    required
                    baseDate={activeDate}
                    previousRange={previousRange}
                    receptionTimePattern={facility.interviewReceptionTimePattern}
                    availabilities={availabilities ?? []}
                    initialValidationAvailabilities={initialValidationAvailabilities}
                    isMultiple={false}
                    onChangeBaseDate={onNextPrevExec}
                    control={formMethods.control}
                    changeRestriction={
                      interviewChangeRestrictionToCalendarChangeableMap[facility.interviewChangeRestriction]
                    }
                    receptionTimeName={facility.interviewReceptionTimeName}
                    isSingleSlotSelection={true}
                  />
                </Stack>
              </GItem>
              <GItem xs={12}>
                <FacilityNoteCard>{facility.interviewNote}</FacilityNoteCard>
              </GItem>
            </GContainer>
          </Box>
          <div>{translate('interviewReservationSelection.label.annotation')}</div>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>

      <Form onSubmit={onSubmit} formMethods={formMethods}>
        <BottomButtonGroup>
          <ButtonOutlinedWarningL type="submit" fullWidth>
            {translate('interviewReservationSelection.button.applyOnTheSelectedSchedule')}
          </ButtonOutlinedWarningL>
          <BackButton />
        </BottomButtonGroup>
      </Form>
    </Stack>
  )
}
