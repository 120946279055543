// 予約機能で共通する面談予約に関する表示部分を定義
// 情報照会、変更内容確認で共有
//

import { Stack } from '@mui/material'
import { ReactNode } from 'react'
import {
  getGenderLabel,
  getInterviewStatusLabel,
  getYesNoLabel,
  getPermitFlagLabel,
} from '../../../../containers/common/codeMaster'
import { interviewStatus, permitFlag } from '../../../../containers/common/constant/classification'
import { translate } from '../../../../i18n'
import { ElapsedMillisecond, formatHmtoHmOver, formatYmd, formatYmdWeek, toApiHms } from '../../../../utils/dateUtil'
import { formatAddress } from '../../../../utils/itemFormatUtil'
import { LabelGItemBase, RowSpacerGItem, ValueGItemBase } from '../grids'

interface Interview {
  facilityName: string

  parentName: string
  parentKana: string
  tel: string
  email: string

  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber?: string
  residenceCategory: string
  residenceCategoryName: string
  relationship?: string

  childName: string
  childKana: string
  childBirthday: Date | ElapsedMillisecond
  childGender: string
  childMedicalHistoryFlag: string
  childMedicalHistory?: string
  childAllergyFlag: string
  childAllergy?: string

  maternalHandbookNo?: string
  note?: string
  facilityNumber?: string
}

interface InterviewReference extends Interview {
  interviewDate?: Date | ElapsedMillisecond
  interviewStartDatetime?: Date | ElapsedMillisecond
  interviewEndDatetime?: Date | ElapsedMillisecond
  status: string
  reservationDatetime: Date | ElapsedMillisecond
  reservationChangeDatetime?: Date | ElapsedMillisecond
  reservationCancelDatetime?: Date | ElapsedMillisecond
  permitFlag: string
}

/** 情報照会時以外で必要なプロパティ */
interface Confirmation {
  interview: Interview
  displayType?: never
}
/** 情報照会時に必要なプロパティ */
interface Reference {
  interview: InterviewReference
  displayType: 'reference'
}
/** 削除時に必要なプロパティ */
interface Delete {
  interview: InterviewReference
  displayType: 'delete'
}

type InterviewDetailContentProps = Confirmation | Reference | Delete

const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={7}>{props.children}</ValueGItemBase>

/** 予約機能の面談予約情報コンテンツ部分 */
export const InterviewDetailContent = (props: InterviewDetailContentProps) => {
  const isShownStatusItem = props.displayType === 'delete' || props.displayType === 'reference'
  const isShownReservationDatetimeItem = props.displayType === 'reference'

  return (
    <>
      <LabelGItem>{translate('system.interviewDetailContent.label.facilityName')}</LabelGItem>
      <ValueGItem>{props.interview.facilityName}</ValueGItem>
      {isShownStatusItem && (
        <>
          <LabelGItem>{translate('system.interviewDetailContent.label.interviewDatetime')}</LabelGItem>
          <ValueGItem>
            {props.interview.status !== interviewStatus.notFixed ? (
              <Stack>
                <div>{props.interview.interviewDate != null && formatYmdWeek(props.interview.interviewDate)}</div>
                {props.interview.interviewDate != null &&
                  props.interview.interviewStartDatetime != null &&
                  props.interview.interviewEndDatetime != null && (
                    <div>
                      {formatHmtoHmOver(
                        { from: props.interview.interviewStartDatetime, to: props.interview.interviewEndDatetime },
                        props.interview.interviewDate
                      )}
                    </div>
                  )}
              </Stack>
            ) : (
              translate('system.interviewDetailContent.text.notFixed')
            )}
          </ValueGItem>
          <LabelGItem>{translate('system.interviewDetailContent.label.status')}</LabelGItem>
          {props.interview.permitFlag === permitFlag.shelved ? (
            <ValueGItem>{getPermitFlagLabel(props.interview.permitFlag)}</ValueGItem>
          ) : (
            <ValueGItem>{getInterviewStatusLabel(props.interview.status)}</ValueGItem>
          )}
        </>
      )}
      <RowSpacerGItem />

      <LabelGItem>{translate('system.interviewDetailContent.label.userName')}</LabelGItem>
      <ValueGItem>{props.interview.parentName}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.userNameKana')}</LabelGItem>
      <ValueGItem>{props.interview.parentKana}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.tel')}</LabelGItem>
      <ValueGItem>{props.interview.tel}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.email')}</LabelGItem>
      <ValueGItem>{props.interview.email}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.postalCode')}</LabelGItem>
      <ValueGItem>{props.interview.postalCode}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.address')}</LabelGItem>
      <ValueGItem>
        {formatAddress(props.interview.address1, props.interview.address2, props.interview.buildingNameRoomNumber)}
      </ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.residenceCategory')}</LabelGItem>
      <ValueGItem>{props.interview.residenceCategoryName}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.relationship')}</LabelGItem>
      <ValueGItem>{props.interview.relationship}</ValueGItem>
      <RowSpacerGItem />

      <LabelGItem>{translate('system.interviewDetailContent.label.childName')}</LabelGItem>
      <ValueGItem>{props.interview.childName}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.childNameKana')}</LabelGItem>
      <ValueGItem>{props.interview.childKana}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.birthday')}</LabelGItem>
      <ValueGItem>{formatYmd(props.interview.childBirthday)}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.gender')}</LabelGItem>
      <ValueGItem>{getGenderLabel(props.interview.childGender)}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.allergy')}</LabelGItem>
      <ValueGItem>
        {[getYesNoLabel(props.interview.childAllergyFlag), props.interview.childAllergy].filter((v) => v).join('\n')}
      </ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.medicalHistory')}</LabelGItem>
      <ValueGItem>
        {[getYesNoLabel(props.interview.childMedicalHistoryFlag), props.interview.childMedicalHistory]
          .filter((v) => v)
          .join('\n')}
      </ValueGItem>
      {/*TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
      <LabelGItem>{translate('system.interviewDetailContent.label.maternalHandbookNo')}</LabelGItem>
      <ValueGItem>{props.interview.maternalHandbookNo}</ValueGItem>
      */}
      <LabelGItem>{translate('system.interviewDetailContent.label.note')}</LabelGItem>
      <ValueGItem>{props.interview.note}</ValueGItem>
      <LabelGItem>{translate('system.interviewDetailContent.label.facilityNumber')}</LabelGItem>
      <ValueGItem>{props.interview.facilityNumber}</ValueGItem>
      {isShownReservationDatetimeItem && (
        <>
          <RowSpacerGItem />
          <LabelGItem>{translate('system.interviewDetailContent.label.reservationDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.interview.reservationDatetime)}</div>
              <div>{toApiHms(props.interview.reservationDatetime)}</div>
            </Stack>
          </ValueGItem>
          {props.interview.reservationChangeDatetime != null && (
            <>
              <LabelGItem>{translate('system.interviewDetailContent.label.reservationChangeDatetime')}</LabelGItem>
              <ValueGItem>
                <Stack>
                  <div>{formatYmdWeek(props.interview.reservationChangeDatetime)}</div>
                  <div>{toApiHms(props.interview.reservationChangeDatetime)}</div>
                </Stack>
              </ValueGItem>
            </>
          )}
          {props.interview.status === interviewStatus.canceled && props.interview.reservationCancelDatetime != null && (
            <>
              <LabelGItem>{translate('system.interviewDetailContent.label.reservationCancelDatetime')}</LabelGItem>
              <ValueGItem>
                <Stack>
                  <div>{formatYmdWeek(props.interview.reservationCancelDatetime)}</div>
                  <div>{toApiHms(props.interview.reservationCancelDatetime)}</div>
                </Stack>
              </ValueGItem>
            </>
          )}
        </>
      )}
    </>
  )
}
