// 在住種別に関する共通処理を定義
//

import { executeGetResidenceCategoryMst } from '../../dataAccess/webApi/dao/residenceCategoryMstDao'
import { residenceCategory } from './constant/classification'

/**
 * 在住種別を取得して結果を返す、また、ラジオボタンでの選択に使用できるように変換した在住種別リストも返す。
 *
 * @returns 施設情報
 */
export const getResidenceCategoriesAndMstData = async () => {
  const apiResponse = await executeGetResidenceCategoryMst()
  const result = apiResponse.result
  
  const categories: {value: string, label: string}[] = [
    {value: residenceCategory.inside, label: result.insideName},
  ]
  if (result.outsideName != null) {
      categories.push({value: residenceCategory.outsid, label: result.outsideName})
  }
  if (result.otherName != null) {
      categories.push({value: residenceCategory.other, label: result.otherName})
  }
  return {categories, mstData: result}
}

