import { styled } from '@mui/material'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { Dialog } from '../components/common/dialog'
import { useAction } from '../../containers/emailVerified/emailVerifiedService'
import { translate } from '../../i18n'

const DialogContentText = styled('div')({
  whiteSpace: 'pre-line',
})

const ButtonBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '8px',
  gap: '8px',
})

export const EmailVerification = () => {
  const { dialog } = useAction()

  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={dialog.onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContentText>{dialog.message}</DialogContentText>
      <ButtonBox>
        <ButtonOutlinedS onClick={dialog.onClick}>
          {translate('mypage.button.member')}
        </ButtonOutlinedS>
        <ButtonOutlinedS onClick={dialog.onClose}>
          {translate('system.button.close')}
        </ButtonOutlinedS>
      </ButtonBox>
    </Dialog>
  )
}
