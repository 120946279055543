import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, FieldPath } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useErrorHandle } from '../../containers/common/error/errorHandler'
import { FacilityReserveEntry, selectFacilityReserveEntry, setFacilityReserve } from '../../containers/common/store/slices/facilityReserve'
import { GoingTimeType } from '../common/reservation'
import { executeGetChild } from '../../dataAccess/webApi/dao/childrenDao'
import { GetChildDto } from '../../dataAccess/webApi/dto/childrenDto'
import { User } from '../common/user'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { toApiYmd, toApiHms } from '../../utils/dateUtil'
import { dateToNumber, undefinedPropsToOptional } from '../../utils/objectUtil'
import { ReactBatchUpdater } from '../../utils/reactUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { facilityReservationConfirmationUrl } from '../common/constant/appUrl'
import { availabilityStatus, yesNo, Flag, mailType } from '../common/constant/classification'
import { getFacility } from '../common/facility'
import { getUseReason } from '../common/useReason'
import { showLoading } from '../common/store/slices/application'
import { getUser } from '../common/user'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { isAfter } from 'date-fns'
import { executeGetProjects } from '../../dataAccess/webApi/dao/projectsDao'
import { getSendingFlagMailSetting } from '../common/getFacilityMailSetting'
import { PostDecodeMstDto } from '../../dataAccess/webApi/dto/decodeMstDto'
import { GOING_DATETIME_DISPLAY_METHODS } from '../common/constant/projectMst'
import {  ReservationSettingDecodeMstKey } from '../common/reservation'
import { setStringifyNamesChildInfoList, getReservationDecodeMstValue, ChildReservationInputType, ChildReservationInfoType, ShowMessage } from '../common/reservation'
import { getFacilityReservationMedicalRequirementSetting } from '../common/facility'
import { GetProjectsDto } from '../../dataAccess/webApi/dto/projectsDto'

type ReservationSettingDecodeMst = ReservationSettingDecodeMstKey<PostDecodeMstDto[]>

export interface Inputs {
  lunchFlag: string
  snackFlag: string
  postponeCancelWaitFlag: string
  goingToFacilityTime: Date
  goingTimes: GoingTimeType[]
  note: string
  showMessages: ShowMessage[]
  children: ChildReservationInputType[]
  consentCheck: string
}

export type facilityReservationInputKey = FieldPath<Inputs>

interface PageState {
  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  /** 利用目的マスタ情報 */
  useReason?: NullPropsToUndefinedType<GetUseReasonDto>
  /** 送信フラグデータ */
  cancelSendingFlag?: boolean
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const entryInput = reserveEntry?.input
  const { addOperationLog } = useOperationLog()

  const [state, setState] = useState<PageState>()

  const formMethods = useForm<Inputs>()

  const history = useHistory()

  const [showMessages, setShowMessages] = useState<ShowMessage[]>()
  const [decodemst, setDecodeMst] = useState<ReservationSettingDecodeMst>()
  const [projectMst, setProjectmst] = useState<GetProjectsDto>()
  
  const { fields: goingTimeFileds } = useFieldArray({
    control: formMethods.control,
    name: 'goingTimes',
  })
  
  const { fields: childrenWatch } = useFieldArray({
    control: formMethods.control,
    name: 'children',
  })

  
  const reservationReference = useMemo(() => {
    if (reserveEntry?.facility && reserveEntry?.parentInfo && reserveEntry?.useReason && reserveEntry?.childInfoList) {
      const { facility, parentInfo, useReasonCategory, useReasonDetailCategory, useReasonDetailDescription, useReason, childInfoList } = reserveEntry
      return {
        facilityName: facility.facilityName,
        useReasonCategory: useReasonCategory,
        useReasonDetailCategory: useReasonDetailCategory,
        useReasonDetailDescription: useReasonDetailDescription,
        childInfoList,
        parentInfo,
        useReason: {...useReason},
      }
    }
  }, [reserveEntry])

  useEffect(() => {
    // デコードマスタ取得
    (async () => {
      const decodeMst = await getReservationDecodeMstValue()
      setDecodeMst(decodeMst)
    })()
  }, [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000025 })
      let isError = false

      // TODO: readonlyを回避するため
      const goingTimes = data.goingTimes.map((goingTime) => ({
        ...goingTime,
      }))

      goingTimes.forEach((goingTime, index) => {
        const goingToFaclityDatetime = new Date(`${goingTime.goingToFacilityDate} ${goingTime.goingToFacilityTime}`)
        const goingHomeDatetime = new Date(`${goingTime.goingHomeDate} ${goingTime.goingHomeTime}`)
        if (isAfter(goingToFaclityDatetime, goingHomeDatetime)) {
          formMethods.setError(`goingTimes.${index}.goingToFacilityTime`, {
            message: '降園予定時間より後の時間を登園予定時間に設定出来ません',
          })
          isError = true
        }
      })

      const children:ChildReservationInfoType[] = JSON.parse(JSON.stringify(data.children));

      children.forEach((child, index) => {
        // 医師連絡票のアップロード利用＆医師連絡票が必須の場合
        if ( state && state.facility?.medicalDocUsage === Flag.ON && state.facility?.medicalDocNecessity === Flag.ON ) {
          if ( child?.medicalDoc?.hasSubmitted === Flag.OFF ) {
            formMethods.setError(`children.${index}.medicalDoc.hasSubmitted`, {
              message: '医師連絡票が取得済でない場合は予約ができません。施設に直接電話してください。',
            })
            isError = true
          }
        }        
      })

      if (isError) return
      dispatch(
        reserveEntry &&
        setFacilityReserve({
          ...reserveEntry,
          goingTimes,
          childInfoList: (decodemst) ? setStringifyNamesChildInfoList(children, decodemst) : children,
          input: {
            ...reserveEntry.input,
            lunchFlag: data.lunchFlag,
            snackFlag: data.snackFlag,
            postponeCancelWaitFlag: data.postponeCancelWaitFlag,
            citizenNote: data.note,
          },
          consentCheck: data.consentCheck
        })
      )
      history.push(facilityReservationConfirmationUrl.url()) //値の送り先を設定
    },
    [reserveEntry, addOperationLog, formMethods]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    dispatch(
      showLoading(
        errorHandle(async () => {
          if (reserveEntry == null) {
            // 前画面入力情報が無い場合はエラーにするので何もしない
            return
          }
          const { facility, user, children, useReason } = await getUserWithSelectionChild(
            reserveEntry.facilityId,
            reserveEntry.childInfoList.map((childInfo) => childInfo.childId),
            new Date(reserveEntry.usageDatetimes[0].usageDate)
          )
          
          const usageDates = reserveEntry.usageDatetimes.map(( usageDatetime ) => usageDatetime.usageDate )
          const reservationMedicalRequirementSetting = await getFacilityReservationMedicalRequirementSetting(reserveEntry.facilityId, usageDates);
          const facilityConsideringProjectMstFromDate = {
            ...facility,
            ...reservationMedicalRequirementSetting
          }
          
          /** 給食注文 */
          let lunchFlag: string = yesNo.no
          if (facilityConsideringProjectMstFromDate.lunchAcceptFlag === yesNo.yes) {
            lunchFlag = entryInput?.lunchFlag ?? facility.lunchInitialSelect ?? yesNo.yes
          }
          /** おやつ注文 */
          let snackFlag: string = yesNo.no
          if (facilityConsideringProjectMstFromDate.snackAcceptFlag === yesNo.yes) {
            snackFlag = entryInput?.snackFlag ?? facilityConsideringProjectMstFromDate.snackInitialSelect ?? yesNo.yes
          }

          /** 登園予定時間 */
          /** 降園予定時間 */
          const goingTimes: GoingTimeType[] = []
          for (let index = 0; index < reserveEntry.usageDatetimes.length; index++) {
            const usageDatetime = reserveEntry.usageDatetimes[index];
            const useFromDatetime = usageDatetime.useFromDatetime
            const projectId = String(facilityConsideringProjectMstFromDate.projectId ?? '')
            const projectMstRes = await executeGetProjects({
              targetDate: toApiYmd(useFromDatetime),
              projectId,
            })
            const projectMst = projectMstRes.result
            setProjectmst(projectMst[0])

            if (
              projectMst.length > 0 &&
              projectMst[0].goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY
            ) {
              const prevGoingTime = reserveEntry.goingTimes[index];
              const goingToFaclityDatetime = new Date(prevGoingTime ? `${prevGoingTime.goingToFacilityDate} ${prevGoingTime.goingToFacilityTime}` : usageDatetime.useFromDatetime)
              const goingHomeDatetime = new Date(prevGoingTime ? `${prevGoingTime.goingHomeDate} ${prevGoingTime.goingHomeTime}` : usageDatetime.useToDatetime)
              const goingToFacilityDate = toApiYmd(new Date(goingToFaclityDatetime))
              const goingToFacilityTime = toApiHms(new Date(goingToFaclityDatetime))
              const goingHomeDate = toApiYmd(new Date(goingHomeDatetime))
              const goingHomeTime = toApiHms(new Date(goingHomeDatetime))
              goingTimes.push({
                goingToFacilityDate,
                goingToFacilityTime,
                goingHomeDate,
                goingHomeTime,
              })
            } 
            else {
              goingTimes.push({
                goingToFacilityDate: '',
                goingToFacilityTime: '',
                goingHomeDate: '',
                goingHomeTime: '',
              })
            }
          }

          // デコードマスタ取得
          setDecodeMst(await getReservationDecodeMstValue())

          let cancelSendingFlag = true
          if (reserveEntry?.usageDatetimes[0].status === availabilityStatus.wait) {
            // メール設定（送信フラグ）情報を取得し、送信フラグ："0"(無)の場合、FALSEとする
            const sendingFlagInfo = await getSendingFlagMailSetting({mailId: mailType.immediatelyCancelVacant, facilityId: reserveEntry.facilityId})
            if (sendingFlagInfo && sendingFlagInfo.sendingFlag === '0') {
              cancelSendingFlag = false
            }
          }

          /** 予約が取れなかった場合項目 */
          let postponeCancelWaitFlag: string = yesNo.no
          if (isShownPostponeCancelWaitItem(facilityConsideringProjectMstFromDate)) {
            if (entryInput) {
              postponeCancelWaitFlag = entryInput.postponeCancelWaitFlag
            } else if (
              reserveEntry.reservationNo == null &&
              reserveEntry.usageDatetimes[0].status === availabilityStatus.wait
            ) {
              // 入力項目が表示されていて 新規キャンセル待ち予約なら
              // 「キャンセル待ちする」を初期選択
              postponeCancelWaitFlag = yesNo.yes
            }
          }
          if (reserveEntry.showMessages.length) {
            setShowMessages(reserveEntry.showMessages)
          }
          // お子様情報のセット
          const childInfoList = setChildInfo(children, reserveEntry, decodemst);
          const convertedChildInfoList:ChildReservationInputType[] = [];
          
          for (const childInfo of childInfoList) {
            const symptomArray = (childInfo.symptom) ? childInfo.symptom.symptom : [];
            convertedChildInfoList.push({
              ...childInfo,
              conditionCheck: childInfo.conditionCheck ?? undefined,
              symptom: {
                additional: childInfo.symptom?.additional ?? null,
                stringify: childInfo.symptom?.stringify,
                symptom: symptomArray,
              }
            })
          }
          
          const batchUpdater = new ReactBatchUpdater()
          batchUpdater.add(() =>
            formMethods.reset({
              ...formMethods.getValues(),
              ...undefinedPropsToOptional({
                purposeOfUse: reserveEntry?.useReasonCategory,
                lunchFlag,
                snackFlag,
                goingTimes,
                postponeCancelWaitFlag,
                note: entryInput?.citizenNote,
                showMessages: showMessages,
                children: convertedChildInfoList,
                consentCheck: reserveEntry.consentCheck
              }),
            })
          )
          batchUpdater.add(() =>
            dispatch(
              setFacilityReserve({
                ...reserveEntry,
                // 施設情報
                facility: {
                  facilityName: facilityConsideringProjectMstFromDate.facilityName,
                  immediatelyReservationFlag: facilityConsideringProjectMstFromDate.immediatelyReservationFlag,
                  lunchAcceptFlag: facilityConsideringProjectMstFromDate.lunchAcceptFlag,
                  snackAcceptFlag: facilityConsideringProjectMstFromDate.snackAcceptFlag,
                  postponeCancelWaitAcceptFlag: facilityConsideringProjectMstFromDate.postponeCancelWaitAcceptFlag,
                  useReasonUsageFlagIrregular: facilityConsideringProjectMstFromDate.useReasonUsageFlagIrregular,
                  useReasonDetailUsageFlagIrregular: facilityConsideringProjectMstFromDate.useReasonDetailUsageFlagIrregular,
                  useReasonUsageFlagEmg: facilityConsideringProjectMstFromDate.useReasonUsageFlagEmg,
                  useReasonDetailUsageFlagEmg: facilityConsideringProjectMstFromDate.useReasonDetailUsageFlagEmg,
                  useReasonUsageFlagRefresh: facilityConsideringProjectMstFromDate.useReasonUsageFlagRefresh,
                  useReasonDetailUsageFlagRefresh: facilityConsideringProjectMstFromDate.useReasonDetailUsageFlagRefresh,
                },
                // 保護者情報
                parentInfo: {
                  name: user.name,
                  kana: user.kana,
                  tel: user.tel,
                  email: user.email,
                  residenceCategory: user.residenceCategory,
                },
                // お子さま情報
                childInfoList: childInfoList,
                useReason,
              })
            )
          )
          batchUpdater.addSetter(setState, { facility: facilityConsideringProjectMstFromDate, useReason, cancelSendingFlag })
          batchUpdater.execute()
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowMessages])

  return {
    reserveEntry,
    reservationReference,
    facility: state?.facility,
    useReason: state?.useReason,
    isShownPostponeCancelWaitItem: isShownPostponeCancelWaitItem(state?.facility),
    formMethods,
    onSubmit,
    showMessages,
    goingTimeFileds,
    decodemst,
    projectMst,
    childrenWatch,
    cancelSendingFlag: state?.cancelSendingFlag
  }
}

//------------------------------------------------------------------------------------------
const isShownPostponeCancelWaitItem = (facility: NullPropsToUndefinedType<GetFacilityDto> | undefined) => {
  if (facility) {
    return facility.immediatelyReservationFlag === yesNo.no && facility.postponeCancelWaitAcceptFlag === yesNo.yes
  } else {
    return false
  }
}

async function getUserWithSelectionChild(
  facilityId: string,
  childIds: string[],
  targetDate: Date
): Promise<{
  facility: NullPropsToUndefinedType<GetFacilityDto>
  user: User
  children: GetChildDto[]
  useReason: NullPropsToUndefinedType<GetUseReasonDto>
}> {
  const [facility, user, useReason] = await Promise.all([
    getFacility(facilityId, targetDate),
    getUser(),
    getUseReason()
  ])

  const childResponse = await Promise.all(
    childIds.map(async (childId) => {
      const childData = await executeGetChild(childId);
      return { childId, ...childData };
    })
  )

  // 各 childResponse の result を取得
  const convertedChildren = childResponse.map(response => {
    const childWithUndefinedProps = response.result
    return { ...childWithUndefinedProps, childId: response.childId }
  })

  return {
    facility,
    user,
    children: convertedChildren,
    useReason,
  }
}

const setChildInfo = (childrenData: GetChildDto[], reserveEntry: FacilityReserveEntry, decodeMstValue: ReservationSettingDecodeMst|undefined): ChildReservationInfoType[] => {
  const createdChildInfoList =  childrenData.map((child) => {
    const childInfo = reserveEntry.childInfoList.find((childInfo) => childInfo.childId === child.childId);
    
    return {
      childId: child.childId,
      childInfo: {
        name: child.name,
        kana: child.kana,
        birthday: dateToNumber(child.birthday),
        gender: child.gender,
      },
      diagonosis: {
        input: childInfo?.diagonosis?.input ?? '',
        additional: childInfo?.diagonosis?.additional ?? '',
        input2: childInfo?.diagonosis?.input2 ?? '',
        additional2: childInfo?.diagonosis?.additional2 ?? '',
      },
      symptom: {
        symptom: childInfo?.symptom?.symptom ?? [],
        additional: childInfo?.symptom?.additional ?? '',
      },
      temperature: {
        integer: childInfo?.temperature?.integer ?? undefined,
        few: childInfo?.temperature?.few ?? undefined
      },
      allergy: {
        flag: child.allergyFlag,
        foodFlag: childInfo?.allergy?.foodFlag ?? Flag.OFF,
      } ,
      medicalDoc: {
        docs: childInfo?.medicalDoc?.docs ?? [],
        hasSubmitted: childInfo?.medicalDoc?.hasSubmitted ?? Flag.OFF,
      },
      febrileSeizures: childInfo?.febrileSeizures ?? Flag.OFF,
      others: childInfo?.others ?? null,
      conditionCheck: childInfo?.conditionCheck ?? undefined,
      conditionCheckNote: childInfo?.conditionCheckNote ?? null,
    }
    
  })
  
  return decodeMstValue ? setStringifyNamesChildInfoList(createdChildInfoList, decodeMstValue) : createdChildInfoList
}
