import { Grid, Stack, styled } from '@mui/material'
import { useAction } from '../../containers/facilitySearchSettingMasterSelection/facilitySearchSettingMasterSelectionService'
import { ButtonL } from '../components/common/buttons/buttonL'
import { Card } from '../components/common/card'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { translate } from '../../i18n'

const ContentBox = styled('div')({
  padding: '0 16px',
})

const InformationField = styled('div')({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
})

const GridWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: '4em',
  },
}))

const OffsetCard = styled(Card)({
  marginTop: '12px',
})

export const FacilitySearchSettingMasterSelection = () => {
  const { guideText, bottomGuideText, facilitySearchSettingMsts, handleClick } =
    useAction()

  return (
    <Stack spacing={2}>
      <ContentBox>
        <InformationField>{guideText?.guideTextContent}</InformationField>
      </ContentBox>

      <GridWrapper>
        <Grid container>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <div>
                <Grid container spacing={2}>
                  {facilitySearchSettingMsts?.map(
                    (facilitySearchSettingMst) => (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        key={facilitySearchSettingMst.projectCategoryId}
                      >
                        <>
                          <ButtonL
                            fullWidth
                            onClick={() =>
                              handleClick(facilitySearchSettingMst)
                            }
                          >
                            {facilitySearchSettingMst.projectCategoryName}
                          </ButtonL>
                          {facilitySearchSettingMst.information ? (
                            <OffsetCard>
                              <Stack>
                                <InformationField>
                                  {facilitySearchSettingMst.information}
                                </InformationField>
                              </Stack>
                            </OffsetCard>
                          ) : null}
                        </>
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </GridWrapper>

      <ContentBox>
        <InformationField>{bottomGuideText?.guideTextContent}</InformationField>
      </ContentBox>

      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
