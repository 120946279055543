// 施設情報に関する共通処理を定義
import { executeGetFacility } from '../../dataAccess/webApi/dao/facilitiesDao'
import { ElapsedMillisecond, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { Flag } from './constant/classification';

/**
 * 指定した施設IDの施設情報を返す。
 * 対象日を省略した場合は、サーバーの現在日で適用期間のマッチが行われる。
 *
 * @param facilityId 施設ID
 * @param targetDate 適用期間のマッチに使用する日付
 * @returns 施設情報
 */
export const getFacility = async (facilityId: string, targetDate?: Date | ElapsedMillisecond) => {
  const apiResponse = await executeGetFacility(
    facilityId,
    ...(targetDate ? [{ targetDate: toApiYmd(targetDate) }] : [])
  )
  const result = nullPropsToUndefined(apiResponse.result)
  // 画像URLは穴あきで設定される可能性があるため
  result.imageUrls = result.imageUrls.filter((url) => url)
  return result
  
}

export const getProjectIds = async (facilityId: string, usageDates: Date[] | ElapsedMillisecond[]): Promise<string[]> => {
  const projectIds:string[] = [];
  for ( const usageDatetime of usageDates ) {
    const facility = await getFacility(facilityId, usageDatetime);
    if ( !facility.projectId ) continue;
    projectIds.push(facility.projectId.toString())
  }
  return projectIds;
}

export type ReservationMedicalRequirementSetting = {
  medicalDocUsage: string;
  medicalDocNote: string;
  medicalDocNecessity: string;
  symptomsInput: string;
  diagonosisInput: string;
  bodyTemperatureInput: string;
  allergyInput: string;
  febrileSeizuresInput: string;
}

/**
 * 病児病後児関連の事業マスタでの設定を複数日程において獲得し、それを統合した設定をリターンする
 */
export const getFacilityReservationMedicalRequirementSetting = async (facilityId: string, usageDates: Date[] | ElapsedMillisecond[]): Promise<ReservationMedicalRequirementSetting> => {
  const returnObject: ReservationMedicalRequirementSetting = {
    medicalDocUsage: Flag.OFF,
    medicalDocNote: '',
    medicalDocNecessity: Flag.OFF,
    symptomsInput: Flag.OFF,
    diagonosisInput: Flag.OFF,
    bodyTemperatureInput: Flag.OFF,
    allergyInput: Flag.OFF,
    febrileSeizuresInput: Flag.OFF
  };

  usageDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  for (const usageDatetime of usageDates) {
    const facility = await getFacility(facilityId, usageDatetime) as ReservationMedicalRequirementSetting;

    // 操作するプロパティを明示的に指定
    const keys: Array<keyof ReservationMedicalRequirementSetting> = ['medicalDocUsage', 'medicalDocNecessity', 'symptomsInput', 'diagonosisInput', 'bodyTemperatureInput', 'allergyInput', 'febrileSeizuresInput', 'medicalDocNote'];

    keys.forEach(key => {
      // 備考に関しては一番最新の設定を表示
      if (key === 'medicalDocNote') {
        if (facility.medicalDocNote && returnObject.medicalDocNote === '') {
          returnObject.medicalDocNote = facility.medicalDocNote;
        }
        return;
      }

      // Flag に基づく条件を適用
      if (facility[key] === Flag.ON && returnObject[key] === Flag.OFF) {
        returnObject[key] = facility[key];
      }
    });
  }

  return returnObject;
};


