import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Grid, styled, Tooltip, IconButton } from '@mui/material'
import { AttentionLabel } from '../components/common/attentionLabel'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
import { ButtonM } from '../components/common/buttons/buttonM'
import { ElevatedCard } from '../components/common/elevatedCard'
import { loginUrl, forgotPasswordUrl } from '../../containers/common/constant/appUrl'
import { setResetPassword, selectResetPasswordError } from '../../containers/common/store/slices/resetPassword'
import { useAction } from '../../containers/resetPassword/resetPasswordService'
import { translate } from '../../i18n'
import { HelpIcon } from '../components/common/icons/helpIcon'
import { ButtonOutlinedBase } from '../components/common/buttons/buttonOutlinedBase'

// ログイン画面に戻るボタン
const BackToLoginButton = styled(ButtonOutlinedBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: 'solid',
}))

// メッセージコンテナ
const NoticeGContainer = styled(GItemContainer)({
  justifyContent: 'center',
  paddingBottom: 20,
})

// 説明文コンテナ
const DescriptionContainer = styled('p')({
  whiteSpace: 'pre-wrap',
})

// 空行を挿入するための部品
const EmptyRow = styled(GItem) ({
  margin: 10,
})

// パスワードヘルプアイコン
const HelpIconButton = styled(IconButton)({ fontSize: '1.8rem', padding: 0, marginLeft: '8px' })

// メインコンテンツコンテナ
const CustomDivContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// メインコンテンツのサブコンテナ
const CustomDivSubContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

/**
 * パスワード再設定画面UIコンポーネント
 */
export const ResetPassword= () => {
  const { formMethods, onSubmit, from } = useAction()
  const error = useSelector(selectResetPasswordError)
  const dispatch = useDispatch()
  const history = useHistory()
  
  if (from !== forgotPasswordUrl.url()) {
    // ワンタイムパスワード要求画面以外からの遷移した場合, ワンタイムパスワード要求画面にリダイレクト
    return <Redirect to={forgotPasswordUrl.url()} />
  }

  return (
    <Grid>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <GContainer >
          {/* 左余白 */}
          <GItem md={3}></GItem>

          {/* メインコンテンツ */}
          <GItem xs={12} md={6}>
            <CustomDivContainer>

              {/* タイトル */}
              <ElevatedCard
                title={translate('resetPassword.title.resetPassword')}
                textAlign='center'
              >
                <GContainer rowSpacing={2}>
                  {/* 説明 */}
                  <CustomDivSubContainer>
                    <DescriptionContainer>
                      {translate('resetPassword.text.description')}
                    </DescriptionContainer>
                  </CustomDivSubContainer>
      
                  {/* エラーメッセージ */}
                  {error &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <AttentionLabel>
                          {translate(error)}
                        </AttentionLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }
      
                  {/* ワンタイムパスワード入力 */}
                  <CustomDivSubContainer>
                    {translate('resetPassword.label.code')}
                    <TextBox
                      name="oneTimeCode"
                      label={translate('resetPassword.label.code')}
                      maxLength={320}
                      textType="oneTimeCode"
                      placeholder="ワンタイムパスワードを入力"
                      required
                    />
                    {/* ワンタイムパスワード入力欄と新パスワード入力欄の間に空白を入れる */}
                    <EmptyRow xs={12}></EmptyRow>
                  </CustomDivSubContainer>
      
                  {/* パスワード入力 */}
                  <CustomDivSubContainer>
                    {translate('resetPassword.label.password')}
                    <Tooltip title={translate('accountCreateForm.text.passwordHelp')} enterTouchDelay={0}>
                      <HelpIconButton>
                        <HelpIcon fontSize="inherit" />
                      </HelpIconButton>
                    </Tooltip>
                    <TextBox
                      name="password"
                      label={translate('resetPassword.label.password')}
                      maxLength={320}
                      textType="password"
                      placeholder="新しいパスワードを入力"
                      required
                    />
                  </CustomDivSubContainer>
      
                  {/* パスワード再入力 */}
                  <CustomDivSubContainer>
                    {translate('resetPassword.label.passwordConfirmation')}
                    <TextBox
                      name="passwordConfirmation"
                      label={translate('resetPassword.label.passwordConfirmation')}
                      maxLength={320}
                      textType="password"
                      placeholder={translate('resetPassword.placeholder.passwordConfirmation')}
                      required
                    />
                  </CustomDivSubContainer>
      
                  {/* パスワード再設定送信ボタン */}
                  <CustomDivSubContainer>
                    <ButtonM fullWidth type="submit">
                      {translate('resetPassword.button.submit')}
                    </ButtonM>
                  </CustomDivSubContainer>
      
                  {/* ログインに戻るボタン */}
                  <CustomDivSubContainer>
                    <BackToLoginButton
                      fullWidth
                      onClick={() => {
                        dispatch(setResetPassword({}))
                        history.push(loginUrl.url())
                      }}
                    >
                      {translate('resetPassword.button.backToLogin')}
                    </BackToLoginButton>
                  </CustomDivSubContainer>
                </GContainer>
              </ElevatedCard>
            </CustomDivContainer>
          </GItem>
          
          {/* 右余白 */}
          <GItem md={3}></GItem>
        </GContainer>
      </Form>
    </Grid>
  )
}