// import { executePost, KANRI_API_NAME } from '../common/apiCaller'
import { executePost } from '../common/apiCaller'
import { FaqData } from '../dto/faqDto'
// import { GuideTextDto, GuideTextData } from '../dto/guideTextDto'

const apiName = 'public'
const apiPath = '/faq'

/**
 * エンドユーザー向けを全件取得
 */
export const executePostFAQsForEnduser = () => {
  return executePost<FaqData[]>(apiName, apiPath, {"visibleRole": "0"})
}


 
