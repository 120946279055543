import { FC } from 'react'
import { Dialog, DialogContent } from '../common/dialog'
import { Stack, styled } from '@mui/material'
import { translate } from '../../../i18n'
import { TitleLabel } from '../common/titleLabel'
import { FlexboxCenter } from '../common/flexboxes'
import { ButtonS } from '../common/buttons/buttonS'
import { ButtonOutlinedS } from '../common/buttons/buttonOutlinedS'

type DownloadDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

const ButtonWrap = styled('div')({
  display: 'grid',
  gridAutoFlow: 'column',
  columnGap: '12px',
  paddingLeft: '1rem',
  paddingRight: '1rem',
})
const StyledDialogContent = styled(DialogContent)({
  paddingLeft: 8,
  paddingRight: 8,
})
const StyledTitle = styled(TitleLabel)({
  paddingLeft: '1rem',
  paddingRight: '1rem',
})

export const DownloadDialog: FC<DownloadDialogProps> = ({ title, isOpen, onClose, onSubmit }) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        <ButtonWrap>
          <ButtonOutlinedS onClick={onClose}>{translate('usageHistory.dialog.button.no')}</ButtonOutlinedS>
          <ButtonS onClick={onSubmit}>{translate('usageHistory.dialog.button.yes')}</ButtonS>
        </ButtonWrap>
      }
      contentComponent={StyledDialogContent}
    >
      <Stack spacing={1}>
        <FlexboxCenter>
          <StyledTitle>{title}</StyledTitle>
        </FlexboxCenter>
      </Stack>
    </Dialog>
  )
}
