import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { executeGetFacility } from '../../dataAccess/webApi/dao/facilitiesDao'
import { executeGetUsageRecord } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { fromApiYmd, fromApiYmdHms, toApiYmd, toApiHm } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { facilityReservationSelectionCreateUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import { getUseReason } from '../common/useReason'
import { openWindow } from '../../utils/reactUtil'
import { getUrlPathname, QueryParams } from '../common/transition'
import { ReceptViewUrlQueryParams } from '../receiptView/receiptViewService'
import { receiptViewUrl } from '../common/constant/appUrl'
import { GOING_DATETIME_DISPLAY_METHODS } from '../common/constant/projectMst'

interface UrlParams {
  reservationNo: string
}

interface UsageDetail {
  facilityId: string
  facilityName: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  goingToFacilityTime?: string
  goingHomeTime?: string
  status: string
  usageMinute?: string
  name: string
  kana: string
  tel: string
  email: string

  childId: string
  childName: string
  childKana: string

  useReasonCategory?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string
  lunchFlag: string
  snackFlag: string
  citizenNote?: string
  lunchCount?: number
  snackCount?: number

  amount?: number
  usageFee?: number
  useCouponFee?: number
  extentionFee?: number
  nightlyFee?: number
  lunchFee?: number
  snackFee?: number
  otherFee1?: number
  otherFee2?: number
  otherFee3?: number
  reduction?: number
  otherFeeDisplayName1?: string
  otherFeeDisplayName2?: string
  otherFeeDisplayName3?: string
  actualUpdateDatetime?: string
  usageReceiptViewMethod: string

  reservationCancelDatetime?: Date
}

interface FacilityInfo {
  lunchAcceptFlag: string
  snackAcceptFlag: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { reservationNo } = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const [usageDetail, setUsageDetail] = useState<UsageDetail>()
  const [facilityInfo, setFacilityInfo] = useState<FacilityInfo>()
  const [useReason, setUseReason] = useState<GetUseReasonDto>()

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          const [result, useReasonMst] = await Promise.all([
            getUsageDetail(reservationNo),
            getUseReason(),
          ])
          attachAccessData({
            accessData: [
              {
                userIdRegFlag: yesNo.yes,
                childId: result.childId,
                usageDate: toApiYmd(result.usageDate),
                reservationNo,
              },
            ],
          })
          setUsageDetail(result)
          setUseReason(useReasonMst)
          
          const facility = await getFacilityInfo(result.facilityId, toApiYmd(result.usageDate))
          setFacilityInfo(facility)
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goReservation = useCallback(
    () =>
      usageDetail &&
      history.push(facilityReservationSelectionCreateUrl.url(usageDetail.facilityId, usageDetail.childId)),
    [usageDetail]
  )

  const goReceiptView = useCallback(() => {
    if (!usageDetail || !reservationNo) return
    const props = nullPropsToUndefined({
      reservationNo,
      facilityId: usageDetail.facilityId
    })

    openWindow(
      getUrlPathname<QueryParams<ReceptViewUrlQueryParams>>(
        receiptViewUrl.routerPath,
        props
      )
    )
    console.log(usageDetail, openWindow, props);
  }, [usageDetail, reservationNo])

  return {
    usageDetail,
    facilityInfo,
    goReservation,
    useReason,
    goReceiptView,
  }
}

const getUsageDetail = async (reservationNo: string) => {
  const response = await executeGetUsageRecord(reservationNo)
  const {
    usageDate,
    useFromDatetime,
    useToDatetime,
    goingToFacilityDatetime,
    goingHomeDatetime,
    reservationCancelDatetime,
    usageMinute,
    goingDatetimeDisplayMethods,
    ...other
  } = response.result

  return nullPropsToUndefined({
    ...other,
    usageDate: fromApiYmd(usageDate),
    useFromDatetime: fromApiYmdHms(useFromDatetime),
    useToDatetime: fromApiYmdHms(useToDatetime),
    goingToFacilityTime:
      goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY
        ? toApiHm(fromApiYmdHms(goingToFacilityDatetime))
        : null,
    goingHomeTime:
      goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY
        ? toApiHm(fromApiYmdHms(goingHomeDatetime))
        : null,
    reservationCancelDatetime: fromApiYmdHms(reservationCancelDatetime),
    usageMinute: formatLocaleTimeAmountHm(usageMinute),
  })
}

const getFacilityInfo = async (facilityId: string, usageDate: string) => {
  const response = await executeGetFacility(facilityId, { targetDate: usageDate })
  const { lunchAcceptFlag, snackAcceptFlag } = response.result
  return { lunchAcceptFlag, snackAcceptFlag }
}
