// メール設定情報に関する共通処理を定義
import { executeGetSendingFlagMailSetting } from '../../dataAccess/webApi/dao/sendingFlagMailSettingDao'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { GetSendingFlagMailSettingDto } from '../../dataAccess/webApi/dto/facilityMailSettingDto'

/**
 * @returns 送信フラグ情報
 */
export const getSendingFlagMailSetting = async (input: GetSendingFlagMailSettingDto) => {
  const apiResponse = await executeGetSendingFlagMailSetting(input)
  const result = nullPropsToUndefined(apiResponse.result)
  return result
}
