import { executeDelete, executeGetNoResultError, executePost, executePut } from '../common/apiCaller'
import {
  GetAccountDto,
  GetAccountIsSsoFirstLoginDto,
  PostAccountIdentityVerifySubmitInputDto,
  PostAccountInputDto,
  PutAccountIdentityVerifyResendEmailInputDto,
  PutAccountInputDto,
} from '../dto/accountDto'

const apiName = 'citizen'
const apiPath = '/account'

export const executeGetAccount = () => {
  return executeGetNoResultError<GetAccountDto>(apiName, apiPath)
}

export const resultCodePutAccount = {
  notChangedEmail: 2,
  notChangedTel: 3,
  alreadyRegisteredEmail: 4,
  alreadyRegisteredTel: 5,
  notMatchedCurrentPassword: 6,
  violatePolicyNewPassword: 7,
} as const

export const executeGetAccountIsSsoFirstLogin = () => {
  return executeGetNoResultError<GetAccountIsSsoFirstLoginDto>(apiName, `${apiPath}/is-sso-first-login`)
}

export const executePutAccount = (input: PutAccountInputDto) => {
  return executePut<null>(apiName, apiPath, input)
}

export const executePostAccount = (input: PostAccountInputDto) => {
  return executePost<null>(apiName, apiPath, input)
}

export const executePostAccountIdentityVerifySubmit = (input: PostAccountIdentityVerifySubmitInputDto) => {
  return executePost<null>(apiName, `${apiPath}/identity-verify/submit`, input)
}

export const executePutAccountIdentityVerifyResendEmail = (input: PutAccountIdentityVerifyResendEmailInputDto) => {
  return executePut<null>(apiName, `${apiPath}/identity-verify/resend-email`, input)
}

export const executeDeleteAccount = () => {
  return executeDelete<null>(apiName, apiPath)
}

export const executePutAccountSsoLinkUnlink = () => {
  return executePut<null>(apiName, `${apiPath}/sso-link/unlink`)
}