import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface MemberAddChildEntry {
  name: string
  kana: string
  birthday: ElapsedMillisecond
  brothersSistersFlag: string
  multipleBirthsFlag: string
  gender: string
  bloodType: string
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
}

interface MemberAddChildState {
  childEntry?: MemberAddChildEntry
}

const initialState: MemberAddChildState = {}

export const memberAddChildSlice = createSlice({
  name: 'memberAddChild',
  initialState,
  reducers: {
    setMemberAddChild: (state, action: PayloadAction<MemberAddChildEntry>) => {
      state.childEntry = action.payload
    },
    clearMemberAddChild: (state) => {
      delete state.childEntry
    },
  },
})
export const { setMemberAddChild, clearMemberAddChild } = memberAddChildSlice.actions
export const selectMemberAddChildEntry = (state: RootState) => state.memberAddChild.childEntry
export default memberAddChildSlice.reducer
