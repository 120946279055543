import { useState, useEffect } from 'react'
import { Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectSystemControl } from '../../containers/common/store/slices/systemControl'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { GContainer, GItem } from '../components/common/grids'
import { DownloadIcon } from '../components/common/icons/downloadIcon'
import { SubTitle } from '../components/common/subTitle'
import { TitleLabel } from '../components/common/titleLabel'
import awsmobile from '../../aws-exports'
import {
  ListObjectsCommand,
  ListObjectsCommandInput,
  ListObjectsCommandOutput,
  S3Client,
} from '@aws-sdk/client-s3'
import { Auth } from 'aws-amplify'

interface DynamicObject {
  [key: string]: any;
}

// フォルダ種別とS3フォルダのオブジェクト
const folderMap: DynamicObject = {
  'citizen': 'citizen',
  'facilityIntro': 'facility-introduction',
  'facilityOpe': 'facility-operation',
  'lgStaff': 'lg-staff',
}

export const Manual = () => {
  const [systemManualPdfUrl, setSystemManualPdfUrl] = useState<string>('')

  const serviceTitle = useSelector(selectSystemControl).serviceTitle
  const bucketName = useSelector(selectSystemControl).s3BucketNameManual
  const region = awsmobile.aws_project_region

  useEffect(() => {
    setS3Url()
  }, [systemManualPdfUrl])

  const openClick = () => {
    window.open(systemManualPdfUrl)
  }
  
  const setS3Url = async () => {
    // 認証情報を使用して S3 クライアントを初期化
    const s3 = new S3Client({
      region: region,
      credentials: await Auth.currentCredentials(),
    })
  
    // Prefix 配下のオブジェクトをリスト化するためのパラメータ
    const param: ListObjectsCommandInput = {
      Bucket: bucketName,
      Prefix: folderMap['citizen'] + '/',
    }
    
    // オブジェクトリストを取得
    const listObjectsCommandOutput: ListObjectsCommandOutput = await s3.send(new ListObjectsCommand(param))
    
    // Prefix 直下のファイルのみ抽出
    const targetFiles = listObjectsCommandOutput.Contents?.sort((a, b) => {
        // 最終更新日で降順ソート
        const timeA = a.LastModified?.getTime() ?? 0
        const timeB = b.LastModified?.getTime() ?? 0
  
        return timeB - timeA
      })
      .map((s3Object) => s3Object.Key)
      .filter((s3ObjectKey) => ! s3ObjectKey?.endsWith('/') && s3ObjectKey?.split('/')?.length === 2) // フォルダを除外
      .filter((s3ObjectKey): s3ObjectKey is string => s3ObjectKey !== undefined)
      .map((s3ObjectKey) => s3ObjectKey.split('/')[1]) // ファイル名を取得
  
    // 最新の更新ファイルを取得
    const targetFileName = targetFiles?.[0] ?? ''
    
    // S3 オブジェクト URL を生成
    const systemManualPdfUrl = `https://${bucketName}.s3.${region}.amazonaws.com/${folderMap['citizen']}/${encodeURIComponent(targetFileName)}`
    
    setSystemManualPdfUrl(systemManualPdfUrl)
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <GContainer>
              <GItem>
                <TitleLabel>{serviceTitle}</TitleLabel>
              </GItem>
              <GItem xs={12}>
                <SubTitle title={'操作マニュアル'} />
              </GItem>
            </GContainer>
            <div>
              {/* 
              マニュアルはダウンロードを強制しています。
              download属性は同一オリジンの URL と、 blob:、 data: の各スキームでのみ動作するので、
              download属性は使用せず
              S3側でpdfのコンテンツタイプをapplication/octet-streamに設定してダウンロードを強制させるようにした
              https://developer.mozilla.org/ja/docs/Web/HTML/Element/a
              */}
              <ButtonL fullWidth onClick={openClick} startIcon={<DownloadIcon />}>
                {translate('manual.button.download')}
              </ButtonL>
            </div>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
