import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { availabilityStatus, yesNo, Flag } from '../../containers/common/constant/classification'
import {
  clearFacilityReserve,
  FacilityReserveEntry,
  selectFacilityReserveEntry,
  selectReserveModifyUpdateDatetime,
} from '../../containers/common/store/slices/facilityReserve'
import { executePostReservation, executePutReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { toApiYmd, toApiYmdHms } from '../../utils/dateUtil'
import { undefinedPropsToNull } from '../../utils/objectUtil'
import { blankToNull } from '../../utils/stringUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { facilityReservationCompletionUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { dispMode } from '../facilityReservationCompletion/facilityReservationCompletionService'
import { getLatestAddOperationDatetime, useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { ChildReservationInfoType } from '../common/reservation'
import { getProjectIds } from '../common/facility'
import { ReservationRequirementSetting, getReservationRequirementSettingsForMultiDate } from '../common/projectMst'
import { ReservationConfirmation } from '../../views/components/common/reservationDetail/reservationDetailGItems'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { GOING_DATETIME_DISPLAY_METHODS } from '../common/constant/projectMst'

type ReservationReference = ReservationConfirmation & {
  useReason: GetUseReasonDto,
  goingDatetimeDisplayMethods: GOING_DATETIME_DISPLAY_METHODS
}

export const useAction = () => {
  const errorHandler = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const updateDatetime = useSelector(selectReserveModifyUpdateDatetime)
  

  const [reservationRequirementSetting, setReservationRequirementSetting] = useState<ReservationRequirementSetting | null>(null);

  const reservationReference: ReservationReference | undefined = useMemo(() => {
    if (reservationRequirementSetting && reserveEntry?.facility && reserveEntry?.parentInfo && reserveEntry?.input && reserveEntry?.useReason) {
      const { facility, parentInfo, useReasonCategory, useReasonDetailCategory, useReasonDetailDescription, input, useReason, childInfoList } = reserveEntry
      
      return {
        ...input,
        ...facility,
        ... reservationRequirementSetting,
        facilityName: facility.facilityName,
        useReasonCategory: useReasonCategory,
        useReasonDetailCategory: useReasonDetailCategory,
        useReasonDetailDescription: useReasonDetailDescription,
        childInfoList,
        parentInfo,
        useReason: {...useReason},
      }
    }
  }, [reservationRequirementSetting])

  const [reservationResultCode, setReservationResultCode] = useState<number>()

  // 初期表示
  useEffect(() => {
    const fetchData = async () => {
      addOperationLog({ operationId: OperationId.OP_00000001 });
      
      if (!reserveEntry || !reserveEntry.facility || !reserveEntry.usageDatetimes) {
        return;
      }
  
      const { facilityId, usageDatetimes } = reserveEntry;
      const toDates = usageDatetimes.map((usageDatetime) => usageDatetime.usageDate);
  
      try {
        const projectIds = await getProjectIds(facilityId, toDates);
        const reservationRequirementSetting = await getReservationRequirementSettingsForMultiDate(projectIds, toDates);
        if (reservationRequirementSetting) {
          setReservationRequirementSetting(reservationRequirementSetting);
        } else {
          console.log('Fetch ERROR');
        }
      } catch (error) {
        console.error('Error fetching reservation requirement setting:', error);
      }
    };
  
    fetchData(); // 非同期関数を呼び出し
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserveEntry]); 


  // 上記の内容で申し込み
  const decide = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000026 })
    const operationLogDatetime = castNonNullable(getLatestAddOperationDatetime());
    
    if (reserveEntry == null) {
      // 前画面情報無しの場合はエラーなので何もしない
      return
    }
    
    dispatch(
      showLoading({
        process: errorHandler(async () => {
          if (reserveEntry.reservationNo == null) {
            // 予約新規登録時
            // childInfoList の長さが 0 のときには何もせずに処理を終える
            // ※アプリケーションの仕様上この画面に到達するときには childInfoList の長さは 1 以上のはず
            if (reserveEntry.childInfoList.length > 0) {
              let clearFacilityReserveFlg: boolean = false

              const response = await postReservation(reserveEntry, operationLogDatetime)
              if (response.resultCode) {
                // 登録失敗時
                setReservationResultCode(response.resultCode)
              } else {
                // 登録成功時
                const displayMode = getDisplayMode(reserveEntry)
                history.push(
                  facilityReservationCompletionUrl.url(reserveEntry.facilityId, reserveEntry.childInfoList[0].childId, displayMode)
                )
                clearFacilityReserveFlg = true
              }

              if (clearFacilityReserveFlg) dispatch(clearFacilityReserve())
            }
          } else {
            // 予約更新時
            if (updateDatetime) {
              const response = await putReservation(reserveEntry, reserveEntry.childInfoList[0], updateDatetime, operationLogDatetime)
              if (response.resultCode) {
                // 登録失敗時
                setReservationResultCode(response.resultCode)
              } else {
                // 登録成功時
                const displayMode = getDisplayMode(reserveEntry)
                history.push(
                  facilityReservationCompletionUrl.url(reserveEntry.facilityId, reserveEntry.childInfoList[0].childId, displayMode)
                )
                dispatch(clearFacilityReserve())
              }
            }
          }
        }, reserveEntry),
        isHiddenMain: false,
      })
    )
  }, [reserveEntry, updateDatetime, addOperationLog, dispatch, errorHandler, history])

  return {
    reserveEntry,
    reservationReference,
    reservationResultCode,
    decide,
  }
}

const getRegistDataCommonForPut = (facilityReserveEntry: FacilityReserveEntry, childReservationInfo: ChildReservationInfoType) => {
  const entryInput = castNonNullable(facilityReserveEntry.input)
  const symptom = childReservationInfo.symptom && childReservationInfo.symptom.symptom !== [] ? Object.values(childReservationInfo.symptom.symptom).filter(s => s !== "").join(",") : null
  const bodyTemperature = childReservationInfo.temperature?.integer ? `${childReservationInfo.temperature?.integer}.${childReservationInfo.temperature?.few?? '0'}`: null
  
  return {
    /* 施設ID */
    facilityId: facilityReserveEntry.facilityId,
    /* お子様ID */
    childId: childReservationInfo.childId,
    /* お子様生年月日 */
    childBirth: childReservationInfo.childInfo?.birthday,
    /** 病児病後児関連 */
    hasMedicalDocSubmitted: (childReservationInfo.medicalDoc) ? childReservationInfo.medicalDoc.hasSubmitted: Flag.OFF, 
    medicalDocs: (childReservationInfo.medicalDoc) ? childReservationInfo.medicalDoc.docs : [],
    symptoms: symptom,
    symptomOther: (childReservationInfo.symptom?.additional !== '' ? childReservationInfo.symptom?.additional : null),
    diagonosis: (childReservationInfo.diagonosis?.input !== '' ? childReservationInfo.diagonosis?.input : null),
    diagonosisOther: (childReservationInfo.diagonosis?.additional !== '' ? childReservationInfo.diagonosis?.additional : null),
    diagonosis2: (childReservationInfo.diagonosis?.input2 !== '' ? childReservationInfo.diagonosis?.input2 : null),
    diagonosisOther2: (childReservationInfo.diagonosis?.additional2 !== '' ? childReservationInfo.diagonosis?.additional2 : null),
    bodyTemperature: bodyTemperature,
    conditionCheck: childReservationInfo.conditionCheck,
    conditionCheckNote: blankToNull(childReservationInfo.conditionCheckNote),
    foodAllergyFlag: childReservationInfo.allergy?.foodFlag?? Flag.OFF,
    febrileSeizuresFlag: childReservationInfo.febrileSeizures?? Flag.OFF,
    uploadDate: Date.now().toString(),
    
    /* キャンセル待ち予約フラグ 
     *   複数日時の同時申込ではキャンセル待ちを選択できないため usageDatetimes の最初の要素だけを見れば十分
     */
    cancelWaitFlag: facilityReserveEntry.usageDatetimes[0].status === availabilityStatus.wait ? yesNo.yes : yesNo.no,
    /* 利用理由 市民画面で必須入力なのでnonNull */
    useReasonCategory: castNonNullable(facilityReserveEntry.useReasonCategory),
    /* 利用詳細事由 */
    useReasonDetailCategory: blankToNull(facilityReserveEntry.useReasonDetailCategory),
    /* 利用詳細事由テキスト入力 */
    useReasonDetailDescription: blankToNull(facilityReserveEntry.useReasonDetailDescription),
    /* 昼食有無 */
    lunchFlag: entryInput.lunchFlag,
    /* おやつ有無 */
    snackFlag: entryInput.snackFlag,
    /* 見送り時のキャンセル待ち可否 */
    postponeCancelWaitFlag: entryInput.postponeCancelWaitFlag,
    /* 備考 */
    citizenNote: blankToNull(entryInput.citizenNote),
    /* 在住種別 */
    residenceCategory: castNonNullable(facilityReserveEntry.parentInfo?.residenceCategory),
  }
}

const getRegistDataCommonForPost = (facilityReserveEntry: FacilityReserveEntry) => {
  const entryInput = castNonNullable(facilityReserveEntry.input)
  
  const childInfoList = convertChildInfoList(facilityReserveEntry.childInfoList)
  
  return {
    /* 施設ID */
    facilityId: facilityReserveEntry.facilityId,
    childInfoList,
    uploadDate: Date.now().toString(),
    
    /* キャンセル待ち予約フラグ 
     *   複数日時の同時申込ではキャンセル待ちを選択できないため usageDatetimes の最初の要素だけを見れば十分
     */
    cancelWaitFlag: facilityReserveEntry.usageDatetimes[0].status === availabilityStatus.wait ? yesNo.yes : yesNo.no,
    /* 利用理由 市民画面で必須入力なのでnonNull */
    useReasonCategory: castNonNullable(facilityReserveEntry.useReasonCategory),
    /* 利用詳細事由 */
    useReasonDetailCategory: blankToNull(facilityReserveEntry.useReasonDetailCategory),
    /* 利用詳細事由テキスト入力 */
    useReasonDetailDescription: blankToNull(facilityReserveEntry.useReasonDetailDescription),
    /* 昼食有無 */
    lunchFlag: entryInput.lunchFlag,
    /* おやつ有無 */
    snackFlag: entryInput.snackFlag,
    /* 見送り時のキャンセル待ち可否 */
    postponeCancelWaitFlag: entryInput.postponeCancelWaitFlag,
    /* 備考 */
    citizenNote: blankToNull(entryInput.citizenNote),
    /* 在住種別 */
    residenceCategory: castNonNullable(facilityReserveEntry.parentInfo?.residenceCategory),
  }
}

const postReservation = async (facilityReserveEntry: FacilityReserveEntry, operationLogDatetime: string) => {
  const goingDatetimes = facilityReserveEntry.goingTimes.map((goingTime) => ({
    goingToFacilityDatetime:
      goingTime.goingToFacilityDate && goingTime.goingToFacilityTime
        ? toApiYmdHms(new Date(`${goingTime.goingToFacilityDate} ${goingTime.goingToFacilityTime}`))
        : null,
    goingHomeDatetime:
      goingTime.goingHomeDate && goingTime.goingHomeTime
        ? toApiYmdHms(new Date(`${goingTime.goingHomeDate} ${goingTime.goingHomeTime}`))
        : null,
  }))
  
  return executePostReservation(
    undefinedPropsToNull({
      ...getRegistDataCommonForPost(facilityReserveEntry),
      usageDatetimes: facilityReserveEntry.usageDatetimes.map((v) => ({
        /* 利用日 */
        usageDate: toApiYmd(v.usageDate),
        /* 利用開始日時 */
        useFromDatetime: toApiYmdHms(v.useFromDatetime),
        /* 利用終了日時 */
        useToDatetime: toApiYmdHms(v.useToDatetime),
        /** fileアップロード用日*/
        usageDateForUpload: v.useFromDatetime
      })),
      /** 登園・降園予定時間 */
      goingDatetimes,
      operationLogDatetime,
    })
  )
}

const putReservation = async (
  facilityReserveEntry: FacilityReserveEntry,
  childInfo: ChildReservationInfoType,
  updateDatetime: string,
  operationLogDatetime: string,
) => {
  const goingDatetimes = facilityReserveEntry.goingTimes.map((goingTime) => ({
    goingToFacilityDatetime:
      goingTime.goingToFacilityDate && goingTime.goingToFacilityTime
        ? toApiYmdHms(new Date(`${goingTime.goingToFacilityDate} ${goingTime.goingToFacilityTime}`))
        : null,
    goingHomeDatetime:
      goingTime.goingHomeDate && goingTime.goingHomeTime
        ? toApiYmdHms(new Date(`${goingTime.goingHomeDate} ${goingTime.goingHomeTime}`))
        : null,
  }))

  return executePutReservation(
    castNonNullable(facilityReserveEntry.reservationNo),
    undefinedPropsToNull({
      ...getRegistDataCommonForPut(facilityReserveEntry, childInfo),
      /* 利用日 */
      usageDate: toApiYmd(facilityReserveEntry.usageDatetimes[0].usageDate),
      /* 利用開始日時 */
      useFromDatetime: toApiYmdHms(facilityReserveEntry.usageDatetimes[0].useFromDatetime),
      /* 利用終了日時 */
      useToDatetime: toApiYmdHms(facilityReserveEntry.usageDatetimes[0].useToDatetime),
      /* 更新日時 */
      updateDatetime: updateDatetime,
      /** fileアップロード用日*/
      usageDateForUpload: facilityReserveEntry.usageDatetimes[0].useFromDatetime,
      operationLogDatetime,
      /** 登園・降園予定時間 */
      goingDatetimes,
    })
  )
}

const getDisplayMode = (reserveEntry: FacilityReserveEntry) => {
  let mode

  const facility = castNonNullable(reserveEntry.facility)

  // 新規登録
  if (reserveEntry.reservationNo == null) {
    // キャンセル待ち予約
    if (reserveEntry.usageDatetimes[0].status === availabilityStatus.wait) {
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        // 即時予約
        mode = dispMode.insertRealtimeCanselWait
        // 承認予約
      } else {
        mode = dispMode.insertApprovalCanselWait
      }
    } else {
      // 即時予約
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.insertRealtime
        // 承認予約
      } else {
        mode = dispMode.insertApproval
      }
    }
    // 変更
  } else {
    // キャンセル待ち予約
    if (reserveEntry.usageDatetimes[0].status === availabilityStatus.wait) {
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.updateRealtimeCanselWait
      } else {
        mode = dispMode.updateApprovalCanselWait
      }
    } else {
      // 即時予約
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.updateRealtime
        // 承認予約
      } else {
        mode = dispMode.updateApproval
      }
    }
  }

  return mode
}

const convertChildInfoList = (childReservationInfoList: ChildReservationInfoType[]) => {
  return childReservationInfoList.map((childReservationInfo) => {
    const symptom = childReservationInfo.symptom && childReservationInfo.symptom.symptom !== [] ? Object.values(childReservationInfo.symptom.symptom).filter(s => s !== "").join(",") : null
    const bodyTemperature = childReservationInfo.temperature?.integer ? `${childReservationInfo.temperature?.integer}.${childReservationInfo.temperature?.few?? '0'}`: null
    
    return {
      /* お子様ID */
      childId: childReservationInfo.childId,
      /* お子様生年月日 */
      childBirth: childReservationInfo.childInfo?.birthday ?? '',  // このページでは childInfo が undefined であることはありえない
      /** 病児病後児関連 */
      hasMedicalDocSubmitted: (childReservationInfo.medicalDoc) ? childReservationInfo.medicalDoc.hasSubmitted: Flag.OFF, 
      medicalDocs: (childReservationInfo.medicalDoc) ? childReservationInfo.medicalDoc.docs : [],
      symptoms: symptom,
      symptomOther: (childReservationInfo.symptom && childReservationInfo.symptom.additional !== '' ? childReservationInfo.symptom.additional : null),
      diagonosis: (childReservationInfo.diagonosis && childReservationInfo.diagonosis.input !== '' ? childReservationInfo.diagonosis.input : null),
      diagonosisOther: (childReservationInfo.diagonosis && childReservationInfo.diagonosis.additional !== '' ? childReservationInfo.diagonosis.additional : null),
      diagonosis2: (childReservationInfo.diagonosis && childReservationInfo.diagonosis.input2 !== '' ? childReservationInfo.diagonosis.input2 : null),
      diagonosisOther2: (childReservationInfo.diagonosis && childReservationInfo.diagonosis.additional2 !== '' ? childReservationInfo.diagonosis.additional2 : null),
      bodyTemperature: bodyTemperature,
      conditionCheck: childReservationInfo.conditionCheck ?? null,
      conditionCheckNote: blankToNull(childReservationInfo.conditionCheckNote),
      foodAllergyFlag: childReservationInfo.allergy?.foodFlag?? Flag.OFF,
      febrileSeizuresFlag: childReservationInfo.febrileSeizures?? Flag.OFF,
    }
  })
}