import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { clearFacilitySearchResult, setFacilitySearchCondition } from '../common/store/slices/facilitySearch'
import {
  executePostGuideText, guideTextId,
} from '../../dataAccess/webApi/dao/guideTextDao'
import { GuideTextData } from '../../dataAccess/webApi/dto/guideTextDto'
import { datePropsToNumber, nullPropsToUndefined } from '../../utils/objectUtil'
import { facilitySearchConditionUrl, facilitySearchResultUrl } from '../common/constant/appUrl'
import { useHistory } from 'react-router-dom'
import { splitRemoveBlank } from '../../utils/stringUtil'
import { selectFacilitySearchSettingMsts, selectProjectMsts, setFacilitySearchSettingMsts, setProjectMsts } from '../common/store/slices/facilitySearchSetting'
import { getFacilitySearchSettingMsts, getProjectMsts } from '../home/homeService'

interface FacilitySearchSettingMsts {
  projectCategoryId: string
  projectCategoryName: string
  intermediateDisplayFlag: string
  searchConditionDisplayFlag: string
  ageSearchFlag: string | null
  projectSearchFlag: string | null
  facilityCategorySearchFlag: string | null
  areaSearchFlag: string | null
  reservationSearchFlag: string | null
  otherSearchFlag: string | null
  useReasonSearchFlag: string | null
  information: string | null
  createUser: string
  createDatetime: string
  updateUser: string
  updateDatetime: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const facilitySearchSettingMsts = useSelector(selectFacilitySearchSettingMsts)
  const projectMsts = useSelector(selectProjectMsts)
  const history = useHistory()

  const [guideText, setGuideText] = useState<GuideTextData>()
  const [bottomGuideText, setBottomGuideText] = useState<GuideTextData>()

  useEffect(() => {
    const initialize = async () => {
      {
        const guideText = await getContactCommonGuideText()
        setGuideText(guideText)
      }

      {
        const bottomGuide = await getContactCommonBottomGuideText()
        setBottomGuideText(bottomGuide)
      }

      {
        const facilitySearchSettingMstsRes = await getFacilitySearchSettingMsts()
        dispatch(setFacilitySearchSettingMsts(facilitySearchSettingMstsRes))
      }

      {
        const projectMstsRes = await getProjectMsts()
        dispatch(setProjectMsts(projectMstsRes))
      }
    }
    dispatch(showLoading(errorHandle(initialize)))
  }, [])

  const handleClick = (facilitySearchSettingMst: FacilitySearchSettingMsts) => {
    const projectIds = projectMsts?.filter((projectMst: any) => projectMst.projectCategoryId === facilitySearchSettingMst.projectCategoryId).map((projectMst: any) => projectMst.projectId)

    if (facilitySearchSettingMst.searchConditionDisplayFlag === '0') {
      dispatch(
        setFacilitySearchCondition(
          nullPropsToUndefined(
            datePropsToNumber({
              // 空の場合全件検索してしまうため、空の場合は存在しないIDを直接指定
              projectIds: projectIds?.length ? splitRemoveBlank(',', projectIds?.join(',')) : ['0'],
            })
          )
        )
      )

      dispatch(clearFacilitySearchResult())
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({
        ...history.location,
        state: {
          isKeep: true,
          projectIds: [],
        },
      })
      history.push(facilitySearchResultUrl.url())
    } else {
      history.push({
        pathname: facilitySearchConditionUrl.url(facilitySearchSettingMst.projectCategoryId),
        state: { projectIds },
      })  
    }
  }

  return {
    guideText,
    bottomGuideText,
    facilitySearchSettingMsts,
    handleClick,
  }
}

/**
 * 案内文を取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getContactCommonGuideText = async () => {
  const response = await executePostGuideText(guideTextId.projectTopCommonGuideText)
  return response.result
}

/**
 * 案内文下段を取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getContactCommonBottomGuideText = async () => {
  const guideTextId = 6
  const response = await executePostGuideText(guideTextId)
  return response.result
}
