import { Control } from 'react-hook-form';
import { Inputs, facilityReservationInputKey } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import { CheckBox } from '../inputs/checkBox';
import { translate } from '../../../../i18n'
import { GItem } from '../grids';
import { Grid } from '@mui/material';
import { yesNo } from '../../../../containers/common/constant/classification';

interface ReservationDetailConsentCheckProps {
    name: facilityReservationInputKey
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const ReservationDetailConsentCheck = (props: ReservationDetailConsentCheckProps) => {
    const { options, name, control } = props;
    const valueLabels = options?.map((option) => (
        {
            value: option.code,
            label: option.name
        }
    ))

    return (
        <>
            {valueLabels?.map((valueLabel, index) => (
                <div key={valueLabel.value} style={{ marginBottom: '8px', display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ width: '30px'}}>{index + 1}.</div>
                    <div style={{ flex: 1 }}>{valueLabel.label}</div>
                </div>
            ))}
            <Grid item>
                <GItem xs={12}>
                    <CheckBox
                        name={name}
                        label={translate('facilityReservationForm.form.medical.consentCheck')}
                        valueAtCheck={yesNo.yes}
                        required
                        valicationMessageKeys={{ required: 'system.error.consentInput' }}
                        control={control}
                    >
                        {translate('facilityReservationForm.form.common.isComfirm')}
                    </CheckBox>
                </GItem>
            </Grid>
        </>
    )
}

export default ReservationDetailConsentCheck;
