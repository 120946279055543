import React from 'react';
import { Control } from 'react-hook-form';
import { Inputs, facilityReservationInputKey } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { RadioButtonGroup } from '../inputs/radioButtonGroup';
import { getYesNos } from '../../../../containers/common/codeMaster';

interface ReservationDetailAllergyProps {
    name: facilityReservationInputKey;
    control: Control<Inputs>;
}

const ReservationDetailAllergy = (props: ReservationDetailAllergyProps) => {
    const { name } = props;

    return (
        <>
            {/** 食物アレルギー有無 */}
            <RadioButtonGroup
                name={`${name}.foodFlag`}
                label="食物アレルギー有無"
                row
                required
                buttonValueLabels={getYesNos().reverse()}
            />
        </>
    );
}

export default ReservationDetailAllergy;
