import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fromApiHms } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { yesNo, interviewStatus } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { interviewReservationDeleteUrl, interviewReservationSelectionUpdateUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { Interview, getInterview } from '../common/interview'
import { getFacility } from '../common/facility'
import { showLoading } from '../common/store/slices/application'

interface UrlParams {
  interviewNo: string
}

type InterviewDetail = {
  cancelLimit?: number
  cancelLimitTime?: Date
  cancelNote?: string
  cancelLimitHolidayOption?: string
  immediatelyReservationFlag?: string
} & Interview

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { interviewNo } = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const [interviewDetail, setInterviewDetail] = useState<InterviewDetail>()

  // 初期表示
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          const result = await getInterviewDetail(interviewNo)
          attachAccessData({ accessData: [{ userIdRegFlag: yesNo.yes, childId: result.childId, interviewNo }] })

          setInterviewDetail(result)
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // キャンセルボタン押下
  const goCancel = useCallback(
    (status: string) => {
      if (status === interviewStatus.wait) {
        addOperationLog({ operationId: OperationId.OP_00000032 })
      } else {
        addOperationLog({ operationId: OperationId.OP_00000031 })
      }

      history.push(interviewReservationDeleteUrl.url(interviewNo))
    },
    [history, interviewNo, addOperationLog]
  )

  // 変更ボタン押下
  const goChange = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000035 })

    history.push(interviewReservationSelectionUpdateUrl.url(interviewNo))
  }, [history, interviewNo, addOperationLog])

  return {
    interviewDetail,
    goCancel,
    goChange
  }
}

/**
 * 面談情報取得
 */
const getInterviewDetail = async (interviewNo: string) => {
  const interview = await getInterview(interviewNo)
  const facility = await getFacility(interview.facilityId, interview.interviewDate)

  return nullPropsToUndefined({
    ...interview,
    cancelLimit: facility.interviewCancelLimit,
    cancelLimitTime: fromApiHms(facility.interviewCancelLimitTime),
    cancelNote: facility.interviewCancelNote,
    cancelLimitHolidayOption: facility.interviewCancelLimitHolidayOption,
    immediatelyReservationFlag: facility.interviewImmediatelyReservationFlag,
  })
}
