import { FormHelperText, styled } from '@mui/material'
import { Control, useFormState } from 'react-hook-form'
import { getValueByNameStr } from '../../../utils/objectUtil'

interface ErrorTextProps {
  name: string
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
}

export const ErrorTextBase = styled(FormHelperText)({
  color: '#c53030',
  fontWeight: 'bold',
})

export const ErrorText = (props: ErrorTextProps) => {
  const { name, control } = props
  const { errors } = useFormState({ name, control, exact: true })
  const error = getValueByNameStr(errors, name)
  return <> {!!error && <ErrorTextBase>{error.message}</ErrorTextBase>}</>
}
