import React from 'react';
import { facilityReservationInputKey } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { RadioButtonGroup } from '../inputs/radioButtonGroup';
import { getYesNos } from '../../../../containers/common/codeMaster';

interface ReservationDetailMedicalDocSubmittion {
    name: facilityReservationInputKey;
}

const ReservationDetailMedicalDocSubmittion = (props: ReservationDetailMedicalDocSubmittion) => {
    const { name } = props;

    return (
        <>
            {/** 医師連絡票有無 */}
            <RadioButtonGroup
                name={`${name}.medicalDoc.hasSubmitted`}
                label="医師連絡票有無"
                row
                required
                buttonValueLabels={getYesNos().reverse()}
            />
        </>
    );
}

export default ReservationDetailMedicalDocSubmittion;
