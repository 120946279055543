import { Grid, Stack } from '@mui/material'
import { informationUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/home/homeService'
import { translate } from '../../i18n'
import { ButtonErrorL } from '../components/common/buttons/ButtonErrorL'
import { ButtonL } from '../components/common/buttons/buttonL'
import { CityFacilityInformationCard } from '../components/common/cityFacilityInformationCard'
import { RightItem } from '../components/common/gridItems'
import { FacilitySearchIcon } from '../components/common/icons/facilitySearchIcon'
import { MyFacilityIcon } from '../components/common/icons/myFacilityIcon'
import { ReserveStatusCheckIcon } from '../components/common/icons/reserveStatusCheckIcon'
import { UsageHistoryIcon } from '../components/common/icons/usageHistoryIcon'
import { Link } from '../components/common/link'
import { SubTitle } from '../components/common/subTitle'

export const Home = () => {
  const {
    informations,
    linkSettingsList,
    showCouponFunctionFlag,
    goMyFacility,
    goReservationStatusList,
    goUsageHistory,
    goCoupon,
    handleClickSearchFacility,
  } = useAction()
  
  if (informations == null) {
    return null
  }
  let firstChildcareTicketButton
  if (showCouponFunctionFlag) {
    firstChildcareTicketButton = (
      <>
        <Grid item xs={12} md={6}>
          <ButtonErrorL fullWidth onClick={goCoupon}>
            {translate('home.button.firstChildcareTicket')}
          </ButtonErrorL>
        </Grid>
      </>
    )
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <SubTitle title={translate('home.text.informationLabel')} />
              {!!informations.length ? (
                <div>
                  <Grid container rowSpacing={1}>
                    {informations.map((info) => (
                      <Grid item xs={12} key={info.informaionNo}>
                        <CityFacilityInformationCard {...info} />
                      </Grid>
                    ))}
                    <RightItem item xs={12}>
                      <Link to={informationUrl.url()}>{translate('home.text.toInformation')}▶</Link>
                    </RightItem>
                  </Grid>
                </div>
              ) : (
                <div>{translate('home.text.noInformation')}</div>
              )}
            </Stack>

            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ButtonL
                    fullWidth
                    onClick={handleClickSearchFacility}
                    startIcon={<FacilitySearchIcon />}
                  >
                    {translate('home.button.facilitySearch')}
                  </ButtonL>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ButtonL fullWidth onClick={goMyFacility} startIcon={<MyFacilityIcon />}>
                    {translate('home.button.myFacility')}
                  </ButtonL>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ButtonL fullWidth onClick={goReservationStatusList} startIcon={<ReserveStatusCheckIcon />}>
                    {translate('home.button.reserveStatusCheck')}
                  </ButtonL>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ButtonL fullWidth onClick={goUsageHistory} startIcon={<UsageHistoryIcon />}>
                    {translate('home.button.usageHistory')}
                  </ButtonL>
                </Grid>
                {firstChildcareTicketButton}
              </Grid>
            </div>

            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <SubTitle title={translate('home.text.links')} />
              </Grid>
              {/*
              <Grid item xs={12}>
                <Link href={aboutTemporaryCustodyOfInfantsUrl} target="_blank">
                  {translate('home.button.aboutTemporaryCustodyOfInfants')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href={aboutTemporaryChildcareBusinessUrl} target="_blank">
                  {translate('home.button.aboutTemporaryChildcareBusiness')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href={aboutFirstChildcareTicketUrl} target="_blank">
                  {translate('home.button.aboutFirstChildcareTicket')}
                </Link>
              </Grid>
              */}
              
              {linkSettingsList && linkSettingsList.map((link) => (
                <Grid key={link.linkId} item xs={12}>
                  <Link href={link.linkUrl || ""} target="_blank">
                    {link.linkName}
                  </Link>
                </Grid>
              ))}
              
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </Stack>
  )
}
